import React, {useEffect, useState} from "react";
import ResponsiveTable from "../../components/ResponsiveTable";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import {WebService} from "../../components/WebService";
import Button from "../../components/CustomButtons/Button";
import * as Swal from "sweetalert2";
import {ArrowForward, DeleteForever} from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import {isHustDomain} from "../../components/Funcoes";

const useStyles = makeStyles(styles);

export default function ManagerClientes({history}) {
  const [clientes, setClientes] = useState([]);

  const getClientes = async () => {
    const {ok, clientes} = await WebService(`/cliente/getClientes`);
    if (ok) {
      setClientes(clientes);
    }
  };

  useEffect(() => {
    getClientes();
  }, []);

  const novoCliente = async () => {
    const {value: nome} = await Swal.fire({
      icon: "question",
      title: "Qual o nome do cliente?",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Cadastrar",
      input: "text"
    });
    if (nome) {
      const {ok} = await WebService(`/cliente/novoCliente`, {nome}, "POST");
      if (ok) {
        await Swal.fire({icon: "success", title: "Cliente cadastrado com sucesso!"});
        getClientes();
      }
    }
  };

  const excluirCliente = async id_cliente => {
    const message = ` Esta ação é irreversível! Se confirmado você irá exlcuir o cliente do sistema.`

    const {value} = await Swal.fire({
      title: 'Confirma esta ação?',
      text: message,
      icon: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: true,
      confirmButtonText: 'Confirma',
      cancelButtonText: 'Cancela',
      reverseButtons: true
    });
    if (value === true) {
      const {ok, mensagem_usuario} = await WebService(`/cliente/excluirCliente`, {id_cliente});
      if (ok) {
        await Swal.fire({
          title: 'Cliente excluído com sucesso!',
          icon: 'success',
          showConfirmButton: false,
          timer: 2500
        });
        await getClientes();
      } else {
        await Swal.fire({
          title: 'Erro ao Executar Operação',
          icon: 'error',
          text: mensagem_usuario,
          showConfirmButton: false,
          timer: 2500
        });
      }
    }
  }

  const actions = [
    {
      active: true,
      icon: 'person_add',
      action: novoCliente.bind(this),
      label: 'Novo Cliente'
    }
  ];

  const acessarContaCliente = async id_cliente => {
    const {ok, token, flag_revenda, nome} = await WebService(`/login/getToken`, {id_cliente});
    if (ok) {
      const dadosAnterior = JSON.stringify(localStorage);
      const localConfigHost = localStorage.getItem('localConfigHost');
      localStorage.clear();
      delete localConfigHost['dadosAnterior'];
      localStorage.setItem('localConfigHost', localConfigHost);
      localStorage.setItem('dadosAnterior', dadosAnterior);
      localStorage.setItem('usuario_gestor', 'true');
      localStorage.setItem('cliente_revenda', flag_revenda);
      localStorage.setItem('nome_revenda', nome);
      localStorage.setItem('token', token);
      localStorage.setItem('@theme-color', JSON.parse(dadosAnterior)['@theme-color'])
      window.location.href = '/dashboard';
    }
  };

  const liberacaoAcesso = async (id_cliente, flag_ativo) => {
    const {ok} = await WebService(`/cliente/liberacaoCliente`, {id_cliente, flag_ativo}, 'POST');
    if(ok) {
      await Swal.fire({icon: "success", title: `Cliente ${flag_ativo ? 'ativado' : 'bloqueado'} com sucesso!`});
      await getClientes();
    }
  };

  const classes = useStyles();

  return (
    <GridContainer>
      <GridItem lg={12} md={12} sm={12} xs={12}>
        <Card>
          <CardBody>
            <ResponsiveTable
              activeImpression={false}
              actions={isHustDomain ? [] : actions}
              colunas={["#", "Nome", "Total usuários", "Total conexões", "Liberação", "Opções"]}
              dados={clientes.map(prop => {
                const {id_cliente, nome, total_conexoes, total_usuarios, flag_ativo} = prop;
                return [
                  id_cliente,
                  nome,
                  total_usuarios,
                  total_conexoes,
                  (
                    <FormControlLabel
                      style={{fontSize: '10px', color: '#8c8c8c'}}
                      label={
                        <Typography style={{fontSize: '14px', float: 'left'}}>
                          Acesso {flag_ativo ? 'liberado' : 'bloqueado'}
                        </Typography>
                      }
                      control={
                        <Switch
                          checked={flag_ativo}
                          onChange={liberacaoAcesso.bind(this, id_cliente, !flag_ativo)}
                          // onClick={liberacaoAcesso.bind(this)}
                          classes={{
                            switchBase: classes.switchBase,
                            checked: classes.switchChecked,
                            thumb: classes.switchIcon,
                            track: classes.switchBar
                          }}
                        />
                      }
                    />
                  ),
                  (
                    <>
                      <Tooltip title={"Acessar cliente"}>
                        <Button color={"primary"} justIcon round simple onClick={() => acessarContaCliente(id_cliente)}>
                          <ArrowForward/>
                        </Button>
                      </Tooltip>
                      {total_usuarios === 0 && total_conexoes == 0 ? (
                        <Tooltip title={'Excluir Cliente'}>
                          <Button color={"danger"} justIcon round simple onClick={() => excluirCliente(id_cliente)}>
                            <DeleteForever/>
                          </Button>
                        </Tooltip>
                      ) : (<></>)
                      }
                    </>
                  )
                ];
              })}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
