import React from 'react';
import { CardBody } from "../../../../components";
import RemoveNode from "../../components/RemoveNode";

const Header = ({icon, title, subtitle, id}) => {
  return (
    <CardBody style={{padding: 5, display: 'flex'}}>
      <div
        style={{
          width: 50,
          height: 50,
          background: `linear-gradient(135deg, #FF3115 0%, #FC2E5D 100%)`,
          borderRadius: 14,
          alignContent: 'center',
          textAlign: "center",
          verticalAlign: 'center',
          position: "relative",
        }}
      >
        <img
          src={icon}
          alt={title}
          style={{
            width: 25,
            top: "50%",
            left: "50%",
            position: "absolute",
            transform: "translate(-50%,-50%)"
          }}
        />
      </div>
      <div style={{ marginLeft: 10, marginTop: 4, fontFamily: 'NunitoRegular' }}>
        <span style={{ color: '#1A1926', fontSize: 18, fontWeight: 600 }}>{title}</span><br/>
        <span style={{ color: '#637085', fontSize: 15 }}>{subtitle}</span>
      </div>
      {id && <RemoveNode id={id}/>}
    </CardBody>
  );
}

export default Header;