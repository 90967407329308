import React, {useState, useEffect, useRef} from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {
  Box, Paper, Divider, Tooltip, Modal, Fade, Backdrop, InputBase, Select, IconButton, MenuItem, ListItemText,
  // Button
} from "@material-ui/core";
import {
  Person,
  Phone,
  Wc,
  Mail,
  FileCopy,
  Save,
  PhoneAndroid,
  PhoneAndroidOutlined,
  Close,
  Search,
} from "@material-ui/icons";
import {useStyles, SelectInput} from "./styles";
import Swal from "sweetalert2";
import {
  hexToRgb,
  hustColorSecondary,
  primaryColor,
  successColor
} from "../../../assets/jss/material-dashboard-pro-react";
import { WebService } from "../../../components/WebService";
import Button from '../../../components/CustomButtons/Button';
import {convertDate, ls} from "../../../components/Funcoes";
import GridItem from "../../../components/Grid/GridItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import GridContainer from "../../../components/Grid/GridContainer";
import api from "../../../components/api";
import ReactTags from 'react-tag-autocomplete';

export default function Perfil({
        nome,
        telefone,
        protocolo,
        onChangeName,
        onSaveName,
        conexao,
        ultimosAtendimentos,
        idContato,
        isGroup,
        grupos,
        getTags,
        tags,
        connections,
        conexaoData,
        onChangeConnection
    }
) {
  const classes = useStyles();
  const reactTags = useRef();

  const [open, setOpen] = useState(false);
  const handleModal = () => setOpen(!open);
  const [nomeCliente, setNomeCliente] = useState("");

  const [departamento, setDepartamento] = useState("");
  const handleChange = ({target: {value}}) => setDepartamento(value);

  const [tagsCalled, setTagsCalled] = useState([]);
  const createTag = async ({name}) => {
    const {data: tag} = await api.post('/tag', {name});
    if (getTags) await getTags();
    const {data: tags} = await api.put(`/called/${protocolo}/tag`, {idTag: tag.id});
    setTagsCalled(tags);
  };

  const removeTag = async tagKey => {
    const tag = tagsCalled[tagKey];
    const {data} = await api.delete(`/called/${protocolo}/tag`, {params: {id: tag.id}});
    setTagsCalled(data);
  };

  const getNomes = async () => {
    const { ok, nome, nomeCliente } = await WebService(`/usuario/getDadosEmpresa`);
    if (ok){
      setNomeCliente(nomeCliente);
    }
  }

  const changeConnection = async () => {
    const conexoesTemp = {};
    for (let i = 0; i < connections.length; i++) {
      const { uuid, conta_nome } = connections[i];
      conexoesTemp[uuid] = conta_nome;
    }
      const { value: conexao } = await Swal.fire({
          title: 'Escolha a conexão para realizar o envio',
          input: 'select',
          inputOptions: conexoesTemp,
          inputPlaceholder: 'Selecione a conexão',
          inputValue: conexaoData.uuid,
          showCancelButton: false,
          allowOutsideClick: false,
          inputValidator: value => value ? "" : "Selecione uma conexão para enviar a mensagem"
      });
      if (!conexao) return false;

      console.log(conexao);
  };

  const [definirGrupo, setDefinirGrupo] = useState({departamento: '', usuario: ''});

  const getCalledTags = async () => {
    const {data} = await api.get(`/called/${protocolo}/tag`);
    setTagsCalled(data);
  };

  useEffect(() => {
    if(protocolo) getCalledTags();
  }, [protocolo]);

  useEffect(() => {
    const group = grupos.find(el => el.id_grupo_whatsapp === idContato);
    setDefinirGrupo({departamento: group ? group.id_department_fk : '', usuario: group ? group.id_user_fk : ''});
  }, [idContato]);

  const [usuarios, setUsuarios] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);

  const getDepartamentos = async () => {
    const {ok, departamentos, departamentosUsuario} = await WebService(`/departamento/getDepartamentos`);
    if (ok) {
      const tempDepartamentos = [];
      const getChild = (d, n) => {
        d.map(prop => {
          const {nome, id_departamento, subDepartamentos} = prop;
          if (subDepartamentos) getChild(subDepartamentos, `${n ? n + ' -> ' : ''}${nome}`);
          else tempDepartamentos.push({nome: `${n ? n + ' -> ' : ''}${nome}`, id_departamento});
        });
      };
      getChild(departamentos);
      setDepartamentos(tempDepartamentos);
    }
  };

  useEffect(() => {
    getUsuarios();
    getDepartamentos();
  }, []);

  const getUsuarios = async () => {
    const {ok, usuarios} = await WebService(`/usuario/getUsuarios`);
    if (ok) setUsuarios(usuarios);
  };

  const [dadosContato, setDadosContato] = useState([]);
  const [nomeCampo, setNomeCampo] = useState('');
  const [valorCampo, setValorCampo] = useState('');

  const getDadosContato = async (id_contato) => {
    const {ok, contato_dado} = await WebService(`/contato/getDadosContato`, {id_contato});
    if (ok) setDadosContato(contato_dado);
  };

  useEffect(() => {
    if (!idContato) return;
    setDadosContato([]);
    if (!isGroup) getDadosContato(idContato);
  }, [idContato]);

  useEffect(() => {
    setNomeCampo('');
    setValorCampo('');
  }, [open]);

  return (
    <>
      <Box className={classes.boxInfoTitle}>
        <h4>PERFIL</h4>
        <hr className="lineTop"/>
      </Box>
      <div className={classes.form}>
        <p className="label">Nome</p>
        <Paper className="formInfo">
          <InputBase
            className="inputFormInfo"
            placeholder="Nome"
            value={nome}
            onChange={({target: {value}}) => onChangeName(value)}
          />
          <Divider className="dividerInfo" orientation="vertical"/>
          <Tooltip title={"Salvar"}>
            <IconButton
              style={{color: primaryColor[0]}}
              onClick={onSaveName}
            >
              <Save/>
            </IconButton>
          </Tooltip>
        </Paper>
      </div>
      {!isGroup ? (
        <div className={classes.form}>
          <p className="label">Telefone</p>
          <Paper className="formInfo">
            <InputBase
              className="inputFormInfo"
              disabled
              placeholder={"Telefone"}
              value={telefone}
            />
            <Divider className="dividerInfo" orientation="vertical"/>
            <span className="iconButtonInfo">
              <Phone/>
          </span>
          </Paper>
        </div>
      ) : ''}
      {!isGroup ? (
        dadosContato.map((prop, key) => {
          // id_contato_dado: 1
          return (
            <div className={classes.form} key={prop.id_contato_dado}>
              <p className="label">{prop.dado_nome}</p>
              <Paper className="formInfo">
                {/*<span className="iconButtonInfo">
                <Save style={{color: primaryColor[0]}}/>
              </span>
              <Divider className="dividerInfo" orientation="vertical"/>*/}
                <InputBase
                  className="inputFormInfo"
                  placeholder={prop.dado_nome}
                  defaultValue={prop.dado_valor}
                  onChange={({target: {value}}) => setDadosContato(dc => {
                    dc[key].novoValor = value;
                    return [...dc];
                  })}
                />
                <Divider className="dividerInfo" orientation="vertical"/>
                <Tooltip title={"Salvar"}>
                  <IconButton
                    style={{
                      color: prop.novoValor && prop.dado_valor !== prop.novoValor ? successColor[0] : primaryColor[0],
                      paddingLeft: 2,
                      paddingRight: 2
                    }}
                    onClick={async () => {
                      if (!(prop.novoValor && prop.dado_valor !== prop.novoValor)) return;
                      const {ok} = await WebService(`/contato/alterarDadoContato`, {
                        id_contato_dado: prop.id_contato_dado,
                        valor: prop.novoValor
                      });
                      if (ok) {
                        setDadosContato(dc => {
                          dc[key].dado_valor = dc[key].novoValor;
                          delete dc[key].novoValor;
                          return [...dc];
                        });
                        await Swal.fire({icon: "success", title: 'Informação alterada com sucesso!'});
                      }
                    }}
                  >
                    <Save/>
                  </IconButton>
                </Tooltip>
                <Tooltip title={"Excluir"}>
                  <IconButton
                    style={{color: primaryColor[0], paddingLeft: 2, paddingRight: 2}}
                    onClick={async () => {
                      const {value} = await Swal.fire({
                        icon: "question",
                        title: 'Deseja realmente excluir a informação do usuário?',
                        showCancelButton: true,
                        cancelButtonText: "Não",
                        confirmButtonText: "Sim"
                      });
                      if (value !== true) return;

                      const {ok} = await WebService(`/contato/excluirDadoContato`, {id_contato_dado: prop.id_contato_dado});
                      if (ok) {
                        await Swal.fire({icon: "success", title: 'Informação do contato excluída com sucesso!'});
                        getDadosContato(idContato);
                      }
                    }}
                  >
                    <Close/>
                  </IconButton>
                </Tooltip>
              </Paper>
            </div>
          );
        })
      ) : ''}

      {/*<div className={classes.form}>
        <p className="label">Gênero</p>
        <Paper className="formInfo">
          <InputBase
            className="inputFormInfo"
            placeholder="Nome"
            defaultValue="Feminino"
          />
          <Divider className="dividerInfo" orientation="vertical"/>
          <span className="iconButtonInfo">
              <Wc/>
            </span>
        </Paper>
      </div>*/}
      {!isGroup ? (
        <div className={classes.form}>
          <center>
            <Button onClick={handleModal} color={'hust'} round>
              Adicionar novas informações
            </Button>
          </center>
        </div>
      ) : ''}
      {/**************************************************************************************************************/}
      {!isGroup ? (
        <>
          <Box className={classes.boxInfoTitle}>
            <h4>ATENDIMENTO</h4>
            <hr className="lineTop"/>
          </Box>
          <div className={classes.form}>
            <p className="label">Protocolo</p>
            <Paper className="formInfo">
              <InputBase
                className="inputFormInfo"
                placeholder={"Protocolo"}
                value={protocolo || ""}
                disabled
              />
              <Divider className="dividerInfo" orientation="vertical"/>
              <Tooltip title="Copiar para área de transferência" placement="top" className="iconButtonInfo">
                <CopyToClipboard
                  text={protocolo}
                  onCopy={() => {
                    console.log("----------");
                    const Toast = Swal.mixin({
                      toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, timerProgressBar: true
                    });
                    Toast.fire({icon: 'success', title: 'Protocolo copiado com sucesso!'});
                  }}
                >
                  <IconButton title="Copiar protocolo">
                    <FileCopy/>
                  </IconButton>
                </CopyToClipboard>
              </Tooltip>
            </Paper>
          </div>
        </>
      ) : ''}
      {!isGroup ? (
      <div className={classes.form}>
        <p className="label">Conexão</p>
        <Paper className="formInfo">
          <Select
              value={conexaoData.id_whatsapp_conexao}
              onChange={async ({ target: { value: connection } }) => {
                const { value } = await Swal.fire({
                  icon: "question",
                  title: 'Deseja alterar a conexão de contato com o cliente?',
                  text: 'O cliente começará a receber as mensagens por este novo número.',
                  showCancelButton: true,
                  cancelButtonText: 'Não',
                  confirmButtonText: 'Sim, trocar conexão!'
                });

                if (!value) return;

                onChangeConnection(connection);
              }}
              style={{ textAlign: 'left' }}
              fullWidth
          >
            {connections.map(prop => (
              // disabled={!prop.flag_ativo} // A rota já está retornando apenas os ativos
              <MenuItem key={prop.id_whatsapp_conexao} value={prop.id_whatsapp_conexao}>
                <ListItemText primary={prop.conta_nome} secondary={prop.conta_numero}/>
              </MenuItem>
            ))}
          </Select>
          {/*<InputBase
            className="inputFormInfo"
            placeholder={"Conexão"}
            value={conexao || ""}
            disabled
          />
          <Divider className="dividerInfo" orientation="vertical"/>
          <Button simple justIcon color={'primary'} round disabled>
              <PhoneAndroidOutlined/>
          </Button>
          <Tooltip title={'Trocar conexão'}>
            <Button simple justIcon color={'primary'} round onClick={changeConnection}>
              <PhoneAndroidOutlined/>
            </Button>
          </Tooltip>*/}
        </Paper>
      </div>
      ) : ''}
      {(!isGroup && protocolo) ? (
        <div className={classes.form}>
          <ReactTags
            allowNew
            newTagText={'Criar tag:'}
            removeButtonText={'Excluir essa tag'}
            placeholderText={'Tags'}
            ref={reactTags}
            minQueryLength={0}
            tags={tagsCalled}
            suggestions={tags}
            onDelete={removeTag}
            onAddition={createTag}
          />
        </div>
      ) : ''}
      {/**************************************************************************************************************/}
      {(isGroup && (!!JSON.parse(ls('usuario_gestor') || false))) ? (
        <>
          <Box className={classes.boxInfoTitle}>
            <h4>Configurações</h4>
            <hr className="lineTop"/>
          </Box>
        <GridContainer style={{margin: 0, width: '100%', marginTop: 10}}>
          <GridItem lg={12} md={12} sm={12} xs={12}>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel>Departamento</InputLabel>
              <Select
                value={definirGrupo.departamento}
                onChange={({ target: { value } }) => {
                  setDefinirGrupo({departamento: value, usuario: ''});
                }}
                style={{ textAlign: 'left' }}
              >
                <MenuItem value={''} key={`dpt_vazio`}>Nenhum</MenuItem>
                {departamentos.map(prop => {
                  const { nome, id_departamento } = prop;
                  return (<MenuItem value={id_departamento} key={`dpt_${id_departamento}`}>{nome}</MenuItem>);
                })}
              </Select>
            </FormControl>
          </GridItem>
          <p>&nbsp;</p>
          {!!definirGrupo.departamento && (
            <GridItem lg={12} md={12} sm={12} xs={12}>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel>Usuário</InputLabel>
                <Select
                  value={definirGrupo.usuario}
                  onChange={({ target: { value } }) => setDefinirGrupo({
                    ...definirGrupo,
                    usuario: value
                  })}
                  style={{ textAlign: 'left' }}
                >
                  <MenuItem value={''} key={`usr_vazio`}>Nenhum</MenuItem>
                  {usuarios.filter(el => el.id_departamento_fk && !el.me && el.id_departamento_fk.split(",").map(prop => parseInt(prop)).indexOf(definirGrupo.departamento) > -1).map(prop => {
                    const { nome, id_usuario } = prop;
                    const sort = [...usuarios].sort((a, b) => a.nome.localeCompare(b.nome));
                    console.log(sort);

                    return (<MenuItem value={id_usuario} key={`usr_${id_usuario}`}>{nome}</MenuItem>);
                  })}
                </Select>
              </FormControl>
            </GridItem>
          )}
          <Button
            color={'hust'}
            fullWidth
            style={{width: 'calc(100% - 20)', marginLeft: 10, marginRight: 10}}
            onClick={async () => {
              const body = {department: definirGrupo.departamento, user: definirGrupo.usuario, id: idContato};
              await api.put('/group/department', body);
              const keyGroup = grupos.map(prop => prop.id_grupo_whatsapp).indexOf(idContato);
              grupos[keyGroup].id_department_fk = definirGrupo.departamento || null;
              grupos[keyGroup].id_user_fk = definirGrupo.usuario || null;
              await Swal.fire({icon: "success", title: 'Dados alterados com sucesso!'});
            }}
          >
            Salvar departamento
          </Button>
        </GridContainer>
        </>
      ) : ''}

      {/**************************************************************************************************************/}
      {/*<div className={classes.form}>
        <p className="label">Departamento padrão para chamados</p>
        <Select
          value={departamento}
          onChange={handleChange}
          input={<SelectInput/>}
        >
          <MenuItem value=""><em>None</em></MenuItem>
          <MenuItem value="vendas">Vendas</MenuItem>
          <MenuItem value="suporte">Suporte</MenuItem>
          <MenuItem value="financeiro">Financeiro</MenuItem>
        </Select>
      </div>*/}

      {/* Modal */}
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paperModal}>
            <Box className={classes.boxInfoTitle}>
              <h4>Nova informação</h4>
              <p>Crie uma nova informação para o perfil do cliente</p>
              {/* <hr className="lineTop"/> */}
            </Box>
            <div className={classes.form}>
              <p className="label">Nome do Campo</p>
              <Paper className="formInfo">
                <InputBase
                  autoComplete={`disable-${new Date().getTime()}`}
                  className="inputFormInfo"
                  placeholder="Ex: Empresa"
                  onChange={({target: {value}}) => setNomeCampo(value)}
                />
              </Paper>
            </div>
            <div className={classes.form}>
              <p className="label">Resposta para o campo criado acima</p>
              <Paper className="formInfo">
                <InputBase
                  autoComplete={`disable-${new Date().getTime()}`}
                  className="inputFormInfo"
                  placeholder="Ex: Supermercado Sul"
                  onChange={({target: {value}}) => setValorCampo(value)}
                />
              </Paper>
            </div>
            <div className="button">
              <Button variant="contained" color={'github'} onClick={async () => setOpen(false)}>
                Cancelar
              </Button>
              <Button
                variant="contained"
                color={'primary'}
                onClick={async () => {
                  const data = {
                    id_contato: idContato,
                    nome: nomeCampo,
                    valor: valorCampo
                  };
                  const {ok} = await WebService(`/contato/adicionarDadoContato`, data, 'POST');
                  if (ok) getDadosContato(idContato);
                  setOpen(false);
                }}
              >
                SALVAR
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
      {/*<center className={classes.brand} style={{}}> <b style={{}} className={classes.marca}>{nomeCliente}</b><br/>LICENCIADO</center>*/}
    </>
  )
}
