import React, { useEffect, useState } from 'react';
import api from "../components/api";
import { Card, CardBody, GridContainer } from "../components";
import CustomInput from "../components/CustomInput/CustomInput";
import GridItem from "../components/Grid/GridItem";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import { FormControlLabel, makeStyles, Tooltip } from "@material-ui/core";
import { useStyles } from "./NewChat/TabsInfo/styles";
import styles from "../assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import Button from "../components/CustomButtons/Button";
import Swal from "sweetalert2";

const useStyles1 = makeStyles(styles);

const groupMessages = [
  'mensagem_inicio_chamado',
  'mensagem_inicio_chamado_imagem',
  'mensagem_finalizacao_chamado',
  'mensagem_fila_atendimento',
  'mensagem_assumir_atendimento',
  'mensagem_cabecalho_escolha_departamento',
  'mensagem_opcao_menu_invalida',
  'mensagem_retorno_ligacao',
  'direcionar_departamento_opcao_invalida',
  'direcionar_departamento_opcao_invalida_mensagem',
  'tempo_transferencia_automatica_departamento',
  'departamento_transferencia_inatividade',
  'feature_send_user_profile_pic_start_called',
  'flag_send_transfer_message',
  'flag_notificate_group_messages',
  'flag_message_change_connection',
];

const Configurations = () => {
  const classes = { ...useStyles(), ...useStyles1() };

  const [ config, setConfig ] = useState({});
  const getConfigs = async () => {
    const { data } = await api.get('/application/config');
    setConfig(data);
  };

  const saveConfig = async () => {
    const newConfig = {};
    Object.keys(config).filter(el => groupMessages.includes(el)).forEach(slug => {
      newConfig[slug] = config[slug].value;
    });
    await api.put('/application/config', newConfig);

    Swal.fire({icon: "success", title: 'Configurações alteradas com sucesso!'});
  };

  useEffect(() => {
    getConfigs();
  }, []);

  return (
    <GridContainer>
      <GridItem lg={12} md={12} sm={12} xs={12}>
        <Card>
          <CardBody>
            <GridContainer>
              {Object.keys(config).filter(el => groupMessages.includes(el)).map(slug => {
                const { description, value, type } = config[slug];
                if (type === 'boolean') {
                  return (
                    <GridItem lg={6} md={6} sm={12} xs={12} key={slug} style={{ position: 'relative' }}>
                      <FormControlLabel
                        className={classes.formCheck}
                        control={
                          <Checkbox
                            checked={JSON.parse(value)}
                            tabIndex={-1}
                            onClick={() => {
                              setConfig(config => {
                                config[slug].value = (!JSON.parse(value)).toString();
                                return { ...config };
                              });
                            }}
                            checkedIcon={<Check className={classes.checkedIcon}/>}
                            icon={<Check className={classes.uncheckedIcon}/>}
                            classes={{ checked: classes.checked, root: classes.root }}
                          />
                        }
                        label={description}
                      />
                    </GridItem>
                  );
                }
                if (type === 'number') {
                  return (
                    <GridItem lg={6} md={6} sm={6} xs={12} key={slug} style={{ position: 'relative' }}>
                      <CustomInput
                        labelText={description}
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          type: "number",
                          onChange: ({ target }) => setConfig(config => {
                            config[slug].value = target.value;
                            return { ...config };
                          }),
                          value
                        }}
                      />
                    </GridItem>
                  )
                }
                return (
                  <GridItem lg={6} md={6} sm={12} xs={12} key={slug} style={{ position: 'relative' }}>
                    <Tooltip title={'Redefinir para o valor padrão'}>
                      <Button
                        style={{ position: 'absolute', zIndex: 9999, bottom: 10, right: 20 }}
                        color={'info'}
                        round
                        justIcon
                        simple
                        onClick={() => {
                          setConfig(config => {
                            config[slug].value = config[slug].defaultValue;
                            return { ...config };
                          });
                        }}
                      >
                        <Check/>
                      </Button>
                    </Tooltip>
                    <CustomInput
                      labelText={description}
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        multiline: true,
                        rows: 5,
                        value,
                        onChange: ({ target: { value } }) => setConfig(config => {
                          config[slug].value = value;
                          return { ...config };
                        })
                      }}
                    />
                  </GridItem>
                );
              })}
              <GridItem lg={12} md={12} sm={12} xs={12}>
                <br/><br/>
                <Button color={'primary'} onClick={saveConfig}>
                  Salvar
                </Button>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

export default Configurations;