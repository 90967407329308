import React, {memo, useEffect, useState} from 'react';
import {Handle, Position} from 'reactflow';
import {Card, CardBody} from "../../../components";
import {MessageOutlined} from "@material-ui/icons";
import RemoveNode from "../components/RemoveNode";

function createTAG({id, data}) {
  const [text, setText] = useState(data.text || '');

  useEffect(() => {
    data.text = text;
  }, [text])

  return (
    <Card
      style={{
        margin: 0,
        border: '1px solid #E49D22',
        minWidth: 150,
        // maxWidth: 300,
        borderRadius: 20,
        boxShadow: '#E49D22 0px 0px 20px -10px',
        padding: 5
      }}
    >
      <RemoveNode id={id}/>
      <CardBody style={{padding: 5, display: 'flex'}}>
        <div
          style={{
            color: '#E49D22',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: 'calc(25% - 80px)',
            marginRight: 10
          }}
        >
          <MessageOutlined/>
        </div>
        <div>
          <span style={{fontWeight: 'bold', fontSize: 13}}>
            Adicionar TAG
          </span>
          <br/>
          <input
            type={'text'}
            style={{
              resize: 'none',
              width: 250,
              // height: 150,
              borderRadius: 10
            }}
            placeholder={'Digite o nome da TAG'}
            onChange={({target: {value}}) => setText(value)}
            value={text}
          />
        </div>
      </CardBody>

      <Handle type="target" position={Position.Left} className="w-16 !bg-teal-500"/>
      <Handle type="source" position={Position.Right} className="w-16 !bg-teal-500"/>
    </Card>
  );
}

export default memo(createTAG);
