import SheetIcon from "../../../../assets/img/icons/files/XLS.png";
import DocIcon from "../../../../assets/img/icons/files/DOC.png";
import FigmaIcon from "../../../../assets/img/icons/files/Figma.png";
import ImageIcon from "../../../../assets/img/icons/files/JPG.png";
import PDFIcon from "../../../../assets/img/icons/files/PDF.png";
import ZIPIcon from "../../../../assets/img/icons/files/ZIP.png";
import PSDIcon from "../../../../assets/img/icons/files/PSD.png";
import {converterTextoWpp, downloadFileAsBlob, fetchAsBlob, removeAttendanceNameFromMessage} from "../../../Funcoes";
import UnknowIcon from "../../../../assets/img/icons/files/Unknown.png";
import {Download} from "../../../../assets/img/icons";
import React from "react";
import {useStyles} from "./Message";
import MessageBoxText from "./MessageBoxText";
import CustomLinearProgress from "../../../CustomLinearProgress/CustomLinearProgress";

export const fileIconByExtension = {
  xls: SheetIcon,
  csv: SheetIcon,
  xlsx: SheetIcon,
  ods: SheetIcon, // OpenDocument Spreadsheet
  doc: DocIcon,
  docx: DocIcon,
  txt: DocIcon, // Text files can use the DocIcon
  rtf: DocIcon, // Rich Text Format files can use the DocIcon
  fig: FigmaIcon,
  jpg: ImageIcon,
  png: ImageIcon,
  svg: ImageIcon,
  jpeg: ImageIcon,
  gif: ImageIcon, // GIF image files can use the ImageIcon
  bmp: ImageIcon, // BMP image files can use the ImageIcon
  tiff: ImageIcon, // TIFF image files can use the ImageIcon
  pdf: PDFIcon,
  zip: ZIPIcon,
  rar: ZIPIcon,
  '7z': ZIPIcon,
  gz: ZIPIcon, // GZipped files can use the ZIPIcon
  tar: ZIPIcon, // TAR archive files can use the ZIPIcon
  psd: PSDIcon,
};

const MessageBoxDocument = ({identifier, fileName, caption, user, mimetype, progress}) => {
  function getFileExtension(fileName, mimetype) {
    let rawExtension = (fileName || mimetype || '');
    if (typeof rawExtension !== 'string') return '';

    let splitFileName = rawExtension.split('.');
    let splitMimetype = rawExtension.split('/');

    let extension = '';

    if (splitFileName.length > 1) extension = splitFileName.pop();
    else if (splitMimetype.length > 1) extension = splitMimetype.pop();

    return extension.toLowerCase();
  }

  const classes = useStyles();

  const renderedMessage = converterTextoWpp(removeAttendanceNameFromMessage(caption, user && user.name), true);

  const fileExtension = getFileExtension(fileName, mimetype);

  const downloadFile = async () => downloadFileAsBlob(await fetchAsBlob(`https://storage.hustapp.com/midias/${identifier}`), fileName || `download.${fileExtension}`);

  return (
    <div>
      <div style={{display: 'flex', cursor: 'pointer'}} onClick={downloadFile}>
        <img
          src={fileIconByExtension[fileExtension] || UnknowIcon}
          alt={fileExtension}
          className={classes.MessageBoxFileIcon}
        />
        <div style={{alignSelf: 'center'}}>
          <span className={classes.MessageBoxFileName}>
            {fileName}
          </span>
          {/*<br/>
          <span style={{marginLeft: 5, fontFamily: 'NunitoRegular', fontWeight: 400, color: '#637085', fontSize: 15}}>
            256 KB
          </span>*/}
        </div>
        <img src={Download} alt={'download'} style={{marginLeft: 10}}/>
      </div>
      {caption ? (
        <>
          <br/>
          <MessageBoxText body={caption} user={user && user.name}/>
        </>
      ) : ''}
      {progress ? (
        <div style={{display: 'flex'}}>
            <CustomLinearProgress
              style={{marginTop: 10, width: 'calc(100% - 30px)', marginBottom: 10}}
              color={'primary'}
              value={progress}
              variant={'buffer'}
              valueBuffer={progress}
            />
          <span style={{fontSize: 13, color: '#FFF', marginLeft: 5}}>
            {progress.toFixed(0)}%
          </span>
        </div>
      ) : ''}
    </div>
  );
};

export default MessageBoxDocument;