import React, { useEffect, useState } from 'react';
import {Backdrop, Fade, Modal} from "@material-ui/core";
import {useStyles} from "../NewChat/TabsInfo/styles";
import Button from '../../components/CustomButtons/Button';
import { baseURLV2 } from "../../components/api";
import {checkBeta} from "../BetaControll";
import { AutoAwesome } from "../../assets/img/icons";
import DotLoader from "react-spinners/DotLoader";
import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";

const ModalEndCalled = ({open, onClose, onCalledEnd, id}) => {
  const classes = useStyles();

  const [ text, setText ] = useState('');
  const [ buttonDisabled, setButtonDisabled ] = useState(false);

  useEffect(() => {
    setText('');
  }, [open]);

  const getCalledResume = async () => {
    try {
      setText('');
      setButtonDisabled(true);
      // Swal.fire({title: "Carregando", allowOutsideClick: false});
      // Swal.showLoading();

      const response = await fetch(`${baseURLV2}/called/resume/${id}`, {
        method: 'GET',
        headers: {
          Accept: 'text/event-stream',
          token: localStorage.getItem('token'),
          authorization: `Bearer ${localStorage.getItem('token')}`
        },
      });

      const reader = response.body.getReader();

      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          break;
        }

        const streamData = new TextDecoder().decode(value);
        try {
          const [ , json ] = streamData.split('data: ');
          const data = JSON.parse(json);
          setText((data.resume || data.text || '').trim());
        } catch (e) {

        }
      }
    } finally {
      setButtonDisabled(false);
    }
    // setText(resume);
    // Swal.hideLoading();
    // Swal.close();
  };

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{timeout: 200}}
    >
      <Fade in={open}>
        <div className={classes.paperModal} style={{border: 'none', minWidth: 600}}>
          <h4 style={{fontFamily: 'NunitoRegular', fontSize: 30, marginBottom: 0, color: primaryColor[0]}}>Encerrar
            conversa {id}
          </h4>
          <hr style={{
            borderBottom: `4px solid ${primaryColor[0]}`,
            width: '40%',
            float: 'left',
            marginTop: 0,
            marginBottom: 0
          }}/>
          <br/>
          <span style={{fontFamily: 'NunitoRegular', color: '#222'}}>
            * Deseja realmente finalizar este atendimento?<br/>
            * Você também pode escrever um resumo no final do atendimento<br/>
            * Essa mensagem não é obrigatória.
          </span>
          <br/><br/>
          <div style={{position: 'relative'}}>
          <span
            style={{
              position: 'absolute',
              left: 20,
              top: -7,
              background: '#FFF',
              paddingLeft: 10,
              paddingRight: 10,
              fontFamily: 'NunitoRegular',
              color: '#333',
              fontSize: 11
            }}
          >
            Relate sobre a conversa
          </span>
            <textarea
              rows="7"
              cols="2"
              onChange={({ target: { value } }) => setText(value)}
              style={{
                width: '100%',
                border: `2px #777 solid`,
                borderRadius: 10,
                resize: 'none',
                paddingLeft: 5,
                paddingRight: 5,
                paddingTop: 10,
                // color: 'rgba(112, 124, 151, 0.5)',
                fontFamily: 'NunitoRegular',
              }}
              value={text}
              placeholder={'Ex.: No atendimento o contato solicitou um orçamento que foi enviado e após 2 dias houve a finalização da venda.'}
            ></textarea>
          </div>
          <div
            style={{
              marginTop: 10,
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Button style={{borderRadius: '24px 24px 24px 0px'}} onClick={onClose}>
              Cancelar
            </Button>
            <div>
              {checkBeta('ia-resume') && (
                <Button
                  color={'github'}
                  style={{ borderRadius: '0px 24px 24px 24px' }}
                  disabled={buttonDisabled}
                  onClick={() => getCalledResume()}
                >
                  {buttonDisabled ? (
                    <DotLoader
                      size={15}
                      color={'#FFF'}
                      loading={true}
                      css={`margin-right: 10px`}
                    />
                  ) : <img src={AutoAwesome} alt={'download'} style={{ marginRight: 10, height: 20 }}/>}
                  Resumo com I.A.
                </Button>
              )}
              {/*<Button
                color={'github'}
                style={{borderRadius: '0px 24px 24px 24px'}}
                onClick={() => onCalledEnd(text || null, true)}
              >
                Iniciar Finalização
              </Button>*/}
              <Button color={'hust'} round onClick={() => onCalledEnd(text || null)}>
                Encerrar
              </Button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default ModalEndCalled;