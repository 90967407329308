import React, { memo, useEffect, useState } from 'react';
import { Handle, Position } from 'reactflow';
import { Card, CardBody } from "../../../components";
import RemoveNode from "../components/RemoveNode";
import Header from "./components/Header";
import { Time } from "../../../assets/img/icons";
import TextBox from "./components/Input/TextBox";

function Timeout({ id, data, selected }) {
  const [ time, setTime ] = useState(data.time || 1);

  useEffect(() => {
    data.time = time;
  }, [ time ]);

  return (
    <Card
      style={{
        margin: 0,
        width: 400,
        borderRadius: 14,
        boxShadow: selected ? '0px 50px 50px 0px rgba(4, 15, 21, 0.15), 0px 40px 30px -30px rgba(4, 15, 21, 0.15)' : '#08365A 0px 0px 20px -10px',
        padding: 5
      }}
    >
      <Header title={'Pausa'} subtitle={'Realiza uma pausa entre processamentos'} icon={Time} id={id} />
      <CardBody style={{padding: 5, display: 'flex'}}>
        <TextBox
          label={'Tempo'}
          value={Math.round(time / 1000)}
          onChange={value => {
            const newTime = parseFloat(value.toString());
            setTime((newTime > 0 ? newTime : 1) * 1000);
          }}
          type={'number'}
        />
      </CardBody>

      <Handle type="target" position={Position.Left} style={{ marginLeft: -3, background: '#E94D3F', width: 16, border: '3px solid #FFFFFF', height: 16 }}/>
      <Handle type="source" position={Position.Right} style={{ marginRight: -3, background: '#FFFFFF', width: 16, border: '2px solid #E94D3F', height: 16 }}/>
    </Card>
  );
}

export default memo(Timeout);
