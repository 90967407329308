import React, {useState, useRef} from 'react';
import {Grid, Box, Paper, Typography, IconButton, Dialog, DialogContent, DialogContentText} from "@material-ui/core";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import {Today, ConfirmationNumber, ArrowForwardIos, Close, Search} from '@material-ui/icons';
import {MessageList, SystemMessage} from "react-chat-elements";
import {useStyles} from "./styles";
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {fade, makeStyles} from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import {convertDate} from "../../../components/Funcoes";
import Tooltip from "@material-ui/core/Tooltip";
import Button from '../../../components/CustomButtons/Button';
import Swal from "sweetalert2";

export default function Historico({lastAttendances}) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = React.useState('paper');

  const handleModal = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleModalClose = () => {
    setOpen(false);
  }

  const descriptionElementRef = useRef(null);
  React.useEffect(() => {
    if (open) {
      const {current: descriptionElement} = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <>
      <Grid container direction="row" spacing={0} className={classes.rootGrid}>
        {/*<Grid item xs={12}>
          <Box className={classes.boxInfoTitle}>
            <h4>HISTÓRICO</h4>
            <hr className="lineTop"/>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.boxHistoryMain} style={{marginTop: '10px'}}>
            <Paper className={classes.paperHistoryMain}>
              <h3>23</h3>
              <p>ATENDIMENTOS</p>
            </Paper>

            <Paper className={classes.paperHistoryMain}>
              <h3>2039</h3>
              <p>MENSAGENS</p>
            </Paper>
          </Box>
        </Grid>*/}
        <Grid item xs={12}>
          <Box className={classes.boxInfoTitle} style={{marginTop: '-5px'}}>
            <h4>Registros</h4>
            <AppBar position="static" className={classes.app} style={{marginTop: '-50px', marginBottom: '-15px'}}>
              <Toolbar style={{height: '20px'}}>
                {/*<div className={classes.search}>
                  <div className={classes.searchIcon} style={{float: 'left', marginTop: '-2px', marginLeft: '230px'}}>
                    <SearchIcon/>
                  </div>

                  <InputBase
                    placeholder="Buscar..."
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    style={{
                      float: 'left',
                      marginLeft: '48px',
                      marginTop: '10px',
                      border: 'none',
                      background: 'transparent'
                    }}
                    inputProps={{'aria-label': 'search'}}
                  />
                </div>*/}
              </Toolbar>
            </AppBar>
            <hr className="lineTop"/>
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.h}>
          {(lastAttendances || []).map(prop => (
            <>
              <Box className={classes.boxRegister} style={{marginBottom: '-20px'}}>
                <Paper className={classes.paperRegister}>
                  <div style={{display: 'flex', width: '100%'}}>
                    <div style={{width: '50%'}}>
                      <strong>Protocolo</strong>
                      <h4 style={{marginBottom: 0}}>{prop.id_chamado}</h4>
                    </div>
                    <div style={{width: '50%'}}>
                      <strong>Data</strong>
                      <h4 style={{marginBottom: 0}}>{convertDate(prop.data)}</h4>
                    </div>
                    {prop.resume ? (
                      <Tooltip title={'Ver resumo'}>
                        <Button
                          style={{
                            position: 'absolute',
                            right: 15,
                            top: 'calc(50% - 25px)',
                          }}
                          color={'info'}
                          simple justIcon round
                          onClick={() => Swal.fire({
                            text: prop.resume,
                            showConfirmButton: false,
                            cancelButtonText: 'Fechar',
                            showCancelButton: true
                          })}
                        >
                          <Search/>
                        </Button>
                      </Tooltip>
                    ) : ''}
                  </div>
                  <div style={{display: 'flex', width: '100%'}}>
                    <div style={{width: '50%'}}>
                      <strong>Departamento</strong>
                      <h4>{prop.nome_departamento}</h4>
                    </div>
                    <div style={{width: '50%'}}>
                      <strong>Atendente</strong>
                      <h4>{prop.nome_usuario}</h4>
                    </div>
                    {/*<div style={{marginLeft: '10px'}}>
                      <ArrowForwardIos className={classes.iconButton} onClick={handleModal('paper')}/>
                    </div>*/}
                  </div>
                </Paper>
              </Box>
              <hr/>
            </>
          ))}
        </Grid>
      </Grid>
      {/* Modal Histórico da Conversa*/}
      <Dialog
        open={open}
        onClose={handleModal}
        fullWidth={true}
        maxWidth={"sm"}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <MuiDialogTitle disableTypography className={classes.root}>
          <h5>Histórico de Conversa | Protocolo: <span style={{color: '#2B87AE'}}>368724</span> | Atendente: <span
            style={{color: '#2B87AE'}}>Italo</span></h5>
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleModalClose}>
            <Close/>
          </IconButton>
        </MuiDialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <SystemMessage text={'Registro realizado no dia 29/02/2020'} className={classes.system}/>
            <MessageList
              className='message-list'
              lockable={true}
              toBottomHeight={'100%'}
              dataSource={[
                {
                  position: 'right',
                  type: 'text',
                  text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit',
                  date: new Date(),
                },
              ]}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  )
}
