import React from "react"
import './styles.css'

const NameSideBarSubtitle = ({name, subtitle}) => {

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {name}
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', position: 'absolute', top: -15 }}>
        <span className={'piscar'}>{subtitle}</span>
      </div>
    </div>)
}

export default NameSideBarSubtitle;
