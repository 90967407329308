import React from 'react';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';

export const customUseStyles = makeStyles(theme => ({
  root: {
    padding: 0
  },

  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    borderRadius: '10px'
  },

  iconButton: {
  },

  iconButtonRoot: {
    padding: 12,
    overflow: 'visible',
    fontSize: '1.5rem',
    textAlign: 'center',
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderRadius: '50%'
  },

  cardIconTitle: {
    color: '#48494a',
  },

  cardIconSubtitle: {
    color: '#8d8f91',
  },
  
  cardIcon: {
    height: '100px',
    width: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    
  },

  imageUpload: {
    display: 'flex',
    justifyContent: 'center'
  },

  importContacts: {
    justifyContent: 'center',
    alignItems: 'center',

    '& .svg': {
      fontSize: '36px',
      color: ''
    }
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  cardUser: {
    maxWidth: '600px',
  },

  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[900],
  },
  
  cardFooter: {
    justifyContent: 'space-between'
  }
 
}));
