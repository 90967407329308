import React, {useEffect, useState} from 'react';
import {CardBody, GridContainer} from "../../components";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import {pontuacaoNumero} from "../../components/Funcoes";
import CardIcon from "../../components/Card/CardIcon";
import Warning from "@material-ui/icons/Warning";
import CardFooter from "../../components/Card/CardFooter";
import GridItem from "../../components/Grid/GridItem";
import {makeStyles} from "@material-ui/core";
import dashboardStyle from "../../assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import {
  dangerBoxShadow,
  dangerColor,
  hexToRgb, hustColorPrimary, hustColorSecondary,
  infoColor,
  primaryColor, successBoxShadow, successColor, warningBoxShadow, warningColor
} from "../../assets/jss/material-dashboard-pro-react";
import AddToHomeScreen from "@material-ui/icons/AddToHomeScreen";
import {ListAlt} from "@material-ui/icons";
import SendIcon from "@material-ui/icons/Send";
import {Bar, defaults, Doughnut} from "react-chartjs-2";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "../../components/CustomButtons/Button";
import BusinessIcon from "@material-ui/icons/Business";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@material-ui/core";
import { checkBeta } from "../../views/BetaControll";
import api from "../../components/api";
import IllustrationSent from '../../assets/img/illustratioon-sent.webp';
import whatsappIcon from '../../assets/img/icons/whatsapp.webp';
import { isPartner } from 'utils/validateIsPartner';

defaults.global.defaultFontColor = "#000";

const useStyles = makeStyles(dashboardStyle);
const Dashboard = ({history}) => {
  const classes = useStyles();

  const [resume, setResume] = useState({connections: 0, sendingLists: 0, campaigns: 0, messages: 0});
  const [campaigns, setCampaigns] = useState([]);
  const [campaignsStatus, setCampaignsStatus] = useState({
    created: 0,
    scheduled: 0,
    running: 0,
    paused: 0,
    finished: 0
  });

  const getDashboard = async () => {
    if(!checkBeta('hust-envios')){
      setResume({connections: 4, sendingLists: 13, campaigns: 43, messages: 6306});
      setCampaignsStatus({ created: 1, scheduled: 2, running: 4, paused: 3, finished: 8 });
      setCampaigns([
        {name: 'Promoção aniversariantes do mês', percent: 92, total: 1235},
        {name: 'Prospecção cidade vizinha', percent: 84, total: 80},
        {name: 'Clientes que não compram a 45 dias', percent: 61, total: 865},
        {name: 'Aviso do evento dia 31 de fevereiro', percent: 23, total: 25},
      ]);
      return;
    }
    const {data} = await api.get(`/dashboard/bulkSend`);

    const {resume, campaigns, campaignsStatus} = data;
    setResume(resume);
    setCampaigns(campaigns);
    setCampaignsStatus(campaignsStatus);
  };

  const [modalNotificationOpen, setModalNotificationOpen] = useState(true);

  useEffect(() => {
    getDashboard();
  }, []);

  return (
    <GridContainer>
      {!checkBeta('hust-envios') ? (
          <Dialog
              open={modalNotificationOpen}
              onClose={() => ''}
              aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle style={{textAlign: 'center'}}>
              Disparos em massa personalizados e interativos!
            </DialogTitle>
            <DialogContent>
              <div style={{display: 'flex', flexDirection: 'column', gap: '8px', alignItems: 'center'}}>
              {!isPartner && (
                <img src={IllustrationSent} alt={'illustration-sent'} style={{ width: 'fit-content', height: 180 }} />

              )}
                <Typography style={{textAlign: 'center'}}>
                  Envie mensagens personalizadas para um grande número de pessoas de forma rápida e fácil.
                  Crie campanhas interativas que convertem leads em clientes. Automatize seus envios de mensagens
                  e economize tempo.
                </Typography>
                <Typography style={{textAlign: 'center', fontWeight: '400'}}>
                Conheça o <strong>{!isPartner && 'Hust'} Envios</strong> hoje mesmo!
                  <br/><br/>

                {isPartner ? (
                  <strong>Entre em contato e conheça mais</strong>)
                  : (
                    <div
                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                      onClick={() => window.open(`https://wa.me/5508008782884?text=Quero%20conhecer%20o%20Hust%20Envios`)}
                    >
                      <img src={whatsappIcon} style={{ width: 15, margin: `-2px 3px 0 0` }} alt={'whatsapp'} />
                      <strong>0800 878 2884</strong>
                    </div>
                  )}
                </Typography>
              </div>
            </DialogContent>
            <DialogActions style={{display: 'flex', justifyContent: 'center'}}>
              <Button color={'github'} round onClick={() => setModalNotificationOpen(false)}>
                Fechar
              </Button>
            {!isPartner && (
              <Button color={'hust'} round onClick={() => {
                window.open(`https://youtu.be/FeuFDdRHaDs?si=q94fxA0mU5lXd6xr`);
              }}>
                Ver vídeo
              </Button>

            )}
              {/*<Button
              color={'hust'}
              round
              onClick={async () => {
                // const {value} = await Swal.fire({
                //   icon: 'question',
                //   title: 'Qual seu número de Whatsapp?',
                //   text: 'Entraremos em contato por este número!',
                //   input: 'text',
                //   showCancelButton: true,
                //   cancelButtonText: 'Cancelar',
                //   confirmButtonText: 'Receber contato'
                // });
                // if (!value) return;
              }}
          >
            Receber contato
          </Button>*/}
            </DialogActions>
          </Dialog>
      ) : ''}
      {[
        {
          title: 'Total de conexões',
          icon: AddToHomeScreen,
          value: resume.connections,
          button: {name: 'Gerenciar Conexões', location: '/conexoes'}
        },
        {
          title: 'Total de listas',
          icon: ListAlt,
          value: resume.sendingLists,
          button: {name: 'Gerenciar Listas', location: '/bulkSend/sendingList'}
        },
        {
          title: 'Total de campanhas',
          icon: ListAlt,
          value: resume.campaigns,
          button: {name: 'Gerenciar Campanhas', location: '/bulkSend/campaign'}
        },
        {title: 'Total de disparos', icon: SendIcon, value: resume.messages},
      ].map((prop, key) => (
        <GridItem xs={12} sm={4} md={3} lg={3} key={key}>
          <Card>
            <CardHeader color="primary" icon>
              <p className={classes.cardCategory} style={{fontSize: '18px'}}>
                {prop.title}
              </p>
              <br></br>
              <div stats style={{display: 'flex', justifyContent: 'space-between'}}>
                <center>
                  <h3 className={classes.cardTitle} style={{marginBottom: '-10px', color: primaryColor[0]}}>
                    <b>{pontuacaoNumero(prop.value)}</b> <small></small>
                  </h3><br/>
                  <div className={classes.stats}>
                    Atualizado agora
                  </div>
                </center>

                <CardIcon color="primary" style={{width: '50px', height: '50px', float: 'right'}}>
                  <prop.icon style={{margin: '0 auto'}}/>
                </CardIcon>
              </div>
            </CardHeader>
            <CardFooter>
              {prop.button && (
                <Button fullWidth color={'hust'} round simple onClick={() => {
                  if (!checkBeta('hust-envios')) return setModalNotificationOpen(true);
                  history.push(prop.button.location);
                }}>
                  <prop.icon/>
                  {prop.button.name}
                </Button>
              )}
            </CardFooter>
          </Card>
        </GridItem>
      ))}
      <GridItem xs={12} sm={12} md={12} lg={12}/>
      <GridItem lg={8} md={8} sm={12} xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <BusinessIcon/>
            </CardIcon>
            <h3 className={classes.cardIconTitle}>Campanhas com melhor performance</h3>
          </CardHeader>
          <CardBody>
            <table style={{width: '100%'}}>
              {campaigns.map((prop, key) => {
                const {name, percent, total} = prop;

                return (
                  <tr key={key}>
                    <td
                      style={{
                        wordWrap: 'break-word',
                        maxWidth: 20
                      }}
                    >
                      <span style={{fontFamily: 'NunitoRegular'}}>{name}</span>
                    </td>
                    <td>
                      <Tooltip title={(<>{name}<br/>Total: {total}</>)} about={'Mais um teste'}>
                        <div
                          style={{
                            transition: '0.8s',
                            minWidth: 35,
                            width: `${percent || 0}%`,
                            height: 15,
                            // backgroundColor: successColor[0],
                            background: `linear-gradient(90deg, ${hustColorPrimary[0]} 0%, ${hustColorSecondary[5]} 100%)`,
                            opacity: 0.8,
                            ...successBoxShadow,
                            borderRadius: '0 10px 10px 0',
                            paddingLeft: 10,
                            display: 'flex',
                            alignContent: 'center',
                            color: '#FFF',
                            fontWeight: 'bold',
                            flexWrap: 'wrap'
                          }}
                        >
                          {pontuacaoNumero(total)}
                        </div>
                      </Tooltip>
                    </td>
                  </tr>
                );
              })}
            </table>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem lg={4} md={4} sm={12} xs={12}>
        <Card>
          <CardBody>
            <Doughnut
              data={{
                labels: ['Criado', 'Agendados', 'Em disparos', 'Pausadas', 'Finalizadas'],
                datasets: [
                  {
                    label: '',
                    data: [
                      campaignsStatus.created,
                      campaignsStatus.scheduled,
                      campaignsStatus.running,
                      campaignsStatus.paused,
                      campaignsStatus.finished
                    ],
                    backgroundColor: [
                      `rgba(${hexToRgb(infoColor[0])}, 0.2)`,
                      `rgba(${hexToRgb(infoColor[4])}, 0.2)`,
                      `rgba(${hexToRgb(successColor[0])}, 0.2)`,
                      `rgba(${hexToRgb(warningColor[0])}, 0.2)`,
                      `rgba(${hexToRgb(primaryColor[0])}, 0.2)`,
                    ],
                    borderColor: [
                      `rgba(${hexToRgb(infoColor[0])}, 1)`,
                      `rgba(${hexToRgb(infoColor[4])}, 1)`,
                      `rgba(${hexToRgb(successColor[0])}, 1)`,
                      `rgba(${hexToRgb(warningColor[0])}, 1)`,
                      `rgba(${hexToRgb(primaryColor[0])}, 1)`,
                    ],
                    borderWidth: 1,
                  },
                ]
              }}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default Dashboard;