import React, { memo, useEffect, useState } from 'react';
import { Handle, Position } from 'reactflow';
import { Card, CardBody } from "../../../components";
import RemoveNode from "../components/RemoveNode";
import Header from "./components/Header";
import {Show, Time} from "../../../assets/img/icons";
import TextBox from "./components/Input/TextBox";
import Select from "./components/Input/Select";

function SendConnectionEvent({ id, data, selected }) {
  const [ event, setEvent ] = useState(data.event || '');

  useEffect(() => {
    data.event = event;
  }, [ event ]);

  return (
    <Card
      style={{
        margin: 0,
        width: 400,
        borderRadius: 14,
        boxShadow: selected ? '0px 50px 50px 0px rgba(4, 15, 21, 0.15), 0px 40px 30px -30px rgba(4, 15, 21, 0.15)' : '#08365A 0px 0px 20px -10px',
        padding: 5
      }}
    >
      <Header title={'Enviar Evento'} subtitle={'Envia um evento para o contato'} icon={Show} id={id} />
      <CardBody style={{padding: 5, display: 'flex'}}>
        <Select options={[
            {name: 'Digitando...', value: 'composing'},
            {name: 'Interromper', value: 'paused'},
            {name: 'Gravando áudio', value: 'recording'},
        ]} value={event} onChange={setEvent}/>
      </CardBody>

      <Handle type="target" position={Position.Left} style={{ marginLeft: -3, background: '#E94D3F', width: 16, border: '3px solid #FFFFFF', height: 16 }}/>
      <Handle type="source" position={Position.Right} style={{ marginRight: -3, background: '#FFFFFF', width: 16, border: '2px solid #E94D3F', height: 16 }}/>
    </Card>
  );
}

export default memo(SendConnectionEvent);
