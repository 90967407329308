import {
  drawerWidth,
  drawerMiniWidth,
  transition,
  containerFluid
} from "assets/jss/material-dashboard-pro-react.js";

const appStyle = theme => ({
  wrapper: {
    position: "relative",
    // height: 'calc(100vh - 15px)',
    // width: 'calc(100% - 15px)',
    height: '100vh',
    width: '100%',
    top: "0",
    "&:after": {
      display: "table",
      clear: "both",
      content: '" "'
    }
  },
  mainPanel: {
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    // overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch"
  },
  content: {
    marginTop: "5px", //=========== Margem do corpo 
    padding: "15px",
    minHeight: "calc(100vh - 123px)"
  },
  container: {...containerFluid},
  map: {
    marginTop: "70px",
    marginLeft: 15,
    marginRight: 15
  },
  mainPanelSidebarMini: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerMiniWidth}px)`
    }
  },
  mainPanelWithPerfectScrollbar: {
    overflow: "hidden !important"
  },
  float:{
    position: 'fixed',
    width:'60px',
    height:'60px',
    bottom:'70px',
    right:'30px',
    borderRadius:'50px',
    textAlign:'center',
    zIndex: '999'
  },
  floatChat:{
    position: 'fixed',
    width:'60px',
    height:'60px',
    bottom:'40px',
    right:'40px',
    borderRadius:'50px',
    textAlign:'center',
    zIndex: '999'
  }
});

export default appStyle;
