import React from "react";
import {getFotoLink} from "../../../Funcoes";
import {makeStyles} from "@material-ui/core";
import MessageBoxText from "./MessageBoxText";
import MessageBoxImage from "./MessageBoxImage";
import MessageBoxAudio from "./MessageBoxAudio";
import MessageBoxVideo from "./MessageBoxVideo";
import MessageBoxDocument from "./MessageBoxDocument";
import styleMessage from './style';

export const useStyles = makeStyles(styleMessage);

const MessageAddons = ({sent, date, user}) => {
  return (
    <>
      <span
        style={{
          top: -5,
          right: sent && user ? 50 : null,
          position: "absolute",
          fontSize: 12,
          fontFamily: 'NunitoRegular'
        }}
      >
        {sent && user ? (<span style={{fontWeight: 600}}>{user.name}{'   '}</span>) : null}
        <span style={{color: '#637085'}}>
          {date.toLocaleTimeString().split(':').slice(0, -1).join(':')}
        </span>
      </span>
      {sent && user ? (
        <img
          alt={user.name}
          src={getFotoLink(user.profilePicture)}
          style={{
            marginLeft: 5,
            marginTop: 5,
            width: 40,
            height: 40,
            borderRadius: '50%'
          }}
        />
      ) : null}
    </>
  );
};

const Message = ({type, sent, date, user, ...rest}) => {
  const classes = useStyles({sent});

  // console.log({type, sent, date, user, rest});

  return (
    <div className={classes.messageContainer}>
      <div className={classes.nessageBox}>
        {type === 'chat' && <MessageBoxText body={rest.body} user={user}/>}
        {type === 'image' && <MessageBoxImage identifier={rest.identifier} caption={rest.caption || rest.body} user={user}/>}
        {type === 'document' && <MessageBoxDocument identifier={rest.identifier} caption={rest.body} user={user} fileName={rest.caption} mimetype={rest.mimetype}/>}
        {type === 'audio' && <MessageBoxAudio identifier={rest.identifier} transcription={rest.caption} user={user}/>}
        {type === 'video' && <MessageBoxVideo identifier={rest.identifier} caption={rest.caption} user={user}/>}
        {!['chat', 'image', 'document', 'audio', 'video'].includes(type) && <div>Not implemented {type}</div>}
      </div>
      <MessageAddons date={date} user={user} sent={sent}/>
    </div>
  );
};

export default Message;