import React, { useEffect, useState } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  DialogActions,
  DialogContent,
  IconButton,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import { Close } from "../../../assets/img/icons";
import Dialog from "@material-ui/core/Dialog";
import { useStyles } from "../../NewChat/TabsInfo/styles";
import styles from "../../../assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import Button from "../../../components/CustomButtons/Button";
import CloseIcon from "@material-ui/icons/Close";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { useStyles as useStyles2 } from "../../Configs/RespostasRapidas/styles";
import SendIcon from "@material-ui/icons/Send";
import UnknowIcon from "../../../assets/img/icons/files/Unknown.png";
import { fileIconByExtension } from "../../../components/v2/Chat/Message/MessageBoxDocument";
import { Document, Page } from "react-pdf";

const useStyles1 = makeStyles({
  ...styles,
  modalNew: { borderRadius: 16 },
  fileList: {
    transition: ".2s",
    borderRadius: 15,
    margin: "5px 0",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    // backgroundColor: '#FFF',
    "&:hover": {
      backgroundColor: "#EEE",
      cursor: "pointer",
    },
  },
});
const ModalUploadMediaFiles = ({ open, onClose, filesInit, isMobile }) => {
  const classes = { ...useStyles(), ...useStyles1(), ...useStyles2() };

  const [files, setFiles] = useState([
    // {
    //   name: 'Teste.png',
    //   type: 'image',
    //   src: 'https://apiv1.hustapp.com/v1/chat/getFotoPerfil?p=iiYUNMqvfchgxWLelrk2fhyRLPOvWvemPlsYCsgZFcDc3ieGRX.png',
    //   description: ''
    // },
    // {
    //   name: 'Teste.png',
    //   type: 'image',
    //   src: 'https://apiv1.hustapp.com/v1/chat/getFotoPerfil?p=iiYUNMqvfchgxWLelrk2fhyRLPOvWvemPlsYCsgZFcDc3ieGRX.png',
    //   description: ''
    // },
    // {
    //   name: 'Teste.png',
    //   type: 'image',
    //   src: 'https://apiv1.hustapp.com/v1/chat/getFotoPerfil?p=iiYUNMqvfchgxWLelrk2fhyRLPOvWvemPlsYCsgZFcDc3ieGRX.png',
    //   description: ''
    // },
  ]);

  useEffect(() => {
    if (open) {
      setFiles(filesInit);
      setSelected(0);
    } else setFiles([]);
  }, [open]);

  const [selected, setSelected] = useState(null);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      classes={{ paper: classes.modalNew }}
      // onClick={({target}) => {
      //   console.log(target);
      // }}
    >
      <DialogTitle>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <h5
            style={{
              fontFamily: "NunitoRegular",
              fontSize: 20,
              marginBottom: 0,
              color: "#1A1926",
              fontWeight: 700,
            }}
          >
            Carregando {files.length} arquivos
          </h5>
          <IconButton
            onClick={onClose}
            style={{ width: 30, height: 30, padding: 0 }}
          >
            <img src={Close} alt={"close"} />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent style={{ overflowX: "hidden" }}>
        {files.map((prop, key) => {
          const isSelected = selected === key;

          prop.name = prop.name || prop.nomeArquivo;
          prop.description = prop.description || prop.descricao;
          prop.src = prop.src || prop.base64;
          // nomeArquivo
          // base64
          // descricao

          const [fileExtension] = prop.name.split(".").slice(-1);
          const isImage = ["png", "jpg", "jpeg", "gif"].includes(fileExtension);

          return (
            <div
              className={classes.fileList}
              style={{ backgroundColor: isSelected ? "#DDD" : "#FFF" }}
              onClick={({ target }) => {
                if (target.type && target.type === "textarea") return;
                setSelected(isSelected ? null : key);
              }}
            >
              <div
                style={{ width: "100%", display: isMobile ? "block" : "flex" }}
              >
                {isImage || !["mp4", "pdf"].includes(fileExtension) ? (
                  <div
                    style={{
                      position: "relative",
                      textAlign: "-webkit-center",
                    }}
                  >
                    <div
                      style={{
                        margin: 5,
                        height: !isImage ? "100%" : isSelected ? 200 : 60,
                        width: isSelected ? 200 : 60,
                        maxWidth: !isImage ? 50 : undefined,
                        borderRadius: 15,
                        transition: "0.3s",
                        backgroundImage: `url(${
                          isImage
                            ? prop.src
                            : fileIconByExtension[fileExtension] || UnknowIcon
                        })`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "100%",
                        backgroundPositionX: "center",
                        backgroundPositionY: "center",
                      }}
                    />
                    {!isImage ? (
                      <span
                        style={{
                          width: "100%",
                          textAlign: "center",
                          position: "absolute",
                          bottom: 15,
                          fontSize: 20,
                        }}
                      >
                        {fileExtension}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
                {fileExtension === "pdf" && (
                  <div style={{ margin: 5 }}>
                    <Document file={prop.src} align="center">
                      <Page pageNumber={1} height={isSelected ? 200 : 60} />
                    </Document>
                  </div>
                )}
                {fileExtension === "mp4" && (
                  <div style={{ margin: 5 }}>
                    <video
                      controls={isSelected}
                      style={{ height: isSelected ? 200 : 60 }}
                    >
                      <source src={prop.src} type="video/mp4" />
                    </video>
                  </div>
                )}
                {/*// **********/}
                <div
                  style={{
                    display: "block",
                    marginLeft: 10,
                    fontFamily: "NunitoRegular",
                    alignSelf: "center",
                    width: "100%",
                  }}
                >
                  <span
                    style={{
                      color: "#1A1926",
                      fontSize: 16,
                      fontWeight: 600,
                      lineBreak: "anywhere",
                    }}
                  >
                    {prop.name}
                  </span>
                  <br />
                  {!isSelected ? (
                    <span
                      style={{
                        color: "#637085",
                        fontSize: 14,
                        fontWeight: 600,
                      }}
                    >
                      {prop.description}
                    </span>
                  ) : (
                    <TextareaAutosize
                      maxRows={30}
                      minRows={5}
                      placeholder={`Digite a legenda para o arquivo...`}
                      className={classes.inputText}
                      value={prop.description}
                      style={{ maxHeight: 500, borderRadius: 10 }}
                      onChange={({ target: { value } }) =>
                        setFiles((files) => {
                          files[key].description = value;
                          return [...files];
                        })
                      }
                    />
                  )}
                </div>
              </div>
              <Tooltip title={"Remover arquivo"}>
                <Button
                  simple
                  round
                  justIcon
                  color={"danger"}
                  style={{ height: "auto" }}
                  onClick={() => {
                    setFiles((files) => {
                      delete files[key];
                      return files.filter((el) => el);
                    });
                  }}
                >
                  <CloseIcon />
                </Button>
              </Tooltip>
            </div>
          );
        })}
      </DialogContent>

      <DialogActions>
        <div
          style={{
            margin: "10px 10px 0px 10px",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Button
            style={{
              borderRadius: "14px 14px 14px 0px",
              backgroundColor: "#637085",
              minHeight: 48,
              minWidth: 150,
            }}
            onClick={() => onClose()}
          >
            Fechar
          </Button>
          <Button
            style={{
              borderRadius: "14px 14px 0px 14px",
              // backgroundColor: '#637085',
              minHeight: 48,
              minWidth: 150,
            }}
            color={"danger"}
            onClick={() => onClose(files)}
            disabled={!files.length}
          >
            Enviar&nbsp;&nbsp;
            <SendIcon />
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default ModalUploadMediaFiles;
