import React, {useEffect, useState} from 'react';
import {Card, CardBody, GridContainer, GridItem, Table} from "../../components";
import Button from '../../components/CustomButtons/Button';
import {ArrowBack} from "@material-ui/icons";
import api from "../../components/api";
import {convertDate} from "../../components/Funcoes";
import ModalCalledHistory from "../Modal/CalledHistory";
import { IconButton } from "@material-ui/core";
import { Chat } from "../../assets/img/icons";
import Tooltip from "@material-ui/core/Tooltip";

const AtendimentosRecusados = ({history}) => {
  const [calleds, setCalleds] = useState([]);

  const getCalleds = async () => {
    const {data} = await api.get('/called/rejected');
    setCalleds(data);
  };

  useEffect(() => {
    getCalleds();
  }, []);

  const [openCalledHistory, setOpenCalledHistory] = useState(null);

  return (
    <GridContainer>
      <ModalCalledHistory open={!!openCalledHistory} id={openCalledHistory} onClose={() => setOpenCalledHistory(null)}/>
      <GridItem lg={12} md={12} sm={12} xs={12}>
        <Button simple color={'primary'} onClick={() => history.push('/relatorios')}>
          <ArrowBack/>Voltar
        </Button>
        <Card>
          <CardBody>
            <Table
              hover
              tableHead={[
                'Protocolo',
                'Contato - Nome',
                'Contato - Número',
                'Usuário',
                'Data',
                ''
              ]}
              tableData={calleds.map(prop => [
                prop.id,
                prop.called.contact.name,
                prop.called.contact.idWhatsapp.split('@')[0],
                prop.user.name,
                convertDate(prop.date, false, true),
                (
                  <Tooltip title={'Ver conversa'}>
                    <IconButton onClick={() => setOpenCalledHistory(prop.id)}>
                      <img alt={'chat'} src={Chat}/>
                    </IconButton>
                  </Tooltip>
                )
              ])}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default AtendimentosRecusados;