import React, {useEffect, useState} from "react";
import {WebService} from "../../components/WebService";
import TreeMenu, {defaultChildren, ItemComponent} from 'react-simple-tree-menu';

import 'react-simple-tree-menu/dist/main.css';
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import {StickyTree} from 'react-virtualized-sticky-tree';
import Tree from "react-animated-tree";
import Button from "../../components/CustomButtons/Button";
import * as Swal from "sweetalert2";
import Danger from "../../components/Typography/Danger";
import {Android, AssignmentInd, Close, Edit, SyncAlt} from "@material-ui/icons";
import SortableTree from "react-sortable-tree";
import {makeStyles, Tooltip} from "@material-ui/core";
import {dangerColor, hexToRgb, primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import CardHeader from "../../components/Card/CardHeader";
import CardIcon from "../../components/Card/CardIcon";
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import Switch from "@material-ui/core/Switch";
import Typography from '@material-ui/core/Typography';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import styles from "../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import BuildIcon from '@material-ui/icons/Build';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {CustomInput, CustomButtons} from "../../components";
import MediaQuery from 'react-responsive';
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import dashboardStyle from "../../assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import {CgInternal} from "react-icons/cg";
import {FaExternalLinkAlt} from "react-icons/fa";

const useStyles = makeStyles({
  ...dashboardStyle,
  ...styles,
  buttonMenu: {
    width: 35,
    height: 35,
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    paddingTop: 5,
    transition: '.3s',
    borderRadius: '50%'
  },
  primaryColor: {
    color: primaryColor[0],
    '&:hover': {background: `rgba(${hexToRgb(primaryColor[0])}, 0.3)`}
  },
  dangerColor: {
    color: dangerColor[0],
    '&:hover': {background: `rgba(${hexToRgb(dangerColor[0])}, 0.3)`}
  }
});

const toggles = makeStyles(styles);

export default function DepartamentoManager() {
  const classes = useStyles();
  const clases = toggles();

  const [departamentos, setDepartamentos] = useState([]);
  const [limita, setLimita] = React.useState(false);
  const [mesmo, setMesmo] = React.useState(false);
  const [mode, setMode] = React.useState('normal');
  const [nome, setNome] = useState('');
  const [id_dep, setId_Dep] = useState('');

  const [open, setOpen] = React.useState(false);

  const limit = async () => {
    await Swal.fire({
      title: 'Ação realizada!',
      icon: 'success',
      showConfirmButton: false,
      timer: 2500
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getDepartamentos = async () => {
    const {ok, departamentos} = await WebService(`/departamento/getDepartamentos`);
    if (ok) setDepartamentos(departamentos);
  };

  const novoDepartamento = async () => {
    const {value: nome} = await Swal.fire({
      title: 'Nome do departamento',
      input: 'text',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      inputValidator: (value) => !value ? 'Digite um nome válido.' : null
    });

    if (nome) {
      const {ok} = await WebService(`/departamento/novoDepartamento`, {nome: nome.trim()}, "POST");
      if (ok) {
        //await Swal.fire({icon: "success", title: "Departamento cadastrado com sucesso!"});
        await getDepartamentos();
      }
    }
  };

  useEffect(() => {
    getDepartamentos();
  }, []);

  const getChild = departamentos => {

    return departamentos.map((prop, key) => {
      const {id_departamento, nome, subDepartamentos, flag_departamento_interno, expanded} = prop;
      return {
        title: `${key + 1} - ${nome}`,
        nome,
        subtitle: flag_departamento_interno ? 'Departamento interno' : '',
        id_departamento,
        flag_isSub: false,
        flag_departamento_interno,
        expanded: [true, false].indexOf(expanded) > -1 ? expanded : true,
        children: subDepartamentos ? getSub(subDepartamentos) : []
      };
    });
  };

  const getSub = sub => {
    return sub.map((prop, key) => {
      const {id_departamento, nome, subDepartamentos, flag_departamento_interno, expanded} = prop;
      return {
        title: `${key + 1} - ${nome}`,
        nome,
        subtitle: flag_departamento_interno ? 'Departamento interno' : '',
        id_departamento,
        flag_isSub: true,
        flag_departamento_interno,
        expanded: [true, false].indexOf(expanded) > -1 ? expanded : true,
        children: subDepartamentos ? getSub(subDepartamentos) : []
      };
    });
  };

  const editarNomeDepartamento = async id_departamento => {
    const {value} = await Swal.fire({icon: "question", title: 'Qual o novo nome para departamento?', input: "text"});
    console.log(value);
    if (value) {
      const {ok} = await WebService(`/departamento/editarNomeDepartamento`, {nome: value, id_departamento}, "POST");
      if (ok) {
        //await Swal.fire({icon: "success", title: "Departamento atualizado com sucesso!"});
        await getDepartamentos();
      }
    }
  };

  const salvar = async () => {
    if (nome) {
      const department = {nome, id_departamento: id_dep, mode};
      const {ok} = await WebService(`/departamento/editarNomeDepartamento`, department, "POST");
      if (ok) {
        await Swal.fire({icon: 'success', title: 'Departamento atualizado com sucesso!'});
        await getDepartamentos();
        setOpen(false);
      }
    } else {
      await Swal.fire({icon: 'warning', title: 'O campo "Nome do departamento" não pode ser vazio'});
    }
  };

  const getIndexCerto = (id) => {
    let k = 0;
    for (k = 0; k < departamentos.length; k++) {
      if (departamentos[k].id_departamento == id) {
        break;
      }
    }
    return k;
  };

  const handleClickOpen = async (id_departamento) => {
    var key = getIndexCerto(id_departamento);
    if (departamentos[key]) {
      const {nome, id_departamento, mode} = departamentos[key];
      setId_Dep(id_departamento);
      setNome(nome);
      setMode(mode);
      setOpen(true);
    } /*else if(departamentos[key].subDepartamentos[0].nome){
      const {nome} = departamentos[key].subDepartamentos[0];
      const {id_departamento} = departamentos[key].subDepartamentos[0];
      setId_Dep(id_departamento);
      setNome(nome);
      setOpen(true);
    }*/
  };

  const marcarDepartamentoInterno = async id_departamento => {
    const {ok} = await WebService(`/departamento/marcarDepartamentoInterno`, {id_departamento});
    if (ok) {
      await Swal.fire({icon: 'success', title: 'Departamentos atualizado com sucesso!'});
      await getDepartamentos();
    }
  };

  const excluirDepartamento = async id_departamento => {
    const {value} = await Swal.fire({
      icon: "question",
      title: 'Deseja realmente excluir o departamento?',
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    });

    if (value) {
      const {ok} = await WebService(`/departamento/excluirDepartamento`, {id_departamento});
      if (ok) {
        //await Swal.fire({icon: 'success', title: 'Departamentos atualizado com sucesso!'});
        await getDepartamentos();
      }
    }
  };

  return (
    <GridContainer>
      {/**************************************************************/}
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Departamento: <b style={{color: '#0FA3E2'}}>{nome}</b></DialogTitle>
        <DialogContent>
          <CustomInput
            labelText="Nome"
            inputProps={{onChange: ({target}) => setNome(target.value), value: nome}}
            autoFocus={true}
            style={{width: '55%', float: 'left', display: 'inline'}}
          />
          {/*<FormControlLabel
                    style={{ fontSize: '10px', color: '#8c8c8c', width: '50%', float: 'right' }}
                    label={<Typography style={{ fontSize: '14px' }}>
                      Restringir exibição de histórico do departamento
                      <p style={{ fontSize: '11.5px' }}>
                        Cada usuário só poderá ver o histórico de seu(s) respectivo(s) atendimento(s)
                      </p>
                    </Typography>}
                    control={
                      <Switch
                        checked={mesmo}
                        onChange={event => setMesmo(event.target.checked)}
                        value="mesmo"
                        color="primary"
                        classes={{
                          switchBase: clases.switchBase,
                          checked: clases.switchChecked,
                          thumb: clases.switchIcon,
                          track: clases.switchBar
                        }}
                      />
                    }
                  />*/}
          <h6>Modo de operação</h6>
          {[
            {
              key: 'normal',
              name: 'Modo Normal',
              description: 'Todos os atendentes do departamento recebem o atendimento'
            },
            {
              key: 'balance',
              name: 'Modo de balanceamento',
              description: 'Mantém todos os atendentes com o mesmo número de atendimentos'
            },
            {
              key: 'sequence',
              name: 'Modo sequencial',
              description: 'Distribui os atendimentos sequencialmente entre os atendentes'
            }
          ].map(prop => (
            <FormControlLabel
              key={prop.key}
              className={classes.formCheck}
              label={<Typography style={{fontSize: '14px'}}>
                {prop.name}
                <p style={{fontSize: '11.5px'}}>
                  {prop.description}
                </p>
              </Typography>}
              control={
                <Checkbox
                  checked={prop.key === mode}
                  tabIndex={-1}
                  onClick={() => setMode(prop.key)}
                  checkedIcon={<Check className={classes.checkedIcon}/>}
                  icon={<Check className={classes.uncheckedIcon}/>}
                  classes={{checked: classes.checked, root: classes.root}}
                />
              }
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{left: 0, width: '100px'}}>
            Cancelar
          </Button>
          <Button onClick={() => salvar()} color="primary" style={{float: 'right', width: '100px'}}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
      {/**************************************************************/}
      <Card>
        <CardHeader color="primary" stats icon>
          <CardIcon color="primary" style={{width: 85, height: 85}}>
            <BusinessCenterIcon/>
          </CardIcon>
          <div style={{float: 'left'}}>
            <h4
              className={classes.cardIconTitle}
              style={{float: 'left', paddingTop: '10px', marginRight: '90%', color: 'black'}}
            >
              Gerenciador de departamentos <br/>
            </h4>
            <p style={{float: 'left', fontSize: '14px', color: 'grey', clear: 'both'}}>
              Aqui são exibidos os departamentos cadastrados no sistema
            </p>
          </div>
          <Button
            color={"hust"} round onClick={novoDepartamento.bind(this)}
            style={{float: 'right', marginTop: '15px'}}
          >
            Novo departamento
          </Button>
        </CardHeader>
        <GridItem lg={12} md={12} sm={12} xs={12}>
          <CardBody style={{height: 'calc(100vh - 294px)', overflowY: 'auto', overflowX: 'hidden'}}>
            <SortableTree
              style={{width: '200vw'}}
              treeData={getChild(departamentos)}
              isVirtualized={false}
              onChange={async treeData => {
                const organizar = d => {
                  return d.map((prop, key) => {
                    const {
                      nome,
                      subtitle,
                      id_departamento,
                      flag_departamento_interno,
                      children,
                      expanded,
                      flag_isSub
                    } = prop;
                    return {
                      nome,
                      subDepartamentos: children && children.length ? organizar(children) : undefined,
                      id_departamento,
                      flag_isSub,
                      flag_departamento_interno,
                      expanded,
                      sequencia: (key + 1)
                    };
                  })
                }
                const departamentos = organizar(treeData);
                const {ok} = await WebService(`/departamento/atualizarOrdemDepartamento`, {departamentos}, "POST");
                if (ok) {
                  await setDepartamentos(departamentos);
                  const Toast = Swal.mixin({
                    toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, timerProgressBar: true
                  });
                  Toast.fire({icon: 'success', title: 'Departamentos atualizados!'});
                }
              }}
              generateNodeProps={({node: {flag_departamento_interno, id_departamento}, treeIndex}) => {
                return ({
                  buttons: [
                    <Tooltip title={`Editar configurações do departamento`}>
                      <Button
                        justIcon
                        simple
                        color={'info'}
                        size={'sm'}
                        //onClick={() => editarNomeDepartamento(id_departamento)}
                        onClick={() => handleClickOpen(id_departamento)}
                        // className={classes.buttonMenu + ' ' + classes.infoColor}
                      >
                        <BuildIcon/>
                      </Button>
                    </Tooltip>,
                    <Tooltip title={`${flag_departamento_interno ? 'Desm' : 'M'}arcar como departamento interno`}>
                      <Button
                        justIcon
                        simple={!flag_departamento_interno}
                        // color={flag_departamento_interno ? 'warning' : 'success'}
                        color={`warning`}
                        size={'sm'}
                        // round
                        onClick={() => marcarDepartamentoInterno(id_departamento)}
                        // className={classes.buttonMenu + ' ' + classes.primaryColor}
                      >
                        {flag_departamento_interno ? <FaExternalLinkAlt style={{width: 14, height: 14}}/> : <CgInternal style={{width: 20, height: 20}}/>}
                      </Button>
                    </Tooltip>,
                    <Tooltip title={"Excluir departamento"}>
                      <button
                        className={classes.buttonMenu + ' ' + classes.dangerColor}
                        onClick={() => excluirDepartamento(id_departamento)}
                      >
                        <Close/>
                      </button>
                    </Tooltip>
                  ]
                })
              }}
            />
            {/*{getChild(departamentos)*/}
            {/*<FormControlLabel
              style={{fontSize: '10px', color: '#8c8c8c', position: 'absolute', right: -10, top: 40, width: '45%'}}
              label={<Typography style={{fontSize: '14px'}}>Limitar exibição de histórico de conversas do atendente
                <p style={{fontSize: '12px'}}>Ao ativar esta opção, os usuários só poderão ver as conversas referentes
                  ao(s) seu(s) respectivo(s) chamado(s)</p>
              </Typography>}
              control={
                <Switch
                  checked={limita}
                  onClick={limit}
                  onChange={event => setLimita(event.target.checked)}
                  value="limita"
                  color="primary"
                  classes={{
                    switchBase: clases.switchBase,
                    checked: clases.switchChecked,
                    thumb: clases.switchIcon,
                    track: clases.switchBar
                  }}
                />
              }
            />*/}
          </CardBody>
        </GridItem>
      </Card>
    </GridContainer>
  )
};
