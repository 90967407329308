import React, {memo, useEffect, useState} from 'react';
import {Handle, Position} from 'reactflow';
import {Card, CardBody} from "../../../components";
import {Menu} from "@material-ui/icons";
import RemoveNode from "../components/RemoveNode";

function endChooseDepartment({id, data}) {
  const [message, setMessage] = useState(data.message || '😃 Olá  {{contact.name}}, tudo bem? Bem-vindo ao nosso sistema de atendimento eletrônico. É um prazer atendê-lo(a).\n\n' +
    '🔢 Para iniciar seu atendimento digite o número da opção desejada:');
  const [button, setButton] = useState(false);
  const [messageInvalidOption, setMessageInvalidOption] = useState(data.messageInvalidOption || '');

  useEffect(() => {
    data.button = false;
    data.message = message;
    data.messageInvalidOption = messageInvalidOption;
  }, [message, messageInvalidOption, button]);

  return (
    <Card
      style={{
        margin: 0,
        border: '1px solid #B62C1A',
        minWidth: 150,
        // maxWidth: 300,
        borderRadius: 20,
        boxShadow: '#B62C1A 0px 0px 20px -10px',
        backgroundColor: `#fff0ee`,
        padding: 5
      }}
    >
      <RemoveNode id={id}/>
      <CardBody style={{padding: 5, display: 'flex'}}>
        <div
          style={{
            color: '#B62C1A',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: 'calc(25% - 25px)',
            marginRight: 10
          }}
        >
          <Menu/>
        </div>
        <div>
          <span style={{fontWeight: 'bold', fontSize: 13}}>
            Escolha de departamentos
          </span>
          <br/>
          {/*NÃO FUNCIONA MAIS - WHATSAPP LIMITOU*/}
          {/*<input type={'checkbox'} checked={button} onClick={() => setButton(!button)}/> Enviar como botões*/}
          {/*<br/>*/}
          Mensagem<br/>
          <textarea
            style={{
              resize: 'none',
              width: 400,

              height: 120,
              borderRadius: 10
            }}
            value={message}
            onChange={({target: {value}}) => setMessage(value)}
          />
        </div>
      </CardBody>
      <Handle type="target" position={Position.Left} className="w-16 !bg-teal-500"/>
    </Card>
  );
}

export default memo(endChooseDepartment);
