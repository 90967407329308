import React, {useEffect, useState} from "react";
import {Card, CardBody, GridContainer, GridItem, Table} from "../../components";
import {ArrowBack, ListAlt} from "@material-ui/icons";
import StatCard from "./components/StatCard";
import Button from "../../components/CustomButtons/Button";
import api from "../../components/api";
import {Tooltip} from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import {infoColor, primaryColor, successColor, warningColor} from "../../assets/jss/material-dashboard-pro-react";
import {pontuacaoNumero} from "../../components/Funcoes";
import Swal from "sweetalert2";

export const CAMPAIGN_STATUS = {
  created: {
    label: 'Criado',
    color: infoColor[0]
  },
  scheduled: {
    label: 'Agendado',
    color: infoColor[0]
  },
  running: {
    label: 'Em disparo',
    color: successColor[0]
  },
  paused: {
    label: 'Pausado',
    color: warningColor[0]
  },
  finished: {
    label: 'Finalizado',
    color: primaryColor[0]
  },
};

const Campaign = ({history}) => {
  const [campaigns, setCampaigns] = useState([]);

  const getCampaigns = async () => {
    const {data} = await api.get('/campaign');
    setCampaigns(data);
  };

  const createCampaign = async () => {
    const {value} = await Swal.fire({
      icon: "question",
      text: 'Qual o nome que deseja dar a sua nova campanha?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Criar Campanha',
      input: "text",
      inputValidator: inputValue => (inputValue && inputValue.trim()) ? null : 'Você precisa digitar um nome válido',
    });
    if (!value) return;

    await api.post('/campaign', {name: value.toString().trim()});
    await getCampaigns();
  };

  const deleteCampaign = async id => {
    const {name} = campaigns.find(el => el.id === id);

    const {value} = await Swal.fire({
      icon: 'question',
      title: 'Deseja realmente excluir a campanha?',
      text: `A campanha [${name}] não poderá ser recuperada após essa ação.`,
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    });
    if (!value) return;

    await api.delete(`/campaign/${id}`);
    await Swal.fire({icon: "success", title: 'Campanha excluida com sucesso!'});
    await getCampaigns();
  };

  useEffect(() => {
    getCampaigns();
  }, []);

  return (
    <GridContainer>
      <StatCard icon={ListAlt} title={'Total de campanhas'} value={campaigns.length}/>
      <StatCard icon={ListAlt} title={'Total de campanhas ativas'}
                value={campaigns.filter(el => el.status === 'running').length} color={'success'}/>
      <GridItem lg={12} md={12} sm={12} xs={12}>
        <Button color={'primary'} style={{borderRadius: 20}} onClick={() => history.push('/bulkSend')} simple>
          <ArrowBack/> Voltar
        </Button>
        <Button color={'hust'} round style={{borderRadius: 20}} onClick={createCampaign}>
          <ListAlt/> Nova campanha
        </Button>
      </GridItem>
      <GridItem lg={12} md={12} sm={12} xs={12}>
        <Card>
          <CardBody>
            <Table
              hover
              tableHead={['#', 'Nome da campanha', 'Número de contatos', 'Status da campanha', 'Opções']}
              tableData={campaigns.map(prop => {
                const status = CAMPAIGN_STATUS[prop.status];
                return [
                  prop.id,
                  prop.name,
                  prop.totalContacts ?
                    (
                      <span style={{fontWeight: 400}}>{pontuacaoNumero(prop.totalContacts)} contatos</span>
                    ) : (
                      <span style={{color: '#BBB'}}>Nenhum contato</span>
                    ),
                  (
                    <span
                      style={{
                        fontWeight: 400,
                        border: `1.5px solid ${status.color}`,
                        padding: '2px 10px',
                        borderRadius: 10,
                        color: status.color,
                        width: 110,
                        textAlign: "center",
                        display: 'block'
                      }}
                    >
                      {status.label}
                    </span>
                  ),
                  (
                    <>
                      <Tooltip title={'Editar Campanha'}>
                        <Button
                          justIcon
                          simple
                          round
                          color={'info'}
                          style={{margin: 0}}
                          onClick={() => history.push(`/bulkSend/campaign/${prop.id}`)}
                        >
                          <Edit/>
                        </Button>
                      </Tooltip>
                      <Tooltip title={'Excluir Campanha'}>
                        <Button
                          justIcon
                          simple
                          round
                          color={'danger'}
                          style={{margin: 0}}
                          onClick={() => deleteCampaign(prop.id)}
                        >
                          <Close/>
                        </Button>
                      </Tooltip>
                    </>
                  )
                ];
              })}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
};

export default Campaign;