import React, { useEffect, useState } from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { DialogActions, DialogContent, IconButton, makeStyles, Tooltip } from "@material-ui/core";
import { Close } from "../../../assets/img/icons";
import { fileIconByExtension } from "../../../components/v2/Chat/Message/MessageBoxDocument";
import UnknowIcon from "../../../assets/img/icons/files/Unknown.png";
import { Document, Page } from "react-pdf";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Button from "../../../components/CustomButtons/Button";
import CloseIcon from "@material-ui/icons/Close";
import SendIcon from "@material-ui/icons/Send";
import styles from "../../../assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import { useStyles } from "../../NewChat/TabsInfo/styles";
import { useStyles as useStyles2 } from "../../Configs/RespostasRapidas/styles";
import TextBox from "../../BOT/CustomNodes/components/Input/TextBox";


const useStyles1 = makeStyles({
  ...styles,
  modalNew: { borderRadius: 16 },
  fileList: {
    transition: ".2s",
    borderRadius: 15,
    margin: "5px 0",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    // backgroundColor: '#FFF',
    "&:hover": {
      backgroundColor: "#EEE",
      cursor: "pointer",
    },
  },
});

const TextSuggestion = ({ open, text, onClose }) => {
  const classes = { ...useStyles(), ...useStyles1(), ...useStyles2() };

  const [replaceDisabled, setReplaceDisabled] = useState(false);

  let intervalDisabled;
  useEffect(() => {
    setReplaceDisabled(true);
    clearTimeout(intervalDisabled);
    intervalDisabled = setTimeout(() => {
      setReplaceDisabled(false);
    }, 2000);
  }, [text]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      classes={{ paper: classes.modalNew }}
      // onClick={({target}) => {
      //   console.log(target);
      // }}
    >
      {/*<DialogTitle>
        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
          <h5 style={{ fontFamily: "NunitoRegular", fontSize: 20, marginBottom: 0, color: "#1A1926", fontWeight: 700 }}>
            Título
          </h5>
          <IconButton
            onClick={onClose}
            style={{ width: 30, height: 30, padding: 0 }}
          >
            <img src={Close} alt={"close"}/>
          </IconButton>
        </div>
      </DialogTitle>*/}

      <DialogContent style={{ overflowX: "hidden" }}>
        <TextBox
          label={'Texto gerado pela IA'}
          value={text}
          // onChange={() => ''}
          rows={text.length < 350 ? 5 : Math.round(text.length / 70)}
          disabled={true}
        />
      </DialogContent>

      <DialogActions>
        <div
          style={{
            margin: "10px 10px 0px 10px",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Button
            style={{ borderRadius: "25px 25px 25px 0px", backgroundColor: "#637085", minHeight: 48, minWidth: 150 }}
            onClick={() => onClose()}
          >
            Fechar
          </Button>
          <Button
            style={{ minHeight: 48, minWidth: 150 }}
            color={"hust"}
            onClick={() => onClose(text)}
            disabled={replaceDisabled}
          >
            Substituir&nbsp;&nbsp;
            <SendIcon/>
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default TextSuggestion;