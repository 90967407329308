import React, {useEffect, useState} from "react";
import MUIDataTable from "mui-datatables";
import Swal from "sweetalert2";

import {WebService} from "../../../components/WebService";

import {Card, CardIcon, CardHeader, CardBody} from '../../../components';

import {IconButton, Chip, Modal, Switch, Tooltip} from '@material-ui/core';
import {Edit, Close, QuestionAnswer} from '@material-ui/icons';

import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import { PostAdd } from '@material-ui/icons';
import {useStyles} from "./styles";
import Form from "./Form";
import {getFotoLink, traducaoTable} from "../../../components/Funcoes";
import {infoColor, primaryColor} from "../../../assets/jss/material-dashboard-pro-react";

export default function RespostasRapidas() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [respostas, setRespostas] = useState([]);
  const [idResposta, setIdResposta] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);

  const handleModal = (id) => {
    setIdResposta(id);
    setOpen(!open);
  }

  const getRespostasRapidas = async () => {
    const {ok, mensagensPredefinida} = await WebService(`/mensagem/getMensagemPredefinida`);
    if (ok) setRespostas(mensagensPredefinida);
  };

  const excluirResposta = async (id_mensagem_predefinida) => {
    const { value } = await Swal.fire({
      title: 'Tem certeza de que deseja excluir essa resposta rápida?',
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Excluir',
      showCloseButton: true,
      focusConfirm: true,
      reverseButtons: true
    });

    if (value !== true) return;

    const {ok} = await WebService(`/mensagem/excluiRespostaRapida`, {id_mensagem_predefinida});
    if (ok) {
      await Swal.fire({
        title: 'Ação realizada!',
        text: `Resposta deletada com sucesso!`,
        icon: 'success',
        showConfirmButton: false,
        timer: 2500
      });
    }

    getRespostasRapidas();
  };

  const getDepartamentos = async () => {
    const {ok, departamentos} = await WebService(`/departamento/getDepartamentos`);
    if (ok) {
      const tempDepartamentos = [];
      const getChild = (d, n) => {
        d.map(prop => {
          const {nome, id_departamento, subDepartamentos} = prop;
          if (subDepartamentos) getChild(subDepartamentos, `${n ? n + ' -> ' : ''}${nome}`);
          else tempDepartamentos.push({nome: `${n ? n + ' -> ' : ''}${nome}`, id_departamento});
        });
      };
      getChild(departamentos);
      const ordemAlfabetica = [...tempDepartamentos].sort((a, b) => a.nome.localeCompare(b.nome));
      setDepartamentos(ordemAlfabetica);
    }
  };

  const nomeDep = (id) => {
    let aux = '';
    if (id !== null && departamentos !== []) {
      id = JSON.parse("[" + id + "]");
      for (let y = 0; y < id.length; y++) {
        for (let i = 0; i < departamentos.length; i++) {
          if (departamentos[i].id_departamento == id[y]) {
            if (aux === '') aux = aux + departamentos[i].nome;
            else aux = aux + ', ' + departamentos[i].nome;
          }
        }
      }

      return aux;
    } else return null;
  };

  useEffect(() => {
    getRespostasRapidas();
    getDepartamentos();
  }, []);

  const getMuiTheme = () => createMuiTheme({
    overrides: {
      MUIDataTable: {root: {marging: 0, border: 0, boxShadow: 'none'}, paper: {margin: 0, boxShadow: "none"}},
      MUIDataTableBodyCell: {root: {padding: '0 15 0 15', maxWidth: '50vw',}}
    }
  });

  const columns = [
    {
      name: 'texto',
      label: "Texto",
    },
    {
      name: 'file',
      label: "Arquivo",
      options: {
        filter: false,
        download: false,
        customBodyRender: (value) => {
          if (!value) return "";
          const {nome_arquivo, extencao} = value;
          return `${nome_arquivo}.${extencao}`;
        }
      }
    },
    {
      name: 'departamento',
      label: 'Departamento',
      options: {
        filter: false,
        customBodyRender: (value) => {
          if (value === '') {
            return <p style={{color: 'silver', fontSize: '13px'}}>Nenhum departamento associado</p>;
          } else {
            return (value);
          }
        }
      }
    },
    {
      name: "tags",
      label: "Tags",
      options: {
        filter: false,
        customBodyRender: (value) => {
          if (value.length > 0)
            return value.map(val => <Chip label={`/${val || ''}`} color={'secondary'}/>)
        }
      }
    },
    {
      name: "actions",
      label: " ",
      options: {
        filter: false,
        download: false,
        customBodyRender: (value) => {
          return (
            <div style={{minWidth: '100px'}}>
              <Tooltip title={"Visualizar/Editar"}>
                <IconButton color="link" onClick={() => handleModal(value)}>
                  <Edit fontSize="small" color="primary" style={{color: infoColor[0]}} />
                </IconButton>
              </Tooltip>
              <Tooltip title={"Deletar"}>
              <IconButton color="link" onClick={() => excluirResposta(value)}>
                <Close fontSize="small" color="error" />
              </IconButton>
            </Tooltip>
           </div>
          );
        }
      },
    }
  ];

  const options = {
    ...traducaoTable,
    filter: false,
    selectableRows: 'none',
    filterType: "dropdown",
    responsive: "scrollFullHeight",
    rowsPerPage: 10,
    customToolbar: () => {
      return (
        <IconButton className={classes.iconButton}>
            <Tooltip title={"Nova Resposta Rápida"}>
              <PostAdd onClick={() => handleModal(null)} />
            </Tooltip>
        </IconButton>
      );
    }
  };
  return (
    <>
      <Card>
        <CardHeader color="primary" icon>
          <CardIcon color="primary">
            <QuestionAnswer/>
          </CardIcon>
          <h4 className={classes.cardIconTitle} style={{fontSize: '18px', color: '#4f4f4f'}}>Respostas Rápidas</h4>
          <p style={{color: '#6c6c6c', fontSize: '13px', marginTop: '-5px'}}>Área de gerenciamento das respostas rápidas utilizadas no sistema.</p>
        </CardHeader>
        <CardBody style={{marginTop: '-20px'}}>
          <MuiThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              data={respostas.map((prop, key) => {
                const {mensagem, chave, id_mensagem_predefinida, id_departamento_fk, file} = prop;
                const deps = nomeDep(id_departamento_fk);

                return [
                  mensagem,
                  file,
                  [deps],
                  [chave],
                  id_mensagem_predefinida,
                ];
              })}
              columns={columns}
              options={options}
            />
          </MuiThemeProvider>
        </CardBody>
      </Card>
      <Modal open={open} onClose={handleModal} className={classes.modal}>
        <Form resposta={idResposta} handleModal={handleModal} hideModal={handleModal} getRespostasRapidas={getRespostasRapidas}/>
      </Modal>
    </>
  );
}
