import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import Switch from '@material-ui/core/Switch';


export const useStyles = makeStyles(theme => ({
  root: {
    padding: 0
  },

  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    borderRadius: '10px'
  },

  iconButton: {
  },

  cardIconTitle: {
    color: '#48494a',
  },

  cardIconSubtitle: {
    color: '#8d8f91',
  },
  
  cardIcon: {
    height: '100px',
    width: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    
  },

  imageUpload: {
    display: 'flex',
    justifyContent: 'center'
  },

  importContacts: {
    justifyContent: 'center',
    alignItems: 'center',

    '& .svg': {
      fontSize: '36px',
      color: ''
    }
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[900],
  },
  
  cardFooter: {
    justifyContent: 'flex-end'
  }
 
}));

export const StyledSwitch = withStyles({
  switchBase: {
    color: '#f5f5f5',
    '&$checked': {
      color: 'green',
    },
    '&$checked + $track': {
      backgroundColor: 'green',
    },
  },
  checked: {},
  track: {},
})(Switch);