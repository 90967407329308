import React, {useEffect, useState} from 'react';
import Swal from "sweetalert2";
import api from "../../components/api";
import ModalConnectionQRCode from "../Modal/ConnectionQRCode";

const CreateNewConnection = ({location: {search}, assets}) => {
  const [modalCreateConnection, setModalCreateConnection] = useState({open: false, QRCodeData: null});
  const [params, setParams] = useState({});

  let intervalQRCode;
  const createConnection = async () => {
    // setModalCreateConnection({open: true, QRCodeData: null, status: 'error'});
    // return;
    setModalCreateConnection({open: true, QRCodeData: null, status: 'configuring'});
    const {data: {uuid, updateFrequency}} = await api.post('/connection', {type: 'whatsapp', partner: true});
    setModalCreateConnection({open: true, QRCodeData: null, status: 'qrcode'});
    let lastQRCodeHash;
    intervalQRCode = setInterval(async () => {
      const configRequest = {params: {uuid, typeQR: 'data'}};
      const {data: {status, QRCodeData, QRCodehash, message, finalUUID}} = await api.get('/connection/status', configRequest);

      if (!['qrcode', 'configuring'].includes(status)) clearInterval(intervalQRCode);

      if (status === 'qrcode') {
        if (QRCodehash === lastQRCodeHash) return;
        console.log(`hust-log|qrcode|${QRCodeData}`);
        lastQRCodeHash = QRCodehash;
        setModalCreateConnection({open: true, QRCodeData, status});
      }

      if (status === 'configuring') {
        console.log(`hust-log|configuring`);
        setModalCreateConnection({open: true, QRCodeData: null, status});
      }
      if (status === 'connected') {
        console.log(`hust-log|connected|${finalUUID}`);
        setModalCreateConnection({open: true, QRCodeData: null, status});
      }
      if (status === 'error') {
        console.log(`hust-log|error`);
        setModalCreateConnection({open: true, QRCodeData: null, status});
      }
    }, updateFrequency);
  };

  useEffect(() => {
    let tempParams = {};
    ((search || "").split("?")[1] || "")
      .split("&")
      .forEach((prop) => {
        const [key, value] = prop.split("=");
        tempParams[key] = value;
      });
    setParams({...tempParams});

    document.getElementById('auth-background-main-div').style['background-image'] = `url("${assets.fundoLogin}")`;

    localStorage.setItem('PARTNER_TOKEN', tempParams['token']);
    const {uuid} = tempParams;
    if (uuid) setModalCreateConnection({open: true, QRCodeData: null, status: 'status', uuid});
    else createConnection();
  }, []);

  return (
    <div>
      <ModalConnectionQRCode
        open={modalCreateConnection.open}
        QRCodeData={modalCreateConnection.QRCodeData}
        status={modalCreateConnection.status}
        uuid={modalCreateConnection.uuid}
        onTryAgain={() => {
          createConnection();
        }}
        onClose={type => {
          if (type === 'modal') return;
          console.log(`hust-log|canceled`);
          setModalCreateConnection({open: false, QRCodeData: null});
          clearInterval(intervalQRCode);
          // getConnections();
        }}
      />
    </div>
  );
};

export default CreateNewConnection;