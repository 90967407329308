import React, {useEffect, useState} from "react";
import Tree from 'react-d3-tree';
import GridItem from "../components/Grid/GridItem";
import GridContainer from "../components/Grid/GridContainer";
import Card from "../components/Card/Card";
import CardBody from "../components/Card/CardBody";
import 'react-sortable-tree/style.css';
import Button from "../components/CustomButtons/Button";
import * as Swal from "sweetalert2";
import CustomInput from "../components/CustomInput/CustomInput";
import {WebService} from "../components/WebService";
import {getFotoLink} from "../components/Funcoes";
import PictureUpload from "../components/CustomUpload/PictureUpload";

export default function BotConfiguracao() {
  const [mensagemInicioChamado, setMensagemInicioChamado] = useState("");
  const [mensagemFinalizacaoChamado, setMensagemFinalizacaoChamado] = useState("");
  const [mensagemFilaAtendimento, setMensagemFilaAtendimento] = useState("");
  const [mensagemAssumirAtendimento, setMensagemAssumirAtendimento] = useState("");
  const [mensagemCabecalhoEscolhaDepartamento, setMensagemCabecalhoEscolhaDepartamento] = useState("");
  const [mensagemInicioChamadoImagem, setMensagemInicioChamadoImagem] = useState("");

  const getConfig = async () => {
    const {ok, configuracoesCliente} = await WebService(`/cliente/getCliente`, {config: true});
    if (ok) {
      const {
        mensagem_inicio_chamado, mensagem_finalizacao_chamado, mensagem_fila_atendimento, mensagem_assumir_atendimento,
        mensagem_cabecalho_escolha_departamento, mensagem_inicio_chamado_imagem
      } = configuracoesCliente;
      setMensagemInicioChamado(mensagem_inicio_chamado);
      setMensagemFinalizacaoChamado(mensagem_finalizacao_chamado);
      setMensagemFilaAtendimento(mensagem_fila_atendimento);
      setMensagemAssumirAtendimento(mensagem_assumir_atendimento);
      setMensagemCabecalhoEscolhaDepartamento(mensagem_cabecalho_escolha_departamento);
      setMensagemInicioChamadoImagem(mensagem_inicio_chamado_imagem);
    }
  };

  const salvarConfig = async () => {
    const configuracoes = {
      mensagem_inicio_chamado: mensagemInicioChamado,
      mensagem_finalizacao_chamado: mensagemFinalizacaoChamado,
      mensagem_fila_atendimento: mensagemFilaAtendimento,
      mensagem_assumir_atendimento: mensagemAssumirAtendimento,
      mensagem_inicio_chamado_imagem: mensagemInicioChamadoImagem,
      mensagem_cabecalho_escolha_departamento: mensagemCabecalhoEscolhaDepartamento
    };

    const {ok} = await WebService(`/cliente/atualizaConfigCliente`, {configuracoes}, "POST");
    if (ok) await Swal.fire({icon: "success", title: "Configurações salvas com sucesso!"});
  };

  useEffect(() => {
    getConfig();
  }, []);

  return (
    <GridContainer>
      <GridItem lg={12} md={12} sm={12} xs={12}>
        <Card>
          <CardBody>
            <GridContainer>
              <GridItem lg={8} md={6} sm={12} xs={12}>
                <CustomInput
                  labelText="Mensagem inicio escolha departamento"
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    multiline: true,
                    rows: 5,
                    value: mensagemCabecalhoEscolhaDepartamento,
                    onChange: ({target: {value}}) => setMensagemCabecalhoEscolhaDepartamento(value)
                  }}
                />
              </GridItem>
              <GridItem lg={4} md={6} sm={12} xs={12}/>

              {/*<GridItem lg={8} md={6} sm={12} xs={12}>
                <CustomInput
                  labelText="Mensagem inicio chamado"
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    multiline: true,
                    rows: 5,
                    value: mensagemInicioChamado,
                    onChange: ({target: {value}}) => setMensagemInicioChamado(value)
                  }}
                />
              </GridItem>
              <GridItem lg={4} md={6} sm={12} xs={12}/>*/}

              <GridItem lg={8} md={6} sm={12} xs={12}>
                <CustomInput
                  labelText="Mensagem fila atendimento"
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    multiline: true,
                    rows: 5,
                    value: mensagemFilaAtendimento,
                    onChange: ({target: {value}}) => setMensagemFilaAtendimento(value)
                  }}
                />
              </GridItem>
              <GridItem lg={4} md={6} sm={12} xs={12}/>

              <GridItem lg={8} md={6} sm={12} xs={12}>
                <CustomInput
                  labelText="Mensagem assumir atendimento"
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    multiline: true,
                    rows: 5,
                    value: mensagemAssumirAtendimento,
                    onChange: ({target: {value}}) => setMensagemAssumirAtendimento(value)
                  }}
                />
              </GridItem>
              <GridItem lg={4} md={6} sm={12} xs={12}/>

              <GridItem lg={8} md={6} sm={12} xs={12}>
                <CustomInput
                  labelText="Mensagem finalização chamado"
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    multiline: true,
                    rows: 5,
                    value: mensagemFinalizacaoChamado,
                    onChange: ({target: {value}}) => setMensagemFinalizacaoChamado(value)
                  }}
                />
              </GridItem>
              <GridItem lg={4} md={6} sm={12} xs={12}>
                <p>&nbsp;</p>
              </GridItem>

              <GridItem lg={8} md={6} sm={12} xs={12}>
                {/*<ImageUpload
                  {...pictureUploadProps}
                  preview={getFotoLink(mensagemInicioChamadoImagem)}
                  accept={"image/*"}
                  onUpload={async imagem => {
                    const {ok, nomeArquivo} = await WebService(`/usuario/alterarFotoPerfil`, {
                      imagem,
                      id_usuario: ""
                    }, "POST");
                    if (ok) {
                      setMensagemInicioChamadoImagem(nomeArquivo);
                      // localStorage.setItem("usuario_foto", `${configHost.baseURL}/usuario/getFotoPerfil?foto_perfil=${nomeArquivo}`);
                      // await Swal.fire({icon: "success", title: `Foto do perfil atualizada com sucesso!`});
                    }
                  }}
                />*/}

                <PictureUpload
                  style={{float: 'left'}}
                  recortar
                  accept={"image/*"}
                  preview={getFotoLink(mensagemInicioChamadoImagem)}
                  onUpload={async imagem => {
                    const {ok, nomeArquivo} = await WebService(`/usuario/alterarFotoPerfil`, {
                      imagem,
                      id_usuario: ""
                    }, "POST");
                    if (ok) {
                      setMensagemInicioChamadoImagem(nomeArquivo);
                      // localStorage.setItem("usuario_foto", `${configHost.baseURL}/usuario/getFotoPerfil?foto_perfil=${nomeArquivo}`);
                      // await Swal.fire({icon: "success", title: `Foto do perfil atualizada com sucesso!`});
                    }
                  }}
                />
                <Button color={"primary"} onClick={() => setMensagemInicioChamadoImagem("")}>
                  Remover
                </Button>
              </GridItem>

              <GridItem lg={4} md={6} sm={12} xs={12}/>

              <GridItem lg={3} md={4} sm={6} xs={12}>
                <br/><br/>
                <Button onClick={salvarConfig.bind(this)} color={"hust"} round fullWidth>
                  Salvar
                </Button>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
