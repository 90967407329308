import React, { useEffect, useState } from "react";
import { Card, CardBody, GridContainer, GridItem, Table } from "../../components";
import CardHeader from "../../components/Card/CardHeader";
import CardIcon from "../../components/Card/CardIcon";
import BusinessIcon from "@material-ui/icons/Business";
import { infoColor, primaryColor, successColor } from "../../assets/jss/material-dashboard-pro-react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import { WebService } from "../../components/WebService";
import Success from "../../components/Typography/Success";
import Danger from "../../components/Typography/Danger";
import { FormControlLabel, Tooltip } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import { formataCPFCNPJ } from "../../components/Funcoes";
import Button from "../../components/CustomButtons/Button";
import Settings from "@material-ui/icons/Settings";
import AddIcon from "@material-ui/icons/Add";
import Swal from "sweetalert2";
import axios from "axios";

const useStyles = makeStyles(styles);

const tagColors = {
  'Hust App': primaryColor,
  'Hust Chat': infoColor,
  'Hust Menu': successColor
};

const ManagerClient = ({ history }) => {
  const classes = useStyles();

  const [ clients, setClients ] = useState([]);
  const [ filters, setFilters ] = useState({ onlyActive: true, onlyWithoutSubscription: false, onlyWithoutUsers: false });

  const getClients = async () => {
    const { ok, companies } = await WebService('/admin/getClients');
    if (ok) setClients(companies);
  };

  useEffect(() => {
    getClients();
  }, []);

  return (
    <div>
      <GridContainer>
        <GridItem lg={3} md={4} sm={6} xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <BusinessIcon/>
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                Clientes Ativos
              </h3>
            </CardHeader>
            <CardBody style={{ textAlign: 'center' }}>
              <h3 className={classes.cardTitle} style={{ color: successColor[0] }}>
                <strong>{clients.filter(el => el.active).length}</strong>
                <small></small>
              </h3>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem lg={12} md={12} sm={12} xs={12}>
          <Button round color={'hust'} onClick={() => history.push('/admin/client/new')}>
            <AddIcon/> Novo Cliente
          </Button>
          <Button
            round
            // disabled={process.env.NODE_ENV !== "development"}
            color={'hust'} simple onClick={async () => {
            const { value } = await Swal.fire({
              icon: "question",
              title: 'Deseja executar a rotina financeira para todos os clientes??',
              html: `
                <small style="float: left; text-align: left;">
                  * Verificação e atualização cadastral<br/>
                  * Realiza o faturamento das assinaturas ativas<br/>
                  * Se não for faturamento mensal, ignora por enquanto<br/>
                  * Verifica se a assinatura está ativa<br/>
                  * Verifica a data de início do faturamento<br/>
                  * Geração e atualização de contrato no gerencial<br/>
                  * Faturamento do contrato no gerencial + emissão nota fiscal (apenas se for pós pago)<br/>
                  * Emissão do boleto no Banco<br/>
                  * Criação da fatura<br/>
                  * Atualização contas pagas pelo cliente<br/>
                  * Envio da notificação para o cliente<br/>
                  * Bloqueio dos clientes inadiplentes
                </small>
              `,
              showCancelButton: true,
              cancelButtonText: 'Não',
              confirmButtonText: 'Sim'
            });
            if (!value) return;

            await axios.put(`https://api.hustapp.com/admin/company/all/run`);
          }}>
            <AddIcon/> Executar rotina para todos
          </Button>
        </GridItem>
        <GridItem lg={12} md={12} sm={12} xs={12}>
          <Card style={{ marginBottom: 0 }}>
            <CardBody>
              <FormControlLabel
                className={classes.formCheck}
                control={
                  <Checkbox
                    checked={filters.onlyActive}
                    tabIndex={-1}
                    // onClick={() => setFilterActive(!filterActive)}
                    onClick={() => setFilters({...filters, onlyActive: !filters.onlyActive})}
                    checkedIcon={<Check className={classes.checkedIcon}/>}
                    icon={<Check className={classes.uncheckedIcon}/>}
                    classes={{ checked: classes.checked, root: classes.root }}
                  />
                }
                label={'Apenas ativos'}
              />
              <FormControlLabel
                className={classes.formCheck}
                control={
                  <Checkbox
                    checked={filters.onlyWithoutSubscription}
                    tabIndex={-1}
                    onClick={() => setFilters({...filters, onlyWithoutSubscription: !filters.onlyWithoutSubscription})}
                    checkedIcon={<Check className={classes.checkedIcon}/>}
                    icon={<Check className={classes.uncheckedIcon}/>}
                    classes={{ checked: classes.checked, root: classes.root }}
                  />
                }
                label={'Apenas sem assinatura'}
              />
              <FormControlLabel
                className={classes.formCheck}
                control={
                  <Checkbox
                    checked={filters.onlyWithoutUsers}
                    tabIndex={-1}
                    onClick={() => setFilters({...filters, onlyWithoutUsers: !filters.onlyWithoutUsers})}
                    checkedIcon={<Check className={classes.checkedIcon}/>}
                    icon={<Check className={classes.uncheckedIcon}/>}
                    classes={{ checked: classes.checked, root: classes.root }}
                  />
                }
                label={'Apenas sem usuários'}
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem lg={12} md={12} sm={12} xs={12}>
          <Card>
            <CardBody>
              <Table
                hover
                tableHead={[
                  'Nome',
                  'CPF/CNPJ',
                  'Situação',
                  'Produtos',
                  'Opções'
                ]}
                tableData={clients
                  .filter(el => (filters.onlyActive ? el.active : true))
                  .filter(el => (filters.onlyWithoutSubscription ? !el.products.filter(el1 => el1).length : true))
                  .filter(el => (filters.onlyWithoutUsers ? !el.users.filter(el1 => el1).length : true))
                  .map(prop => {
                  const { id, name, vat, active, products } = prop;
                  return [
                    name,
                    formataCPFCNPJ(vat),
                    active ? (<Success>Ativo</Success>) : (<Danger>Desativado</Danger>),
                    // JSON.stringify(products),
                    products.map(prop => {
                      const tagColor = tagColors[prop] || infoColor;
                      return (
                        <p
                          style={{
                            padding: 0,
                            margin: 1,
                            backgroundColor: tagColor[0],
                            borderRadius: 5,
                            textAlign: "center",
                            color: '#FFF',
                            fontWeight: 700,
                            boxShadow: `${tagColor[3]} 0px 0px 10px -5px`
                          }}
                        >
                          {prop}
                        </p>
                      );
                    }),
                    (
                      <div>
                        <Tooltip title={'Editar'}>
                          <Button
                            round simple justIcon color={'primary'}
                            style={{ minWidth: 0, width: 25, height: 25 }}
                            onClick={() => history.push(`/admin/client/${id}`)}
                          >
                            <Settings/>
                          </Button>
                        </Tooltip>
                      </div>
                    )
                  ];
                })}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  )
}

export default ManagerClient;