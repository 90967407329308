import React, { memo, useEffect, useRef, useState } from "react";
import { Handle, Position } from "reactflow";
import { Card, CardBody } from "../../../components";
import {
  MessageOutlined,
  PermMedia,
  VolumeUp,
  Image,
  VideoCall,
  FileCopy,
  Close,
} from "@material-ui/icons";
import RemoveNode from "../components/RemoveNode";
import {
  apiURLWithoutVersion,
  WebService,
} from "../../../components/WebService";
import Swal from "sweetalert2";
import { Document, Page, pdfjs } from "react-pdf";
import Button from "../../../components/CustomButtons/Button";
import AttachFile from "@material-ui/icons/AttachFile";
import { Tooltip } from "@material-ui/core";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const mediaTypeMap = {
  image: {
    name: "Imagem",
    icon: Image,
  },
  audio: {
    name: "Audio",
    icon: VolumeUp,
  },
  video: {
    name: "Vídeo",
    icon: VideoCall,
  },
  pdf: {
    name: "PDF",
    icon: FileCopy,
  },
};

function media({ id, data }) {
  const [mediaType, setMediaType] = useState(data.mediaType || "");
  const [source, setSource] = useState(data.source || "");
  const [sticker, setSticker] = useState(!!data.sticker);
  const [fileName, setFileName] = useState(data.fileName || "");
  const [mimetype, setMimeType] = useState(data.mimetype || "");

  const { icon: Icon, name } = mediaTypeMap[mediaType || "image"];

  const attachFileRef = useRef();

  useEffect(() => {
    data.mediaType = mediaType;
    data.source = source;
    data.fileName = fileName;
    data.sticker = sticker;
    data.mimetype = mimetype;
  }, [mediaType, source, fileName, mimetype, sticker]);

  return (
    <Card
      style={{
        margin: 0,
        border: "1px solid #08365A",
        minWidth: 150,
        maxWidth: 400,
        borderRadius: 20,
        boxShadow: "#08365A 0px 0px 20px -10px",
        padding: 5,
        paddingRight: 30,
      }}
    >
      <RemoveNode id={id} />
      <CardBody style={{ padding: 5, display: "flex" }}>
        <div
          style={{
            color: "#08365A",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "calc(25% - 25px)",
            marginRight: 10,
          }}
        >
          <Icon />
        </div>
        <div>
          {/*<span style={{fontWeight: 'bold', fontSize: 13}}>
            Enviar{' '}
            <select
              onChange={({target: {value}}) => setMediaType(value)}
              value={mediaType}
            >
              <option value={''} disabled>Selecione uma opção</option>
              <option value={'image'}>Imagem</option>
              <option value={'audio'}>Áudio</option>
              <option value={'video'}>Vídeo</option>
              <option value={'pdf'}>PDF</option>
            </select>
          </span>
          <br/>*/}
          {/*mediaType, source, fileName, mimeType*/}
          {mediaType === "image" && (
            <>
              <input
                type={"checkbox"}
                checked={sticker}
                onClick={() => setSticker(!sticker)}
              />{" "}
              Enviar como sticker
              <br />
            </>
          )}
          <br />
          <Button
            color={"linkedin"}
            round
            justIcon
            onClick={() => attachFileRef.current.click()}
          >
            <AttachFile />
          </Button>
          {fileName ? (
            <>
              <Tooltip
                title={"Excluir arquivo"}
                onClick={() => {
                  setSource("");
                  setFileName("");
                  setMimeType("");
                  setMediaType("");
                }}
              >
                <Button justIcon simple color={"primary"}>
                  <Close />
                </Button>
              </Tooltip>
              <div
                style={{
                  alignContent: "center",
                  justifyContent: "center",
                  display: "flex",
                  flexWrap: "wrap",
                  marginLeft: 10,
                }}
              >
                <span style={{ width: "100%" }}>Tipo: {mediaType}</span>
                <span style={{ width: "100%" }}>Nome: {fileName}</span>
              </div>
            </>
          ) : (
            <div
              style={{
                alignContent: "center",
                justifyContent: "center",
                display: "flex",
                flexWrap: "wrap",
                marginLeft: 10,
              }}
            >
              <span style={{ width: "100%" }}>Escolha um arquivo</span>
            </div>
          )}
          {/*<small>Arquivo</small><br/>*/}
          {/*<input value={source} onChange={({target: {value}}) => setSource(value)}/> <br/>*/}
          <input
            type={"file"}
            accept={".jpg,.jpeg,.png,.mp4,.pdf,.mp3"}
            style={{ display: "none" }}
            ref={attachFileRef}
            onChange={async (e) => {
              try {
                e.preventDefault();
                let reader = new FileReader();
                let file = e.target.files[0];
                const { name, type: mimeType } = file;
                const [type, extension] = mimeType.split("/");
                const types = {
                  jpg: "image",
                  jpeg: "image",
                  png: "image",
                  mp4: "video",
                  pdf: "pdf",
                  mp3: "audio",
                  mpeg: "audio",
                };

                // TODO - Upload Arquivo

                const fileBase64 = await new Promise((resolve) => {
                  reader.onloadend = () => resolve(reader.result);
                  reader.readAsDataURL(file);
                });

                const { ok, id_arquivo_midia, fullFileName } = await WebService(
                  `/whatsapp_api/uploadMidia`,
                  {
                    base64: fileBase64.split("base64,")[1],
                    nome_arquivo: name,
                    mimetype: mimeType,
                  },
                  "POST"
                );

                if (!ok)
                  return Swal.fire({
                    icon: "error",
                    title: "Não foi possível fazer upload do arquivo.",
                  });

                const [uuid] = fullFileName.split(".");
                setSource(
                  `${apiURLWithoutVersion}/v1/sistema/getFileByUUID?uuid=${uuid}`
                );
                setFileName(name);
                setMimeType(mimeType);
                setMediaType(types[extension]);
                // console.log({
                //   url: `${apiURLWithoutVersion}/v1/sistema/getFileByUUID?uuid=${uuid}`,
                //   name,
                //   type: types[extension]
                // });
                // setCampaignMessage(campaignMessage => {
                //   campaignMessage.notification.file = {
                //     url: `${apiURLWithoutVersion}/v1/sistema/getFileByUUID?uuid=${uuid}`,
                //     name,
                //     type: types[extension]
                //   };
                //   return {...campaignMessage};
                // });
              } finally {
                // delete e.target.files;
                // e.target.files = null;
                // attachFileRef.current.files = [];
              }
            }}
          />
          <br />
          {/*<small>Nome do arquivo</small><br/>
          <input value={fileName} onChange={({target: {value}}) => setFileName(value)}/> <br/>*/}
          {mediaType === "image" && (
            <img
              src={source}
              alt={fileName}
              style={{
                width: 187,
                borderRadius: 5,
                boxShadow: "#000 0px 0px 10px -3px",
                marginTop: 20,
              }}
            />
          )}
          {mediaType === "pdf" && (
            <Document
              file={source}
              align="center"
              style={{ textAlign: "-webkit-center" }}
            >
              <Page pageNumber={1} height={300} />
            </Document>
          )}
        </div>
        {/*{mediaType === 'audio' && (*/}
        {/*  <video controls="" preload="none">*/}
        {/*    <source src="https://apiv1.hustapp.com/v1/chat/getMediaFromMessageID?id_mensagem=82497842&amp;token=SZrAS65UB99cywajiQh3NH5b8rNxn1QXCdCBELNo4K0A2mIJ2F" type="audio/ogg"/>*/}
        {/*  </video>*/}
        {/*)}*/}
      </CardBody>

      <Handle
        type="target"
        position={Position.Left}
        className="w-16 !bg-teal-500"
      />
      <Handle
        type="source"
        position={Position.Right}
        className="w-16 !bg-teal-500"
      />
    </Card>
  );
}

export default memo(media);
