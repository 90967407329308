import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ExitToApp from "@material-ui/icons/ExitToApp";
import Button from "../CustomButtons/Button";
import styles from "../../assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import {sairSistema} from "../Funcoes";

const useStyles = makeStyles(styles);

export default function HeaderLinks({setToken}) {
  const classes = useStyles();

  return (
    <div>
      <Button
        color="transparent"
        simple
        aria-label="Dashboard"
        justIcon
        className={classes.buttonLink}
        muiClasses={{label: ""}}
        onClick={sairSistema.bind(this)}
      >
        <ExitToApp className={classes.headerLinksSvg + " " + (classes.links)}/>
      </Button>
    </div>
  );
}

HeaderLinks.propTypes = {
  rtlActive: PropTypes.bool
};
