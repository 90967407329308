import React, {useEffect, useState} from 'react';
import {Backdrop, Fade, Modal} from "@material-ui/core";
import {useStyles} from "../NewChat/TabsInfo/styles";
import Button from '../../components/CustomButtons/Button';
import {GridContainer, Table} from "../../components";
import {convertDate, isValidDate} from "../../components/Funcoes";
import api from "../../components/api";
import Success from "../../components/Typography/Success";
import Danger from "../../components/Typography/Danger";
import Info from "../../components/Typography/Info";
import {Close} from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import Swal from "sweetalert2";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import GridItem from "../../components/Grid/GridItem";

const ModalScheduledMessages = ({open, onClose, idContact, contactName, idConnection}) => {
  const classes = useStyles();

  const [messages, setMessages] = useState([]);
  const [scheduleMessage, setScheduleMessage] = useState({date: new Date(), text: ''});

  const getMessages = async () => {
    const {data} = await api.get(`/message/schedule/${idContact}`);
    setMessages(data);
  };

  const deleteMessage = async id => {
    const {value} = await Swal.fire({
      icon: "question",
      title: 'Deseja realmente excluir a mensagem?',
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    });
    if (!value) return;
    await api.delete(`/message/schedule/${id}`);
    await Swal.fire({icon: "success", title: 'Mensagem excluida com sucesso!'});
    getMessages();
  };

  const scheduleNewMessage = async () => {
    const {value} = await Swal.fire({
      // text: 'Digite a mensagem que deseja agendar',
      input: 'textarea',
      inputLabel: 'Message',
      inputPlaceholder: 'Type your message here...',
      inputAttributes: {
        'aria-label': 'Type your message here'
      },
      showCancelButton: true,
      confirmButtonText: 'Próximo',
      cancelButtonText: 'Cancelar'
    });

    // await Swal.fire({input: 'datetime'});
  };
  const [tab, setTab] = useState(0);

  useEffect(() => {
    setMessages([]);
    setTab(0);
    if (open && idContact) getMessages();
  }, [open]);

  useEffect(() => {
    setScheduleMessage({text: '', date: new Date()});
  }, [tab]);

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{timeout: 200}}
      style={{maxWidth: 800, marginLeft: 'auto', marginRight: 'auto'}}
    >
      <Fade in={open}>
        {/*<div className={classes.paperModal} style={{border: 'none', minWidth: 600}}>*/}
        <div className={classes.paperModal} style={{border: 'none'}}>
          <h4 style={{fontFamily: 'NunitoRegular', fontSize: 30, marginBottom: 0, color: '#08365A'}}>
            MENSAGENS AGENDADAS
          </h4>
          <hr style={{
            borderBottom: `4px solid #08365A`,
            width: '40%',
            float: 'left',
            marginTop: 0,
            marginBottom: 0
          }}/>
          <br/>
          {tab === 0 ? (
            <>
              <span style={{fontFamily: 'NunitoRegular', color: '#08365A'}}>
                Essas são as mensagens agendadas para serem enviadas para <strong>{contactName}</strong>.
              </span>
              <br/><br/>
              <div style={{position: 'relative'}}>
                <Table
                  // tableHead={[]}
                  hover
                  tableData={messages.map(prop =>
                    [
                      convertDate(prop.dateToSend, false, true),
                      prop.text,
                      <small>{prop.user.name}</small>,
                      prop.flagSent ? <Success>Enviada</Success> : prop.flagError ? <Danger>Falha no envio</Danger> :
                        <Info>Aguardando</Info>,
                      !prop.flagSent && !prop.flagError && (
                        <Tooltip title={'Excluir mensagem'}>
                          <Button
                            color={'primary'}
                            style={{
                              margin: '0px 1px',
                              padding: 0,
                              width: 25,
                              height: 25,
                              minWidth: 25
                            }}
                            onClick={() => deleteMessage(prop.id)}
                            simple
                            round
                            justIcon
                          >
                            <Close/>
                          </Button>
                        </Tooltip>
                      )
                    ]
                  )}
                />
              </div>
            </>
          ) : ''}
          {tab === 1 ? (
            <GridContainer>
              <GridItem lg={12} md={12} sm={12} xs={12}>
                <TextareaAutosize
                  maxRows={30}
                  minRows={5}
                  placeholder="Digite a mensagem que deseja agendar..."
                  className={classes.inputText}
                  value={scheduleMessage.text}
                  style={{width: '100%', borderRadius: 10, fontFamily: 'NunitoRegular', fontSize: 16}}
                  onChange={({target: {value}}) => setScheduleMessage({...scheduleMessage, text: value})}
                />
                <br/>
              </GridItem>
              <GridItem lg={6} md={6} sm={6} xs={6}>
                <br/>
                <InputLabel className={classes.label} style={{marginBottom: -10}}>
                  Data de envio
                </InputLabel>
                <br/>
                <FormControl fullWidth>
                  <Datetime
                    timeFormat={true}
                    onChange={e => typeof e === 'object' ? setScheduleMessage({
                      ...scheduleMessage,
                      date: e.toDate()
                    }) : (e.length === 10 ? (isValidDate(new Date(e.split("/").reverse().join("-"))) ? setScheduleMessage({
                      ...scheduleMessage,
                      date: new Date(e.split("/").reverse().join("-"))
                    }) : "") : "")}
                    value={scheduleMessage.date}
                    inputProps={{placeholder: "Data de envio", id: 'data-envio'}}
                  />
                </FormControl>
              </GridItem>
            </GridContainer>
          ) : ''}
          <div
            style={{
              marginTop: 10,
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Button style={{borderRadius: '24px 24px 24px 0px'}} onClick={onClose}>
              Fechar
            </Button>
            <div>
              <Button
                color={tab === 0 ? 'hust' : 'github'}
                round
                // style={{borderRadius: '24px 24px 0px 24px'}}
                onClick={() => setTab(tab === 0 ? 1 : 0)}
              >
                {tab === 0 && 'Nova mensagem'}
                {tab === 1 && 'Ver mensagens'}
              </Button>
              {tab === 1 && (
                <Button
                  color={'hust'}
                  round
                  onClick={async () => {
                    const message = {
                      text: scheduleMessage.text,
                      dateToSend: scheduleMessage.date,
                      connection: { id: idConnection },
                      contact: { id: idContact },
                    };

                    await api.post(`/message/schedule`, message);
                    setScheduleMessage({text: '', date: new Date()})
                    await Swal.fire({icon: "success", title: 'Mensagem agendada com sucesso!'});
                    setTab(0);
                  }}
                >
                  Agendar
                </Button>
              )}
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default ModalScheduledMessages;