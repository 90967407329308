import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
  },

  revenda_grid: {
    marginTop: '50px', 

  },
  media: {
    border: '#fff 3px solid'
  }

}));
