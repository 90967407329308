import React, { useCallback, useEffect, useRef, useState } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DialogActions, DialogContent, IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { Close } from '../../../assets/img/icons';
import Dialog from '@material-ui/core/Dialog';
import { useStyles } from '../../NewChat/TabsInfo/styles';
import styles from '../../../assets/jss/material-dashboard-pro-react/views/registerPageStyle';
import Button from '../../../components/CustomButtons/Button';
import CloseIcon from '@material-ui/icons/Close';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { useStyles as useStyles2 } from '../../Configs/RespostasRapidas/styles';
import SendIcon from '@material-ui/icons/Send';
import Webcam from 'react-webcam';
import { Camera, Cached } from '@material-ui/icons';
import Swal from "sweetalert2";

const useStyles1 = makeStyles({
  ...styles,
  modalNew: { borderRadius: 16 },
  fileList: {
    transition: '.2s',
    borderRadius: 15,
    margin: '5px 0',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    // backgroundColor: '#FFF',
    '&:hover': {
      backgroundColor: '#EEE',
      cursor: 'pointer',
    },
  },
});
const ModalUploadMediaCamera = ({ open, onClose, onUpload }) => {
  const classes = { ...useStyles(), ...useStyles1(), ...useStyles2() };

  const [files, setFiles] = useState([]);
  const [camState, setCamState] = useState('');

  // *************************
  const [deviceKey, setDeviceKey] = useState(0);
  const [devices, setDevices] = useState([]);
  const [frontCameraDevice, setFrontCameraDevice] = useState(true);

  const handleDevices = useCallback(
    mediaDevices => setDevices(mediaDevices.filter(({ kind }) => kind === 'videoinput')),
    [setDevices],
  );

  useEffect(() => {
    if (camState !== 'granted') return;
    if (navigator.mediaDevices && typeof navigator.mediaDevices.enumerateDevices === "function")
      navigator.mediaDevices.enumerateDevices().then(handleDevices);
    else
      console.log("enumerateDevices não é suportado neste navegador.");

    // navigator.mediaDevices.enumerateDevices().then(data => {
    //   console.log('devices', data);
    // });
  }, [camState, handleDevices]);
  // *************************

  const inicializeCamera = async () => {
    try {
      const { state } = await navigator.permissions.query({ name: 'camera' });
      setCamState(state);
      if (state === 'granted') {
        return true;
      } else if (state === 'prompt') {
        await Swal.fire({
          title: 'Você deve fornecer permissão para acesso a camera.',
          text: 'Será aberto um popup do navegador solicitando a permissão.',
          icon: 'info',
        });
        await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
        const { state: newState } = await navigator.permissions.query({ name: 'camera' });
        setCamState(newState);
      } else if (state === 'denied') {
        await Swal.fire({
          title: 'O sistema não possui permissão para acessar a câmera.',
          text: 'Verifique se as conexões do navegador não está bloqueando o acesso a camera.',
          icon: 'warning',
        });
        return false;
      }
    } catch (e) {
      Swal.fire({ icon: "error", title: 'Ocorreu um erro.', text: e.toString() });
    }
  }

  const webcamRef = useRef(null);
  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setFiles(files => {
      files.push({ name: `IMG_CAMERA_${files.length + 1}.jpg`, type: 'image', src: imageSrc });

      return [...files];
    });
  }, [webcamRef, files]);

  useEffect(() => {
    if (open) inicializeCamera();
    setFiles([]);
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      classes={{ paper: classes.modalNew }}
      // onClick={({target}) => {
      //   console.log(target);
      // }}
    >
      <DialogTitle>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
          <div />
          <IconButton onClick={onClose} style={{ width: 30, height: 30, padding: 0 }}>
            <img src={Close} alt={'close'} />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent style={{ overflowX: 'hidden' }}>
        <div style={{ position: 'relative' }}>
          {/*{JSON.stringify(devices)}*/}
          {devices.length ? (
            <>
              <div>
                <span
                  style={{
                    fontFamily: 'NunitoRegular',
                    fontSize: 20,
                    textAlign: 'center',
                    width: '100%',
                    display: 'block',
                  }}
                >
                  {devices[deviceKey].label}
                  {/*<br/>{devices[deviceKey].deviceId || 'Nada'}*/}
                  {/*<br/>{frontCameraDevice.toString()}*/}
                </span>
                <Webcam
                  audio={false}
                  // height={720}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  style={{ maxWidth: '100%' }}
                  videoConstraints={{
                    width: 1280,
                    height: 720,
                    facingMode: 'user',
                    // facingMode: frontCameraDevice ? 'user' : { exact: 'environment' },
                    deviceId: devices[deviceKey].deviceId,
                  }}
                />
              </div>
            </>
          ) : (
            <div style={{ width: '100%', textAlign: 'center' }}>
              <h4>Nenhuma camera disponível</h4>
            </div>
          )}
        </div>
        <div style={{ display: 'flex' }}>
          {files.map((prop, key) => {
            return (
              <div>
                <img
                  alt={prop.name}
                  src={prop.src}
                  style={{ height: 40, margin: '0 5px', borderRadius: 10, boxShadow: '#000 0px 0px 11px -5px' }}
                />
              </div>
            );
          })}
        </div>
      </DialogContent>

      <DialogActions style={{display: 'block'}}>
        <div
          style={{
            width: '100%',
            zIndex: 999999,
            osition: 'relative',
            alignItems: 'center',
            display: 'flex',
            margin: '0 auto',
            justifyContent: 'center',
            height: 20
          }}
        >
          <Button
            round
            justIcon
            style={{
              // position: 'absolute',
              // bottom: -25,
              // left: '50%',
              // transform: 'translate(-50%, -50%)',
              width: 60,
              height: 60,
            }}
            color={'primary'}
            onClick={capture}
          >
            <Camera/>
          </Button>
          <Button
            round
            justIcon
            style={{
              // position: 'absolute',
              // top: -25,
              // left: 'calc(50% + 70px)',
              // transform: 'translate(-50%, -50%)',
              width: 40,
              height: 40,
            }}
            color={'warning'}
            onClick={() => {
              console.log(deviceKey, devices.length);
              setDeviceKey(deviceKey + 1 >= devices.length ? 0 : deviceKey + 1);
              setFrontCameraDevice(!frontCameraDevice);
            }}
          >
            <Cached/>
          </Button>
        </div>
        {/*<div>*/}
          <div
            style={{
              margin: '10px 10px 0px 10px',
              display: 'flex',
              justifyContent: 'space-between',
              width: 'calc(100% - 20px)',
            }}
          >
            <Button
              style={{
                borderRadius: '25px 25px 25px 0px',
                backgroundColor: '#637085',
                minHeight: 48,
                minWidth: 100,
              }}
              onClick={onClose}
            >
              Fechar
            </Button>
            <Button
              style={{
                // borderRadius: '14px 14px 0px 14px',
                // backgroundColor: '#637085',
                minHeight: 48,
                minWidth: 100,
              }}
              color={'hust'}
              round
              disabled={!files.length}
              onClick={() => {
                onClose();
                onUpload(files);
              }}
            >
              Próximo&nbsp;&nbsp;
              <SendIcon/>
            </Button>
          </div>
        {/*</div>*/}
      </DialogActions>
    </Dialog>
  );
};

export default ModalUploadMediaCamera;
