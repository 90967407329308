import React from 'react';
import {makeStyles} from '@material-ui/core';
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import {infoColor, primaryColor, successColor} from "../../../assets/jss/material-dashboard-pro-react";
import {pontuacaoNumero} from "../../../components/Funcoes";
import CardIcon from "../../../components/Card/CardIcon";
import CardFooter from "../../../components/Card/CardFooter";
import dashboardStyle from "../../../assets/jss/material-dashboard-pro-react/views/dashboardStyle";

const useStyles = makeStyles(dashboardStyle);

const colors = {
  primary: primaryColor,
  success: successColor,
  info: infoColor
};

const StatCard = React.memo(({title, value, icon: Icon, color}) => {
  const classes = useStyles();

  return (
    <GridItem xs={12} sm={4} md={3} lg={3}>
      <Card>
        <CardHeader color={color || 'primary'} icon>
          <p className={classes.cardCategory} style={{fontSize: '18px'}}>
            {title}
          </p>
          <br></br>
          <div stats style={{display: 'flex', justifyContent: 'space-between'}}>
            <center>
              <h3 className={classes.cardTitle} style={{marginBottom: '-10px', color: (colors[color || 'primary'] || primaryColor)[0]}}>
                <b>{value}</b> <small></small>
              </h3><br/>
              <div className={classes.stats}>
                Atualizado agora
              </div>
            </center>

            <CardIcon color={color || 'primary'} style={{width: '50px', height: '50px', float: 'right'}}>
              <Icon style={{margin: '0 auto'}}/>
            </CardIcon>
          </div>
        </CardHeader>
        <CardFooter/>
      </Card>
    </GridItem>
  );
});

export default StatCard;