import React from 'react';

const TextBox = ({label, rows, value, style, onChange, type, disabled}) => {
  return (
    <div style={{marginTop: 5, marginBottom: 5}}>
      <span style={{ color: '#1A1926', fontSize: 15 }}>
        {label}
      </span>
      {rows ? (
        <textarea
          disabled={disabled}
          style={{
            resize: 'none',
            width: `100%`,
            // height: 40,
            borderRadius: 14,
            fontSize: 14,
            border: '1px solid var(--Border-100, #EBEEF2)',
            padding: 10,
            fontFamily: 'NunitoRegular',
            ...(style || {})
          }}
          rows={rows}
          placeholder={'...'}
          value={value}
          onChange={({ target: { value } }) => onChange(value)}
        />
      ): (
        <input
          type={type || 'text'}
          style={{
            resize: 'none',
            // width: 240,
            width: `100%`,
            height: 40,
            borderRadius: 14,
            fontSize: 14,
            border: '1px solid var(--Border-100, #EBEEF2)',
            paddingLeft: 10,
            ...(style || {})
          }}
          onChange={({ target: { value } }) => onChange(value)}
          placeholder={'...'}
          value={value}
          disabled={disabled}
        />
      )}
    </div>
  );
};

export default TextBox;