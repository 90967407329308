const styleMessage = {
  messageContainer: ({sent}) => ({
    width: '100%',
    display: "flex",
    position: "relative",
    justifyContent: `flex-${sent ? 'end' : 'start'}`,
  }),
  nessageBox: ({sent}) => ({
    boxShadow: `0px 2px 8px rgba(0, 0, 0, 0.25)`,
    borderRadius: `1px 24px 24px 24px`,
    margin: '20px 0px',
    padding: `10px 20px`,
    maxWidth: '60%',
    width: 'fit-content'
    , ...(sent ? {
      background: `#E94D3F`,
      color: '#FFFFFF',
      borderRadius: `24px 1px 24px 24px`
    } : {
      background: `#F5F7FA`,
      color: '#28263B',
    })
  }),
  MessageBoxImage: {
    width: '100%',
    borderRadius: 16,
    '&:after': {
      content: '""',
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      background: 'rgba(0,0,0,0.5)',
    }
  },
  MessageBoxFileIcon: {
    height: 40,
    borderRadius: 0,
    marginRight: 10,
  },
  MessageBoxFileName: {
    fontFamily: 'NunitoRegular',
    fontWeight: 400,
    // color: '#1A1926',
    color: '#FFF',
    fontSize: 15
  }
};

export default styleMessage;