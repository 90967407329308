import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Email from "@material-ui/icons/Email";
import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";
import CustomInput from "../components/CustomInput/CustomInput.js";
import Button from "../components/CustomButtons/Button.js";
import Card from "../components/Card/Card.js";
import CardBody from "../components/Card/CardBody.js";
import CardHeader from "../components/Card/CardHeader.js";
import CardFooter from "../components/Card/CardFooter.js";
import stylesLogin from "../assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import stylesLock from "../assets/jss/material-dashboard-pro-react/views/lockScreenPageStyle.js";
import {WebService} from "../components/WebService";
import * as Swal from "sweetalert2";
import CardAvatar from "../components/Card/CardAvatar";
import {alterarSenhaUsuario, ls} from "../components/Funcoes";
import FacebookProvider, {Login} from 'react-facebook-sdk';
import defaultAvatar from "../assets/img/faces/avatar.jpg"
import api from "../components/api";

const Logo = require("../assets/img/logo.svg");
const useStylesLogin = makeStyles(stylesLogin);
const useStylesLock = makeStyles(stylesLock);

export default function LoginPage({setToken, assets}) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [lock, setLock] = useState(!!ls('usuario_email'));
  const [visualizarSenha, setVisualizarSenha] = useState(false);
  const [recuperarSenha, setRecuperarSenha] = useState(false);

  const classes = (lock ? useStylesLock : useStylesLogin)();

  const realizaLoginFacebook = async ({profile, tokenDetail}) => {
    const {name, id, email} = profile;

    const {accessToken} = tokenDetail;
    localStorage.setItem('usuario_nome', name);
    localStorage.setItem('usuario_email', email);
    await realizaLogin(accessToken, id);
  };

  const realizaLogin = async () => {
    const e = lock ? ls('usuario_email') : email;
    const {ok, token, flag_gestor, flag_revenda, localConfigHost, feature_groups, flag_redefinir_senha} = await WebService(`/login/login`, {email: e, senha}, "POST");
    if (ok) {
      // if (window.isNativeApp) {
      //   localStorage.setItem('usuario_email', email);
      //   localStorage.setItem('usuario_nome', 'Italo Tavares');
      //   localStorage.setItem('usuario_foto', 'https://storage.hustapp.com/foto_perfil/HfzxPxvj8rPc6Xz00nZBFG9hbM6ICFB3ICKxCe4K8Z3vgNaY5b.png');
      // }
      localStorage.setItem('usuario_gestor', flag_gestor);
      localStorage.setItem('cliente_revenda', flag_revenda);
      localStorage.setItem('localConfigHost', JSON.stringify(localConfigHost || ''));
      localStorage.setItem('feature-groups', feature_groups);
      localStorage.setItem('token', token);
      if (flag_redefinir_senha) await alterarSenhaUsuario({token}, false);
      window.location.reload();
      // setToken(token);
    }
  };

  const onSubmit = async e => {
    e.preventDefault();
    await realizaLogin();
  };

  const recuperarAcesso = async () => {
    try {
      Swal.fire({title: "Carregando", allowOutsideClick: false});
      Swal.showLoading();
      await api.post(`/auth/password-reset`, {email});
      await Swal.fire({
        icon: "success",
        title: "Recuperação realiza com sucesso!",
        // text: `Se o email informado possuir um cadastro, `
      });
      setRecuperarSenha(false);
    } finally {
      Swal.hideLoading();
    }
  };

  setTimeout(function () {
    setCardAnimation("");
  }, 400);

  if (lock) {
    return (
      <div className={classes.container}>
        <form onSubmit={onSubmit}>
          <Card profile className={classes.customCardClass + " " + classes[cardAnimaton]}>
            <CardAvatar profile className={classes.cardAvatar}>
              <a href="#" onClick={e => e.preventDefault()}>
                <img src={ls('usuario_foto') || defaultAvatar} alt="..."/>
              </a>
            </CardAvatar>
            <CardBody profile>
              <h4 className={classes.cardTitle}>{ls('usuario_nome')}</h4>
              <CustomInput
                labelText="Senha"
                formControlProps={{fullWidth: true}}
                inputProps={{
                  type: visualizarSenha ? 'text' : 'password',
                  autoComplete: "off",
                  onChange: ({target}) => setSenha(target.value),
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon
                        className={classes.inputAdornmentIcon}
                        onClick={() => setVisualizarSenha(!visualizarSenha)}
                        style={{cursor: 'pointer'}}
                      >
                        {visualizarSenha ? 'remove_red_eye' : 'lock_outline'}
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              />
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
              <GridContainer justify={"center"}>
                <GridItem lg={12} md={12} sm={12} xs={12}>
                  <Button color="hust" round onClick={realizaLogin.bind(this)}>
                    Acessar
                  </Button>
                  <Button
                    color="hust"
                    round
                    simple
                    fullWidth
                    onClick={() => {
                      localStorage.setItem('usuario_email', "");
                      setLock(false)
                    }}
                  >
                    Outro usuário
                  </Button>
                </GridItem>
              </GridContainer>
            </CardFooter>
          </Card>
        </form>
      </div>
    );
  }

  if (recuperarSenha) {
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <form onSubmit={recuperarAcesso}>
              <Card login className={classes[cardAnimaton]}>
                <CardHeader className={`${classes.cardHeader} ${classes.textCenter}`} color="primary">
                  <h4 className={classes.cardTitle}>Recuperar Senha</h4>
                  <p>&nbsp;</p>
                </CardHeader>
                <CardBody>
                  <CustomInput
                    labelText="Usuário ou email"
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      onChange: ({target: {value}}) => setEmail(value.toLowerCase()),
                      value: email,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email className={classes.inputAdornmentIcon}/>
                        </InputAdornment>
                      )
                    }}
                  />
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <GridContainer>
                    <GridItem lg={12} md={12} sm={12} xs={12}>
                      <Button color="hust" round fullWidth block onClick={recuperarAcesso.bind(this)}>
                        Recuperar
                      </Button>
                      <Button color="primary" simple fullWidth block onClick={() => setRecuperarSenha(false)}>
                        Voltar para login
                      </Button>
                    </GridItem>
                  </GridContainer>
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form onSubmit={onSubmit}>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="primary"
              >
                <img
                  alt={"logo"}
                  src={assets.login}
                  style={{width: 'calc(100% - 50px)', marginTop: 10, marginBottom: 10}}
                />
                {/*<h4 className={classes.cardTitle} style={{fontSize: 12}}>
                  {"plataforma de atendimento digital".toUpperCase()}
                </h4>*/}
                {/*<h4 className={classes.cardTitle}>Acessar o sistema</h4>
                <div className={classes.socialLine}>
                  <FacebookProvider appId="211779299985941">
                    <Login
                      scope={"email"}
                      onResponse={realizaLoginFacebook}
                      onError={() => Swal.fire({icon: "warning", title: "Ocorreu um erro ao entrar com o Facebook"})}
                    >
                      <Button color="transparent" justIcon className={classes.customButtonClass}>
                        <i className={`fab fa-facebook-square`}/>
                      </Button>
                    </Login>
                  </FacebookProvider>
                </div>*/}
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText="Usuário ou email"
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    onChange: ({target}) => setEmail((target.value).toLowerCase()), value: email,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputAdornmentIcon}/>
                      </InputAdornment>
                    )
                  }}
                />
                <CustomInput
                  labelText="Senha"
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    onChange: ({target}) => setSenha(target.value),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon
                          className={classes.inputAdornmentIcon}
                          onClick={() => setVisualizarSenha(!visualizarSenha)}
                          style={{cursor: 'pointer'}}
                        >
                          {visualizarSenha ? 'remove_red_eye' : 'lock_outline'}
                        </Icon>
                      </InputAdornment>
                    ),
                    type: visualizarSenha ? 'text' : 'password',
                    autoComplete: "off"
                  }}
                />
                <input type="submit" style={{visibility: 'hidden'}}/>
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <GridContainer>
                  <GridItem lg={12} md={12} sm={12} xs={12}>
                    <Button color="hust" round fullWidth block onClick={realizaLogin.bind(this)}>
                      Acessar
                    </Button>
                    <Button color="primary" round simple fullWidth onClick={() => setRecuperarSenha(true)}>
                      Esqueci minha senha
                    </Button>
                  </GridItem>
                </GridContainer>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
