import axios from "axios";
import Swal from "sweetalert2";
import { checkBeta } from "../views/BetaControll";

// export const baseURLV2 = checkBeta('new-api-endpoint') ? `https://api.hustapp.com/v2` : `https://api.macrochat.com.br/v2`;
export const baseURLV2 = checkBeta("new-api-endpoint")
  ? `https://api.hustapp.com/v2`
  : `https://api.hustapp.com/v2`;
const api = axios.create({ baseURL: baseURLV2 });

let timeout;
api.interceptors.request.use((req) => {
  // timeout = setTimeout(() => {
  //   Swal.fire({title: "Carregando", allowOutsideClick: false});
  //   Swal.showLoading();
  // }, 800);
  const token  = localStorage.getItem('PARTNER_TOKEN') || localStorage.getItem('token');
  req.headers.token = token;
  req.headers.authorization = `Bearer ${token}`;
  req.headers["app-version"] = "1.0.6";
  return req;
});

api.interceptors.response.use(
  (res) => {
    clearTimeout(timeout);
    Swal.close();
    // TODO - Tratar erros
    return res;
  },
  (e) => {
    clearTimeout(timeout);
    Swal.close();

    const { message, userMessage } = e?.response?.data;
    const title = userMessage || "Ocorreu um erro na requisição.";
    const text = message;

    Swal.fire({ icon: "warning", title, text });
    throw e;
  }
);

export default api;
