/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import {makeStyles} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import styles from "assets/jss/material-dashboard-pro-react/components/footerStyle.js";
const useStyles = makeStyles(styles);

export default function Footer(props) {
    const classes = useStyles();
    const {fluid, white, assets} = props;
    const container = cx({[classes.container]: !fluid, [classes.containerFluid]: fluid, [classes.whiteColor]: white});
    const anchor = classes.a + cx({[" " + classes.whiteColor]: white});
    const block = cx({[classes.block]: true, [classes.whiteColor]: white});
    return '';
    return (
        <footer className={classes.footer}>
            <div className={container}>
                {/*<div className={classes.left}>
                    <List className={classes.list}>
                        <ListItem className={classes.inlineBlock}>
                            <a href="https://macrochat.com.br" className={block}>
                                {assets['nomeAplicacao']}
                            </a>
                        </ListItem>
                    </List>
                </div>*/}
                <p className={classes.right}>
                    &copy; {1900 + new Date().getYear()}{" "}
                    <a href="https://hustapp.com" className={anchor} target="_blank">
                        {assets['nomeAplicacao']}
                    </a>
                </p>
            </div>
        </footer>
    );
}

Footer.propTypes = {
    fluid: PropTypes.bool,
    white: PropTypes.bool,
    rtlActive: PropTypes.bool
};
