import React, {useEffect} from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

// core components
import Button from "components/CustomButtons/Button.js";

import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

import AutorenewIcon from '@material-ui/icons/Autorenew';
import { CustomButtons } from "components";

export const pictureUploadProps = {
  fullWidth: true,
  addButtonProps: {color: 'info', fullWidth: true},
  changeButtonText: "Alterar imagem",
  removeButtonText: "Remover Imagem",
  selectButtonText: "Selecionar imagem"
};

export default function ImageUploadRevenda(props) {
  const {onUpload, accept} = props;

  const [file, setFile] = React.useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(props.avatar ? defaultAvatar : defaultImage);
  let fileInput = React.createRef();

  useEffect(() => {
    if (props.preview) setImagePreviewUrl(props.preview);
  }, [props.preview]);

  const handleImageChange = e => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setFile(file);
      setImagePreviewUrl(reader.result);
      if (onUpload) onUpload(reader.result);
    };
    reader.readAsDataURL(file);
  };
  // eslint-disable-next-line
  const handleSubmit = e => {
    e.preventDefault();
    // file is the file/image uploaded
    // in this function you can save the image (file) on form submit
    // you have to call it yourself
  };
  const handleClick = () => {
    fileInput.current.click();
  };
  const handleRemove = () => {
    setFile(null);
    setImagePreviewUrl(props.avatar ? defaultAvatar : defaultImage);
    if (onUpload) onUpload("");
    fileInput.current.value = null;
  };
  let {avatar, addButtonProps, changeButtonProps, removeButtonProps} = props;
  return (
    <div className="fileinput text-center" style={{width: props.fullWidth ? '100%' : undefined}}>
      <input type="file" onChange={handleImageChange} ref={fileInput} accept={accept}/>
      <div>
        {props.preview === null ? (
          <Button {...addButtonProps} onClick={() => handleClick()}>
            {avatar ? "Add Photo" : props.selectButtonText}
          </Button>
        ) : (
          <span>
            
            <IconButton onClick={() => handleClick()} style={{background: 'white',color: '#0098DA', marginRight: '10%', cursor: 'pointer', boxShadow: 'none'}} title="Alterar imagem">
              <AutorenewIcon fontSize="medium" />
            </IconButton>
            {avatar ? null : null}
            <IconButton onClick={() => handleRemove()} style={{background: 'white',color: '#CD0000',  marginLeft: '10%', cursor: 'pointer', boxShadow: 'none', }}  title="Remover imagem">
                <DeleteIcon fontSize="medium"/>
            </IconButton>
          </span>
        )}
      </div>
    </div>
  );
}

ImageUploadRevenda.propTypes = {
  avatar: PropTypes.bool,
  fullWidth: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
  removeButtonText: PropTypes.string,
  changeButtonText: PropTypes.string,
  selectButtonText: PropTypes.string,
  preview: PropTypes.string
};
