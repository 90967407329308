import React from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';

import {Tabs, Tab} from '@material-ui/core';

import bg from '../../assets/img/backgroundChat.webp';

import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import notificationsStyle from "../../assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import styleMessage from "../../components/v2/Chat/Message/style";
import {checkBeta} from "../BetaControll";

export const drawerWidth = 336;

export const useStyles = makeStyles(theme => ({
  ...notificationsStyle(theme),
  // ...(checkBeta('new-send-message') ? styleMessage : {}),
  ...styleMessage,
  root: {
    display: 'flex',
    margin: 0,
  },
  main: {
    height: '100vh',
    backgroundColor: '#FFFFFF',
  },
  iconColorWhite: {
    color: '#FFFFFF',
  },
  iconColorBlueMain: {
    color: '#115877',
  },
  iconColorBlueSecondary: {
    color: '#2B86AE',
  },
  iconColorPrimary: {
    color: '#FF3115',
  },
  headerMain: {
    height: 146,
    background: 'linear-gradient(90deg, #444, #333)',
    boxShadow: '0px 3px 6px #00000029',
    // borderRadius: '0 0 10px 10px',
  },
  avatarSmall: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  headerInfo: {
    height: 60,
    background: 'linear-gradient(90deg, #161616, #000)',
    boxShadow: '0px 3px 6px #00000029',
    width: drawerWidth,
    // borderRadius: '0 0 10px 0',
  },
  anex: {
    position: 'absolute',
    '&.MuiButtonBase-root, &.MuiFab-root, &.MuiSpeedDial-fab, &.MuiFab-primary': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      color: 'transparent',
    },
    '&.MuiFab-primary:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      color: 'transparent',
    }
  },
  titleLogo: {
    margin: 10,
    display: 'block',
    textAlign: 'left',
    font: 'Bold 18px/23px Roboto',
    letterSpacing: '0px',
    marginLeft: 25,
    marginTop: 15,
    color: '#FFFFFF',
  },
  inputSearch: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  formSearch: {
    margin: 10,
    marginBottom: 0,
    padding: '3px',
    paddingRight: 10,
    display: 'flex',
    alignItems: 'center',
    borderRadius: '17px 0 0 17px',
    boxShadow: 'none'
  },
  menuTab: {
    marginBottom: 0,
    paddingBottom: 0
  },
  listMessage: {
    width: '100%',
    maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  badgeMensageiro: {
    top: 45,
    left: -15,
    width: 25,
    marginBottom: 30,
    padding: 5,
    borderRadius: 10,
    position: 'absolute',
  },
  badgeNotificacao: {
    top: 18,
    right: 20,
    bottom: 0,
    padding: 3,
    position: 'absolute',
  },
  boxTextHeader: {
    float: 'right',
    display: 'block',
    marginTop: -5
  },
  chatListProfilePicture: {
    width: 50,
    height: 50,
    margin: '0 10px 0 -10px',
    float: 'right',
    border: '1px solid #FF3115',
    borderRadius: 15,
    animationDuration: 700
  },
  divider: {
    display: 'block',
    margin: '3px',
    backgroundColor: '#C9D1D5A3',
  },
  chatHeader: {
    display: 'inline',
    flexDirection: 'row',
    height: '60px',
    background: 'linear-gradient(90deg, #333, #161616)',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '0',
  },
  chatProfilePictureAtendente: {
    width: 40,
    height: 40,
    marginRight: 15,
    marginTop: 5,
    float: 'left',
    cursor: 'pointer',
    borderRadius: '50%',
    border: '2px solid #ffffff'
  },
  chatProfilePicture: {
    width: 50,
    height: 50,
    margin: '5px 12px 12px 12px',
    float: 'left',
    borderRadius: 10,
    border: '1px solid #ffffff'
  },
  chatTextHead: {
    lineHeight: 0,
    justifyContent: 'center',
    float: 'left',
    color: '#ffffff',
  },
  chatIconsHead: {
    float: 'right',
    marginTop: '5px',
    marginRight: theme.spacing(2),
    '& svg, .material-icons': {
      color: '#FFF',
      // marginRight: 4
    }
  },
  chatCards: {
    height: 'calc(100vh - 170px)',
    marginTop: 0,
    marginBottom: 0,
    overflow: 'auto',
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
      backgroundColor: '#F5F5F5',
      borderRadius: 10
    },
    '&::-webkit-scrollbar': {
      width: 3,
      borderRadius: 10,
      backgroundColor: '#F5F5F5'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 10,
      backgroundImage: `linear-gradient(60deg, ${primaryColor[1]}, ${primaryColor[2]})`
    }
  },
  chatInputMessage: {
    padding: 4,
    width: '100%',
    backgroundColor: '#FFF',
    // height: 70,
    maxHeight: 80,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 10,
    marginTop: 10
  },
  input: {
    border: 'none',
    margin: theme.spacing(1),
    width: '100%',
    height: 70,
    maxHeight: 80,
    resize: 'none',
    overflow: 'auto !important',
    fontFamily: '"Roboto", sans-serif',
    '&::-webkit-scrollbar-track': {
      background: 'none',
      borderRadius: 10
    },
    '&::-webkit-scrollbar': {
      width: 5,
      borderRadius: 10,
      background: 'none'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 10,
      backgroundImage: `linear-gradient(60deg, ${primaryColor[1]}, ${primaryColor[2]})`
    }
  },
  iconButton: {
    padding: 5,
  },
  dividerForm: {
    height: 28,
    margin: 4,
  },
  mainPaper: {
    height: '100vh',
    background: `url(${bg})`,
    backgroundPosition: 'center',
    backgroundSize: 400,
    backgroundRepeat: 'repeat'
  },
  chatHeight: {
    backgroundColor: 'transparent',
    height: 'calc(100vh - 100px)',
    overflow: 'auto',
    width: '100%',
    marginBottom: theme.spacing(2),
    maxHeight: 'calc(100vh  - 145px)',
    '&::-webkit-scrollbar-track': {
      background: 'none',
      borderRadius: 10
    },
    '&::-webkit-scrollbar': {
      width: 5,
      borderRadius: 10,
      background: 'none'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 10,
      backgroundImage: `linear-gradient(60deg, ${primaryColor[1]}, ${primaryColor[2]})`
    }
  },

  backgroundChatVazio: {
    height: '100vh',
    // width: '100%',
    overflow: 'hidden',
    // background: `url(${backgroundNoChatSelected})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 300,
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },

  appBar: {
    top: 'auto',
    bottom: 0,
    background: '#2B86AE',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
    //position: 'fixed'
  },

  // ********************************** Drawer/Menu-INFO **********************************************
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    // overflow: 'hidden',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },

  // ****************************************** MODAL **************************************************
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  cardUser: {
    maxWidth: '600px',
  },

  formControl: {
    width: '100%',
    maxWidth: '100%',
    marginTop: '12px'
  },

  formCheck: {
    marginTop: '22px'
  },

  cardIconTitle: {
    color: '#48494a',
  },
  cardIconSubtitle: {
    color: '#8d8f91',
  },

  imageUpload: {
    marginTop: '35px',
  },
}));

//******************************* Menu Tab ****************************

export const StyledTabs = withStyles({
  root: {
    justifyContent: 'center',
    alignContent: 'center',
    zIndex: 1,
  },
  indicator: {
    height: 4,
    backgroundColor: '#FFF'
  }
})((props) => <Tabs {...props} TabIndicatorProps={{children: <div/>}}/>);

export const StyledTab = withStyles((theme) => ({
  root: {
    overflowX: 'hidden',
    minWidth: 70,
    width: '100px',
    paddingLeft: 0,
    paddingRight: 0,
    lineHeight: 'inherit',
    fontFamily: 'Roboto, sans-serif',
    color: '#ffffff',
    fontSize: '12px',
    fontWeight: '300',
    textTransform: 'uppercase',
  },
  labelIcon: {
    minHeight: 53,
    paddingTop: 0
  },
  textColorInherit: {
    opacity: 0.4
  },
  wrapper: {
    overflowX: 'hidden',
    flexDirection: 'row',
    '& svg, .material-icons': {
      fontSize: '16px',
      marginTop: '5px',
      marginRight: '3px'
    },
    overflow: 'hidden'
  },
  labelContainer: {
    padding: 0,
  }
}))((props) => <Tab disableRipple {...props} />);


//******************************* Tabs Espera ****************************
export const TabsEspera = withStyles({
  root: {
    justifyContent: 'center',
    alignContent: 'center',
    zIndex: 1,
  },
  indicator: {
    height: 4,
    bottom: 'auto',
    top: 0,
    backgroundColor: '#F5F5F5'
  }
})((props) => <Tabs {...props} TabIndicatorProps={{children: <div/>}}/>);

export const TabEspera = withStyles((theme) => ({
  root: {
    overflowX: 'hidden',
    minWidth: '10ch',
    // width: '120px',
    paddingLeft: 0,
    paddingRight: 0,
    lineHeight: 'inherit',
    fontFamily: 'Roboto, sans-serif',
    color: '#ffffff',
    fontWeight: '300',
    textTransform: 'uppercase',
  },
  wrapper: {
    overflowX: 'hidden',
    flexDirection: 'row',
    '& svg, .material-icons': {
      fontSize: '16px',
      marginTop: '5px',
      marginRight: '3px'
    }
  },
}))((props) => <Tab disableRipple {...props} />);
