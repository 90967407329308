import React, {useEffect, useState} from 'react';
import CardHeader from "../components/Card/CardHeader";
import CardIcon from "../components/Card/CardIcon";
import BusinessIcon from "@material-ui/icons/Business";
import {Card, CardBody, GridContainer, GridItem, Table} from "../components";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import api, {baseURLV2} from "../components/api";
import {convertDate, formatoDinheiro, pontuacaoNumero} from "../components/Funcoes";
import Success from "../components/Typography/Success";
import Danger from "../components/Typography/Danger";
import Warning from "../components/Typography/Warning";
import {infoColor, successColor} from "../assets/jss/material-dashboard-pro-react";
import Tooltip from "@material-ui/core/Tooltip";
import {ArrowDownward, AttachMoney, FileCopy, FindInPage, Pageview} from "@material-ui/icons";
import Button from '../components/CustomButtons/Button'
import Swal from "sweetalert2";

const useStyles = makeStyles(styles);

const Financeiro = () => {
  const classes = useStyles();

  const [ivoices, setIvoices] = useState([]);

  const getIvoices = async () => {
    const { data } = await api.get('/financial');
    setIvoices(data.sort((a, b) => ((a.dateDue > b.dateDue) ? -1 : ((a.dateDue < b.dateDue) ? 1 : 0))));
    // console.log({data})
  };

  useEffect(() => {
    getIvoices();
  }, []);

  return (
    <div>
      <GridContainer>
        <GridItem lg={3} md={4} sm={6} xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <BusinessIcon/>
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                Faturas em aberto
              </h3>
            </CardHeader>
            <CardBody style={{textAlign: 'center'}}>
              <h3 className={classes.cardTitle}
                  style={{color: ivoices.filter(el => !el.flagPaid).length ? '#F08220' : successColor[0]}}>
                {!ivoices.filter(el => !el.flagPaid).length ? (
                  <span>Tudo em dia</span>
                ) : (
                  <b>{ivoices.filter(el => !el.flagPaid).length}</b>
                )}
                <small></small>
              </h3>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem lg={12} md={12} sm={12} xs={12}>
          <Card>
            <CardBody>
              <Table
                hover
                tableHead={[
                  'Competência',
                  'Valor',
                  'Data vencimento',
                  'Situação',
                  // 'Data do Pagamento',
                  'Opções'
                ]}
                tableData={ivoices.map(prop => {
                  const { datePaid, dateDue, os, document, link, file_link_nfse, file_link_nfse_xml } = prop;

                  const paymentLate = new Date(datePaid).getTime() > new Date(dateDue).getTime();
                  return [
                    convertDate(prop.dateCompetence).slice(3, 10),
                    formatoDinheiro(prop.value),
                    convertDate(prop.dateDue),
                    prop.flagPaid ? <Success>Pago</Success> : (paymentLate ? <Danger>Atrasado</Danger> : <Warning><strong>Em aberto</strong></Warning>),
                    // prop.flagPaid ? prop.datePaid && (paymentLate ?
                    //   <Warning>{convertDate(prop.datePaid)}</Warning> : convertDate(prop.datePaid)) : null,
                    (
                      <>
                        <Tooltip title={'Nota Fiscal'}>
                          <Button
                            color={'info'}
                            simple
                            justIcon
                            round
                            onClick={async () => {
                              const {value, dismiss} = await Swal.fire({
                                icon: "question",
                                title: 'Acesso a sua nota fiscal.',
                                showCancelButton: true,
                                cancelButtonText: 'Baixar XML',
                                confirmButtonText: 'Visualizar'
                              });

                              if (value === true) window.open(file_link_nfse);
                              if (dismiss === 'cancel') window.open(file_link_nfse_xml);
                            }}
                          >
                            <FileCopy/>
                          </Button>
                        </Tooltip>
                        {!prop.flagPaid && (
                          <Tooltip title={'Visualizar fatura'}>
                            <Button color={'primary'} simple justIcon round onClick={() => window.open(link)}>
                              <FindInPage/>
                            </Button>
                          </Tooltip>
                        )}
                      </>
                    ),
                  ]
                })}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  )
};

export default Financeiro;