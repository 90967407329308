import React, {useEffect, useState} from 'react';
import {DialogActions, DialogContent, IconButton, makeStyles} from "@material-ui/core";
import {useStyles} from "../NewChat/TabsInfo/styles";
import Button from '../../components/CustomButtons/Button';
import api from "../../components/api";
import styles from "../../assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import MessageList from "../../components/v2/Chat/MessageList";
import {Close} from "../../assets/img/icons";

const useStyles1 = makeStyles({...styles, modalNew: {borderRadius: 16}});
const ModalCalledHistory = ({open, onClose, id}) => {
  const classes = {...useStyles(), ...useStyles1()};

  const [messages, setMessages] = useState([]);

  const getCalledMessages = async () => {
    const {data} = await api.get(`/called/${id}/messages`);
    setMessages(data);
  };

  useEffect(() => {
    if (open && id) getCalledMessages();
    if (!open) setMessages([]);
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      classes={{ paper: classes.modalNew }}
    >
      <DialogTitle>
        <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center'}}>
          <h4 style={{fontFamily: 'NunitoRegular', fontSize: 28, marginBottom: 0, color: '#1A1926', fontWeight: 700}}>
            Atendimento protocolo: <span style={{color: '#E94D3F'}}>{id}</span>
          </h4>
          <IconButton onClick={onClose} style={{width: 30, height: 30, padding: 0}}>
            <img src={Close} alt={'close'}/>
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent style={{overflowX: 'hidden'}}>
        {/*------------------------------------------------------------------------------------*/}
        <MessageList messages={messages}/>
        {/*------------------------------------------------------------------------------------*/}
        {/*{messages.map((prop, key) => {
          prop.date = new Date(prop.date);
          const {id, type, body, caption, date, sent, user} = prop;

          let showDate = true;
          if (key > 0) {
            const prevDate = messages[key - 1].date;
            showDate = !(prevDate.getDate() === date.getDate() && prevDate.getMonth() === date.getMonth() && prevDate.getFullYear() === date.getFullYear());
          }

          {showDate && (
            <div style={{display: 'flex', alignItems: "center", color: '#637085', margin: '5px 0'}}>
              <div style={{width: '100%', border: '0.5px solid #EBEEF2'}}/>
              <div style={{margin: '0px 20px'}}>{date.toLocaleDateString()}</div>
              <div style={{width: '100%', border: '0.5px solid #EBEEF2'}}/>
            </div>
          )}
        })}*/}
        {/*------------------------------------------------------------------------------------*/}
      </DialogContent>
      <DialogActions>
        <div
          style={{
            margin: '10px 10px 0px 10px',
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Button
            style={{
              borderRadius: '14px 14px 14px 0px',
              backgroundColor: '#637085',
              minHeight: 48,
              minWidth: 150
            }}
            onClick={onClose}
          >
            Fechar
          </Button>
          <div/>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default ModalCalledHistory;