import React, {memo, useEffect, useState} from 'react';
import {Handle, Position} from 'reactflow';
import {Card, CardBody} from "../../../components";
import {MessageOutlined, Person} from "@material-ui/icons";
import {WebService} from "../../../components/WebService";
import RemoveNode from "../components/RemoveNode";

function endTransferCalled({id, data}) {
  const [departments, setDepartments] = useState([]);
  const [department, setDepartment] = useState(data.department);

  useEffect(() => {
    data.department = department;
  }, [department]);

  const getCalleds = async () => {
      const {ok, departamentos} = await WebService(`/departamento/getDepartamentos`);
      if (ok) {
        const tempDepartamentos = [];
        const getChild = (d, n) => {
          d.map(prop => {
            const {nome, id_departamento, subDepartamentos} = prop;
            if (subDepartamentos) getChild(subDepartamentos, `${n ? n + ' -> ' : ''}${nome}`);
            else tempDepartamentos.push({name: `${n ? n + ' -> ' : ''}${nome}`, id: id_departamento});
          });
        };
        getChild(departamentos);
        setDepartments([...tempDepartamentos].sort((a, b) => a.name.localeCompare(b.name)));
      }
  };

  useEffect(() => {
    getCalleds();
  }, []);

  return (
    <Card
      style={{
        margin: 0,
        border: '1px solid #B62C1A',
        minWidth: 150,
        // maxWidth: 300,
        borderRadius: 20,
        boxShadow: '#B62C1A 0px 0px 20px -10px',
        backgroundColor: `#fff0ee`,
        padding: 5
      }}
    >
      <RemoveNode id={id}/>
      <CardBody style={{padding: 5, display: 'flex'}}>
        <div
          style={{
            color: '#B62C1A',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: 'calc(25% - 25px)',
            marginRight: 10
          }}
        >
          <Person/>
        </div>
        <div>
          <span style={{fontWeight: 'bold', fontSize: 13}}>
            Transferir atendimento
          </span>
          <br/>
          <select
            onChange={({target: {value}}) => setDepartment(value)}
            value={department}
          >
            <option value={''} disabled>Selecione o departamento</option>
            {departments.map((prop, key) => <option key={prop.id} value={prop.id}>{prop.name}</option>)}
          </select>
        </div>
      </CardBody>

      <Handle type="target" position={Position.Left} className="w-16 !bg-teal-500"/>
    </Card>
  );
}

export default memo(endTransferCalled);
