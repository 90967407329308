import React from 'react';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';

export const customUseStyles = makeStyles(theme => ({
  root: {
    border: 0
  },

  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[900],
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  cardUser: {
    maxWidth: '600px',
  },

  // formControl: {
  //   width: '100%',
  //   maxWidth: '100%',
  //   marginTop: '12px'
  // },

  formCheck: {
    marginTop: '22px'
  },

  cardIconTitle: {
    color: '#48494a',
  },

  cardFooter: {
    justifyContent: 'flex-end'
  }
}));
