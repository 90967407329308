import React, {useEffect, useRef, useState} from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  DialogContent,
  Fade,
  IconButton,
  makeStyles,
  Tab,
  Tabs,
  Tooltip
} from "@material-ui/core";
import {useStyles} from "../../NewChat/TabsInfo/styles";
import styles from "../../../assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import DialogTitle from "@material-ui/core/DialogTitle";
import {Close} from "../../../assets/img/icons";

import Button from '../../../components/CustomButtons/Button';
import api from "../../../components/api";
import Dialog from "@material-ui/core/Dialog";
import MessageList from "../../../components/v2/Chat/MessageList";
import {Editor} from '@tinymce/tinymce-react';
import CustomInput from "../../../components/CustomInput/CustomInput";
import {GridContainer, GridItem} from "../../../components";
import {convertDate, converterTextoWpp, getFotoLink, isValidDate} from "../../../components/Funcoes";
import {EditAttributesOutlined} from "@material-ui/icons";
import {
  FaCalendarPlus, FaCommentMedical, FaPlus,
  FaPlusCircle,
  FaRegBell,
  FaRegCalendarCheck,
  FaRegCalendarPlus,
  FaRegEdit, FaSave
} from "react-icons/fa";
import Swal from "sweetalert2";
import {FaCalendarCheck, FaCheck, FaRegStarHalfStroke} from "react-icons/fa6";
import {IoIosCloseCircle, IoMdFlash, IoMdPersonAdd} from "react-icons/io";
import {IoPersonOutline, IoRadioButtonOffSharp, IoRadioButtonOn, IoTicketOutline} from "react-icons/io5";
import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import {primaryColor} from "../../../assets/jss/material-dashboard-pro-react";
import ViewUserActions from "../../Tasks/components/ViewUserActions";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import {ImRadioChecked2} from "react-icons/im";
import CustomLinearProgress from "../../../components/CustomLinearProgress/CustomLinearProgress";
import {MdClose, MdOutlineAddTask} from "react-icons/md";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import {WebService} from "../../../components/WebService";
import {TaskPriorityLabel, TaskStatusLabel} from "../../Tasks/taskHelper";

const useStyles1 = makeStyles({
  ...styles, modalNew: {borderRadius: 16, maxWidth: 1000},
  removeMargin: {
    margin: `0px !important`,
    '&:before': {
      border: 'none'
    }
  },
  removeUnderline: {
    // transition: '.3s',
    // '& > *::before': {
    //   border: 'none',
    //   '&:hover': {
    //     border: `1px solid ${primaryColor[0]}`
    //   }
    // }
  }
});

const defatultTaskData = {
  title: '',
  description: '',
  comments: [],
  subTasks: [
    // {id: 1, checked: true, label: 'Possuir ao menos 30 dias de conteúdos'},
    // {id: 2, checked: true, label: 'Definir temas principais sobre o conteúdo'},
    // {id: 3, checked: true, label: 'Planejamento detalhado de postagens'},
    // {id: 4, checked: false, label: 'Calendário de publicação'},
    // {id: 5, checked: false, label: 'Métricas de sucesso para acompanhamento'},
  ]
};

const TaskViewer = ({open, onClose, id}) => {
  const classes = {...useStyles(), ...useStyles1()};

  const editorRef = useRef(null);

  const [task, setTask] = useState(defatultTaskData);
  const [users, setUsers] = useState(defatultTaskData);
  const [newComment, setNewComment] = useState({open: false, text: ''});
  // const [comments, setComments] = useState([
  //   {id: 1, user: {id: 5, name: 'Italo Tavares', profilePicture: 'HfzxPxvj8rPc6Xz00nZBFG9hbM6ICFB3ICKxCe4K8Z3vgNaY5b.png'}, text: 'Show de bola!!', date: new Date()},
  //   {id: 2, user: {id: 5, name: 'Italo Tavares', profilePicture: 'HfzxPxvj8rPc6Xz00nZBFG9hbM6ICFB3ICKxCe4K8Z3vgNaY5b.png'}, text: 'Comentário antigo', date: new Date('2024-05-04 14:00:00')},
  // ]);
  const [editModeTitle, setEditModeTitle] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const getTask = async () => {
    const {data} = await api.get(`/task/${id}`);
    setTask(data);
  };

  const getUsers = async () => {
    const { ok, usuarios } = await WebService(`/usuario/getUsuarios`);
    if (ok) setUsers(usuarios);
  };

  const updateTask = async (id, params) => {
    await api.put(`/task/${id}`, params);
    await getTask();
  };

  const removeTask = async id => {
    const {value} = await Swal.fire({
      icon: "question",
      title: 'Deseja ralmente remover?',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    });
    if (!value) return;
    await api.delete(`/task/${id}`);
    setTask(task => {
      const keyTask = task.subTasks.map(prop1 => prop1.id).indexOf(id);
      delete task.subTasks[keyTask];
      task.subTasks = task.subTasks.filter(el => el);
      return {...task};
    });
  }

  const addComment = async () => {
    const {data} = await api.post(`/task/${id}/comment`, {text: newComment.text});
    setTask(task => {
      task.comments.push(data);
      return {...task};
    });
    setNewComment({open: false, text: ''});
    Swal.mixin({toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, timerProgressBar: true})
      .fire({icon: 'success', title: 'Comentario adicionado com sucesso!'});
  };

  const changeTask = async (id, params) => {
    await api.put(`/task/${id}`, params);
    setTask({...task, ...params});
    Swal.mixin({toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, timerProgressBar: true})
      .fire({icon: 'success', title: 'Tarefa atualizada!'});
  };

  const createTaskItem = async () => {
    const {data} = await api.post('/task', {title: 'Item para verificação', task: {id}});
    setTask(task => {
      task.subTasks.push(data);
      return {...task};
    })
  };

  useEffect(() => {
    if (open && id) {
      getTask();
      getUsers();
    }
    if (!open) setTask(defatultTaskData);
  }, [open]);

  const addRecipient = async () => {
    const usersTemp = {};
    users.forEach(user => {
      usersTemp[user.id_usuario] = user.nome;
    });
    const {value} = await Swal.fire({
      input: 'select',
      inputOptions: usersTemp,
      inputPlaceholder: 'Selecione o usuário',
      // inputValue: conexaoData.uuid,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      allowOutsideClick: false,
      inputValidator: value => value ? "" : "Selecione um usuario para atribuir a tarefa"
    });
    if(!value) return;

    await updateTask(id, {user: {id: value}});
  };

  const removeUser = async () => {
    const {value} = await Swal.fire({
      icon: "question",
      title: 'Deseja ralmente remover o usuário responsável?',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    });
    if (!value) return;
    await api.delete(`/task/${id}/user`);
    setTask(task => ({...task, user: null}));
  };

  const removeCalled = async idCalled => {
    const {value} = await Swal.fire({
      icon: "question",
      title: 'Deseja ralmente remover o chamado?',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    });
    if (!value) return;
    await api.delete(`/task/${id}/called/${idCalled}`);
    setTask(task => {
      task.calleds = task.calleds.filter(el => el.id !== Number(idCalled));
      return {...task};
    });
  };

  // useEffect(() => {
  //   if (open && !editMode && id) {
  //     changeTask(id, {description: task.description});
  //   }
  // }, [editMode]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      // fullScreen
      classes={{paper: classes.modalNew}}
    >
      <DialogTitle>
        <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center'}}>
          <h4 style={{
            fontFamily: 'NunitoRegular',
            fontSize: 28,
            marginBottom: 0,
            color: '#1A1926',
            fontWeight: 700,
            display: 'flex',
            alignItems: 'center',
            width: 'calc(100% - 50px)'
          }}>
            {/*Tarefa: <span style={{color: '#E94D3F'}}>{id}</span>*/}
            <Button
              onClick={() => {
                setEditModeTitle(!editModeTitle);
                if (editModeTitle) changeTask(id, {title: task.title});
              }}
              justIcon simple color={'primary'}
              style={{padding: 0, margin: '-3px 5px 0px 5px', width: 20, height: 20, minWidth: 20}}
            >
              <FaRegEdit/>
            </Button>
            {editModeTitle ? (
              <CustomInput labelText={''} formControlProps={{fullWidth: true, style: {margin: 0, padding: 0}}}
                           inputProps={{
                             value: task.title,
                             onChange: ({target: {value}}) => setTask({...task, title: value})
                           }}/>
            ) : (<span style={{
              color: '#E94D3F',
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: 'nowrap'
            }}>{task.title}</span>)}
          </h4>
          <IconButton onClick={onClose} style={{width: 30, height: 30, padding: 0}}>
            <img src={Close} alt={'close'}/>
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent style={{overflowX: 'hidden'}}>
        <GridContainer>
          {/*<GridItem lg={12} md={12} sm={12} xs={12}>*/}
          {/*  */}
          {/*</GridItem>*/}
          <GridItem lg={8} md={8} sm={12} xs={12}>
            <Tooltip title={'Marcar como completa'}>
              <Button
                justIcon
                color={'primary'}
                style={{width: 30, height: 30, minWidth: 30, padding: 0}}
                onClick={() => updateTask(id, {status: 'finished'}).then(onClose)}
              >
                <FaCheck/>
              </Button>
            </Tooltip>
            <hr/>
            {/*<CustomInput
              labelText={'Título'}
              formControlProps={{fullWidth: true}}
              inputProps={{ value: task.title }}
            />*/}
            <strong>Descrição</strong>
            <Button onClick={() => {
              setEditMode(!editMode);
              if (editMode) {
                changeTask(id, {description: task.description});
              }
            }} justIcon simple color={'primary'}
                    style={{padding: 0, margin: '-3px 0px 0px 5px', width: 15, height: 15, minWidth: 15}}
            ><FaRegEdit/></Button>
            <br/>
            {editMode ? (
                <Editor
                  apiKey='ipt1ks61cuzfqgcp5hbwlwmqaw9iw1krq4w307tmfifjgu2p'
                  onInit={(_evt, editor) => editorRef.current = editor}
                  init={{
                    resize: false,
                    plugins: '',
                    language: 'pt_BR',
                    toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                    selector: 'textarea',
                    mergetags_list: [
                      {value: 'First.Name', title: 'First Name'},
                      {value: 'Email', title: 'Email'},
                    ],
                  }}
                  value={task.description || ''}
                  onEditorChange={description => setTask({...task, description})}
                />
              ) :
              task.description ? (
                <div
                  style={{marginBottom: 0}}
                  dangerouslySetInnerHTML={{__html: converterTextoWpp(task.description || '')}}
                />
              ) : (<span style={{color: '#999999'}}>Digite a descrição da tarefa...</span>)
            }
            <hr/>

            <span
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: "center",
                fontWeight: 900,
                color: primaryColor[0]
              }}
              onClick={createTaskItem}
            >
              <FaPlusCircle/>&nbsp;Item para Verificação
            </span>
            {!task.subTasks.length ? (
              <span
                style={{color: '#999999'}}>Itens da lista de verificação não foram encontrados para esta tarefa</span>
            ) : (
              <div style={{display: 'flex', alignItems: 'center'}}>
                <CustomLinearProgress
                  color={'primary'}
                  value={(task.subTasks.filter(el => el.status === 'finished').length / task.subTasks.length) * 100}
                  variant={'determinate'}
                  height
                  style={{width: '100%', marginBottom: 0, borderRadius: 10}}
                />&nbsp;
                {((task.subTasks.filter(el => el.status === 'finished').length / task.subTasks.length) * 100).toFixed()}%
              </div>
            )}
            {task.subTasks.map(prop => {
              return (
                <div
                  key={prop.id}
                  style={{display: 'flex', alignItems: "center"}}
                >
                  <Checkbox
                    checked={prop.status === 'finished'}
                    tabIndex={-1}
                    onClick={async () => {
                      const params = {status: prop.status === 'finished' ? 'pending' : 'finished'};
                      await api.put(`/task/${prop.id}`, params);
                      setTask(task => {
                        const keyTask = task.subTasks.map(prop1 => prop1.id).indexOf(prop.id);
                        task.subTasks[keyTask] = {...task.subTasks[keyTask], ...params};
                        return {...task};
                      });
                    }}
                    checkedIcon={<ImRadioChecked2 className={classes.checkedIcon} style={{borderRadius: '50%', padding: 2}}/>}
                    icon={<IoRadioButtonOffSharp className={classes.uncheckedIcon} style={{borderRadius: '50%'}}/>}
                    classes={{checked: classes.checked, root: classes.root}}
                    style={{width: 38}}
                  />
                  <span style={{width: '100%', textDecoration: prop.checked ? 'line-through' : 'none', color: '#555'}}>
                     <CustomInput
                       labelText={''}
                       formControlProps={{fullWidth: true, style: {margin: 0, padding: 0}}}
                       inputProps={{
                         className: classes.removeMargin,
                         value: prop.title,
                         // underline: false,
                         onChange: ({target: {value}}) => {
                           setTask(task => {
                             const keyTask = task.subTasks.map(prop1 => prop1.id).indexOf(prop.id);
                             task.subTasks[keyTask].title = value;
                             return {...task};
                           });
                         },
                         onBlur: async () => {
                           const params = {title: prop.title};
                           await api.put(`/task/${prop.id}`, params);
                         }
                       }}
                     />
                    {/*{prop.title}*/}
                  </span>
                  {/*<div style={{width: 96, display: 'flex'}}> Quando Estão os 3 botões*/}
                  <div style={{width: 38, display: 'flex'}}>
                    {/*<Tooltip title={'Converter para tarefa'}>
                      <Button color={'success'} simple justIcon round size={'sm'}>
                        <MdOutlineAddTask/>
                      </Button>
                    </Tooltip>
                    <Tooltip title={'Definir Responsável'}>
                      <Button color={'info'} simple justIcon round size={'sm'}>
                        <IoMdPersonAdd/>
                      </Button>
                    </Tooltip>*/}
                    <Tooltip title={'Excluir'}>
                      <Button color={'danger'} simple justIcon round size={'sm'} onClick={() => removeTask(prop.id)}>
                        <MdClose/>
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              )
            })}
            <hr/>
            <Tabs
              value={'comments'}
              onChange={() => ''}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
            >
              <Tab value="comments" label="Comentários"/>
              <Tab value="activity" label="Atividade"/>
            </Tabs>
            <Accordion
              expanded={newComment.open}
              onChange={() => setNewComment({open: !newComment.open, text: ''})}
              style={{boxShadow: 'none'}}
              sx={{
                '& .MuiAccordion-region': {height: newComment.open ? 'auto' : 0},
                '& .MuiAccordionDetails-root': {display: newComment.open ? 'block' : 'none'},
              }}
            >
              <AccordionSummary style={{padding: 0}}>
                <span
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: "center",
                    fontWeight: 900,
                    color: primaryColor[0]
                  }}
                >
                  <FaCommentMedical/>&nbsp;Adicionar comentário
                </span>
              </AccordionSummary>
              <AccordionDetails style={{padding: 0}}>
                <div
                  style={{
                    border: '1px solid #DDD',
                    padding: '0px 0',
                    borderRadius: 10,
                    marginTop: -15,
                    width: '100%'
                  }}
                >
                  <CustomInput
                    formControlProps={{fullWidth: true, style: {margin: 0, padding: 0}}}
                    inputProps={{
                      placeholder: "Escreva um comentário...",
                      value: newComment.text,
                      onChange: ({target: {value}}) => setNewComment({...newComment, text: value}),
                      multiline: true,
                      rows: 5,
                    }}
                  />
                  <Button color={'hust'} style={{height: 30, padding: '0 15px'}} onClick={addComment}>
                    Comentar
                  </Button>
                </div>
              </AccordionDetails>
            </Accordion>
            {task.comments.sort((a, b) => a.date > b.date ? -1 : (a.date < b.date ? 1 : 0)).map(prop => {
              return (
                <div key={prop.id} style={{display: 'flex', marginTop: 20, alignItems: 'center'}}>
                  <img src={getFotoLink(prop.user.profilePicture)} alt={prop.user.name}
                       style={{width: 35, height: 35, borderRadius: '50%'}}/>
                  <div style={{marginLeft: 10}}>
                    <strong style={{color: '#1A1926'}}>{prop.user.name}</strong>&nbsp;
                    <small>{convertDate(prop.date, false, true, false, true)}</small>
                    <br/>
                    {prop.text}
                  </div>
                </div>
              );
            })}
          </GridItem>
          <GridItem lg={4} md={4} sm={12} xs={12}
                    style={{background: '#EEE', borderRadius: 15, boxShadow: '0 0 10px -5px #666'}}>
            <br/>
            <strong>Informações da Tarefa</strong> <br/>
            <small>Criado em 13/01/2021 17:23:07</small>
            <hr style={{borderTop: '1px solid #CCC'}}/>
            {[
              {icon: FaRegStarHalfStroke, label: `Status: ${TaskStatusLabel[task.status]?.label}`},
              {
                icon: FaRegCalendarPlus, label: (<>
                    Início:&nbsp;
                    <Datetime
                      timeFormat={true}
                      onChange={e => typeof e === 'object' ? changeTask(id, {dateStart: e.toDate()}) : (e.length === 10 ? (isValidDate(new Date(e.split("/").reverse().join("-"))) ? changeTask(id, {dateStart: new Date(e.split("/").reverse().join("-"))}) : "") : "")}
                      value={new Date(task.dateStart)}
                      inputProps={{placeholder: "Data de início", style: {background: 'none'}}}
                    />
                  </>
                )
              },
              {
                icon: FaRegCalendarCheck, label: (<>
                    Limite:&nbsp;
                    <Datetime
                      timeFormat={true}
                      onChange={e => typeof e === 'object' ? changeTask(id, {dateDue: e.toDate()}) : (e.length === 10 ? (isValidDate(new Date(e.split("/").reverse().join("-"))) ? changeTask(id, {dateDue: new Date(e.split("/").reverse().join("-"))}) : "") : "")}
                      value={task.dateDue ? new Date(task.dateDue) : ''}
                      inputProps={{placeholder: "Data final", style: {background: 'none'}}}
                    />
                  </>
                )
              },
              {icon: IoMdFlash, label: `Prioridade: ${TaskPriorityLabel[task.priority]?.label}`},
            ].map((prop, key) => (
              <span key={key} style={{display: 'flex', alignItems: "center"}}><prop.icon color={'#e94d3f'}/>
                &nbsp;{prop.label}</span>
            ))}
            <hr style={{borderTop: '1px solid #CCC'}}/>
            <span style={{display: 'flex', alignItems: "center"}}><FaRegBell color={'#e94d3f'}/>&nbsp;Lembretes</span>

            <hr style={{borderTop: '1px solid #CCC'}}/>
            {/*<IoTicketOutline/> Chamados <p/>*/}
            <span style={{display: 'flex', alignItems: "center"}}><IoTicketOutline
              color={'#e94d3f'}/>&nbsp;Chamados</span>
            <p/>
            <div style={{display: 'flex'}}>
              {task?.calleds?.map((called, k) => {
                const {id, contact: {name, idWhatsapp}} = called;
                const [phone] = idWhatsapp.split('@');
                return (
                  <ViewUserActions
                    name={called.id + ' - ' + (name || phone)}
                    profilePicture={called.contact.profilePicture}
                    showRemove
                    onDelete={() => removeCalled(id)}
                  />
                );
              })}
            </div>
            <hr style={{borderTop: '1px solid #CCC'}}/>
            <span style={{display: 'flex', alignItems: "center"}}><IoPersonOutline color={'#e94d3f'}/>&nbsp;Destinatário</span>
            <p/>
            {task.user ? (
              <ViewUserActions
                name={task.user.name}
                profilePicture={task.user.profilePicture}
                showRemove
                onDelete={removeUser}
              />
            ) : (
              <Tooltip title={'Definir Responsável'}>
                <Button color={'primary'} justIcon size={'sm'} round onClick={addRecipient}>
                  <FaPlus/>
                </Button>
              </Tooltip>
            )}
            <hr style={{borderTop: '1px solid #CCC'}}/>
          </GridItem>
        </GridContainer>
      </DialogContent>
    </Dialog>
  );
};

export default TaskViewer;