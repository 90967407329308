import React, {useEffect, useState} from "react";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardBody from "../../components/Card/CardBody";
import InputAdornment from "@material-ui/core/InputAdornment";
import {IconButton, Tooltip} from "@material-ui/core";
import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import {Apps, Search, Web} from "@material-ui/icons";
import {CustomInput} from "../../components";
import ImageUpload from "../../components/CustomUpload/ImageUpload";
import ImageUploadRevenda from "../../components/CustomUpload/ImageUploadRevenda";
import {WebService} from "../../components/WebService";
import {getFotoLink} from "../../components/Funcoes";
import Button from "../../components/CustomButtons/Button";
import ChartistGraph from "react-chartist";
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import WallpaperIcon from '@material-ui/icons/Wallpaper';
import PanoramaIcon from '@material-ui/icons/Panorama';
import ImageIcon from '@material-ui/icons/Image';
import FingerprintIcon from '@material-ui/icons/Fingerprint';


import * as Swal from "sweetalert2";
import './styles';

const pictureUploadProps = {
  fullWidth: true,
  addButtonProps: {color: 'info', fullWidth: true},
  changeButtonText: "Alterar imagem",
  removeButtonText: "Remover Imagem",
  selectButtonText: "Selecionar imagem",
};

export default function ManagerRevenda() {
  const [nomeAplicacao, setNomeAplicacao] = useState("");

  const [configuracoes, setConfiguracoes] = useState({
    url: 'macrochat.app',
    nome_aplicacao: "Macrochat",
    imagem_fundo_tela_login: `/MacrochatImages/MacrochatFundoLogin.jpg`,
    imagem_logo_tela_login: `/MacrochatImages/MacrochatLogin.svg`,
    imagem_fundo_menu: `/MacrochatImages/MacrochatFundoMenu.jpg`,
    imagem_icone_logo: `/MacrochatImages/MacrochatLogo.png`,
    imagem_nome_aplicacao: `/MacrochatImages/MacrochatNome.png`
  });

  const getConfiguracaoRevenda = async () => {
    const {ok, ...outros} = await WebService(`/cliente/getConfiguracaoRevenda`);
    if (ok) {
      const keys = Object.keys(configuracoes);
      const tempConfiguracoes = Object.assign({}, configuracoes);

      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (outros[key]) tempConfiguracoes[key] = outros[key];
      }

      setConfiguracoes(tempConfiguracoes);
    }
  };

  const alterarConfig = async (slug, valor) => {
    const tempConfiguracoes = Object.assign({}, configuracoes);
    if (slug) tempConfiguracoes[slug] = valor;
    const {ok} = await WebService(`/cliente/setConfiguracaoRevenda`, tempConfiguracoes, "POST");
    if (ok) {
      setConfiguracoes(tempConfiguracoes);
      await Swal.fire({icon: "success", title: "Configurações alteradas com sucesso!"});
      window.location.reload();
    }
  };

  const alterarImagem = async (slug, imagem) => {
    if (imagem) {
      const {ok, nomeArquivo} = await WebService(`/usuario/alterarFotoPerfil`, {imagem}, "POST");
      if (ok) await alterarConfig(slug, getFotoLink(nomeArquivo));
    } else {
      await alterarConfig(slug, "");
    }
  };

  useEffect(() => {
    getConfiguracaoRevenda();
  }, []);

  const useStyles = makeStyles((theme) => ({
    root: {
      maxWidth: 345,
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
      '&:hover': {
        backgroundSize: 'contain'
      }
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
     color: 'white',
     background: '#0098DA',
     border: '1px #0098da solid',
     borderRadius: '70px',
     width: '40px',
     height: '40px',
     padding: '5px'
    },
  }));
  
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <GridContainer>
      <GridItem lg={12} md={12} sm={12} xs={12} style={{display: 'inline-grid'}}>
        
            <GridContainer style={{display: 'flex'}} >
            <Card style={{marginLeft: '1.5%', marginRight: '5%'}}>
              <CardBody>
              <GridItem lg={6} md={4} sm={6} xs={12} style={{ display: 'inline-grid', width: '40%', fontSize: '16px'}}>
                <CustomInput
                  labelText="Nome da aplicação"
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    value: configuracoes.nome_aplicacao,
                    endAdornment: (
                      <InputAdornment position="end">
                        
                      </InputAdornment>
                    ),
                    onChange: ({target: {value}}) => setConfiguracoes({...configuracoes, nome_aplicacao: value})
                  }}
                />
              </GridItem>
              <GridItem lg={6} md={4} sm={6} xs={12} style={{ display: 'inline-grid', width: '40%'}}>
                <CustomInput
                style={{width: '30%'}}
                  labelText="URL acesso"
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    value: configuracoes.url,
                    endAdornment: (
                      <InputAdornment position="end">
                        
                      </InputAdornment>
                    ),
                    onChange: ({target: {value}}) => setConfiguracoes({...configuracoes, url: value})
                  }}
                />
              </GridItem>
          

              <GridItem lg={4} md={4} sm={6} xs={12} style={{ display: 'inline-grid', width: '20%', direction: 'rt1'}}>
                
                <Button color={"info"} fullWidth onClick={alterarConfig.bind(this)}>
                  Salvar
                </Button>
              </GridItem>
              </CardBody>
              </Card>
              <GridItem lg={12} md={12} sm={12} xs={12} style={{alignItems: "stretch", direction: 'row'}}>
              
                <GridContainer style={{display: 'flex'}}>

                <GridItem lg={4} md={4} sm={6} xs={12}>

                  <Card className={classes.root} >
                  <CardHeader
                    avatar={
                      <WallpaperIcon className={classes.avatar}/>
                    }
                    title="Fundo tela de login"
                  />
                  <CardMedia
                    className={classes.media}
                    image={configuracoes.imagem_fundo_tela_login}
                    title="Fundo login"
                    style={{backgroundColor: '#EEEEEE', borderTop: '#4f4f4f 1px solid', borderBottom: '#4f4f4f 1px solid'}}
                  />
                  
                <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                Escolha a imagem que será exibida como fundo da página de login
                </Typography>
              </CardContent>
              <ImageUploadRevenda
                  {...pictureUploadProps}
                    onUpload={base => alterarImagem('imagem_fundo_tela_login', base)}
                />
                  </Card>
                  </GridItem>

                  <GridItem lg={4} md={4} sm={6} xs={12}>

                  <Card className={classes.root}>
                  <CardHeader
                    avatar={
                      <ImageIcon className={classes.avatar}/>
                    }
                    title="Logomarca tela de login"
                  />
                  <CardMedia
                    className={classes.media}
                    image={configuracoes.imagem_logo_tela_login}
                    title="Fundo login"
                    style={{backgroundColor: '#EEEEEE', borderTop: '#4f4f4f 1px solid', borderBottom: '#4f4f4f 1px solid'}}
                  />
                  
                <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                Escolha a imagem que será exibida como logomarca na página de login
                </Typography>
              </CardContent>
              <ImageUploadRevenda
                  {...pictureUploadProps}
                    onUpload={base => alterarImagem('imagem_logo_tela_login', base)}
                />
                  </Card>
                  </GridItem>   

                  <GridItem lg={4} md={4} sm={6} xs={12}>

                  <Card className={classes.root}>
                  <CardHeader
                    avatar={
                      <PanoramaIcon className={classes.avatar}/>
                    }
                    title="Imagem fundo menu"
                  />
                  <CardMedia
                    className={classes.media}
                    image={configuracoes.imagem_fundo_menu}
                    title="Fundo menu"
                    style={{backgroundColor: '#EEEEEE', borderTop: '#4f4f4f 1px solid', borderBottom: '#4f4f4f 1px solid'}}
                  />
                  
                <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                Escolha a imagem que será exibida como fundo do menu.
                </Typography>
              </CardContent>
              <ImageUploadRevenda
                  {...pictureUploadProps}
                    onUpload={base => alterarImagem('imagem_fundo_menu', base)}
                />
                  </Card>
                  </GridItem>   

                  <GridItem lg={4} md={4} sm={6} xs={12}>

                  <Card className={classes.root}>
                  <CardHeader
                    avatar={
                      <ImageIcon className={classes.avatar}/>
                    }
                    title="Ícone Logomarca"
                  />
                  <CardMedia
                    style={{backgroundColor: '#EEEEEE', borderTop: '#4f4f4f 1px solid'}}
                    title="Ícone logomarca"
                  />
                  
              <img src={configuracoes.imagem_icone_logo} height="195px" width="195px" style={{marginBottom: '0%', alignSelf: 'center'}}/>
              <CardContent style={{ borderTop: '#4f4f4f 1px solid'}}>
                <Typography variant="body2" color="textSecondary" component="p">
                Escolha a imagem de logomarca padrão da sua empresa.
                </Typography>
                </CardContent>
              <ImageUploadRevenda
                  {...pictureUploadProps}
                    onUpload={base => alterarImagem('imagem_icone_logo', base)}
                />
                  </Card>
                  </GridItem>   

                  <GridItem lg={4} md={4} sm={6} xs={12}>

                  <Card className={classes.root}>
                  <CardHeader
                    avatar={
                      <FingerprintIcon className={classes.avatar}/>
                    }
                    title="Imagem Nome da Aplicação"
                  />
                  <CardMedia
                    title="Nome da aplicação"
                    style={{backgroundColor: '#EEEEEE', borderTop: '#4f4f4f 1px solid'}}
                  />
                  <img src={configuracoes.imagem_nome_aplicacao} height="100px" style={{marginBottom: '27%'}}/>
                <CardContent style={{ borderTop: '#4f4f4f 1px solid'}}>
                <Typography variant="body2" color="textSecondary" component="p">
                Escolha a imagem que conterá o nome da aplicação.
                </Typography>
              </CardContent>
              <ImageUploadRevenda
                  {...pictureUploadProps}
                    onUpload={base => alterarImagem('imagem_nome_aplicacao', base)}
                />
                  </Card>
                  </GridItem>   
                            
                </GridContainer>
              </GridItem>
            </GridContainer>
          
      </GridItem>
    </GridContainer>


  )
};