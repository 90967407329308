import React from 'react';

const Checkbox = ({label, checked, onClick}) => {
  // const classes = useStyles();
  const classes = {};
  return (
    <div style={{display: 'flex', marginTop: 5, marginBottom: 5}}>
      <input
        type={'checkbox'}
        checked={checked}
        onClick={onClick}
        style={{width: 20, height: 20}}
      />
      &nbsp;&nbsp;&nbsp;
      <span style={{color: '#28263B', marginTop: 3}}>
        {label}
      </span>
    </div>
  );
};

export default Checkbox;