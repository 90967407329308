import React, {useEffect, useRef, useState} from "react";

//components theme
import {Card, CardHeader, CardBody, CardFooter, CardIcon, CustomButtons} from "../../../components";

//@material-ui
import {IconButton} from '@material-ui/core';
import {Backup} from '@material-ui/icons';

import {customUseStyles} from "./styles";
import {infoColor, primaryColor} from "../../../assets/jss/material-dashboard-pro-react";
import Button from "../../../components/CustomButtons/Button";

import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {RingLoader} from "react-spinners";
import {ls} from "../../../components/Funcoes";
import {WebService} from "../../../components/WebService";

const SwalR = withReactContent(Swal);

function CustomToolbar({onFinish}) {
  const classes = customUseStyles();
  const refUploadCSV = useRef();
  const [mensagemCarregando, setMensagemCarregando] = useState("");

  useEffect(() => {
    const div = document.getElementById('labelCarregandoUploadContatos');
    if (div) div.innerText = mensagemCarregando;
  }, [mensagemCarregando]);

  const processarContatos = async e => {
    try {
      SwalR.fire({
        title: (
          <div align={"center"}>
            <RingLoader size={150} color={infoColor[0]} loading={true}/>
            Carregando...
            <p id={"labelCarregandoUploadContatos"} style={{fontSize: 15, lineHeight: 1}}/>
          </div>
        ),
        allowOutsideClick: false,
        showConfirmButton: false
      });
      // *****************************************
      e.preventDefault();
      let reader = new FileReader();
      const file = await new Promise(resolve => {
        const [f] = e.target.files;
        reader.onloadend = () => resolve(atob(reader.result.split(",")[1]));
        reader.readAsDataURL(f);
      });
      const contatos = file.split("\n").map(prop => prop.split(",").slice(0, 2).map(p => (p || "").trim()));
      for (let i = 0; i < contatos.length; i++) {
        const [numero, nome] = contatos[i];
        setMensagemCarregando(`Processando contato [${nome}]`);
        await WebService(`/contato/forcarAtualizarDadosContato`, {numero, nome}, "POST", false, () => "", false);
      }
    } finally {
      SwalR.close();
    }
    await Swal.fire({icon: "success", title: "Contatos processados com sucesso!"});
    if (onFinish) onFinish();
  };

  return (
    <>
      <input
        type={"file"}
        ref={refUploadCSV}
        accept={".csv"}
        style={{display: 'none'}}
        onChange={processarContatos.bind(this)}
      />
      <Card className={classes.cardUser}>
        <CardHeader color="info" icon>
          <CardIcon color="info">
            <Backup/>
          </CardIcon>
          <h4 className={classes.cardIconTitle}>IMPORTAR CONTATOS</h4>
        </CardHeader>
        <CardBody>
          <center>
            <IconButton className={classes.importContacts} onClick={() => refUploadCSV.current.click()}>
              <Backup style={{color: primaryColor[4], fontSize: '80px'}}/>
            </IconButton>
            <p>Selecione o arquivo .csv </p>
          </center>
        </CardBody>
        <CardFooter className={classes.cardFooter} stats>
          <Button color="info">Salvar</Button>
        </CardFooter>
      </Card>
    </>
  );
}

export default CustomToolbar;
