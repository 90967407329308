import React, {Fragment} from "react";
import Message from "./Message/Message";
import {convertDate} from "../../Funcoes";

const MessageList = ({messages}) => {
  return (
    <div style={{overflowX: 'hidden', width: '100%'}}>
      {messages.map(({id, type, date, sent, user, ...rest}, key) => (
        <Fragment key={id}>
          {!key || new Date(date).getDate() !== new Date(messages[key - 1].date).getDate() ? (
            <div style={{width: '100%', textAlign: 'center', margin: '5px 0', padding: '5px 0'}}>
              <span
                style={{
                  width: 60,
                  background: '#555',
                  textAlign: 'center',
                  padding: '5px 12px',
                  borderRadius: 6,
                  color: '#FFF',
                  fontWeight: 600,
                  boxShadow: '#000 0px 0px 10px -5px'
                }}
              >
                {convertDate(date)}
              </span>
            </div>
          ): ''}
        <Message type={type} date={new Date(date)} sent={sent} user={user} {...rest}/>
        </Fragment>
      ))}
      {/*TODO - Adicionar citações*/}
    </div>
  );
};

export default MessageList;