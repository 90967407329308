import {
  primaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  whiteColor, hexToRgb
} from "assets/jss/material-dashboard-pro-react.js";

const badgeStyle = {
  badge: {
    borderRadius: "12px",
    padding: "5px 12px",
    textTransform: "uppercase",
    fontSize: "10px",
    fontWeight: "700",
    lineHeight: "1",
    color: whiteColor,
    textAlign: "center",
    verticalAlign: "baseline",
    display: "inline-block",
    transition: '.3s',
    cursor: 'default'
  },
  primary: {
    border: `1px solid ${primaryColor[0]}`,
    backgroundColor: `rgba(${hexToRgb(primaryColor[0])}, 0.5)`,
    '&:hover': {
      backgroundColor: `rgba(${hexToRgb(primaryColor[0])}, 0.7)`,
    }
  },
  warning: {
    border: `1px solid ${warningColor[0]}`,
    backgroundColor: `rgba(${hexToRgb(warningColor[0])}, 0.5)`,
    '&:hover': {
      backgroundColor: `rgba(${hexToRgb(warningColor[0])}, 0.7)`,
    }
  },
  danger: {
    border: `1px solid ${dangerColor[0]}`,
    backgroundColor: `rgba(${hexToRgb(dangerColor[0])}, 0.5)`,
    '&:hover': {
      backgroundColor: `rgba(${hexToRgb(dangerColor[0])}, 0.7)`,
    }
  },
  success: {
    border: `1px solid ${successColor[0]}`,
    backgroundColor: `rgba(${hexToRgb(successColor[0])}, 0.5)`,
    '&:hover': {
      backgroundColor: `rgba(${hexToRgb(successColor[0])}, 0.7)`,
    }
  },
  info: {
    border: `1px solid ${infoColor[0]}`,
    backgroundColor: `rgba(${hexToRgb(infoColor[0])}, 0.5)`,
    '&:hover': {
      backgroundColor: `rgba(${hexToRgb(infoColor[0])}, 0.7)`,
    }
  },
  rose: {
    backgroundColor: roseColor[0]
  },
  gray: {
    backgroundColor: grayColor[0]
  }
};

export default badgeStyle;
