import React, {useEffect, useRef, useState} from 'react';
import {Card, CardBody, GridContainer, GridItem} from "../../components";
import api from "../../components/api";
import TextField from "@material-ui/core/TextField";
import Button from "../../components/CustomButtons/Button";
import {ArrowBack, Backup, Close, Edit, LibraryAdd, PersonAdd, PersonPinCircle, Save, Sync} from "@material-ui/icons";
import MUIDataTable from "mui-datatables";
import {formataNumeroTelefone, getFotoLink, traducaoTable} from "../../components/Funcoes";
import {MuiThemeProvider} from "@material-ui/core/styles";
import {getMuiTheme} from "../Usuarios/Contatos/Contatos";
import {Avatar, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tooltip} from "@material-ui/core";
import default_avatar from "../../assets/img/default-avatar.png";
import CustomToolbarSelect from "../Usuarios/Toolbar/CustomToolbarSelect";
import {customUseStyles} from "../Usuarios/Contatos/styles";
import Swal from "sweetalert2";
import Group from "@material-ui/icons/Group";
import ContactImportComponent from "../Componentes/ContactImportComponent";

const SendingListEdit = ({history, computedMatch: {params} }) => {
  const classes = customUseStyles();

  const [sendingList, setSendingList] = useState({id: 0, name: '', tags: []});
  const [contacts, setContacts] = useState([]);

  const getSendingList = async id => {
    const {data: sendingList} = await api.get(`/sendingList/${id}`);
    setSendingList(sendingList);
    await getContactsList(sendingList.id);
  };

  const getContactsList = async id => {
    const {data: contacts} = await api.get(`/sendingList/${id}/contact`);
    setContacts(contacts);
  };

  const importFile = async e => {
    e.preventDefault();
    const [file] = e.target.files;
    // const file = await new Promise(resolve => {
    //   let reader = new FileReader();
    //   let file = e.target.files[0];
    //   console.log(file);
    //   reader.onloadend = () => resolve(reader.result);
    //   reader.readAsDataURL(file);
    // });
    // e.target.files = [];
    // console.log(file);

    const data = new FormData();
    data.append('file', file, file.name);

    await api.post(`/sendingList/${sendingList.id}/contact/import`, data);
    await getSendingList(sendingList.id);
  };

  const saveName = async () => {
    if (!sendingList.name.trim()) return Swal.fire({icon: "warning", title: 'O nome da campanha não pode ser vazio'});
    await api.put(`/sendingList/${sendingList.id}`, {name: sendingList.name});
    await Swal.fire({icon: "success", title: 'Lista de envio salva com sucesso!'});
  };

  const removeContact = async id => {
    const {value} = await Swal.fire({
      icon: 'question',
      title: 'Deseja realmente remover o contato da lista?',
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    });
    if (!value) return;

    await api.delete(`/sendingList/${sendingList.id}/contact/${id}`);
    await Swal.fire({icon: "success", title: 'Contato removido com sucesso!'});
    await getContactsList(sendingList.id);
  };

  useEffect(() => {
    if (!params || !params.id) history.push('/bulkSend/sendingList');
    else getSendingList(params.id);
  }, []);

  const refUpload = useRef();

  return (
    <GridContainer>
      <input
        type={'file'}
        accept={'.csv,.xls,.xlsx,.txt'}
        style={{display: "none"}}
        ref={refUpload}
        onChange={importFile}
      />
      <GridItem lg={12} md={12} sm={12} xs={12}>
        <Card>
          <CardBody>
            <GridContainer>
              <GridItem lg={8} md={8} sm={8} xs={8}>
                <TextField
                  label="Nome da Lista"
                  type="text"
                  value={sendingList.name}
                  fullWidth
                  onChange={({target: {value}}) => setSendingList({...sendingList, name: value})}
                />
              </GridItem>
              <GridItem lg={4} md={4} sm={4} xs={4}>
                <Button color={'primary'} simple fullWidth onClick={saveName}>
                  <Save/> Salvar
                </Button>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem lg={12} md={12} sm={12} xs={12}>
        <Button color={'primary'} style={{borderRadius: 20}} onClick={() => history.push('/bulkSend/sendingList')} simple>
          <ArrowBack/> Voltar
        </Button>
      </GridItem>
      <GridItem lg={12} md={12} sm={12} xs={12}>
        <Card>
          <CardBody>
            <MuiThemeProvider theme={getMuiTheme()}>
              <MUIDataTable
                columns={[
                  {
                    name: 'id',
                    label: 'ID',
                    options: {
                      display: 'excluded',
                      filter: false,
                      download: false
                    }
                  },
                  {
                    name: 'name',
                    label: "Contato",
                    options: {
                      filter: true,
                      download: false,
                      customBodyRender: (value) => {
                        return (
                          <List dense className={classes.root}>
                            <ListItem>
                              <ListItemAvatar>
                                <Avatar
                                  alt={value.name}
                                  src={value.profilePicture ? getFotoLink(value.profilePicture) : default_avatar}
                                  className={classes.small}
                                />
                              </ListItemAvatar>
                              {value.name ?
                                <ListItemText primary={value.name}/>
                                :
                                <ListItemText primary={"Nome não definido"} style={{color: '#ccc'}}/>
                              }
                            </ListItem>
                          </List>
                        );
                      }
                    }
                  },
                  {
                    name: "phone",
                    label: "Telefone",
                    options: {
                      filter: true,
                    }
                  },
                  {
                    name: "actions",
                    label: " ",
                    options: {
                      filter: false,
                      download: false,
                      customBodyRender: (value, data, a, b, c) => {
                        return (
                          <div style={{display: 'flex', justifyContent: 'center'}}>
                            <Tooltip title={"Excluir contato"}>
                              <IconButton color="link" onClick={() => removeContact(value)}>
                                <Close fontSize="small" color="error"/>
                              </IconButton>
                            </Tooltip>
                          </div>
                        );
                      }
                    },
                  },
                  {
                    name: 'name',
                    label: 'Nome',
                    options: {
                      display: 'excluded',
                      filter: true,
                      download: false
                    }
                  },
                ]}
                options={{
                  ...traducaoTable,
                  filter: false,
                  print: false,
                  download: false,
                  viewColumns: false,
                  filterType: "dropdown",
                  responsive: "scrollFullHeight",
                  rowsPerPage: 10,
                  selectableRowsOnClick: 'true',
                  selectableRows: 'none',
                  customToolbar: () => {
                    return (
                      <>
                        <Tooltip title={"Importar Contatos"}>
                          <ContactImportComponent
                            buttonProps={{className: classes.iconButtonRoot, simple: true, justIcon: true}}
                            sendingList={sendingList.id}
                            onFinish={() => history.push('/contatos/importacao')}
                          >
                            <Backup/>
                          </ContactImportComponent>
                        </Tooltip>
                        <Tooltip title={"Adicionar contatos manualmente"}>
                          <IconButton className={classes.iconButton} color={'primary'} disabled>
                            <PersonAdd />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={"Adicionar contatos de lista"}>
                          <IconButton className={classes.iconButton} color={'primary'} disabled>
                            <LibraryAdd/>
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={"Importar contatos do google"}>
                          <IconButton className={classes.iconButton} color={'primary'} disabled>
                            <Group/>
                          </IconButton>
                        </Tooltip>
                      </>
                    );
                  }
                }}
                data={contacts.map(contact => {
                  return [
                    contact.id,
                    {name: contact.name, profilePicture: contact.profilePicture},
                    contact.idWhatsapp.split('@')[0],
                    contact.id,
                    contact.name
                  ]
                })}
              />
            </MuiThemeProvider>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
};

export default SendingListEdit;