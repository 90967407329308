import React from "react";
import {useStyles} from "./Message";
import MessageBoxText from "./MessageBoxText";

const MessageBoxImage = ({identifier, caption, user}) => {
  const classes = useStyles();

  return (
    <>
      <img
        src={`https://storage.hustapp.com/midias/${identifier}`}
        alt={identifier}
        className={classes.MessageBoxImage}
      />
      {caption && <MessageBoxText user={user} body={caption}/>}
    </>
  );
};

export default MessageBoxImage