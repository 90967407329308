import React, {useRef, useState} from 'react';
import ModalContactImportConfig from "../Modal/ContactImportConfig";
import {GridItem} from "../../components";
import Button from "../../components/CustomButtons/Button";
import api from "../../components/api";

const ContactImportComponent = ({buttonProps, children, sendingList, onFinish}) => {
  const [jobImport, setjobImport] = useState(0);

  const refUpload = useRef();

  const importFile = async e => {
    e.preventDefault();
    const [file] = e.target.files;

    const data = new FormData();
    data.append('file', file, file.name);

    const {data: job} = await api.post(`/contact/import`, data, {params: {sendingList}});
    setjobImport(job.id);
  };

  return (
    <>
      <input
        type={'file'}
        accept={'.csv,.xls,.xlsx,.txt'}
        style={{display: "none"}}
        ref={refUpload}
        onChange={importFile}
      />
      <ModalContactImportConfig
        open={!!jobImport}
        idJob={jobImport}
        onClose={status => {
          setjobImport(0);
          if (status && onFinish) onFinish();
        }}
      />
      <Button round color={"hust"} rount {...buttonProps} onClick={() => refUpload.current.click()} >
        {children}
        {/*Importar novo arquivo*/}
      </Button>
    </>
  );
};

export default ContactImportComponent;