import React, {useState, useEffect} from "react";
import MUIDataTable from "mui-datatables";

import {WebService} from "../../../components/WebService";
import {getFotoLink, traducaoTable} from "../../../components/Funcoes";

import {Card, CardIcon, CardHeader, CardBody} from '../../../components';

import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  IconButton,
  Modal,
  Tooltip,
  Chip
} from '@material-ui/core';
import {Close, Edit, PeopleAlt, PersonAdd} from '@material-ui/icons';

import {createMuiTheme, makeStyles, MuiThemeProvider} from '@material-ui/core/styles';
import {useStyles} from "./styles";
import * as Swal from "sweetalert2";
import UploadContatos from '../Toolbar/UploadContatos';
import Form from '../Toolbar/Form';
import default_avatar from '../../../assets/img/default-avatar.png';
import Switch from "@material-ui/core/Switch";
import customCheckboxRadioSwitch from "../../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import {dangerColor} from "../../../assets/jss/material-dashboard-pro-react";

const CustomModal = ({handleClose, show, children}) => {
  const classes = useStyles();
  return (
    <Modal open={show} onClose={handleClose} className={classes.modal}>
      <>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <Close color="error"/>
        </IconButton>
        {children}
      </>
    </Modal>
  );
};

export default function Utilizadores({id_empresa}) {
  const classes = useStyles();
  const classes1 = makeStyles(() => ({...customCheckboxRadioSwitch}))();

  const [idUser, setIdUser] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [flagGestor, setFlagGestor] = useState(false);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("");
  const [departamentos, setDepartamentos] = useState([]);

  const getDepartamentos = async () => {
    const {ok, departamentos} = await WebService(`/departamento/getDepartamentos`);
    if (ok) {
      const tempDepartamentos = [];
      const getChild = (d, n) => {
        d.map(prop => {
          const {nome, id_departamento, subDepartamentos} = prop;
          if (subDepartamentos) getChild(subDepartamentos, `${n ? n + ' -> ' : ''}${nome}`);
          else tempDepartamentos.push({nome: `${n ? n + ' -> ' : ''}${nome}`, id_departamento});
        });
      };
      getChild(departamentos);
      setDepartamentos(tempDepartamentos);
    }
  };

  const handleModal = (value, type) => {
    setIdUser(value);
    setOpen(!open);

    switch (type) {
      case 'atualizaUsuário':
        setType('atualizaUsuário');
        break;
      case 'importaContato':
        setType('importaContato');
        break;

      default:
        break;
    }
  }

  const atualizaFlagGestor = async (id, flag_gestor) => {
    const user = usuarios.filter((user) => {
      return user.id_usuario === id;
    });

    const message = flag_gestor === true ? `Você irá tornar ${user[0].nome} um gestor do sistema.` : `Você irá retirar as permissões de ${user[0].nome} e não será mais gestor do sistema.`
    const messageConfirm = flag_gestor === true ? `${user[0].nome} agora é um gestor do sistema.` : `${user[0].nome} não é mais gestor do sistema.`

    const {value} = await Swal.fire({
      title: 'Confirma esta ação?',
      text: message,
      icon: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: true,
      confirmButtonText: 'Confirma',
      cancelButtonText: 'Cancela',
      reverseButtons: true
    });
    if (value === true) {
      const {ok} = await WebService(`/usuario/atualizaStatusGestor`, {
        id_usuario: user[0].id_usuario,
        flag_gestor: flag_gestor
      });
      if (ok) {
        await getUsuarios();
        await Swal.fire({
          title: 'Ação realizada!',
          text: `${messageConfirm}`,
          icon: 'success',
          showConfirmButton: false,
          timer: 2500
        });
      }
    }

  };

  const excluirUsuario = async id_usuario => {
    const user = usuarios.filter((user) => {
      return user.id_usuario === id_usuario;
    });

    const message = ` Esta ação irá desativar o usuário ${user[0].nome} do sistema.`

    const {value} = await Swal.fire({
      title: 'Confirma esta ação?',
      text: message,
      icon: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: true,
      confirmButtonText: 'Confirma',
      cancelButtonText: 'Cancela',
      reverseButtons: true
    });
    if (value === true) {
      const {ok, text} = await WebService(`/usuario/desativarUsuario`, {id_usuario});
      if (ok) {
        await getUsuarios();
        await Swal.fire({
          title: 'Ação realizada!',
          text: `${text}`,
          icon: 'success',
          showConfirmButton: false,
          timer: 2500
        });
      }
    }
  };

  const getUsuarios = async () => {
    const {ok, usuarios} = await WebService(`/usuario/getUsuarios`, {id_empresa});
    if (ok)
      setUsuarios(usuarios);
  };

  const alterarStatusUsuario = async (status, id_usuario) => {
    const {value: confirm} = await Swal.fire({
      icon: 'question',
      title: 'Deseja realmente alterar o status do usuário?',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancellButtonText: 'Não'
    });

    if (!confirm) return;

    const statusData = {id_usuario, flag_ativo: !status};

    const {ok} = await WebService(`/usuario/alterarStatus`, statusData);
    if (ok) {
      await Swal.fire({icon: 'success', title: 'Usuário alterado com sucesso!'});
      getUsuarios();
    }
  };

  useEffect(() => {
    getDepartamentos();
    getUsuarios();
  }, []);

  const getMuiTheme = () => createMuiTheme({
    overrides: {
      MUIDataTable: {
        root: {
          marging: 0,
          border: 0,
          boxShadow: 'none'
        },
        paper: {
          margin: 0,
          boxShadow: "none",
        }
      },
      MUIDataTableBodyCell: {
        root: {
          padding: 0,
        }
      }
    }
  });

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        display: 'excluded',
        filter: false
      }
    },
    {
      name: "user",
      label: "Nome",
      options: {
        filter: true,
        sort: true,
        display: "excluded"
      }
    },
    {
      name: "user",
      label: "Nome",
      options: {
        filter: false,
        download: false,
        customBodyRender: (value) => {
          const {avatar, nome} = value.user;
          const novo = nome.toString();
          return (
            <List dense className={classes.root}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    alt={nome}
                    src={avatar ? getFotoLink(avatar) : default_avatar}
                    className={classes.small}
                  />
                </ListItemAvatar>
                <ListItemText primary={novo}/>
              </ListItem>
            </List>
          );
        }
      },
    },
    {
      name: "email",
      label: "E-mail",
      options: {
        filter: false,
      }
    },
    {
      name: "flag_active",
      label: "Ativo",
      options: {
        filter: false,
        download: true,
        customBodyRender: (status, {rowData}) => {
          const [id_usuario] = rowData;

          return (
            <Switch
              checked={!!status}
              onClick={() => alterarStatusUsuario(status, id_usuario)}
              value="Status"
              classes={{
                switchBase: classes1.switchBase,
                checked: classes1.switchChecked,
                thumb: classes1.switchIcon,
                track: classes1.switchBar
              }}
            />
          )
        }
      }
    },
    {
      name: "departamento",
      label: "Departamento",
      options: {
        filter: false,
        customBodyRender: (value) => (value.length > 0) ? value.map(val => <Chip  style={{margin: 5}} label={val} size="small" />) : ''
      }
    },
    {
      name: "flag_gestor",
      label: "Gestor do Sistema",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          const {rowIndex} = tableMeta;

          const {flag_gestor, id_usuario} = usuarios[rowIndex];
          return (
            <Switch
              checked={!!flag_gestor}
              onClick={() => atualizaFlagGestor(id_usuario, !usuarios[rowIndex].flag_gestor)}
              name="flag_gestor"
              classes={{
                switchBase: classes1.switchBase,
                checked: classes1.switchChecked,
                thumb: classes1.switchIcon,
                track: classes1.switchBar
              }}
            />
          );
        }
      }
    },
    {
      name: "actions",
      label: " ",
      options: {
        filter: false,
        download: false,
        customBodyRender: (value) => {
          return (
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <IconButton color="link" onClick={() => handleModal(value, 'atualizaUsuário')}>
                <Tooltip title="Editar">
                  <Edit fontSize="small" color="primary"/>
                </Tooltip>
              </IconButton>
              <IconButton color="link" title="Deletar" onClick={() => excluirUsuario(value)}>
                <Close fontSize="small" color="error"/>
              </IconButton>
            </div>
          );
        }
      },
    }
  ];

  const options = {
    textLabels: traducaoTable,
    filter: true,
    filterType: "dropdown",
    responsive: "scrollFullHeight",
    rowsPerPage: 10,
    selectableRows: 'none',
    //enableNestedDataAccess: '.',
    sort: false,
    // customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
    //   <CustomToolbarSelect selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows} />
    // ),
    setRowProps: row => {
      const user = usuarios.find(el => el.id_usuario === row[0]);
      return {style: {backgroundColor: !user.flag_ativo ? dangerColor[6] : ""}};
    },
    customToolbar: () => {
      return (
        <>
          {/* <Tooltip title={"Importar Contatos"} onClick={() => handleModal(null, 'importaContato')}>
            <IconButton className={classes.iconButton}>
              <Backup />
            </IconButton>
          </Tooltip> */}
          <IconButton className={classes.iconButton}>
            <Tooltip title={"Novo Contato"}>
              <PersonAdd onClick={async () => {
                const {value} = await Swal.fire({
                  icon: "question",
                  title: 'Cadastrar novo usuário.',
                  text: 'Adicionar novos usuários poderá acarretar em alteração no valor da sua fatura. Deseja continuar?',
                  showCancelButton: true,
                  cancelButtonText: 'Não',
                  confirmButtonText: 'Sim'
                });

                if (value) handleModal(null, 'atualizaUsuário');
              }}/>
            </Tooltip>
          </IconButton>
        </>
      );
    }
  };

  return (
    <>
      <Card>
        <CardHeader color="primary" icon>
          <CardIcon color="primary" className={classes.cardIcon}>
            <PeopleAlt style={{height: '50px', width: '50px'}}/>
          </CardIcon>
          <h4 className={classes.cardIconTitle}>Usuários do Sistema <br/> <small className={classes.cardIconSubtitle}>(Gestores,
            supervisores, atendentes e etc...)</small></h4>
        </CardHeader>
        <CardBody style={{margin: 0, padding: 0}}>
          <MuiThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              columns={columns}
              options={options}
              data={
                usuarios.map(user => {
                  return [
                    user.id_usuario,
                    user.nome,
                    {user: {"nome": user.nome, "avatar": user.foto_perfil}},
                    user.email,
                    user.flag_ativo,
                    (user.id_departamento_fk || '').split(',').map(prop => {
                      return (departamentos.find(el => parseFloat(el.id_departamento) === parseFloat(prop)) || {}).nome
                    }),
                    { flag: { "id_usuario": user.id_usuario, "flag_gestor": user.flag_gestor } },
                    user.id_usuario,
                  ]
                })}
            />
          </MuiThemeProvider>
        </CardBody>
      </Card>

      {/* MODAL */}
      <CustomModal show={open} handleClose={handleModal}>
        {type === 'atualizaUsuário' ?
          <Form usuario={idUser} hideModal={handleModal} getUsers={getUsuarios}/>
          : <UploadContatos/>
        }
      </CustomModal>
    </>
  );
}
