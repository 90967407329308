import React from 'react';
import {Tooltip} from "@material-ui/core";
import {getFotoLink} from "../../../components/Funcoes";
import {primaryColor} from "../../../assets/jss/material-dashboard-pro-react";
import {makeStyles} from "@material-ui/core/styles";

const style = {
  external: {
    width: 40,
    height: 40,
    padding: 3,
    border: `2px solid transparent`,
    transition: '.3s',
    borderRadius: '50%',
    borderStyle: 'dashed',
    position: 'relative',
    '&:hover > span': {
      display: 'block'
    },
    '&:hover': {
      border: `2px solid ${primaryColor[0]}`,
      borderStyle: 'dashed',
      // '&:after': {
      //   content: '"x"',
      //   color: '#FF0000',
      //   fontWeight: 900,
      //   width: 10,
      //   position: 'absolute',
      //   bottom: -18,
      //   left: 'calc(50% - 5px)',
      //   textAlign: 'center',
      //   cursor: 'pointer'
      // }
    },
  },
  delete: {
    content: '"x"',
    color: '#FF0000',
    fontWeight: 900,
    width: 30,
    height: 22,
    position: 'absolute',
    bottom: -20,
    left: 'calc(50% - 15px)',
    textAlign: 'center',
    cursor: 'pointer',
    display: 'none',
    transition: '.3s'
  }
};

const useStyles = makeStyles(style);
const ViewUserActions = ({onClick, onDelete, name, profilePicture, showRemove, multiple, style}) => {
  const classes = useStyles();

  return (
    <div
        className={classes.external}
        style={{marginLeft: multiple ? -18 : 0, ...(style || {}), ...(onClick ? {cursor: 'pointer'} : {})}}
        onClick={onClick}
    >
      <Tooltip title={name}>
        <img
          src={getFotoLink(profilePicture)}
          alt={name}
          style={{width: 30, height: 30, borderRadius: '50%', border: `1px solid #FF3115`}}
        />
      </Tooltip>
      {showRemove ? (
        <Tooltip title={'Remover'}>
        <span className={classes.delete} onClick={onDelete}>
          x
        </span>
        </Tooltip>
      ) : ''}
    </div>
  );
};

export default ViewUserActions;