import React, { memo, useEffect, useState } from 'react';
import { Handle, Position } from 'reactflow';
import { Card, CardBody } from "../../../components";
import Header from "./components/Header";
import { Send } from "../../../assets/img/icons";
import TextBox from "./components/Input/TextBox";

function SendMessage({ id, data, selected }) {
  const [ text, setText ] = useState(data.text || '');

  useEffect(() => {
    data.text = text;
  }, [ text ])

  return (
    <Card
      style={{
        margin: 0,
        width: 420,
        borderRadius: 14,
        boxShadow: selected ? '0px 50px 50px 0px rgba(4, 15, 21, 0.15), 0px 40px 30px -30px rgba(4, 15, 21, 0.15)' : '#08365A 0px 0px 20px -10px',
        padding: 5
      }}
    >
      <Header title={'Mensagem'} subtitle={'Envia uma mensagem de texto'} icon={Send} id={id}/>
      <CardBody style={{padding: 5}}>
        <TextBox label={''} value={text} rows={6} onChange={setText}/>
      </CardBody>

      <Handle type="target" position={Position.Left} style={{ marginLeft: -3, background: '#E94D3F', width: 16, border: '3px solid #FFFFFF', height: 16 }}/>
      <Handle type="source" position={Position.Right} style={{ marginRight: -3, background: '#FFFFFF', width: 16, border: '2px solid #E94D3F', height: 16 }}/>
    </Card>
  );
}

export default memo(SendMessage);
