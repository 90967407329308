import React, {useRef, useState} from "react";
import {useStyles} from "./Message";

const MessageBoxAudio = ({identifier, transcription, user}) => {
  const classes = useStyles();

  // const renderedMessage = converterTextoWpp(removeAttendanceNameFromMessage(caption, user && user.name), true);

  // const downloadFile = async () => downloadFileAsBlob(await fetchAsBlob(`https://storage.hustapp.com/midias/${identifier}`), fileName || `download.${fileExtension}`);

  const playIconContainer = useRef();
  const muteIconContainer = useRef();
  const audioRef = useRef();
  const seekSliderRef = useRef();

  const [playState, setPlayState] = useState('stoped');
  const [playAnimation, setPlayAnimation] = useState(null);

  // useEffect(() => {
  //   setPlayAnimation(lottieWeb.loadAnimation({
  //     container: playIconContainer.current,
  //     path: 'https://maxst.icons8.com/vue-static/landings/animated-icons/icons/pause/pause.json',
  //     renderer: 'svg',
  //     loop: false,
  //     autoplay: false,
  //     name: "Play Animation",
  //     initialSegment: [14, 27],
  //   }));
  //   // const muteAnimation = lottieWeb.loadAnimation({
  //   //   container: muteIconContainer.current,
  //   //   path: 'https://maxst.icons8.com/vue-static/landings/animated-icons/icons/mute/mute.json',
  //   //   renderer: 'svg',
  //   //   loop: false,
  //   //   autoplay: false,
  //   //   name: "Mute Animation",
  //   // });
  //
  //   // playAnimation.playSegments([14, 27], true);
  //
  //   // playAnimation.goToAndStop(14, true);
  //
  //   // O código JS do jogador de áudio vai aqui
  //
  // }, []);
  //
  // useEffect(() => {
  //   if (!playAnimation) return;
  //   console.log({playAnimation});
  //   const audio = audioRef.current;
  //   if (playState === 'pause') {
  //     audio.play();
  //     playAnimation.playSegments([14, 27], true);
  //     // requestAnimationFrame(whilePlaying);
  //     // playState = 'pause';
  //   } else if (playState === 'play') {
  //     audio.pause();
  //     playAnimation.playSegments([0, 14], true);
  //     // cancelAnimationFrame(raf);
  //     // playState = 'play';
  //   }
  // }, [playState]);
  //
  // useEffect(() => {
  //   console.log(audioRef.current);
  // }, [audioRef]);

  return (
    <div key={identifier}>
      <audio src={`https://storage.hustapp.com/midias/${identifier}`} preload="metadata" controls></audio>
      <span>
        {transcription || ''}
      </span>
      {/*<button*/}
      {/*  ref={playIconContainer}*/}
      {/*  style={{width: 50, height: 50}}*/}
      {/*  onClick={() => setPlayState(['stoped', 'pause'].includes(playState) ? 'play' : 'pause')}*/}
      {/*/>*/}
      {/*<div>*/}
      {/*  <input type="range" ref={seekSliderRef} id="seek-slider" max="100" value="0"/>*/}
      {/*  <span*/}
      {/*    style={{*/}
      {/*      display: `inline-block`,*/}
      {/*      width: 37,*/}
      {/*      textAlign: `center`,*/}
      {/*      fontSize: 20,*/}
      {/*      margin: `28.5px 0 18.5px 0`,*/}
      {/*      float: `left`*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    0:00*/}
      {/*  </span>*/}
      {/*  <span*/}
      {/*    id="duration"*/}
      {/*    style={{*/}
      {/*      display: `inline-block`,*/}
      {/*      width: 37,*/}
      {/*      textAlign: `center`,*/}
      {/*      fontSize: 20,*/}
      {/*      margin: `28.5px 0 18.5px 0`,*/}
      {/*      float: `left`*/}
      {/*    }}*/}
      {/*  >*/}
      {/*  0:00*/}
      {/*</span>*/}
      {/*</div>*/}
      {/*<button ref={muteIconContainer} style={{width: 50, height: 50}}/>*/}
    </div>
  );
};

export default MessageBoxAudio;