import React, {useEffect, useState} from "react";
import Card from "../components/Card/Card";
import Button from "../components/CustomButtons/Button";
import Swal from "sweetalert2";
import {configHost, WebService} from "../components/WebService";
import Success from "../components/Typography/Success";
import Danger from "../components/Typography/Danger";
import Code from "@material-ui/icons/Code";
import {Close, PersonAdd} from '@material-ui/icons';
import GridContainer from "../components/Grid/GridContainer";
import GridItem from "../components/Grid/GridItem";
import CardHeader from "../components/Card/CardHeader";
import CardIcon from "../components/Card/CardIcon";
import CardFooter from "../components/Card/CardFooter";
import extendedTablesStyle from "../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";
import buttonStyle from "../assets/jss/material-dashboard-pro-react/components/buttonStyle";
import dashboardStyle from "../assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import {cardTitle} from "../assets/jss/material-dashboard-pro-react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Tooltip,
  Typography
} from "@material-ui/core";
import Build from "@material-ui/icons/Build";
import {getFotoLink, nomeProprio} from "../components/Funcoes";
import {AsYouType, parsePhoneNumberFromString} from 'libphonenumber-js';
import Warning from "../components/Typography/Warning";
import Icon from "@material-ui/core/Icon";
import Info from "../components/Typography/Info";
import {RotateLeft} from "@material-ui/icons";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  },
  cardCategory: {
    color: "#999999",
    marginTop: "10px"
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px"
    }
  },
  marginTop30: {
    marginTop: "30px"
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px"
    }
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: "#999999"
  },
  ...extendedTablesStyle,
  ...buttonStyle,
  ...dashboardStyle
};
const useStyles = makeStyles(styles);

export default function ListarConexoes({history, webSocket}) {
  const [conexoes, setConexoes] = useState([]);
  const [atualiza, setAtualiza] = useState(false);
  const [openModal, setOpenModal] = useState(false)

  const classes = useStyles();

  const novaConexaoWhatsApp = async () => {
    // if (conexoes.filter(el => el.flag_ativo && !el.status.sessaoIniciada).length) {
    //   Swal.fire({
    //     icon: "info",
    //     title: 'Não é possível adicionar nova conexão enquanto todas não estiverem inicializadas.',
    //     text: 'Aguarde alguns momentos até todas as conexões estarem inicializadas.'
    //   });
    //   return false;
    // }
    const {token} = localStorage;
    let conectado = false;
    const ws = new WebSocket(`ws${configHost.protocol}://${configHost.baseURL}:${configHost.portWSS}`);
    ws.onmessage = message => {
      const {data} = message;
      const {metodo, QRCode, ok, mensagem_usuario, descricao_usuario, autenticado} = JSON.parse(data);
      switch (metodo) {
        case 'login':
          if (autenticado)
            ws.send(JSON.stringify({metodo: 'QRCodeWhatsApp', token}));
          break;
        case 'QRCodeWhatsApp':
          if (QRCode) {
            Swal.hideLoading();
            Swal.fire({
              showConfirmButton: false,
              html: `<img alt='QR Code' src="${QRCode}"/>`,
              allowOutsideClick: false,
            });
          } else {
            if (ok) {
              conectado = true;
              Swal.fire({
                showConfirmButton: true,
                title: "WhatsApp conectado com sucesso!",
                icon: 'success'
              }).then(getConexoes.bind(this));
              ws.close();
            } else {
              Swal.fire({title: mensagem_usuario, text: descricao_usuario, allowOutsideClick: false});
              Swal.showLoading();
            }
          }
          break;
      }
    };

    ws.onopen = () => {
      Swal.fire({title: "Aguarde...", text: `Iniciando conexão`, allowOutsideClick: false});
      ws.send(JSON.stringify({metodo: 'login', token}));
      Swal.showLoading();
    };

    ws.onclose = () => {
      try {
        ws.close();
      } catch (e) {

      }

      if (!conectado) {
        Swal.fire({
          icon: "warning",
          title: "Ocorreu um erro ao conectar um novo dispositivo.",
          text: "Tente novamente."
        });
      }
    };
  };

  const getConexoes = async () => {
    const {ok, whatsapp_conexao} = await WebService(`/whatsapp_api/getConexoes`);
    if (ok)
      setConexoes(whatsapp_conexao);
    setAtualiza(!atualiza);
  };

  const excluirDispositivo = async id_whatsapp_conexao => {
    const {value} = await Swal.fire({
      icon: "question",
      title: 'Deseja realmente excluir a conexão?',
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    });
    if (!value) return;

    const {ok} = await WebService(`/whatsapp_api/excluirDispositivo`, {id_whatsapp_conexao});
    if (ok) {
      await Swal.fire({icon: "success", title: "Conexão excluída com sucesso!"});
      await getConexoes();
    }
  };

  const setConexaoPrincipal = async (id) => {
    const {ok} = await WebService(`/whatsapp_api/setConexaoPrincipal`, {id});
    if (ok) {
      await getConexoes();
      await Swal.fire({icon: "success", title: 'Conexão principal definida com sucesso!'});
    }
  };

  const reiniciarConexao = async (uuid) => {
    const {value} = await Swal.fire({
      icon: "question",
      title: 'Deseja realmente reiniciar a conexão?',
      text: 'Essa ação é usada para reestabelecer sua conta caso esteja com algum problema.',
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    });
    if (!value) return;

    const {ok} = await WebService(`/whatsapp_api/reiniciarSessaoWhatsAppM`, {uuid});
    if (ok) Swal.fire({icon: "success", title: 'Sua conexão foi reiniciada com sucesso!'});
  };

  useEffect(() => {
    getConexoes();
  }, []);

  const novaConexaoWhatsAppRemoto = async () => {
    const {value: name} = await Swal.fire({icon: "question", title: "Digite o nome do usuário.", input: "text"});
    if (name) {
      const {token} = localStorage;
      await Swal.fire({
        icon: "success",
        html: `<code>http${configHost.protocol}://${configHost.baseURL}${configHost.portHttp}${configHost.versionAPI}/whatsapp_api/novaConexaoRemota?name=${btoa(name)}&token=${token}</code>`
      });
    } else {
      await Swal.fire({icon: "warning", title: 'Digite um nome de conexão válido.'});
    }
  };

  const receberStatusWS = async ({data}) => {
    const {uuid, metodo, ...outros} = JSON.parse(data);
    if (metodo === 'statusConexao') {
      const tempConexoes = [...conexoes];
      tempConexoes[tempConexoes.map(p => p.uuid).indexOf(uuid)].status = outros;
      setConexoes(tempConexoes);
    }
  };

  useEffect(() => {
    if (webSocket) {
      webSocket.send(JSON.stringify({metodo: 'adicionarEvento', evento: 'monitorarStatusConexoes'}));
      webSocket.addEventListener("message", receberStatusWS);
      return () => {
        webSocket.send(JSON.stringify({metodo: 'removerEvento', evento: 'monitorarStatusConexoes'}));
        webSocket.removeEventListener("message", receberStatusWS);
      };
    }
  }, [webSocket, atualiza]);

  return (
    <div>
      <GridContainer>
        <GridItem lg={2} md={4} sm={6} xs={12}>
          <Button color={"hust"} onClick={() => setOpenModal(true)} >
            Nova conexão
          </Button>
          <Dialog
            open={openModal}
            onClose={() => setOpenModal(false)}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle style={{textAlign: 'center'}}>
              Nova conexão
            </DialogTitle>
            <DialogContent>
              <div style={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                <Typography style={{textAlign: 'center'}}>
                  Adicionar uma nova conexão poderá acarretar em alteração no valor da sua fatura.
                </Typography>
                <Typography style={{textAlign: 'center', color: 'red'}}>
                  OBS: Reconectar números que estão desconectados não gera uma nova cobrança.
                </Typography>
                <Typography style={{textAlign: 'center', fontWeight: '400'}}>
                  Deseja continuar?
                </Typography>
              </div>

            </DialogContent>
            <DialogActions style={{display: 'flex', justifyContent: 'center'}}>
              <Button onClick={() => setOpenModal(false)}>
                Não
              </Button>
              <Button
                color={"danger"}
                onClick={() => {
                  setOpenModal(false)
                  novaConexaoWhatsApp()
                }}
              >
                Sim
              </Button>
            </DialogActions>

          </Dialog>
        </GridItem>
        {/*<GridItem lg={2} md={4} sm={6} xs
Remover nome do atendente ao enviar mensagem={12}>
          <Button color={"info"} onClick={novaConexaoWhatsAppRemoto.bind(this)} fullWidth>
            Enviar código de conexão
          </Button>
        </GridItem>*/}
      </GridContainer>
      <GridContainer style={{display: 'flex'}}>
        {conexoes.map((prop, key) => {
          const {
            uuid,
            id_whatsapp_conexao,
            flag_ativo,
            flag_principal,
            conta_nome,
            conta_numero,
            conta_marca_celular,
            status,
            foto_perfil
          } = prop;
          const {
            outraAba,
            desconectado,
            carregando,
            nivelBateria,
            celularDesconectadoInternet,
            sessaoIniciada
          } = status;
          const configMensagem = (
            <Tooltip title={"Configurações da conexão"}>
              <Button
                justIcon round
                color={"warning"}
                simple
                onClick={() => history.push({pathname: `/conexoes/configuracoes`, state: {id_whatsapp_conexao, key}})}
                style={{ padding: 0, minWidth: 30, width: 30, height: 30 }}
              >
                <Build/>
              </Button>
            </Tooltip>
          );

          const getToken = (
            <Button justIcon round simple color={"info"} onClick={() => Swal.fire({html: `<code>${uuid}</code>`})}style={{ padding: 0, minWidth: 30, width: 30, height: 30 }}>
              <Code/>
            </Button>
          );

          const tipo = 'whatsapp';
          const tipoConta = {whatsapp: 'success', telegram: 'info'};

          const dispositivoOnline = !celularDesconectadoInternet && (status && !desconectado) && !outraAba;

          return (
            <GridItem xs={12} sm={6} md={4} lg={3} key={key} style={{display: 'inline-flex'}}>
              <Card>
                <CardHeader color={tipoConta[tipo]} stats icon>
                  <CardIcon color={tipoConta[tipo]} style={{width: 85, height: 85}}>
                    <i className={`fab fa-${tipo}`} style={{position: "absolute", marginLeft: -55}}/>
                    <img
                      alt={conta_nome}
                      className={`imagem-perfil-conexao`}
                      src={getFotoLink(foto_perfil)}
                      style={{
                        margin: -15,
                        width: 85,
                        height: 85,
                        borderRadius: 3,
                        opacity: 0,
                        position: "absolute",
                        marginLeft: -70
                      }}
                    />
                  </CardIcon>
                  <p></p>
                  <h3 className={classes.cardTitle}>
                    <small>{nomeProprio(conta_nome)}</small>
                  </h3>
                  <h4 className={classes.cardTitle}>
                    <small>{new AsYouType().input(`+${conta_numero}`)}</small>
                  </h4>
                  <p className={classes.cardCategory}>
                    {nomeProprio(conta_marca_celular)}
                  </p>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats}>
                    {flag_ativo && !desconectado ? (
                      outraAba ?
                        <Warning>WhatsApp aberto em outro local</Warning> : (
                          !sessaoIniciada ? (
                            <Info>Conexão não inicializada</Info>
                          ) : (
                            celularDesconectadoInternet ?
                              <Warning>Celular desconectado da internet</Warning> : <Success>Ativo</Success>
                          )
                        )
                    ) : <Danger>Desconectado</Danger>}
                  </div>
                  <p>
                    <div>
                      {flag_ativo ? (
                        <>
                          {configMensagem}
                          {getToken}
                          <Tooltip title={'Reiniciar conexão'}>
                            <Button justIcon round simple color={"success"} onClick={() => reiniciarConexao(uuid)} style={{ padding: 0, minWidth: 30, width: 30, height: 30 }}>
                              <RotateLeft/>
                            </Button>
                          </Tooltip>
                        </>
                      ) : ''}
                      <Tooltip title={`${flag_ativo ? 'Desconectar' : 'Excluir'} conexão`}>
                        <Button
                          justIcon
                          round
                          color={"danger"}
                          simple
                          onClick={() => excluirDispositivo(id_whatsapp_conexao)}
                          style={{ padding: 0, minWidth: 30, width: 30, height: 30 }}
                        >
                          <Close/>
                        </Button>
                      </Tooltip>
                    </div>
                  </p>
                </CardFooter>
              </Card>
            </GridItem>
          );
        })}
      </GridContainer>
    </div>
  );
}
