import React, { useEffect } from "react";

import defaultImage from "../../assets/img/default-avatar.png";

import { Modal, Card, IconButton } from "@material-ui/core";
import Button from "../../components/CustomButtons/Button";
import { Close } from '@material-ui/icons';

import 'react-image-crop/dist/ReactCrop.css';
import { makeStyles } from "@material-ui/core";
import modalStyle from "../../assets/jss/material-dashboard-pro-react/modalStyle";
import ReactCrop from 'react-image-crop';

const useStyles = makeStyles(modalStyle);
let imageRef;
let fileUrl;

export default function PictureUpload({ label, accept, onUpload, preview, recortar, simple }) {
  const classes = useStyles();
  const [file, setFile] = React.useState("");
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(preview || defaultImage);
  const [crop, setCrop] = React.useState({ aspect: 1, width: 250, top: '50%', bottom: '50%', transform: 'translate(-50%, -50%' });
  const [croppedImageUrl, setCroppedImageUrl] = React.useState("");
  const [base64corte, setBase64corte] = React.useState("");

  useEffect(() => {
    if (preview)
      setImagePreviewUrl(preview);
  }, [preview]);

  const makeClientCrop = async (crop, c1) => {
    if (imageRef && crop.width && crop.height)
      setCroppedImageUrl(await getCroppedImg(imageRef, crop, 'newFile.jpeg'));
  };

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );
    const base64 = canvas.toDataURL();
    setBase64corte(base64);

    return new Promise(resolve => canvas.toBlob(blob => {
      if (!blob)
        return;
      blob.name = fileName;
      window.URL.revokeObjectURL(fileUrl);
      fileUrl = window.URL.createObjectURL(blob);
      resolve(fileUrl);
    }, 'image/jpeg'));
  };

  const handleImageChange = e => {
    e.preventDefault();
    const reader = new FileReader();
    const newFile = e.target.files[0];
    if (newFile) {
      reader.onloadend = () => {
        setFile(reader.result);
        if (onUpload && !recortar) {
          // onUpload(reader.result.split(";")[1].slice(7));
          onUpload(reader.result, newFile);
        }
        // setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(newFile);
    }
  };

  const handleRemove = () => {
    setFile(null);
    setCroppedImageUrl(null);
    setImagePreviewUrl(defaultImage);
    setBase64corte(null);
  };

  if (simple) {
    return (
      <div className="" style={{marginTop: 10}}>
        <input
          defaultValue={fileUrl}
          type={"file"}
          onChange={handleImageChange.bind(this)}
          accept={accept}
          onClick={e => e.target.value = null}
        />
      </div>
    );
  }

  return (
    <>
      <div className="picture-container">
        <div className="picture">
          <img src={imagePreviewUrl} className="picture-src" alt="..." />
          <input
            type={"file"}
            onChange={handleImageChange.bind(this)}
            accept={accept}
            onClick={e => e.target.value = null}
          />
        </div>
        {croppedImageUrl &&
          <span>
            <IconButton title="Remover" onClick={() => handleRemove()}>
              <Close color="error" />
            </IconButton>
          </span>
        }
      </div>
      <Modal
        open={file && recortar}
        keepMounted
        onClose={() => { }}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Card style={{
            display: 'grid',
            alignItems: 'center',
            justifyContent: 'center',
          }}>

            <div style={{ maxWidth: '500px', maxHeight: '500px', margin: '2px 3px' }}>
              <ReactCrop
                src={file ? file : null}
                crop={crop}
                onImageLoaded={image => imageRef = image}
                onComplete={makeClientCrop.bind(this)}
                onChange={crop => setCrop(crop)}
              />
            </div>
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: '0 3px 1px 3px'
            }}>
              <Button
                round
                block
                color="secondary"
                onClick={() => {
                  handleRemove()
                  setFile(null)
                  setImagePreviewUrl(imagePreviewUrl);
                }}>
                  Cancelar
              </Button>
              <Button
                round
                block
                color="info"
                onClick={() => {
                  setFile(null);
                  setImagePreviewUrl(croppedImageUrl);
                  if (onUpload && recortar)
                    onUpload(base64corte, croppedImageUrl);
                }}>
                  Salvar
                </Button>
            </div>
          </Card>
      </Modal>
    </>
  );
}
