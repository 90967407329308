import React, {useEffect, useState} from 'react';
import {CardBody, GridContainer, Table} from "../../components";
import {ArrowBack, ListAlt} from "@material-ui/icons";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import {pontuacaoNumero} from "../../components/Funcoes";
import CardIcon from "../../components/Card/CardIcon";
import CardFooter from "../../components/Card/CardFooter";
import Button from "../../components/CustomButtons/Button";
import {makeStyles, Tooltip} from "@material-ui/core";
import dashboardStyle from "../../assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import Person from "@material-ui/icons/Person";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Swal from "sweetalert2";
import api from "../../components/api";
import StatCard from "./components/StatCard";

const useStyles = makeStyles(dashboardStyle);

const SendingList = ({history}) => {
  const classes = useStyles();

  const [sendingLists, setSendingLists] = useState([]);

  const createSendingList = async () => {
    const {value} = await Swal.fire({
      icon: "question",
      text: 'Qual o nome que deseja dar a sua nova lista de envios?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Criar Lista',
      input: "text",
      inputValidator: inputValue => (inputValue && inputValue.trim()) ? null : 'Você precisa digitar um nome válido',
    });
    if (!value) return;

    await api.post('/sendingList', {name: value.toString().trim()});
    await getSendingList();
  };

  const getSendingList = async () => {
    const {data} = await api.get('/sendingList');
    setSendingLists(data);
  };

  const deleteSendingList = async (id) => {
    const {name} = sendingLists.find(el => el.id === id);
    const {value} = await Swal.fire({
      icon: 'question',
      title: 'Deseja realmente excluir a lista?',
      text: `A lista [${name}] não poderá ser recuperada após essa ação.`,
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    });
    if (!value) return;

    await api.delete(`/sendingList/${id}`);
    await getSendingList();
  };

  useEffect(() => {
    getSendingList();
  }, []);

  return (
    <GridContainer>
      <StatCard icon={ListAlt} title={'Total de listas'} value={sendingLists.length}/>
      <StatCard icon={Person} title={'Total de contatos'} value={sendingLists.reduce((p,c) => p + c.totalContacts, 0)}/>

      <GridItem lg={12} md={12} sm={12} sx={12}>
        <Button color={'hust'} round style={{borderRadius: 20}} onClick={() => history.push('/bulkSend')} simple>
          <ArrowBack/> Voltar
        </Button>
        <Button color={'hust'} round style={{borderRadius: 20}} onClick={createSendingList}>
          <ListAlt/> Nova lista
        </Button>
      </GridItem>
      <GridItem lg={12} md={12} sm={12} sx={12}>
        <Card>
          <CardBody>
            <Table
              hover
              tableHead={['#', 'Nome da lista', 'Quantidade de contatos', 'Configurações da lista']}
              tableData={sendingLists.map(prop =>
                [
                  prop.id,
                  prop.name,
                  `${pontuacaoNumero(prop.totalContacts)} Contatos`,
                  (
                    <>
                      <Tooltip title={'Editar Lista'}>
                        <Button justIcon simple round color={'info'} style={{margin: 0}} onClick={() => history.push(`/bulkSend/sendingList/${prop.id}`)}>
                          <Edit/>
                        </Button>
                      </Tooltip>
                      <Tooltip title={'Excluir Lista'}>
                        <Button justIcon simple round color={'danger'} style={{margin: 0}} onClick={() => deleteSendingList(prop.id)}>
                          <Close/>
                        </Button>
                      </Tooltip>
                    </>
                  )
                ]
              )}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default SendingList;