import React, {memo} from 'react';
import {Handle, Position} from 'reactflow';
import {Card, CardBody} from "../../../components";
import {CallEnd} from "@material-ui/icons";
import RemoveNode from "../components/RemoveNode";
import {hexToRgb} from "../../../assets/jss/material-dashboard-pro-react";

function endCalled({id, data}) {
  return (
    <Card
      style={{
        margin: 0,
        border: '1px solid #B62C1A',
        minWidth: 150,
        maxWidth: 300,
        borderRadius: 20,
        boxShadow: '#B62C1A 0px 0px 20px -10px',
        backgroundColor: `#fff0ee`,
        padding: 5
      }}
    >
      <RemoveNode id={id}/>
      <CardBody style={{padding: 5, display: 'flex'}}>
        <div
          style={{
            color: '#B62C1A',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: 'calc(25% - 25px)',
            marginRight: 10
          }}
        >
          <CallEnd/>
        </div>
        <div>
          <span style={{fontWeight: 'bold', fontSize: 13}}>
            Finalizar Atendimento
          </span>
        </div>
      </CardBody>
      <Handle type="target" position={Position.Left} className="w-16 !bg-teal-500"/>
    </Card>
  );
}

export default memo(endCalled);
