import React, {useEffect, useState} from "react";
import {Line, defaults, Bar} from "react-chartjs-2";
import {makeStyles} from "@material-ui/core/styles";
import BusinessCenter from '@material-ui/icons/BusinessCenter';
import Warning from '@material-ui/icons/Warning';
import GolfCourse from '@material-ui/icons/GolfCourse';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import {getFotoLink} from "../components/Funcoes";
import {List, ListItem, ListItemAvatar, ListItemText, Avatar, Tooltip} from '@material-ui/core';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ForumIcon from '@material-ui/icons/Forum';
import styles from "../assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import {mesesAno, pontuacaoNumero, zeroEsquerda} from "../components/Funcoes";
import {WebService} from "../components/WebService";
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import {primaryColor, successColor, warningColor} from "../assets/jss/material-dashboard-pro-react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from 'components/CustomButtons/Button';

defaults.global.defaultFontColor = "white";

const chartOptions = {
  scales: {
    yAxes: [
      {
        gridLines: {color: "rgba(255 ,255, 255, 0.3)", drawBorder: true},
        ticks: {color: "#FFF", fontColor: "#FFF"}
      }
    ],
    xAxes: [{gridLines: {display: false}, ticks: {fontColor: "#FFF"}}]
  },
};

const useStyles = makeStyles(styles);

export default function NewDash({id_empresa, history}) {
  const classes = useStyles();

  const [chamadosHora, setChamadosHora] = useState({
    labels: new Array(24).fill(null).map((p, k) => `${zeroEsquerda(k)}h`),
    series: new Array(24).fill(0)
  });

  var [chamadosHoje, setChamadosHoje] = useState(0);

  const [rankingDepartamentos, setRankingDepartamentos] = useState([]);
  const [rankingDepartamentosGeral, setRankingDepartamentosGeral] = useState([]);
  const [rankingUsuarios, setRankingUsuarios] = useState([]);
  const [rankingUsuariosGeral, setRankingUsuariosGeral] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [usuariosAvalia, setUsuariosAvalia] = useState([]);
  const [resumoAtendimento, setResumoAtendimento] = useState({
    total_atendimentos: 0,
    total_em_atendimento: 0,
    total_aguardando_atendimento: 0,
  });

  const [departamentos, setDepartamentos] = useState([]);
  const [contatos, setContatos] = useState([]);
  const [list, setList] = useState([]);
  const [listAno, setListAno] = useState([]);
  const [intervalo, setIntervalo] = useState('');

  const [totalMes, setTotalMes] = useState(0);

  const [ticketsSemana, setTicketsSemana] = useState({
    labels: ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab', 'Dom'],
    series: new Array(7).fill(0)
  });

  const [ticketsMes, setTicketsMes] = useState({
    labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    series: new Array(12).fill(0)
  });

  const [ano, setAno] = useState(0);

  var aux = 0;
  var off = 0;

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openU, setOpenU] = React.useState(false);
  const handleClickOpenU = () => {
    setOpenU(true);
  };

  const handleCloseU = () => {
    setOpenU(false);
  };

  const [openD, setOpenD] = React.useState(false);
  const handleClickOpenD = () => {
    setOpenD(true);
  };

  const handleCloseD = () => {
    setOpenD(false);
  };

  const [openn, setOpenn] = React.useState(false);
  const handleClickOpenn = () => {
    setOpenn(true);
  };

  const handleClosee = () => {
    setOpenn(false);
  };

  const getContatos = async () => {
    const {ok, contatos} = await WebService(`/contato/getContatos`, {id_empresa});
    if (ok) setContatos(contatos);
  };

  const getUsuarios = async () => {
    const {ok, usuarios} = await WebService(`/usuario/getUsuarios`, {id_empresa});
    if (ok)
      setUsuariosAvalia(usuarios);
  };

  const getDepartamentos = async () => {
    const {ok, departamentos} = await WebService(`/departamento/getDepartamentos`);
    if (ok) setDepartamentos(departamentos);
  };

  const getSelecionaSemana = async (inter, ano) => {
    const {ok, selected} = await WebService(`/dashboard/getSemanaDinamica`, {intervalo: inter});
    if (ok){
      const chamadosSemanaSel = new Array(7).fill(0);
      for(let s = 0; s < selected.length; s++){
        const {total, dia} = selected[s];
        chamadosSemanaSel[dia] = parseInt(total);
      }
      setTicketsSemana({...ticketsSemana, series: chamadosSemanaSel});
    };
  };

  const getSelecionaAno = async (ano) => {
    const {ok, selected} = await WebService(`/dashboard/getMesDinamico`, {ano});
    if (ok){
      const chamadosAnoSel = new Array(12).fill(0);
      for(let s = 0; s < selected.length; s++){
        const {total, mes} = selected[s];
        chamadosAnoSel[mes - 1] = parseInt(total);
      }
      setTicketsMes({...ticketsMes, series: chamadosAnoSel});
    };
  };

  const getDataFim = (w, y) => {
    var simple = new Date(y, 0, w * 7 -2);
    return simple;
  };

  const getDataInicio = (w, y) => {
    var simple = new Date(y, 0, (w - 1) * 7 -1);
    return simple;
  };

  const colocaZero = (d) => {
    if(d < 10){
      return '0'+d;
    };
    return d;
  };

  const getDashboard = async () => {
    const {ok, chamadosDias, chamadosHoje, chamadosAno, chamadosSemana, chamadosHora, rankingDepartamentos, rankingDepartamentosGerl, rankingUsuarios, rankingUsuariosGeral, usuarios, resumoAtendimento, weeks, years, chamadosMes} = await WebService(`/dashboard/getDashboard`);

    if (ok) {
      // Organizando chamados mês
      setTotalMes(chamadosMes.total);
      const chamadosHoraTotal = new Array(24).fill(0);
      for (let i = 0; i < chamadosHora.length; i++) {
        const {hora, total} = chamadosHora[i];
        chamadosHoraTotal[hora - 3] = parseInt(total);
      }

      var hoje = chamadosHoje;
      if (hoje[0]) {
        setChamadosHoje(hoje[0].total);
      }

      setIntervalo(weeks[0].weeks);
      setList(weeks);

      setListAno(years.map(prop => prop.years));
      setAno(years[0].years);

      setChamadosHora(ch => {
        return {...ch, series: chamadosHoraTotal};
      });

      const chamadosSemanaTotal = new Array(7).fill(0);
      for (let i = 0; i < chamadosSemana.length; i++) {
        const {dia, total} = chamadosSemana[i];
        chamadosSemanaTotal[dia] = parseInt(total);
      }
      setTicketsSemana({...ticketsSemana, series: chamadosSemanaTotal});

      const chamadosAnoTotal = new Array(7).fill(0);
      for (let i = 0; i < chamadosAno.length; i++) {
        const {mes, total} = chamadosAno[i];
        chamadosAnoTotal[mes - 1] = parseInt(total);
      }
      setTicketsMes({...ticketsMes, series: chamadosAnoTotal});

      setRankingUsuariosGeral(rankingUsuariosGeral);
      setRankingUsuarios(rankingUsuarios);
      setRankingDepartamentos(rankingDepartamentos);
      setRankingDepartamentosGeral(rankingDepartamentosGerl);
      setUsuarios(usuarios);
      setResumoAtendimento(resumoAtendimento);
    }
  };

  useEffect(() => {
    getDashboard();
    getDepartamentos();
    getUsuarios();

  }, []);

  return (
    <div>
      <GridContainer style={{display: 'flex'}}>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Button color={'hust'} onClick={() => history.push(`/dashboard-realtime`)}>
            Em tempo real
          </Button>
        </GridItem>
        <GridItem xs={12} sm={4} md={4} lg={4}>
            <Card>
              <CardHeader color="warning" icon>

                <p className={classes.cardCategory} style={{fontSize: '18px'}}>Em espera</p><br></br>
                <div stats style={{display: 'flex', justifyContent: 'space-between'}}>

                  <center>

                    <h3 className={classes.cardTitle} style={{marginBottom: '-10px', color: '#F08220'}}>
                      <b>{pontuacaoNumero(resumoAtendimento.total_aguardando_atendimento)}</b> <small></small>
                    </h3><br/>
                    <div className={classes.stats}>
                      Atualizado agora
                    </div>
                  </center>

                  <CardIcon color="warning" style={{width: '50px', height: '50px', float: 'right'}}>
                    <Warning style={{margin: '0 auto'}}/>
                  </CardIcon>
                </div>
              </CardHeader>
              <CardFooter>

              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={4} md={4} lg={4}>
            <Card>
              <CardHeader icon>

                <p className={classes.cardCategory} style={{fontSize: '18px'}}>Em execução</p><br/>

                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <center>
                    <h3 className={classes.cardTitle} style={{marginBottom: '-10px', color: primaryColor[0]}}>
                      <b>{pontuacaoNumero(resumoAtendimento.total_em_atendimento)}</b>
                    </h3>
                    <br/>
                    <div className={classes.stats}>
                      Atualizado agora
                    </div>
                  </center>
                  <CardIcon color="primary" style={{width: '50px', height: '50px'}}>
                    <ForumIcon style={{margin: '0'}}/>
                  </CardIcon>
                </div>
              </CardHeader>
              <CardFooter>

              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={4} md={4} lg={4}>
            <Card>
              <div>
                <CardHeader color="info" icon>

                  <p className={classes.cardCategory} style={{fontSize: '18px'}}>Atendentes Online</p><br></br>
                  <div stats style={{display: 'flex', justifyContent: 'space-between'}}>
                    <center>

                      <h3 className={classes.cardTitle} style={{marginBottom: '-10px', color: '#14A1D9'}}>
                        <b>{usuarios.filter(el => el.online).length}</b></h3>
                      <br/>
                      <div className={classes.stats}>
                        Atualizado agora
                      </div>

                    </center>
                    <CardIcon color="info" style={{width: '50px', height: '50px'}}>
                      <BusinessCenter style={{margin: '0'}}/>
                    </CardIcon>
                  </div>
                </CardHeader>
                <CardFooter>

                </CardFooter></div>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4} >
          <Card chart >
            <CardHeader color="primary">
              <Bar
                //height={90} 

                data={{
                  labels: ticketsMes.labels,
                  datasets: [
                    {
                      highlight: "red",
                      label: "",
                      backgroundColor: 'rgba(255, 255, 255, 0.6)',
                      borderColor: 'rgba(255, 255, 255, 1)',
                      borderWidth: 1,
                      hoverBackgroundColor: 'rgba(255, 255, 255, 0.8)',
                      hoverBorderColor: 'rgba(255, 255, 255, 1)',
                      data: ticketsMes.series
                    }
                  ]
                }}

                options={chartOptions}
              />
            </CardHeader>
            <CardFooter>
              <h4 className={classes.cardTitle}>Atendimentos por Ano</h4>
              <Select
                value={ano}
                onChange={({target: {value}}) => setAno(value)}
                style={{position: 'absolute', width: 100, bottom: 15, right: 20}}
              >
                {listAno.map(prop => (
                  <MenuItem value={prop} key={prop} onClick={() => getSelecionaAno(prop)}>{prop}</MenuItem>
                ))}
              </Select>
              <p className={classes.cardCategory}>

              </p>
            </CardFooter>
          </Card>
        </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card chart >
              <CardHeader color="info">
                <Bar
                  //height={90}
                  data={{
                    labels: ticketsSemana.labels,
                    datasets: [
                      {
                        highlight: "red",
                        label: "",
                        backgroundColor: 'rgba(255, 255, 255, 0.6)',
                        borderColor: 'rgba(255, 255, 255, 1)',
                        borderWidth: 1,
                        hoverBackgroundColor: 'rgba(255, 255, 255, 0.8)',
                        hoverBorderColor: 'rgba(255, 255, 255, 1)',
                        data: ticketsSemana.series
                      }
                    ]
                  }}

                  options={chartOptions}
                />
              </CardHeader>
              <CardFooter>
                <h4 className={classes.cardTitle}>Atendimentos Semanais</h4>
                <Select
                  value={intervalo}
                  onChange={({target: {value}}) => setIntervalo(value)}
                  style={{position: 'absolute', width: 120, bottom: 15, right: 10}}
                >
                  {list.map((prop, key) => {
                    const {weeks, ano} = prop;
                    const inicioDia = colocaZero(new Date(getDataInicio(weeks, ano)).getDate());
                    const inicioMes = colocaZero(new Date(getDataInicio(weeks, ano)).getMonth() + 1);
                    const inicioAno = new Date(getDataInicio(weeks, ano)).getFullYear();

                    const fimDia = colocaZero(new Date(getDataFim(weeks, ano)).getDate());
                    const fimMes = colocaZero(new Date(getDataFim(weeks, ano)).getMonth() + 1);
                    const fimAno = new Date(getDataFim(weeks, ano)).getFullYear();

                    return (
                      <MenuItem
                        key={key}
                        value={weeks}
                        onClick={() => getSelecionaSemana(weeks, ano)}
                        style={{fontWeight: !key ? 800: ''}}
                      >
                        {`${inicioDia}/${inicioMes}/${inicioAno} a ${fimDia}/${fimMes}/${fimAno}`}
                      </MenuItem>
                    );
                  })}
                </Select>
                <p className={classes.cardCategory}/>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={5} lg={4}>
            <Card chart >
              <CardHeader color="warning">
                <Line
                  data={{
                    labels: chamadosHora.labels,
                    datasets: [
                      {
                        highlight: "red",
                        label: "",
                        backgroundColor: 'rgba(255, 255, 255, 0.6)',
                        borderColor: 'rgba(255, 255, 255, 1)',
                        borderWidth: 1,
                        hoverBackgroundColor: 'rgba(255, 255, 255, 0.8)',
                        hoverBorderColor: 'rgba(255, 255, 255, 1)',
                        data: chamadosHora.series
                      }
                    ]
                  }}
                  options={chartOptions}
                />
              </CardHeader>
              <CardFooter chart>
                <h4 className={classes.cardTitle}>Fluxo de Chamados Abertos por Hora</h4>
              </CardFooter>
            </Card>
          </GridItem>
      </GridContainer>

      <Card style={{marginTop: '-0px'}}>
        <CardBody>
          <GridContainer lg={12} md={12} sm={12} xs={12} style={{display: 'flex'}}>
            <GridItem lg={4} md={4} sm={4} xs={12} style={{borderRight: '1px solid #eee'}}>
              <p className={classes.cardCategory} style={{fontSize: '18px', color: successColor[0], fontWeight: '400'}}>
                <CheckCircleIcon style={{marginBottom: '-3px', float: 'left'}} fontSize="small"/>&nbsp;&nbsp;Finalizados
              </p>
              <br/>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div>
                  <center>
                    <h5 className={classes.cardTitle} style={{fontSize: '18px'}}>
                      {pontuacaoNumero(chamadosHoje)}
                    </h5>
                    <p className={classes.cardCategory} style={{fontSize: '13px', marginTop: '-15px'}}>Hoje</p></center>
                </div>

                <div>
                  <center>
                    <h5 className={classes.cardTitle} style={{fontSize: '18px'}}>{pontuacaoNumero(totalMes)}</h5>
                    <p className={classes.cardCategory} style={{fontSize: '13px', marginTop: '-15px'}}>Este mês</p>
                  </center>
                </div>

                <div>
                  <center>
                    <h5 className={classes.cardTitle}
                        style={{fontSize: '18px'}}>{pontuacaoNumero(resumoAtendimento.total_atendimentos)}</h5>
                    <p className={classes.cardCategory} style={{fontSize: '13px', marginTop: '-15px'}}>Total</p>
                  </center>
                </div>
              </div>
            </GridItem>

            <GridItem lg={4} md={4} sm={4} xs={12} style={{borderRight: '1px solid #eee'}}>
              <p className={classes.cardCategory} style={{fontSize: '18px', color: primaryColor[0], fontWeight: '400'}}>
                <ContactPhoneIcon style={{marginBottom: '-3px', float: 'left'}} fontSize="small"/>
                &nbsp;&nbsp;Status dos Atendentes
              </p>
              <br/>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div onClick={handleClickOpen} style={{cursor: 'pointer'}}>
                  <center>
                    <Tooltip title={"Clique para mais detalhes"}>
                      <h5 className={classes.cardTitle} style={{fontSize: '18px'}}>
                        {usuarios.filter(el => el.online).length}
                      </h5>
                      </Tooltip>
                      <p className={classes.cardCategory} style={{fontSize: '13px', marginTop: '-15px'}}>Online</p>
                  </center>
                </div>

                <div onClick={handleClickOpenn} style={{cursor: 'pointer'}}>
                  <center>
                  <Tooltip title={"Clique para mais detalhes"}>
                    <h5 className={classes.cardTitle}
                        style={{fontSize: '18px'}}>{usuarios.filter(el => !el.online).length}</h5>
                  </Tooltip>
                    <p className={classes.cardCategory} style={{fontSize: '13px', marginTop: '-15px'}}>Offline</p>

                  </center>
                </div>

                <div>
                  <center>
                    <h5 className={classes.cardTitle} style={{fontSize: '18px'}}>{usuarios.length}</h5>
                    <p className={classes.cardCategory} style={{fontSize: '13px', marginTop: '-15px'}}>Total</p>
                  </center>
                </div>
              </div>

            </GridItem>


            <GridItem xs={12} sm={4} md={4} lg={4} >

            <Tooltip title={"Quantidade média de atendimentos por operador"}>
              <p className={classes.cardCategory} style={{fontSize: '18px', color: `${warningColor[2]}`, fontWeight: '400'}}>
                <PermContactCalendarIcon style={{marginBottom: '-3px', float: 'left'}} fontSize="small"/>&nbsp;&nbsp;Média por atendente </p>
                </Tooltip>
              <br/>

              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div>
                  <center>
                    <h5 className={classes.cardTitle} style={{fontSize: '18px'}}>
                    {(pontuacaoNumero(chamadosHoje) / usuarios.length).toFixed(1)}
                    </h5>
                    <p className={classes.cardCategory} style={{fontSize: '13px', marginTop: '-15px'}}>Hoje</p>
                  </center>
                </div>

                <div>
                  <center>
                    <h5 className={classes.cardTitle} style={{fontSize: '18px'}}>
                      {(pontuacaoNumero(totalMes) / usuarios.length).toFixed(1)}
                    </h5>
                    <p className={classes.cardCategory} style={{fontSize: '13px', marginTop: '-15px'}}>Mensal</p>
                  </center>
                </div>

                <div>
                  <center>
                    <h5 className={classes.cardTitle} style={{fontSize: '18px'}}>
                    {(pontuacaoNumero(resumoAtendimento.total_atendimentos) / usuarios.length).toFixed(1)}
                    </h5>
                    <p className={classes.cardCategory} style={{fontSize: '13px', marginTop: '-15px'}}>Total</p>
                  </center>
                </div>


              </div>

            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <GridContainer style={{display: 'flex'}}>
        <GridItem xs={12} sm={12} md={6} style={{display: 'inline-grid'}}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Estatísticas dos funcionários</h4>
              <p className={classes.cardCategoryWhite}>
                Atendentes que mais resolveram chamados
              </p>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="warning"
                hover
                striped
                tableHead={[
                  <GolfCourse/>,
                  "Nome",
                  mesesAno[new Date().getMonth() - 2],
                  mesesAno[new Date().getMonth() - 1],
                  mesesAno[new Date().getMonth()],
                  <b style={{color: primaryColor[0]}}>Total</b>
                ]}
                tableData={rankingUsuarios.map((prop, key) => {
                  const {nome, total_mes_atual, total_mes_1, total_mes_2} = prop;
                  const total_last_months = (total_mes_1 + total_mes_2 + total_mes_atual);
                  return [
                    key + 1,
                    nome,
                    <>&nbsp;&nbsp;{total_mes_2}</>,
                    <>&nbsp;&nbsp;{total_mes_1}</>,
                    <>&nbsp;&nbsp;{total_mes_atual}</>,
                    <b style={{color: primaryColor[0]}}><>&nbsp;&nbsp;{total_last_months}</>
                    </b>
                  ];
                })}
              />
              <br/>
              <Button onClick={handleClickOpenU} style={{float: 'right', color: 'white', backgroundColor: primaryColor[0]}}>Mostrar Todos</Button>
            </CardBody>

          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6} style={{display: 'inline-grid'}}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Estatísticas dos Departamentos</h4>
              <p className={classes.cardCategoryWhite}>
                Departamentos que mais atenderam chamados
              </p>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="warning"
                hover
                striped
                tableHead={[
                  <GolfCourse/>,
                  "Nome",
                  mesesAno[new Date().getMonth() - 2],
                  mesesAno[new Date().getMonth() - 1],
                  mesesAno[new Date().getMonth()],
                  <b style={{color: primaryColor[0]}}>Total</b>
                ]}
                tableData={rankingDepartamentos.map((prop, key) => {
                  const {nome, total_mes_atual, total_mes_1, total_mes_2} = prop;
                  const total_last_months = (total_mes_1 + total_mes_2 + total_mes_atual);
                  return [
                    key + 1,
                    <>{nome ? nome : <p style={{color: 'silver'}}>Departamento Desativado</p>} </>,
                    <>&nbsp;&nbsp;{total_mes_2}</>,
                    <>&nbsp;&nbsp;{total_mes_1}</>,
                    <>&nbsp;&nbsp;{total_mes_atual}</>,
                    <b style={{color: primaryColor[0]}}><>&nbsp;&nbsp;{total_last_months}</>
                    </b>
                  ];
                })}
              />
              <br/>
              <Button onClick={handleClickOpenD} style={{float: 'right', color: 'white', backgroundColor: primaryColor[0]}}>Mostrar Todos</Button>
            </CardBody>
          </Card>
        </GridItem>


      </GridContainer>





      <Dialog onClose={handleClose} open={open} >
      <DialogTitle id="simple-dialog-title">Atendentes Online</DialogTitle>
      <List style={{width: '27vw'}}>
        {usuarios.map((prop, key) => {
          const {id_usuario, online, nome, foto_perfil} = prop;
          if(online){
             return (
             <ListItem>
               <ListItemAvatar>
                <Avatar className={classes.avatar} src={getFotoLink(foto_perfil)} style={{borderRadius:  '10px'}}>
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={nome} />
             </ListItem>);
          };
          if(!online){
            aux++;
          }
        })}
        {aux == usuarios.length ?
          <Typography style={{color: 'grey', textAlign: 'center'}}>
            Não há usuários online.
          </Typography>
          : <></>}
      </List>
    </Dialog>

    <Dialog onClose={handleClosee} open={openn} >
      <DialogTitle id="simple-dialog-title">Atendentes Offline</DialogTitle>
      <List style={{width: '27vw'}}>
        {usuarios.map((prop, key) => {
          const {id_usuario, online, nome, foto_perfil} = prop;
          if(!online){
             return (
             <ListItem>
               <ListItemAvatar>
                <Avatar className={classes.avatar} src={getFotoLink(foto_perfil)} style={{borderRadius:  '10px'}}>
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={nome} />
             </ListItem>);
          };
          if(online){
            off++;
          }
        })}
        {off == usuarios.length ?
        <Typography style={{color: 'grey', textAlign: 'center'}}>
          Não há usuários offline.
        </Typography>
        : <></>}

      </List>
    </Dialog>




    <Dialog onClose={handleCloseU} open={openU} >
      <DialogTitle id="simple-dialog-title">Ranking Geral de Usuários</DialogTitle>
      <CardBody >
      <Table
                tableHeaderColor="warning"
                hover
                striped
                tableHead={[
                "Nº",
                  <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>,
                  "Nome",
                  mesesAno[new Date().getMonth() - 2],
                  mesesAno[new Date().getMonth() - 1],
                  mesesAno[new Date().getMonth()],
                  <b style={{color: primaryColor[0]}}>Total</b>
                ]}
                tableData={rankingUsuariosGeral.map((prop, key) => {
                  const {nome, total_mes_atual, total_mes_1, total_mes_2, foto_perfil} = prop;
                  const total_last_months = (total_mes_1 + total_mes_2 + total_mes_atual);
                  return [
                    key + 1,
                    <Avatar className={classes.avatar} src={getFotoLink(foto_perfil)} style={{borderRadius:  '10px'}}>
                    </Avatar>,
                    nome,
                    <>&nbsp;&nbsp;{total_mes_2}</>,
                    <>&nbsp;&nbsp;{total_mes_1}</>,
                    <>&nbsp;&nbsp;{total_mes_atual}</>,
                    <b style={{color: primaryColor[0]}}><>&nbsp;&nbsp;{total_last_months}</>
                    </b>
                  ];
                })}
              />
      </CardBody>
    </Dialog>

    <Dialog onClose={handleCloseD} open={openD} >
      <DialogTitle id="simple-dialog-title">Ranking Geral de Departamentos</DialogTitle>
      <CardBody >
      <Table
                tableHeaderColor="warning"
                hover
                striped
                tableHead={[
                  <GolfCourse/>,
                  "Nome",
                  mesesAno[new Date().getMonth() - 2],
                  mesesAno[new Date().getMonth() - 1],
                  mesesAno[new Date().getMonth()],
                  <b style={{color: primaryColor[0]}}>Total</b>
                ]}
                tableData={rankingDepartamentosGeral.map((prop, key) => {
                  const {nome, total_mes_atual, total_mes_1, total_mes_2} = prop;
                  const total_last_months = (total_mes_1 + total_mes_2 + total_mes_atual);
                  return [
                    key + 1,
                    <>{nome ? nome : <p style={{color: 'silver'}}>Departamento Desativado</p>} </>,
                    <>&nbsp;&nbsp;{total_mes_2}</>,
                    <>&nbsp;&nbsp;{total_mes_1}</>,
                    <>&nbsp;&nbsp;{total_mes_atual}</>,
                    <b style={{color: primaryColor[0]}}><>&nbsp;&nbsp;{total_last_months}</>
                    </b>
                  ];
                })}
              />
      </CardBody>
    </Dialog>

    </div>
  );
}
