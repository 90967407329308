import React, {memo, useEffect, useState} from 'react';
import {Handle, Position} from 'reactflow';
import {Card, CardBody} from "../../../components";
import {Language} from "@material-ui/icons";
import RemoveNode from "../components/RemoveNode";

function callAPI({data, id}) {
  const [api, setApi] = useState(data.api || '');
  const [method, setMethod] = useState(data.method || '');
  const [body, setBody] = useState(data.body || '');

  useEffect(() => {
    data.api = api;
    data.method = method;
    data.body = body;
  }, [api, method, body]);

  const validationMethod = method !== "get"
  return (
    <Card
      style={{
        margin: 0,
        border: '1px solid #08365A',
        minWidth: 150,
        maxWidth: 300,
        borderRadius: 20,
        boxShadow: '#08365A 0px 0px 20px -10px',
        padding: 5
      }}
    >
      <RemoveNode id={id}/>
      <CardBody style={{padding: 5, display: 'flex'}}>
        <div
          style={{
            color: '#08365A',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: 'calc(25% - 25px)',
            marginRight: 10
          }}
        >
          <Language/>
        </div>
        <div>
          <span style={{fontWeight: 'bold', fontSize: 13}}>
            Chamada API
          </span>
          <br/>
          <input value={api} onChange={({target: {value}}) => setApi(value)}/>
          <br/>
          <span>Médoto: </span>
          <select onChange={({target: {value}}) => setMethod(value)} value={method}>
            <option value={''} disabled>Selecione uma Opção</option>
            <option value={'get'}>GET</option>
            <option value={'post'}>POST</option>
            <option value={'put'}>PUT</option>
            <option value={'delete'}>DELETE</option>
          </select>
          {validationMethod ? (
            <>
              <br/>
              <small>body requisição</small>
              <textarea
                defaultValue={JSON.stringify(body)}
                onChange={({target: {value}}) => setBody(JSON.parse(value))}
                style={{
                  // backgroundColor: (() => {try{JSON.parse(JSON.stringify(body));return true;}catch(e){return false;}})() ? '' : '#F00',
                  resize: 'none',
                  width: 250,
                  height: 80
                }}
              />
            </>
          ) : ''}
          {/*api*/}
          {/*body*/}
          {/*method*/}
          {/*jsonNode*/}
        </div>
      </CardBody>

      <Handle type="target" position={Position.Left} className="w-16 !bg-teal-500"/>
      <Handle type="source" position={Position.Right} className="w-16 !bg-teal-500"/>
    </Card>
  );
}

export default memo(callAPI);
