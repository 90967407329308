import React from 'react';

const Select = ({ label, style, value, onChange, options }) => {
  return (
    <div style={{ marginTop: 5, marginBottom: 5 }}>
      <span style={{ color: '#1A1926', fontSize: 15 }}>
        {label}
      </span>
      <select
        style={{ width: '100%', fontSize: 16, padding: 5, borderRadius: 14, height: 40, ...(style || {}) }}
        value={value}
        onChange={({ target: { value } }) => onChange(value)}
      >
        {(options || []).map((prop, key) => <option key={key} value={prop.value} disabled={!!prop.disabled}>{prop.name}</option>)}
      </select>
    </div>
  );
};

export default Select;