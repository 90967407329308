import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";

import wizardStyle from "assets/jss/material-dashboard-pro-react/components/wizardStyle.js";
import {Tooltip} from "@material-ui/core";

class WizardNavigation extends React.Component {
  constructor(props) {
    super(props);
    var width;
    if (this.props.steps.length === 1) {
      width = "100%";
    } else {
      if (window.innerWidth < 600) {
        if (this.props.steps.length !== 3) {
          width = "50%";
        } else {
          width = 100 / 3 + "%";
        }
      } else {
        if (this.props.steps.length === 2) {
          width = "50%";
        } else {
          width = 100 / 3 + "%";
        }
      }
    }
    this.state = {
      currentStep: 0,
      color: this.props.color,
      nextButton: this.props.steps.length > 1 ? true : false,
      previousButton: false,
      finishButton: this.props.steps.length === 1 ? true : false,
      width: width,
      movingTabStyle: {
        transition: "transform 0s"
      },
      allStates: {}
    };
    this.navigationStepChange = this.navigationStepChange.bind(this);
    this.refreshAnimation = this.refreshAnimation.bind(this);
    this.updateWidth = this.updateWidth.bind(this);
  }

  wizard = React.createRef();

  componentDidMount() {
    this.refreshAnimation(0);
    window.addEventListener("resize", this.updateWidth);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWidth);
  }

  updateWidth() {
    this.refreshAnimation(this.state.currentStep);
  }

  navigationStepChange(key) {
    if (this.props.steps) {
      this.setState({
        currentStep: key,
        nextButton: (this.props.steps.length > key + 1),
        previousButton: (key > 0),
        finishButton: (this.props.steps.length === key + 1)
      });
      if (this.props.onChange)
        this.props.onChange(key);
      this.refreshAnimation(key);
    }
  }

  refreshAnimation(index) {
    var total = this.props.steps.length;
    var li_width = 100 / total;
    var total_steps = this.props.steps.length;
    var move_distance =
      this.wizard.current.children[0].offsetWidth / total_steps;
    var index_temp = index;
    var vertical_level = 0;

    var mobile_device = window.innerWidth < 600 && total > 3;

    if (mobile_device) {
      move_distance = this.wizard.current.children[0].offsetWidth / 2;
      index_temp = index % 2;
      li_width = 50;
    }

    this.setState({width: li_width + "%"});

    var step_width = move_distance;
    move_distance = move_distance * index_temp;

    var current = index + 1;

    if (current === 1 || (mobile_device === true && index % 2 === 0)) {
      move_distance -= 8;
    } else if (
      current === total_steps ||
      (mobile_device === true && index % 2 === 1)
    ) {
      move_distance += 8;
    }

    if (mobile_device) {
      vertical_level = parseInt(index / 2, 10);
      vertical_level = vertical_level * 38;
    }
    var movingTabStyle = {
      width: step_width,
      transform:
        "translate3d(" + move_distance + "px, " + vertical_level + "px, 0)",
      transition: "all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)"
    };
    this.setState({movingTabStyle: movingTabStyle});
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const {active} = nextProps;
    if (active || active === 0)
      this.navigationStepChange(active);
  }

  render() {
    const {classes, color, steps} = this.props;
    const ActiveIcon = steps[this.state.currentStep].icon || null;
    const ActiveTooltip = steps[this.state.currentStep].tooltip || "";

    return (
      <div ref={this.wizard}>
        <Card className={classes.card} style={{minHeight: 0, margin: 0}}>
          <div className={classes.wizardNavigation} style={{marginTop: -20}}>
            <ul className={classes.nav}>
              {steps.map((prop, key) => {
                return (
                  <Tooltip title={prop.tooltip || ""}>
                    <li className={classes.steps} key={key} style={{width: this.state.width}}>
                      <a
                        href="#"
                        className={classes.stepsAnchor}
                        onClick={e => {
                          e.preventDefault();
                          this.navigationStepChange(key);
                        }}
                      >
                        <prop.icon/>
                      </a>
                    </li>
                  </Tooltip>
                );
              })}
            </ul>
            <Tooltip title={ActiveTooltip}>
              <div className={classes.movingTab + " " + classes[color]} style={this.state.movingTabStyle}>
                <ActiveIcon/>
              </div>
            </Tooltip>
          </div>
        </Card>
      </div>
    );
  }
}

WizardNavigation.defaultProps = {
  color: "info",
  title: "Here should go your title",
  subtitle: "And this would be your subtitle",
  previousButtonText: "Previous",
  previousButtonClasses: "",
  nextButtonClasses: "",
  nextButtonText: "Next",
  finishButtonClasses: "",
  finishButtonText: "Finish"
};

WizardNavigation.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf([
    "primary",
    "warning",
    "danger",
    "success",
    "info",
    "white",
    "rose"
  ]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  tooltip: PropTypes.string,
  validate: PropTypes.bool,
  onChange: PropTypes.func
};

export default withStyles(wizardStyle)(WizardNavigation);
