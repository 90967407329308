import { Handle, Position } from 'reactflow';
import React, { memo, useEffect, useState } from 'react';
import { Card, CardBody } from "../../../components";
import { Delete, Drag, List2 } from "../../../assets/img/icons";
import Header from "./components/Header";
import TextBox from "./components/Input/TextBox";
import Checkbox from "./components/Input/Checkbox";
import {hexToRgb, infoColor} from "../../../assets/jss/material-dashboard-pro-react";
import Select from "./components/Input/Select";

function SwitchOption({ id, data, selected }) {
  const [ responseType, setResponseType ] = useState('number');
  // const [button, setButton] = useState(!!data.button);
  const [ button, setButton ] = useState(false);
  const [ options, setOptions ] = useState(data.options || []);
  const [ messageSwitchOptions, setMessageSwitchOptions ] = useState(data.messageSwitchOptions || '');
  const [ messageInvalidOption, setMessageInvalidOption ] = useState(data.messageInvalidOption || '');

  const [ flagLimitResponseTime, setFlagLimitResponseTime ] = useState(data.flagLimitResponseTime || false);
  const [ timeResponse, setTimeResponse ] = useState(data.timeResponse || '10:00');
  const [ repeatQuantity, setRepeatQuantity ] = useState(data.repeatQuantity || 1);

  useEffect(() => {
    data.responseType = responseType;
    data.button = false;
    data.options = options;
    data.messageSwitchOptions = messageSwitchOptions;
    data.messageInvalidOption = messageInvalidOption;

    data.flagLimitResponseTime = flagLimitResponseTime;
    data.timeResponse = timeResponse;
    data.repeatQuantity = repeatQuantity;
  }, [ responseType, button, options, messageSwitchOptions, messageInvalidOption, flagLimitResponseTime, timeResponse, repeatQuantity ]);

  if (!data.department) data.department = false;

  return (
    <Card
      style={{
        margin: 0,
        width: 420,
        borderRadius: 14,
        boxShadow: selected ? '0px 50px 50px 0px rgba(4, 15, 21, 0.15), 0px 40px 30px -30px rgba(4, 15, 21, 0.15)' : '#08365A 0px 0px 20px -10px',
        padding: 5
      }}
    >
      <Header title={'Menu'} subtitle={'Solicita o usuário escolher uma opção'} icon={List2} id={id}/>
      <CardBody style={{padding: 5}}>
        <TextBox label={'Mensagem'} rows={7} value={messageSwitchOptions} onChange={setMessageSwitchOptions}/>
        <TextBox label={'Mensagem para opção inválida'} rows={5} value={messageInvalidOption} onChange={setMessageInvalidOption}/>
        <div>
          {/*NÃO FUNCIONA MAIS - WHATSAPP LIMITOU*/}
          {/*<input type={'checkbox'} checked={button} onClick={() => setButton(!button)}/> Enviar como botões*/}
          {/*<br/>*/}
          <div style={{ width: '100%', display: 'flex', placeContent: 'end' }}>
            <button
              style={{
                borderRadius: 14,
                border: '1px solid #FFF',
                background: '#FD2F53',
                boxShadow: '0px 4px 16px 0px rgba(100, 100, 100, 0.5)',
                cursor: 'pointer',
                color: '#FFF',
                fontSize: 25,
                width: 31,
                height: 31
              }}
              onClick={() => {
                setOptions(options => {
                  const key = options.length + 1;
                  options.push({ key, value: `Opção ${key}` });
                  return [ ...options ];
                });
              }}
            >
              +
            </button>
          </div>
          {options.map((prop, key) => (
            <div
              key={key}
              style={{
                borderRadius: 14,
                border: '1px solid #EBEEF2',
                margin: '10px 0',
                height: 60,
                display: 'flex',
              }}
            >
              <img src={Drag} alt={'drag'} style={{ width: 25, marginLeft: 5, marginRight: 10 }}/>
              <span style={{ fontSize: 18, color: '#28263B', marginTop: 19 }}>
                {prop.key}
              </span>
              {/*<Select label={''} style={{ marginLeft: 20, marginTop: 5, width: 270 }}/>*/}
              <TextBox
                label={''}
                value={prop.value}
                style={{ marginLeft: 20, marginTop: 5, width: 270 }}
                onChange={value => {
                  setOptions(options => {
                    options[key].value = value.toString();
                    return [...options];
                  });
                }}
              />
              <img
                src={Delete}
                alt={'delete'}
                style={{ width: 25, marginLeft: 15, cursor: 'pointer' }}
                onClick={() => {
                  // TODO - FIX - Excluir ligações também
                  setOptions(option => {
                    delete option[key];
                    return [...option].filter(el => el);
                  });
                }}
              />
            </div>
          ))}
        </div>
        <div style={{position: 'relative'}}>
          <Checkbox label={'Limitar tempo de resposta'} checked={flagLimitResponseTime} onClick={() => setFlagLimitResponseTime(!flagLimitResponseTime)}/>
          {flagLimitResponseTime ? (
            <span
              style={{
                alignContent: 'center',
                right: 5,
                position: 'absolute',
                top: 5,
                background: `rgba(${hexToRgb(infoColor[0])}, 0.3)`,
                padding: '0 5px',
                borderRadius: 10,
                color: infoColor[0],
                fontWeight: 600
              }}
            >
              Caso não tenha resposta
            </span>
          ) : ''}
        </div>
        {flagLimitResponseTime ? (
          <>
            <TextBox label={'Nome de Armazenamento'} type={'time'} value={timeResponse} onChange={setTimeResponse}/>
            <Select label={'Repetir pergunta antes do limite atigido'} value={repeatQuantity} onChange={setRepeatQuantity} options={new Array(10).fill(null).map((_, key) => ({name: `${key + 1}x`, value: key + 1}))}/>
          </>
        ): ''}
      </CardBody>

      <Handle type="target" position={Position.Left} style={{ marginLeft: -3, background: '#E94D3F', width: 16, border: '3px solid #FFFFFF', height: 16 }}/>
      {options.map((prop, key) => {
        // const element = document.getElementById(`${id}_label_${key}`);
        return (
          <Handle
            type="source"
            position={Position.Right}
            id={`${id}_handle_${key}`}
            style={{
              right: -8,
              bottom: 'auto',
              top: 482 + (70 * key),
              background: '#FFFFFF',
              width: 16,
              border: '2px solid #E94D3F',
              height: 16,
            }}
          />
        )
      })}
      {flagLimitResponseTime ? (
        <Handle type="source" id={`handle_escape_${id}`} position={Position.Right} style={{ marginRight: -3, background: '#FFFFFF', width: 16, border: '2px solid #E94D3F', height: 16, top: 'auto', bottom: 141 }}/>
      ) : ''}
    </Card>
  );
}

export default memo(SwitchOption);
