import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    padding: 0
  },

  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  cardModal: {
    maxWidth: '600px',
  },

  cardIconTitle: {
    color: '#959CA4',
  },

  formControl: {
    width: '100%',
    maxWidth: '100%',
  },

  inputText: {
    border: 'none',
    borderBottom: '1px solid #949494',
    margin: '10px 0',

    width: '100%',
    height: '120px',
    maxHeight: '120px',

    resize: 'none',
    overflow: 'auto !important',

    fontSize: '16px',
    fontFamily: '"Roboto", sans-serif',

    '&::-webkit-scrollbar-track': {
      background: '#none',
      borderRadius: 10
    },
    '&::-webkit-scrollbar': {
      width: 5,
      borderRadius: 10,
      background: 'none'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 10,
      backgroundImage: `linear-gradient(60deg, #2B86AE, #2B86B7})`
    },
  },
}));

