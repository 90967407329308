import React, {useEffect, useState} from 'react';
import {Card, CardBody, CardHeader, CardIcon, GridContainer, Table} from "../../components";
import {Close, Edit, QuestionAnswer} from "@material-ui/icons";
import {useStyles} from "./RespostasRapidas/styles";
import api from "../../components/api";
import Button from "../../components/CustomButtons/Button";
import {Tooltip} from "@material-ui/core";
import Swal from "sweetalert2";

const Tags = () => {
  const classes = useStyles();

  const [tags, setTags] = useState([]);

  const getTags = async () => {
    const {data} = await api.get('/tag');
    setTags(data);
  };

  const removeTags = async id => {
    const tag = tags.find(el => el.id === id);
    const {value} = await Swal.fire({
      icon: 'question',
      title: 'Deseja realmente excluir a tag?',
      html: `Se você exlcuir a tag todos os atendimentos mapeados com a tag <strong>${tag.name}</strong> serão perdidos.`,
      showCancelButton: true,
      showConfirmButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    });
    if (!value) return;

    await api.delete(`/tag/${id}`);
    await Swal.fire({icon: "success", title: 'Tag excluida com sucesso!'});
    getTags();
  };

  const editTag = async (id) => {
    const tag = tags.find(el => el.id === id);
    const {value} = await Swal.fire({
      icon: 'question',
      title: 'Qual o novo nome que deseja dar para a tag?',
      input: "text",
      inputValue: tag.name,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Alterar',
      showCancelButton: true
    });
    if (!value) return;

    await api.put(`/tag/${id}`, {name: value});
    await Swal.fire({icon: "success", title: 'Tag atualizada com sucesso!'});
    getTags();
  };

  useEffect(() => {
    getTags();
  }, []);

  return (
    <GridContainer>
      <Card>
        <CardHeader color="primary" icon>
          <CardIcon color="primary">
            <QuestionAnswer/>
          </CardIcon>
          <h4 className={classes.cardIconTitle} style={{fontSize: '18px', color: '#4f4f4f'}}>Respostas Rápidas</h4>
          <p style={{color: '#6c6c6c', fontSize: '13px', marginTop: '-5px'}}>Área de gerenciamento das tags no sistema.</p>
        </CardHeader>
        <CardBody style={{marginTop: '-20px'}}>
          <Table
            hover
            tableHead={['#', 'Nome', 'Opções']}
            tableData={tags.map(prop => [
              prop.id,
              prop.name,
              (
                <>
                  <Tooltip title={'Editar'}>
                    <Button
                      simple
                      round
                      justIcon
                      color={'info'}
                      style={{minWidth: 0, width: 15, height: 15}}
                      onClick={() => editTag(prop.id)}
                    >
                      <Edit/>
                    </Button>
                  </Tooltip>
                  <Tooltip title={'Excluir'}>
                    <Button
                      simple
                      round
                      justIcon
                      color={'danger'}
                      style={{minWidth: 0, width: 15, height: 15}}
                      onClick={() => removeTags(prop.id)}
                    >
                      <Close/>
                    </Button>
                  </Tooltip>
                </>
              )
            ])}
          />
        </CardBody>
      </Card>
    </GridContainer>
  );
}

export default Tags;