import React, {useEffect, useState} from 'react';
import {Backdrop, DialogActions, DialogContent, Fade, FormControlLabel, makeStyles} from "@material-ui/core";
import {useStyles} from "../NewChat/TabsInfo/styles";
import Button from '../../components/CustomButtons/Button';
import {Table} from "../../components";
import api from "../../components/api";
import styles from "../../assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Swal from "sweetalert2";
import TextField from "@material-ui/core/TextField";

const useStyles1 = makeStyles(styles);
const ModalContactImportConfig = ({open, onClose, idJob}) => {
  const classes = {...useStyles(), ...useStyles1()};
  const [fields, setFields] = useState({});
  const [customFields, setCustomFields] = useState({});
  const [columns, setColumns] = useState([]);
  const [replaceName, setReplaceName] = useState(false);

  const getJob = async () => {
    const {data} = await api.get(`/contact/import/${idJob}`);
    const {config} = data;
    const { headersColumns, fields, replaceName } = config;

    setReplaceName(replaceName);
    setColumns(headersColumns);
    setFields(fields);
    setCustomFields({});
  };

  const startImport = async () => {
    const data = {id: idJob, replaceName, fields};
    Object.keys(customFields).forEach(key => {
      if (!customFields[key]) {
        Swal.fire({
          icon: "warning",
          title: 'Um campo customizado não possui um nome definido.',
          text: 'Verifique todos os parâmetros e tente novamente.'
        });
        throw new Error('FIELD_ERROR');
      }
      data.fields[key] = customFields[key];
    });
    await api.put(`/contact/import`, data);
    await Swal.fire({icon: "success", title: 'Importação inciada com sucesso!'});
    onClose(true);
  };

  useEffect(() => {
    if (open && idJob) getJob();
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
    >
      <DialogTitle>
        <h4 style={{fontFamily: 'NunitoRegular', fontSize: 30, marginBottom: 0, color: '#08365A'}}>
          IMPORTAR CONTATOS
        </h4>
        <hr
          style={{
            borderBottom: `4px solid #08365A`,
            width: '40%',
            float: 'left',
            marginTop: 0,
            marginBottom: 0
          }}
        />
        <br/>
        <FormControlLabel
          className={classes.formCheck}
          control={
            <Checkbox
              checked={replaceName}
              tabIndex={-1}
              onClick={() => setReplaceName(!replaceName)}
              checkedIcon={<Check className={classes.checkedIcon}/>}
              icon={<Check className={classes.uncheckedIcon}/>}
              classes={{checked: classes.checked, root: classes.root}}
            />
          }
          label="Substituir nome do contato"
        />
      </DialogTitle>
      <DialogContent>
        <Table
          tableData={columns.map((prop, key) => {
            const fieldFind = fields[key];
            const customFieldFind = customFields[key];

            return [
              (
                <>
                  <small>Coluna {key + 1}</small><br/>
                  {prop}
                </>
              ),
              (
                <FormControl className={classes.formControl} fullWidth style={{minWidth: 100}}>
                  <InputLabel>Tipo do campo</InputLabel>
                  <Select
                    value={fieldFind || ''}
                    onChange={({target: {value}}) => {
                      setCustomFields(customField => {
                        if (value !== 'custom') delete customField[key];
                        else customField[key] = prop;
                        return { ...customField };
                      });

                      setFields(fields => {
                        if (!value) delete fields[key];
                        else fields[key] = value;
                        return {...fields};
                      });
                    }}
                  >
                    <MenuItem value={''}>
                      Não associar
                    </MenuItem>
                    <MenuItem
                      value={'contact.number'}
                      disabled={Object.keys(fields).map(prop => fields[prop]).includes('contact.number')}
                    >
                      Número de telefone
                    </MenuItem>
                    <MenuItem
                      value={'contact.name'}
                      disabled={Object.keys(fields).map(prop => fields[prop]).includes('contact.name')}
                    >
                      Nome do Contato
                    </MenuItem>
                    <MenuItem
                      value={'custom'}
                      // disabled={Object.keys(fields).map(prop => fields[prop]).includes('contact.name')}
                    >
                      Customizado
                    </MenuItem>
                  </Select>
                </FormControl>
              ),
              fieldFind === 'custom' ? (
                <TextField
                  label="Nome do campo"
                  type="text"
                  value={customFields[key] || ''}
                  fullWidth
                  onChange={({ target: { value } }) => {
                    setCustomFields(customField => {
                      customField[key] = value;
                      return { ...customField };
                    });
                  }}
                />
              ) : ''
            ];
          })}
        />
      </DialogContent>
      <DialogActions>
        <div
          style={{
            marginTop: 10,
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Button style={{borderRadius: '24px 24px 24px 0px'}} onClick={onClose}>
            Fechar
          </Button>
          <Button color={'hust'} round onClick={startImport}>
            Iniciar Importação
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default ModalContactImportConfig;