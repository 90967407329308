import React from "react";
import {makeStyles} from "@material-ui/core";
import MessageBoxText from "./MessageBoxText";

const MessageBoxVideo = ({identifier, caption, user}) => {
  return (
    <div key={identifier}>
      <video
        src={`https://storage.hustapp.com/midias/${identifier}`}
        preload="metadata"
        controls
        style={{width: '100%'}}
      />
      {caption && <MessageBoxText body={caption} user={user}/>}
    </div>
  );
};

export default MessageBoxVideo;