import React, {useEffect, useRef, useState} from 'react';
import {Card, CardBody, GridContainer, GridItem, Table} from "../components";
import api from "../components/api";
import CustomLinearProgress from "../components/CustomLinearProgress/CustomLinearProgress";
import {convertDate} from "../components/Funcoes";
import Button from "../components/CustomButtons/Button";
import Info from "../components/Typography/Info";
import Success from "../components/Typography/Success";
import Danger from "../components/Typography/Danger";
import ModalContactImportConfig from "./Modal/ContactImportConfig";
import Settings from "@material-ui/icons/Settings";
import ContactImportComponent from "./Componentes/ContactImportComponent";
import {ArrowBack, ListAlt} from "@material-ui/icons";
import {Tooltip} from "@material-ui/core";

const statusComponents = {
  created: (<Info>Importação criada</Info>),
  running: (<Info>Em andamento</Info>),
  finished: (<Success>Finalizado</Success>),
  error: (<Danger>Erro</Danger>),
}

const ContactsImport = ({history}) => {
  const [jobs, setJobs] = useState([]);
  const [jobImport, setjobImport] = useState(0);

  const getJobs = async () => {
    const {data} = await api.get('/contact/import');
    setJobs(data);
  };

  useEffect(() => {
    getJobs();

    const interval = setInterval(getJobs, 5000);

    return () => clearInterval(interval);
  }, []);

  const refUpload = useRef();

  return (
    <GridContainer>
      <ModalContactImportConfig
        open={!!jobImport}
        idJob={jobImport}
        onClose={() => {
          setjobImport(0);
          getJobs();
        }}
      />
      <GridItem lg={12} md={12} sm={12} xs={12}>
        <Button color={'primary'} style={{borderRadius: 20}} onClick={() => history.push('/contatos')} simple>
          <ArrowBack/> Voltar
        </Button>
        <ContactImportComponent>
          Importar novo arquivo
        </ContactImportComponent>
      </GridItem>
      <GridItem lg={12} md={12} sm={12} xs={12}>
        <Card>
          <CardBody>
            <Table
              tableHead={['#', 'Tipo', 'Data de Início', 'Data de Finalização', 'Status', 'Progresso', '']}
              tableData={jobs.map(prop => {
                return [
                  prop.id,
                  'Importação de contatos',
                  convertDate(prop.dateStart, false, true),
                  prop.dateEnd ? convertDate(prop.dateEnd, false, true) : '',
                  <strong>{statusComponents[prop.status]}</strong>,
                  prop.status === 'running' ?
                    (
                      <>
                        <CustomLinearProgress
                          color={'primary'}
                          value={prop.progress}
                          variant={'buffer'}
                          valueBuffer={prop.progress}
                        />
                        {prop.progress}%
                      </>
                    ) : '',
                  (
                    <>
                      {['created', 'error'].includes(prop.status) && (
                        <Button justIcon simple color={'info'} round onClick={() => setjobImport(prop.id)}>
                          <Settings/>
                        </Button>
                      )}
                      {prop.config.sendingList && (
                        <Tooltip title={'Acessar Lista de envio'}>
                          <Button
                            justIcon
                            simple
                            color={'info'}
                            round
                            onClick={() => history.push(`/bulkSend/sendingList/${prop.config.sendingList}`)}
                          >
                            <ListAlt/>
                          </Button>
                        </Tooltip>
                      )}
                    </>
                  )
                ];
              })}
              hover
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
};

export default ContactsImport;