import React, {useEffect, useState} from 'react';
import {Card, CardBody, GridContainer, GridItem, Table} from "../../components";
import Danger from "../../components/Typography/Danger";
import Warning from "../../components/Typography/Warning";
import Success from "../../components/Typography/Success";
import Info from "../../components/Typography/Info";
import {
  dangerBoxShadow,
  dangerColor,
  grayColor,
  hexToRgb, infoColor,
  successBoxShadow,
  successColor,
  warningBoxShadow,
  warningColor
} from "../../assets/jss/material-dashboard-pro-react";
import Tooltip from "@material-ui/core/Tooltip";
import {Bar} from "react-chartjs-2";
import Button from "../../components/CustomButtons/Button";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import {IconButton, Modal} from "@material-ui/core";
import {convertDate, isValidDate} from "../../components/Funcoes";
import api from "../../components/api";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import Swal from "sweetalert2";
import ModalCalledHistory from "../Modal/CalledHistory";
import {Chat} from "../../assets/img/icons";

const barColors = {
  0: dangerColor,
  1: dangerColor,
  2: dangerColor,
  3: dangerColor,
  4: dangerColor,
  5: dangerColor,
  6: dangerColor,
  7: warningColor,
  8: warningColor,
  9: successColor,
  10: successColor,
};

const useStyles = makeStyles(styles);

const AvaliacaoAtendimento = () => {
  const classes = useStyles();

  const [openModalReplies, setOpenModalReplies] = useState(false);

  const [startDate, setStartDate] = useState(new Date(new Date().setDate(1)));
  const [endDate, setEndDate] = useState(new Date());

  const [nps, setNPS] = useState({
    enabled: false,
    nps: 0,
    evaluationReplies: 0,
    evaluationSent: 0,
    resume: {
      prommoters: {total: 0, percent: 0},
      neutral: {total: 0, percent: 0},
      detractors: {total: 0, percent: 0},
    },
    evaluations: {
      0: {total: 0, calleds: []},
      1: {total: 0, calleds: []},
      2: {total: 0, calleds: []},
      3: {total: 0, calleds: []},
      4: {total: 0, calleds: []},
      5: {total: 0, calleds: []},
      6: {total: 0, calleds: []},
      7: {total: 0, calleds: []},
      8: {total: 0, calleds: []},
      9: {total: 0, calleds: []},
      10: {total: 0, calleds: []},
    }
  });

  const getEvaluations = async () => {
    const {data} = await api.get(`/called/evaluation`, {params: {start: startDate, end: endDate}});
    setNPS(data);
  };

  const changeCalledEvaluation = async enabled => {
    await api.put(`/called/evaluation/${enabled}`);
    setNPS(nps => ({...nps, enabled}));
    Swal.fire({
      icon: "success",
      title: `Avaliação de atendimento ${enabled ? 'habilitada' : 'desabilitada'} com sucesso!`
    });
  };

  useEffect(() => {
    getEvaluations();
  }, []);

  const allCalleds = [];
  const userCalleds = {};
  Object.keys(nps.evaluations).forEach(p => {
    const {calleds} = nps.evaluations[p];
    allCalleds.push(...calleds);

    calleds.forEach(p1 => {
      const {called, rate} = p1;
      if (!userCalleds[called.user?.id]) userCalleds[called.user?.id] = { ...called?.user, calleds: [] };
      userCalleds[called.user?.id].calleds.push({ ...called, rate });
    });
  });
  const {nps: npsValue, evaluationReplies, evaluationSent, resume: {neutral, detractors, prommoters}} = nps;
  const evaluationRepliesPercent = (evaluationReplies / evaluationSent) * 100;
  const ComponentActualColor = npsValue > 51 ? (npsValue > 76 ? Success : Info) : (npsValue <= 0 ? Danger : Warning);
  const getColorRate = (rate, component = false) => rate > 7 ? (rate > 8 ? component ? successColor : 'success' : component ? infoColor : 'info') : (rate <= 6 ? component ? dangerColor : 'danger' : component ? warningColor : 'warning');
  const rateAvg = allCalleds.reduce((cont, p) => p.rate + cont, 0) / allCalleds.length;

  const [openCalledHistory, setOpenCalledHistory] = useState(null);

  return (
    <GridContainer>
      <ModalCalledHistory open={!!openCalledHistory} id={openCalledHistory} onClose={() => setOpenCalledHistory(null)}/>
      <Modal
        open={openModalReplies}
        keepMounted
        onClose={() => setOpenModalReplies(false)}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Card style={{width: '700px', maxHeight: '80%'}}>
          <CardBody style={{overflowY: 'auto'}}>
            <Table
              hover
              // tableHead={['', 'Cliente', 'Telefone', 'Atendente', 'Protocolo', 'Data', '']}
              tableData={allCalleds
                .sort((a, b) => new Date(a.date).getTime() > new Date(b.date).getTime() ? -1 : new Date(a.date).getTime() < new Date(b.date).getTime() ? 1 : 0)
                .map(prop => [
                  <Button color={getColorRate(prop.rate)} disabled justIcon style={{margin: 0}}>
                    {prop.rate}
                  </Button>,
                  <strong>{prop.called?.contact?.name}</strong>,
                  prop.called?.contact?.idWhatsapp?.split('@')[0],
                  prop.called?.user?.name,
                  prop.called?.id,
                  convertDate(prop.date, false, true),
                  (
                    <Tooltip title={'Ver conversa'}>
                      <IconButton onClick={() => setOpenCalledHistory(prop.called?.id)}>
                        <img alt={'chat'} src={Chat}/>
                      </IconButton>
                    </Tooltip>
                  )
                ])}
            />
          </CardBody>
        </Card>
      </Modal>
      <GridItem lg={12} md={12} sm={12} xs={12}>
        {/*--------------------------------------------------------------------------------------------------*/}
        <Card>
          <CardBody>
            <GridContainer>
              <GridItem lg={12} md={12} sm={12} xs={12}>
                <FormControlLabel
                  style={{fontSize: '10px', color: '#8c8c8c'}}
                  label={
                    <Typography style={{fontSize: '14px', float: 'left'}}>
                      Usar avaliação de atendimento
                    </Typography>
                  }
                  control={
                    <Switch
                      checked={nps.enabled}
                      onChange={() => changeCalledEvaluation(!nps.enabled)}
                      // onClick={liberacaoAcesso.bind(this)}
                      classes={{
                        switchBase: classes.switchBase,
                        checked: classes.switchChecked,
                        thumb: classes.switchIcon,
                        track: classes.switchBar
                      }}
                    />
                  }
                />
              </GridItem>
              <GridItem lg={3} md={3} sm={4} xs={6}>
                <InputLabel className={classes.label} style={{marginBottom: -10}}>
                  Período Inicial
                </InputLabel>
                <br/>
                <FormControl fullWidth>
                  <Datetime
                    timeFormat={false}
                    onChange={e => typeof e === 'object' ? setStartDate(e.toDate()) : (e.length === 10 ? (isValidDate(new Date(e.split("/").reverse().join("-"))) ? setStartDate(new Date(e.split("/").reverse().join("-"))) : "") : "")}
                    // value={startDate}
                    initialValue={startDate}
                    inputProps={{placeholder: "Período Inicial", id: 'periodo-inicial'}}
                  />
                </FormControl>
              </GridItem>
              <GridItem lg={3} md={3} sm={4} xs={6}>
                <InputLabel className={classes.label} style={{marginBottom: -10}}>
                  Período Final
                </InputLabel>
                <br/>
                <FormControl fullWidth>
                  <Datetime
                    timeFormat={false}
                    onChange={e => typeof e === 'object' ? setEndDate(e.toDate()) : (e.length === 10 ? (isValidDate(new Date(e.split("/").reverse().join("-"))) ? setEndDate(new Date(e.split("/").reverse().join("-"))) : "") : "")}
                    // value={endDate}
                    initialValue={endDate}
                    inputProps={{placeholder: "Período Final", id: 'periodo-final'}}
                  />
                </FormControl>
              </GridItem>
              {/*-------------------------------------------------*/}

              <Button color={'primary'} onClick={getEvaluations}>
                Buscar
              </Button>
              {/*--------------------------------------------------------------------------------------------------*/}
            </GridContainer>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <GridContainer>
              <GridItem lg={2} md={3} sm={4} xs={12} style={{textAlign: 'center'}}>
                <ComponentActualColor>
                  <h1 style={{fontSize: '5em', fontWeight: 400, marginBottom: 0, marginTop: 0}}>
                    {Math.round(npsValue)}
                  </h1>
                </ComponentActualColor>
                Net Promoter Score
              </GridItem>
              <GridItem lg={10} md={9} sm={8} xs={12}>
                {[
                  {
                    name: 'Detratores',
                    total: Math.round(detractors.percent),
                    color: dangerColor[0],
                    shadow: dangerBoxShadow
                  },
                  {
                    name: 'Neutros',
                    total: Math.round(neutral.percent),
                    color: warningColor[0],
                    shadow: warningBoxShadow
                  },
                  {
                    name: 'Promotores',
                    total: Math.round(prommoters.percent),
                    color: successColor[0],
                    shadow: successBoxShadow
                  }
                ].map((prop, key) => {
                  const {name, total, color, shadow} = prop;

                  return (
                    <div key={key} style={{marginBottom: 10}}>
                      <span style={{fontFamily: 'NunitoRegular'}}>{name}</span>
                      <Tooltip title={name}>
                        <div
                          style={{
                            transition: '0.8s',
                            minWidth: 35,
                            width: `${total}%`,
                            height: 30,
                            backgroundColor: color, ...shadow,
                            borderRadius: '0 10px 10px 0',
                            paddingLeft: 10,
                            display: 'flex',
                            alignContent: 'center',
                            color: '#FFF',
                            fontWeight: 'bold',
                            flexWrap: 'wrap'
                          }}
                        >
                          {total}%
                        </div>
                      </Tooltip>
                    </div>
                  );
                })}
              </GridItem>
              <GridItem lg={12} md={12} sm={12} xs={12} style={{marginTop: 20, color: grayColor[0]}}>
                <GridContainer>
                  <GridItem lg={3} md={3} sm={3} xs={6}>
                    <h1 style={{margin: 0, fontWeight: 'bold', color: getColorRate(rateAvg, true)[0]}}>
                      {isNaN(rateAvg) ? 'Sem dados' : rateAvg.toFixed(1)}
                    </h1>
                    Média das notas
                  </GridItem>
                  <GridItem lg={3} md={3} sm={3} xs={6}>
                    <h1 style={{margin: 0, fontWeight: 'bold'}}>{evaluationReplies}</h1>
                    Respostas totais
                  </GridItem>
                  <GridItem lg={3} md={3} sm={3} xs={6}>
                    <h1 style={{margin: 0, fontWeight: 'bold'}}>{evaluationSent}</h1>
                    Pesquisas enviadas
                  </GridItem>
                  <GridItem lg={3} md={3} sm={3} xs={6}>
                    <h1 style={{margin: 0, fontWeight: 'bold'}}>
                        {isNaN(evaluationRepliesPercent) ? 'Sem dados' : evaluationRepliesPercent.toFixed(2) + '%'}
                    </h1>
                    Taxa de respostas
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
        <Button color={'primary'} onClick={() => setOpenModalReplies(true)}>
          Detalhar Avaliações
        </Button>
        {/*-------------------------------------------------*/}
        <GridContainer>
          <GridItem lg={6} md={6} sm={12} xs={12}>
            <Card>
              <CardBody>
                <Bar
                  // height={300}

                  data={{
                    labels: new Array(11).fill(0).map((prop, key) => key.toString()),
                    datasets: [
                      {
                        label: "",
                        backgroundColor: new Array(11).fill(0).map((p, k) => `rgba(${hexToRgb(barColors[k][0])}, 0.6)`),
                        borderColor: new Array(11).fill(0).map((p, k) => `rgba(${hexToRgb(barColors[k][0])}, 1)`),
                        borderWidth: 1,
                        hoverBackgroundColor: new Array(11).fill(0).map((p, k) => `rgba(${hexToRgb(barColors[k][0])}, 0.8)`),
                        hoverBorderColor: new Array(11).fill(0).map((p, k) => `rgba(${hexToRgb(barColors[k][0])}, 1)`),
                        data: new Array(11).fill(0).map((p, k) => nps.evaluations[k].total)
                      }
                    ]
                  }}
                  options={{
                    scales: {
                      yAxes: [{ticks: {beginAtZero: true, min: 0}}],
                      xAxes: [{gridLines: {display: false}, ticks: {fontColor: "#000"}}]
                    },
                  }}
                />
              </CardBody>
            </Card>
          </GridItem>
          <GridItem lg={6} md={6} sm={12} xs={12}>
            <Card>
              <CardBody>
                <Table
                  hover
                  tableHead={['', 'Atendente', 'Media das notas', 'Total Atendimentos']}
                  tableData={
                    Object.keys(userCalleds)
                      .map(prop => {
                        const {calleds} = userCalleds[prop];
                        prop = userCalleds[prop];
                        prop.userRateAvg = calleds.reduce((cont, p) => p.rate + cont, 0) / calleds.length;
                        return prop;
                      }).sort((a, b) => a.userRateAvg > b.userRateAvg ? -1 : (a.userRateAvg < b.userRateAvg) ? 1 : 0)
                      .map((prop, key) => {
                        const {name, calleds, userRateAvg} = prop;
                        // getColorRate
                        return [
                          <strong>{key + 1}º</strong>,
                          name,
                          <Button disabled justIcon color={getColorRate(userRateAvg)} style={{margin: 0}}>
                            {(calleds.reduce((cont, p) => p.rate + cont, 0) / calleds.length).toFixed(1)}
                          </Button>,
                          calleds.length,
                          ''
                        ]
                      })
                  }
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
};

export default AvaliacaoAtendimento;