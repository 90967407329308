import React, {useState, useEffect, useCallback} from "react";
import MUIDataTable from "mui-datatables";

import {WebService} from "../../../components/WebService";
import {getFotoLink, formataNumeroTelefone, traducaoTable} from "../../../components/Funcoes";

import {Card, CardIcon, CardHeader, CardBody, GridItem, GridContainer} from '../../../components';

import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  IconButton,
  Chip,
  Switch,
  Modal,
  Tooltip
} from '@material-ui/core';
import {Close, Edit, PeopleAlt, PersonAdd, Backup, Sync} from '@material-ui/icons';

import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import {customUseStyles} from "./styles";

import * as Swal from "sweetalert2";

import CustomToolbarSelect from "../Toolbar/CustomToolbarSelect";
import UploadContatos from '../Toolbar/UploadContatos';
import FormContatos from './FormContatos';

import default_avatar from '../../../assets/img/default-avatar.png';
import Button from "../../../components/CustomButtons/Button";

const CustomModal = ({handleClose, show, children}) => {
  const classes = customUseStyles();
  return (
    <Modal open={show} onClose={handleClose} className={classes.modal}>
      <>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <Close color="error"/>
        </IconButton>
        {children}
      </>
    </Modal>
  );
};

export const getMuiTheme = () => createMuiTheme({
  overrides: {
    MUIDataTable: {
      root: {
        marging: 0,
        border: 0,
        boxShadow: 'none'
      },
      paper: {
        margin: 0,
        boxShadow: "none",
      }
    },
    MUIDataTableBodyCell: {
      root: {
        padding: 0,
      }
    }
  }
});

export default function Contatos({id_empresa, history}) {
  const classes = customUseStyles();

  const [idContato, setIdContato] = useState([]);
  const [contatos, setContatos] = useState([]);

  const [open, setOpen] = useState(false);
  const [type, setType] = useState("");


  const handleModal = (value, type) => {
    setIdContato(value);
    setOpen(!open);

    switch (type) {
      case 'atualizaContato':
        setType('atualizaContato');
        break;
      case 'importaContato':
        setType('importaContato');
        break;

      default:
        break;
    }
  }


  const excluirContato = async key => {
    const {nome, id_contato} = contatos[key];

    const message = ` Esta ação é irreversível! Se confirmado você irá exlcuir o contato ${nome} do sistema.`

    const {value} = await Swal.fire({
      title: 'Confirma esta ação?',
      text: message,
      icon: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: true,
      confirmButtonText: 'Confirma',
      cancelButtonText: 'Cancela',
      reverseButtons: true
    });
    if (value === true) {
      const {ok} = await WebService(`/contato/excluirContato`, {id_contato});
      if (ok) {
        await Swal.fire({
          title: 'Contato excluído com sucesso!',
          icon: 'success',
          showConfirmButton: false,
          timer: 2500
        });
        await getContatos();
      }
    }
  };

  const getContatos = async () => {
    const {ok, contatos} = await WebService(`/contato/getContatos`, {id_empresa});
    if (ok) setContatos(contatos);
  };

  useEffect(() => {
    getContatos();
  }, []);

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        display: 'excluded',
        filter: false,
        download: false
      }
    },
    {
      name: 'contato',
      label: 'Contato',
      options: {
        display: 'excluded',
        filter: true,
        sort: true
      }
    },
    {
      name: 'nome',
      label: "Contato",
      options: {
        filter: false,
        download: false,
        customBodyRender: (value) => {
          return (
            <List dense className={classes.root}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    key={value.avatar}
                    alt={value.nome}
                    src={value.avatar ? getFotoLink(value.avatar) : default_avatar}
                    className={classes.small}
                  />
                </ListItemAvatar>
                {value.nome ?
                  <ListItemText primary={value.nome}/>
                  :
                  <ListItemText primary={"Nome não definido"} style={{color: '#ccc'}}/>
                }
              </ListItem>
            </List>
          );
        }
      },
    },
    {
      name: "telefone",
      label: "Telefone",
      options: {
        filter: true,
      }
    },
    {
      name: "actions",
      label: " ",
      options: {
        filter: false,
        download: false,
        customBodyRender: (value, data, a, b, c) => {
          return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <Tooltip title={"Atualizar dados do contato automaticamente"}>
                <IconButton onClick={() => buscarDadosContato(value, data)}>
                  <Sync fontSize="small" color="success"/>
                </IconButton>
              </Tooltip>
              <Tooltip title={"Editar contato"}>
                <IconButton color="link" onClick={() => handleModal(value, 'atualizaContato')}>
                  <Edit fontSize="small" color="primary"/>
                </IconButton>
              </Tooltip>
              <Tooltip title={"Excluir contato"}>
                <IconButton color="link" onClick={() => excluirContato(data.rowIndex)}>
                  <Close fontSize="small" color="error"/>
                </IconButton>
              </Tooltip>
            </div>
          );
        }
      },
    }
  ];

  const buscarDadosContato = async (v, data) => {
    const {rowData} = (data || {rowData: []});
    const [id_contato] = rowData;
    const {ok} = await WebService(`/contato/buscarDadosContato`, {id_contato});
    if (ok) Swal.fire({icon: "success", title: "Contato atualizado com sucesso!"});
  };

  const options = {
    ...traducaoTable,
    filter: true,
    filterType: "dropdown",
    responsive: "scrollFullHeight",
    rowsPerPage: 10,
    selectableRowsOnClick: 'true',
    selectableRows: 'none',
    downloadOptions: {
      filename: 'Contatos_Macrochat.csv',
      separator: ';',
      filterOptions: {
        useDisplayedColumnsOnly: false,
        useDisplayedRowsOnly: false,
      }
    },
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
      <CustomToolbarSelect selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows}/>
    ),
    customToolbar: () => {
      return (
        <>
          <Tooltip title={"Importar Contatos"}>
            <IconButton className={classes.iconButton}>
              <Backup onClick={() => history.push(`/contatos/importacao`)} />
            </IconButton>
          </Tooltip>
          <Tooltip title={"Novo Contato"}>
            <IconButton className={classes.iconButton}>
              <PersonAdd onClick={() => handleModal(null, 'atualizaContato')}/>
            </IconButton>
          </Tooltip>
          <Tooltip title={"Atualizar dados contatos"}>
            <IconButton className={classes.iconButton}>
              <Sync onClick={() => buscarDadosContato()}/>
            </IconButton>
          </Tooltip>
        </>
      );
    }
  };

  return (
    <GridContainer>
      <GridItem lg={12} md={12} sm={12} xs={12}>
        <Button color={'hust'} round onClick={() => history.push(`/contatos/importacao`)}>
          Status das Importações
        </Button>
      </GridItem>
      <GridItem lg={12} md={12} sm={12} xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary" className={classes.cardIcon}>
              <PeopleAlt style={{height: '50px', width: '50px'}}/>
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Contatos <br/> <small className={classes.cardIconSubtitle}>Contatos
              regristrados no sistema para realização de atendimentos</small></h4>
          </CardHeader>
          <CardBody style={{margin: 0, padding: 0}}>
            <MuiThemeProvider theme={getMuiTheme()}>
              <MUIDataTable
                columns={columns}
                options={options}
                data={contatos.map(contato => {
                  return [
                    contato.id_contato,
                    contato.nome,
                    {"nome": contato.nome, "avatar": contato.foto_perfil},
                    formataNumeroTelefone(contato.id_whatsapp.split("@")[0]),
                    contato.id_whatsapp.split("@")[0],
                  ]
                })}
              />
            </MuiThemeProvider>
          </CardBody>
        </Card>
      </GridItem>
      {/* MODAL */}
      <CustomModal show={open} handleClose={handleModal}>
        {type === 'atualizaContato' ?
          (<FormContatos modalAdicionarContato={handleModal} id_contato={idContato}/>)
          : (<UploadContatos onFinish={() => getContatos()}/>)
        }
      </CustomModal>
    </GridContainer>
  );
}
