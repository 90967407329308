import React, {useEffect, useState} from 'react';
import {Card, CardBody, GridContainer, GridItem} from "../components";
// import Typography from "@material-ui/core/Typography";
// import Switch from "@material-ui/core/Switch";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import {FormControlLabel, Switch} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {WebService} from "../components/WebService";

const useStyles = makeStyles(styles);

const BetaControll = () => {
  const classes = useStyles();

  const classesSwitch = {
    switchBase: classes.switchBase,
    checked: classes.switchChecked,
    thumb: classes.switchIcon,
    track: classes.switchBar
  };

  const [features, setFeatures] = useState({});

  const getFeatures = async () => {
    const {ok, features} = await WebService('/sistema/checkFeatures');
    if (ok) setFeatures(features);
  };

  const updateFeatures = async (slug, enabled) => {
    const {ok} = await WebService('/sistema/updateFeature', {slug, enabled});
    if (ok) {
      localStorage.setItem(`flag-${slug}`, enabled.toString());
      window.location.reload();
    }
  };

  useEffect(() => {
    getFeatures();
  }, []);

  return (
    <GridContainer>
      <GridItem lg={12} md={12} sm={12} xs={12}>
        <h2>Controle de funcionalidades beta</h2>
        <Card>
          <CardBody>
            {Object.keys(features).map(prop => {
              return (
                <FormControlLabel
                  key={prop}
                  style={{fontSize: '10px', color: '#8c8c8c'}}
                  label={<Typography style={{fontSize: '14px', float: 'left'}}> {features[prop].name} </Typography>}
                  control={
                    <Switch
                      // checked={checkBeta(prop)}
                      checked={features[prop].enabled}
                      onChange={({target: {checked}}) => updateFeatures(prop, checked)}
                      classes={classesSwitch}
                    />
                  }
                />
              );
            })}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

const checkBeta = slug => localStorage.getItem(`flag-${slug}`) === 'true';

export {checkBeta};
export default BetaControll;