import React, {useEffect, useRef, useState} from 'react';
import {StyledTab, StyledTabs} from "../NewChat/styles";
import {Card, CardBody, GridContainer, GridItem, Table} from "../../components";
import {FormControlLabel, makeStyles, Tab, Tabs, Tooltip} from "@material-ui/core";
import {
  ArrowBack,
  Devices,
  Message,
  Dashboard as DashboardIcon,
  ListAlt,
  Pause,
  Save,
  Person, Edit, Remove, Delete, Close, Attachment, PlayArrow, ScheduleRounded
} from "@material-ui/icons";
import Build from "@material-ui/icons/Build";
import Button from "../../components/CustomButtons/Button";
import StatCard from "./components/StatCard";
import SendIcon from "@material-ui/icons/Send";
import {
  dangerColor, grayColor,
  hexToRgb, infoBoxShadow,
  infoColor, primaryBoxShadow, primaryColor, successBoxShadow,
  successColor, warningBoxShadow,
  warningColor
} from "../../assets/jss/material-dashboard-pro-react";
import {Doughnut} from "react-chartjs-2";
import CardIcon from "../../components/Card/CardIcon";
import BusinessIcon from "@material-ui/icons/Business";
import CardHeader from "../../components/Card/CardHeader";
import dashboardStyle from "../../assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import TextField from "@material-ui/core/TextField";
import api, {baseURLV2} from "../../components/api";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import styles from "assets/jss/material-dashboard-pro-react/components/tasksStyle.js";
import Swal from "sweetalert2";
import {converterTextoWpp, ls, pontuacaoNumero, zeroEsquerda} from "../../components/Funcoes";
import CustomInput from "../../components/CustomInput/CustomInput";
import AttachFile from "@material-ui/icons/AttachFile";
import {FilePdf} from "@styled-icons/boxicons-solid";
import {apiURL, apiURLWithoutVersion, WebService} from "../../components/WebService";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Timer from "react-compound-timer";
import CustomLinearProgress from "../../components/CustomLinearProgress/CustomLinearProgress";
import moment from "moment";

const useStyles = makeStyles({
  ...dashboardStyle,
  ...styles,
  addWeek: {
    transition: '.3s',
    cursor: "pointer",
    '&:after': {
      width: 30,
      height: 30,
      content: '"+"',
      position: 'absolute',
      textAlign: 'center',
      justifyContent: 'center',
      fontSize: 20,
      display: 'none',
      alignContent: 'center',
      flexWrap: 'wrap',
      borderRadius: 5
    },
    '&:hover': {
      '&:after': {
        display: 'flex',
        color: '#FFF !important'
      },

      backgroundColor: `rgba(${hexToRgb(infoColor[0])}, 0.5)`,
      border: `1.5px solid ${infoColor[0]} !important`,
      color: `rgba(${hexToRgb(infoColor[0])}, 0.5) !important`
    }
  }
});
const week = {
  monday: 'SEG',
  tuesday: 'TER',
  wednesday: 'QUA',
  thursday: 'QUI',
  friday: 'SEX',
  saturday: 'SAB',
  sunday: 'DOM'
};

function calculateAverage(a, b) {
  return (a + b) / 2;
}

// FIX - ARRUMAR ESSA BAGAçA. NÃO FUNCIONOU
function estimateSendingTime({days, schedule, timezone, pauseBetweenMessages, intervalBetweenMessages}, startDate) {
  const totalMessages = 1000; // You can adjust this number as needed
  const intervalBetweenMessagesAVG = calculateAverage(intervalBetweenMessages.min, intervalBetweenMessages.max);
  const averageMessagesBeforePause = calculateAverage(pauseBetweenMessages.quantity.min, pauseBetweenMessages.quantity.max);
  const averagePause = calculateAverage(pauseBetweenMessages.time.min, pauseBetweenMessages.time.max);

  const hoursPerDay = Object.values(days).reduce((acc, daySlots) => {
    return acc + daySlots.reduce((dayAcc, slot) => {
      const start = new Date(`1970-01-01T${slot.startTime}:00Z`);
      const end = new Date(`1970-01-01T${slot.endTime}:00Z`);
      return dayAcc + (end - start) / 3600000; // Convert milliseconds to hours
    }, 0);
  }, 0);

  const timePerMessage = totalMessages * intervalBetweenMessagesAVG;
  const numPauses = totalMessages / averageMessagesBeforePause;
  const totalPauseTime = numPauses * averagePause;

  const totalTime = timePerMessage + totalPauseTime;
  const numDays = Math.ceil(totalTime / (hoursPerDay * 3600)); // Convert hours to seconds
  const remainingTime = (totalTime - (numDays - 1) * hoursPerDay * 3600) / 3600; // Convert seconds to hours

  const endDate = new Date(startDate);
  endDate.setDate(endDate.getDate() + numDays);
  endDate.setHours(endDate.getHours() + remainingTime);

  return {
    numDays,
    remainingTime,
    startDate: new Date(startDate),
    endDate
  };
}

const secondsToTime = secondsTime => {
  const minutes = secondsTime / 60;
  const seconds = secondsTime % 60;

  return `${zeroEsquerda(minutes)}:${zeroEsquerda(seconds)}`
};

const timeToSeconds = time => {
  const [minutesTime, secondsTime] = time.split(':').map(parseFloat);

  return (minutesTime * 60) + secondsTime;
};

const CAMPAIGN_STATUS_CONFIG = {
  created: {label: 'Criado', color: infoColor[0]},
  scheduled: {label: 'Agendado', color: infoColor[0]},
  running: {label: 'Em execução', color: successColor[0]},
  paused: {label: 'Pausada', color: warningColor[0]},
  finished: {label: 'Finalizado', color: grayColor[0]},
};

const CampaignEdit = ({history, computedMatch: {params}}) => {
  const classes = useStyles();

  const [activeTab, setActiveTab] = useState(0);
  const [totalEstimatedTime, setTotalEstimatedTime] = useState({endDate: moment(), numDays: 0, remainingTime: 0});
  const [campaign, setCampaign] = useState({
    id: 0,
    name: '',
    status: 'created',
    connections: [],
    sendingLists: [],
    totalContacts: 0,
    config: {
      schedule: {active: false, date: new Date()},
      days: {
        monday: [{startTime: '08:00', endTime: '12:00'}, {startTime: '14:00', endTime: '18:00'}],
        tuesday: [{startTime: '08:00', endTime: '12:00'}, {startTime: '14:00', endTime: '18:00'}],
        wednesday: [{startTime: '08:00', endTime: '12:00'}, {startTime: '14:00', endTime: '18:00'}],
        thursday: [{startTime: '08:00', endTime: '12:00'}, {startTime: '14:00', endTime: '18:00'}],
        friday: [{startTime: '08:00', endTime: '12:00'}, {startTime: '14:00', endTime: '18:00'}],
        saturday: [{startTime: '08:00', endTime: '12:00'}],
        sunday: [],
      },
      intervalBetweenMessages: {min: 0, max: 0},
      pauseBetweenMessages: {quantity: {min: 0, max: 0}, time: {min: 0, max: 0}}
    }
  });
  const [connections, setConnections] = useState([]);
  const [sendingLists, setSendingLists] = useState([]);
  const [bots, setBots] = useState([]);

  const [campaignMessage, setCampaignMessage] = useState({
    type: 'notification',
    notification: {
      message: '',
      file: undefined,
      // file: {
      //   type: 'pdf',
      //   name: 'Italo.png',
      //   url: 'https://apiv1.hustapp.com/v1/chat/getFotoPerfil?p=HfzxPxvj8rPc6Xz00nZBFG9hbM6ICFB3ICKxCe4K8Z3vgNaY5b.png'
      // }
    },
    bot: {
      id: 0
    }
  });

  const attachFileRef = useRef();

  const getBots = async () => {
    const {data} = await api.get('/bot');
    setBots(data.filter(el => el.config.callerType === 'Campaign'));
  };

  const [campaignStats, setCampaignStats] = useState({
      totalMessagesSent: 0,
      viewStatus: {
        repliedMessages: 0,
        readMessages: 0,
        receivedMessages: 0
      },
      sendingStatus: {
        errors: 0,
        sentMessages: 0,
        pendingMessages: 0
      }
    }
  );
  const getCampaignStats = async id => {
    const {data} = await api.get(`/campaign/${id}/dashboard`);
    setCampaignStats(data);
  };

  const getCampaign = async (id, all = true) => {
    const {data} = await api.get(`/campaign/${id}`);
    const {notification, bot} = data;

    delete data.notification;
    delete data.bot;
    setCampaign(data);

    setCampaignMessage({
      type: !!notification ? 'notification' : 'bot',
      notification,
      bot
    });

    if (all) {
      await Promise.all([
        getCampaignStats(data.id),
        getSendingList(),
        getConnections(),
        getBots(),
      ]);
    }
  };

  const getSendingList = async () => {
    const {data} = await api.get('/sendingList');
    setSendingLists(data);
  };

  const getConnections = async () => {
    const {data} = await api.get('/connection');
    setConnections(data);
  };

  const changeCampaignStatus = async status => {
    const {data} = await api.put(`/campaign/${campaign.id}`, {status});
    setCampaign(campaign => {
      campaign.status = data.status;
      return {...campaign};
    });
    await Swal.fire({
      icon: "success",
      title: 'Status alterado com sucesso!',
      text: `A alteração pode levar alguns segundos para fazer efeito.`
    });
  };

  const saveConfig = async () => {
    const {data} = await api.put(`/campaign/${campaign.id}`, {config: campaign.config});
    await Swal.fire({icon: "success", title: 'Campanha salva com sucesso!'});
  };

  const saveName = async () => {
    if (!campaign.name.trim()) return Swal.fire({icon: "warning", title: 'O nome da campanha não pode ser vazio'});
    const {data} = await api.put(`/campaign/${campaign.id}`, {name: campaign.name});
    await Swal.fire({icon: "success", title: 'Campanha salva com sucesso!'});
  };

  const saveMessage = async () => {
    const {data} = await api.put(`/campaign/${campaign.id}`, {message: campaignMessage});
    await Swal.fire({icon: "success", title: 'Mensagem da campanha salva com sucesso!'});
  };

  const addConnection = async (id, status) => {
    const {data} = await api[status ? 'post' : 'delete'](`/campaign/${campaign.id}/connection/${id}`, {});
    setCampaign(campaign => {
      campaign.connections = data;
      return {...campaign};
    });
  };

  const addSendingList = async (id, status) => {
    const {data} = await api[status ? 'post' : 'delete'](`/campaign/${campaign.id}/sendingList/${id}`, {});
    setCampaign(campaign => {
      campaign.sendingLists = data;
      return {...campaign};
    });
  };

  useEffect(() => {
    if (!params || !params.id) history.push('/bulkSend/campaign');
    else getCampaign(params.id);
  }, []);

  useEffect(() => {
    if (activeTab === 0 && campaign.id) {
      getCampaignStats(campaign.id)
      const interval = setInterval(() => getCampaignStats(campaign.id), 10 * 1000);
      return () => clearInterval(interval);
    }
  }, [activeTab]);

  useEffect(() => {
    // const {endDate} = estimateSendingTime(campaign.config, new Date());
    setTotalEstimatedTime(estimateSendingTime(campaign.config, new Date()));
  }, [campaign]);

  useEffect(() => {
    if (campaign.id && activeTab === 0) getCampaign(campaign.id, false);
  }, [activeTab]);

  return (
    <GridContainer>
      <GridItem lg={12} md={12} sm={12} xs={12}>
        <Button color={'primary'} round onClick={() => history.push('/bulkSend/campaign')} simple>
          <ArrowBack/> Voltar
        </Button>
      </GridItem>
      <GridItem lg={4} md={4} sm={6} xs={12}>
        <Card>
          <CardBody>
            <CustomInput
              labelText="Nome da campanha"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: campaign.name || '',
                onChange: ({target: {value}}) => setCampaign({...campaign, name: value}),
                type: "text",
                // style: {minWidth: 350},
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon
                      className={classes.inputAdornmentIcon}
                      onClick={saveName}
                      color={'primary'}
                      style={{cursor: 'pointer'}}
                    >
                      save
                    </Icon>
                  </InputAdornment>
                ),
              }}
            />
          </CardBody>
        </Card>
      </GridItem>
      <GridItem lg={4} md={4} sm={6} xs={12}>
        <Card>
          <CardBody>
            Status da campanha:
            &nbsp;&nbsp;
            <span
              style={{
                border: `1px solid ${CAMPAIGN_STATUS_CONFIG[campaign.status].color}`,
                color: CAMPAIGN_STATUS_CONFIG[campaign.status].color,
                borderRadius: 10,
                padding: '2px 5px',
                fontWeight: 600
              }}
            >
              {CAMPAIGN_STATUS_CONFIG[campaign.status].label}
            </span>
            <br/>
            {/*Tempo total estimado:{' '}
            {moment.duration(moment(totalEstimatedTime.endDate).diff(moment())).humanize(false)}
            {totalEstimatedTime.numDays > 0 ? totalEstimatedTime.numDays + ` dia${totalEstimatedTime.numDays > 1 ? 's' : ''}, ` : ''}
            {totalEstimatedTime.remainingTime - (totalEstimatedTime.remainingTime % 1)} horas e{' '}
            {(((totalEstimatedTime.remainingTime % 1) * 60) /1).toFixed()} minutos
            */}
            {campaign.status === 'paused' ? (
              <>
                <br/>
                <Button color={'success'} onClick={changeCampaignStatus.bind(this, 'running')} round>
                  <PlayArrow/> Retomar Campanha
                </Button>
              </>
            ) : ''}
            {campaign.status === 'running' ? (
              <>
                <br/>
                <Button color={'info'} onClick={changeCampaignStatus.bind(this, 'paused')} round>
                  <Pause/> pausar
                </Button>
              </>
            ) : ''}
            {campaign.status === 'created' ? (
              <>
                <br/>
                <Button color={'success'} onClick={changeCampaignStatus.bind(this, 'running')} round>
                  <PlayArrow/> Iniciar Agora
                </Button>
                {/*<Button color={'info'} onClick={() => history.push('/bulkSend/campaign')} round>
                  <ScheduleRounded/> Agendar envio
                </Button>*/}
              </>
            ) : ''}
          </CardBody>
        </Card>
      </GridItem>
      <GridItem lg={12} md={12} sm={12} xs={12}>
        <Tabs value={activeTab} onChange={(a, b) => setActiveTab(b)} variant="fullWidth" TabIndicatorProps={{children: <div/>}}>
          <Tab disableRipple label={(<div style={{display: 'flex'}}><DashboardIcon/>&nbsp;Dashboard</div>)}/>
          <Tab disableRipple label={(<div style={{display: 'flex'}}><Devices/>&nbsp;Conexões e listas</div>)}/>
          <Tab disableRipple label={(<div style={{display: 'flex'}}><Message/>&nbsp;Mensagens da campanha</div>)}/>
          <Tab disableRipple label={(<div style={{display: 'flex'}}><Build/>&nbsp;Configurações de envio</div>)}/>
        </Tabs>
      </GridItem>
      <GridItem lg={12} md={12} sm={12} xs={12}>
        {activeTab === 0 && (
          <GridContainer>
            <StatCard
              title={'Total de conexões'}
              value={campaign.connections.length}
              color={campaign.connections.length ? 'info' : 'primary'}
              icon={Devices}
            />
            <StatCard
              title={'Total de listas'}
              value={campaign.sendingLists.length}
              color={campaign.sendingLists.length ? 'info' : 'primary'}
              icon={ListAlt}
            />
            <StatCard
              title={'Total de contatos'}
              value={campaign.totalContacts}
              color={campaign.totalContacts ? 'info' : 'primary'}
              icon={Person}
            />
            <StatCard title={(
              <>
                Total de disparos
                {Number(((campaignStats.totalMessagesSent / campaign.totalContacts) * 100).toFixed(0)) ? (
                  <CustomLinearProgress
                    color={'primary'}
                    value={Number(((campaignStats.totalMessagesSent / campaign.totalContacts) * 100).toFixed(0))}
                    style={{marginBottom: 5}}
                    variant={'determinate'}
                    valueBuffer={Number(((campaignStats.totalMessagesSent / campaign.totalContacts) * 100).toFixed(0))}
                  />
                ) : ''}
              </>
            )} value={campaignStats.totalMessagesSent} color={'primary'} icon={SendIcon}/>
            {/*<StatCard title={'Respostas não entendidas'} value={0} color={'primary'} icon={() => ''}/>*/}
            <GridItem lg={12} md={12} sm={12} xs={12}/>
            <GridItem lg={4} md={4} sm={6} xs={12}>
              <Card>
                <CardHeader color="primary" icon>
                  <h4 className={classes.cardIconTitle}>Visualizações e respostas</h4>
                </CardHeader>
                <CardBody>
                  <div style={{width: '100%', textAlign: '-webkit-center'}}>
                    {[
                      {name: 'Enviadas', color: primaryColor[0], percentage: Number(((campaignStats.totalMessagesSent / campaign.totalContacts) * 100).toFixed(0)) || 0, max: 100, quantity: campaignStats.totalMessagesSent, shadow: primaryBoxShadow},
                      {name: 'Recebidos', color: warningColor[0], percentage: Number((((campaignStats.viewStatus.receivedMessages + campaignStats.viewStatus.readMessages) / campaign.totalContacts) * 100).toFixed(0)) || 0, max: 85, quantity: campaignStats.viewStatus.receivedMessages, shadow: warningBoxShadow},
                      {name: 'Lidos', color: infoColor[0], percentage: Number(((campaignStats.viewStatus.readMessages / campaign.totalContacts) * 100).toFixed(0)) || 0, max: 75, quantity: campaignStats.viewStatus.readMessages, shadow: infoBoxShadow},
                      {name: 'Respostas', color: successColor[0], percentage: Number(((campaignStats.viewStatus.repliedMessages / campaign.totalContacts) * 100).toFixed(0)) || 0, max: 60, quantity: campaignStats.viewStatus.repliedMessages, shadow: successBoxShadow},
                    ].map(prop => (
                      <div style={{position: 'relative', maxWidth: `${prop.max}%`, marginBottom: 10}}>
                        <div style={{position: 'absolute', width: `${prop.percentage}%`, height: 25, background: `rgba(${hexToRgb(prop.color)}, 0.5)`, top: 0, borderRadius: 8}}/>
                        <Tooltip title={`${prop.name} - ${pontuacaoNumero(prop.quantity)}`}>
                          <div style={{position: 'absolute', width: `100%`, height: 25, color: '#FFF', top: 0, fontWeight: 'bold', fontSize: 15, paddingTop: 2}}>
                            {prop.name} - {prop.percentage.toFixed(0)}%
                          </div>
                        </Tooltip>
                        <div style={{width: '100%', height: 25, background: `rgba(${hexToRgb(prop.color)}, 0.5)`, borderRadius: 8, border: `1px solid ${prop.color}`, ...prop.shadow}}/>
                      </div>
                    ))}
                  </div>
                  {/*<Doughnut
                    data={{
                      labels: ['Respondidos', 'Lidos', 'Recebidos'],
                      datasets: [
                        {
                          label: '',
                          data: [
                            campaignStats.viewStatus.repliedMessages,
                            campaignStats.viewStatus.readMessages,
                            campaignStats.viewStatus.receivedMessages,
                          ],
                          backgroundColor: [
                            `rgba(${hexToRgb(infoColor[4])}, 0.2)`,
                            `rgba(${hexToRgb(successColor[0])}, 0.2)`,
                            `rgba(${hexToRgb(warningColor[0])}, 0.2)`,
                          ],
                          borderColor: [
                            `rgba(${hexToRgb(infoColor[4])}, 1)`,
                            `rgba(${hexToRgb(successColor[0])}, 1)`,
                            `rgba(${hexToRgb(warningColor[0])}, 1)`,
                          ],
                          borderWidth: 1,
                        },
                      ]
                    }}
                  />*/}
                </CardBody>
              </Card>
            </GridItem>
            <GridItem lg={4} md={4} sm={6} xs={12}>
              <Card>
                <CardHeader color="primary" icon>
                  <h4 className={classes.cardIconTitle}>Mensagens enviadas e mensagens a enviar</h4>
                </CardHeader>
                <CardBody>
                  <Doughnut
                    data={{
                      labels: ['Falha', 'Enviada', 'A Enviar'],
                      datasets: [
                        {
                          label: '',
                          data: [
                            campaignStats.sendingStatus.errors,
                            campaignStats.sendingStatus.sentMessages,
                            campaignStats.sendingStatus.pendingMessages,
                          ],
                          backgroundColor: [
                            `rgba(${hexToRgb(dangerColor[0])}, 0.2)`,
                            `rgba(${hexToRgb(successColor[0])}, 0.2)`,
                            `rgba(${hexToRgb(infoColor[0])}, 0.2)`,
                          ],
                          borderColor: [
                            `rgba(${hexToRgb(dangerColor[0])}, 1)`,
                            `rgba(${hexToRgb(successColor[0])}, 1)`,
                            `rgba(${hexToRgb(infoColor[0])}, 1)`,
                          ],
                          borderWidth: 1,
                        },
                      ]
                    }}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        )}
        {activeTab === 1 && (
          <GridContainer>
            <GridItem lg={6} md={6} sm={12} xs={12}>
              <Card>
                <CardBody>
                  <Table
                    hover
                    tableHead={['', '#', 'Nome', 'Identificador', 'Tipo de Conexão']}
                    tableData={connections.map(prop => {
                      return [
                        prop.type === 'whatsapp' && (
                          <Checkbox
                            checked={campaign.connections.map(prop1 => prop1.id).includes(prop.id)}
                            tabIndex={-1}
                            onClick={() => addConnection(prop.id, !campaign.connections.map(prop1 => prop1.id).includes(prop.id))}
                            checkedIcon={<Check className={classes.checkedIcon}/>}
                            icon={<Check className={classes.uncheckedIcon}/>}
                            classes={{
                              checked: classes.checked,
                              root: classes.root
                            }}
                          />
                        ),
                        prop.id,
                        prop.name,
                        prop.identifier,
                        prop.type
                      ];
                    })}
                  />
                </CardBody>
              </Card>
            </GridItem>
            <GridItem lg={6} md={6} sm={12} xs={12}>
              <Card>
                <CardBody>
                  <Table
                    hover
                    tableHead={['', '#', 'Nome da lista', 'Quantidade de contatos']}
                    tableData={sendingLists.map(prop => {
                      return [
                        (
                          <Checkbox
                            checked={campaign.sendingLists.map(prop1 => prop1.id).includes(prop.id)}
                            tabIndex={-1}
                            onClick={() => addSendingList(prop.id, !campaign.sendingLists.map(prop1 => prop1.id).includes(prop.id))}
                            checkedIcon={<Check className={classes.checkedIcon}/>}
                            icon={<Check className={classes.uncheckedIcon}/>}
                            classes={{
                              checked: classes.checked,
                              root: classes.root
                            }}
                          />
                        ),
                        prop.id,
                        prop.name,
                        prop.totalContacts
                      ];
                    })}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        )}
        {activeTab === 2 && (
          <GridContainer>
            <GridItem lg={6} md={6} sm={8} xs={12}>
              <Card>
                <CardBody>
                  <GridContainer>
                    <GridItem lg={12} md={12} sm={12} xs={12}>
                      <Button color={"hust"} round onClick={saveMessage}>
                        Salvar mensagem
                      </Button>
                    </GridItem>

                    <GridItem lg={12} md={12} sm={12} xs={12}>
                      <FormControlLabel
                        className={classes.formCheck}
                        control={
                          <Checkbox
                            checked={campaignMessage.type === 'notification'}
                            tabIndex={-1}
                            onClick={() => setCampaignMessage(campaignMessage => {
                              campaignMessage.type = 'notification';
                              if (!campaignMessage.bot) campaignMessage.bot = {id: 0};
                              if (!campaignMessage.notification) campaignMessage.notification = {message: ''};
                              return {...campaignMessage};
                            })}
                            checkedIcon={<Check className={classes.checkedIcon}/>}
                            icon={<Check className={classes.uncheckedIcon}/>}
                            classes={{checked: classes.checked, root: classes.root}}
                          />
                        }
                        label="Modo Notificação"
                      />
                      <p
                        style={{fontSize: '14px', color: 'grey', clear: 'both', textAlign: 'justify', marginBottom: 0}}>
                        O modo notificação é utilizado para enviar mensagens simples, como textos, imagens, vídeos ou
                        áudios, sem promover interações diretas com o usuário. Ao responder à notificação, o usuário
                        iniciará um atendimento convencional.
                      </p>
                      {campaignMessage.type === 'notification' && (
                        <>
                          <CustomInput
                            labelText="Mensagem"
                            formControlProps={{fullWidth: true}}
                            inputProps={{
                              multiline: true,
                              rows: 10,
                              value: campaignMessage.notification.message,
                              onChange: ({target: {value}}) => setCampaignMessage(campaignMessage => {
                                campaignMessage.notification.message = value;
                                return {...campaignMessage};
                              })
                            }}
                          />
                          <div style={{display: 'flex'}}>
                            <Button color={'linkedin'} round justIcon onClick={() => attachFileRef.current.click()}>
                              <AttachFile/>
                            </Button>
                            {campaignMessage.notification.file && (
                              <>
                                <Tooltip
                                  title={'Excluir arquivo'}
                                  onClick={() => setCampaignMessage(campaignMessage => {
                                    delete campaignMessage.notification.file;
                                    return {...campaignMessage};
                                  })}
                                >
                                  <Button justIcon simple color={'primary'}>
                                    <Close/>
                                  </Button>
                                </Tooltip>
                                <div
                                  style={{
                                    alignContent: 'center',
                                    justifyContent: 'center',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    marginLeft: 10
                                  }}
                                >
                                  <span style={{width: '100%'}}>Tipo: {campaignMessage.notification.file.type}</span>
                                  <span style={{width: '100%'}}>Nome: {campaignMessage.notification.file.name}</span>
                                </div>
                              </>
                            )}
                          </div>
                          <input
                            type={'file'}
                            accept={'.jpg,.jpeg,.png,.mp4,.pdf,.mp3'}
                            style={{display: 'none'}}
                            ref={attachFileRef}
                            onChange={async e => {
                              try {
                                e.preventDefault();
                                let reader = new FileReader();
                                let file = e.target.files[0];
                                const {name, type: mimeType} = file;
                                const [type, extension] = mimeType.split('/');
                                const types = {
                                  jpg: 'image',
                                  jpeg: 'image',
                                  png: 'image',
                                  mp4: 'video',
                                  pdf: 'pdf',
                                  mp3: 'audio',
                                  mpeg: 'audio',
                                };

                                // TODO - Upload Arquivo

                                const fileBase64 = await new Promise(resolve => {
                                  reader.onloadend = () => resolve(reader.result);
                                  reader.readAsDataURL(file);
                                });

                                const {
                                  ok,
                                  id_arquivo_midia,
                                  fullFileName
                                } = await WebService(`/whatsapp_api/uploadMidia`, {
                                  base64: fileBase64.split('base64,')[1],
                                  nome_arquivo: name,
                                  mimetype: mimeType
                                }, "POST");

                                if (!ok) return Swal.fire({
                                  icon: "error",
                                  title: 'Não foi possível fazer upload do arquivo.'
                                });

                                const [uuid] = fullFileName.split('.');

                                console.log({
                                  url: `${apiURLWithoutVersion}/v1/sistema/getFileByUUID?uuid=${uuid}`,
                                  name,
                                  type: types[extension]
                                });
                                setCampaignMessage(campaignMessage => {
                                  campaignMessage.notification.file = {
                                    url: `${apiURLWithoutVersion}/v1/sistema/getFileByUUID?uuid=${uuid}`,
                                    name,
                                    type: types[extension]
                                  };
                                  return {...campaignMessage};
                                });
                              } finally {
                                // delete e.target.files;
                                // e.target.files = null;
                                // attachFileRef.current.files = [];
                              }
                            }}
                          />
                        </>
                      )}
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem lg={12} md={12} sm={12} xs={12}>
                      <FormControlLabel
                        className={classes.formCheck}
                        control={
                          <Checkbox
                            checked={campaignMessage.type === 'bot'}
                            tabIndex={-1}
                            onClick={() => setCampaignMessage(campaignMessage => {
                              campaignMessage.type = 'bot';
                              if (!campaignMessage.bot) campaignMessage.bot = {id: 0};
                              if (!campaignMessage.notification) campaignMessage.notification = {message: ''};
                              return {...campaignMessage};
                            })}
                            checkedIcon={<Check className={classes.checkedIcon}/>}
                            icon={<Check className={classes.uncheckedIcon}/>}
                            classes={{checked: classes.checked, root: classes.root}}
                          />
                        }
                        label="Modo BOT"
                      />
                      <p style={{fontSize: '14px', color: 'grey', clear: 'both', textAlign: 'justify'}}>
                        O modo BOT possibilita a criação de fluxos dinâmicos, os quais interagem automaticamente com o
                        usuário conforme o fluxo estabelecido. Ao desenvolver seu BOT, certifique-se de criar respostas
                        adequadas e claras, visando melhorar a experiência do usuário e promover uma comunicação
                        eficiente e envolvente.
                      </p>
                      {campaignMessage.type === 'bot' && (
                        <GridContainer>
                          <GridItem lg={8} md={8} sm={8} xs={8}>
                            <small>Escolha o BOT</small>
                            <Select
                              value={campaignMessage.bot.id || ''}
                              onChange={({target: {value}}) => {
                                setCampaignMessage(campaignMessage => {
                                  campaignMessage.bot.id = value;
                                  return {...campaignMessage};
                                })
                              }}
                              fullWidth
                            >
                              <MenuItem disabled value={''}>Selecione o BOT</MenuItem>
                              {bots.map((prop, key) => {
                                const {name, id} = prop;
                                return (
                                  <MenuItem key={key} value={id}>
                                    {name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </GridItem>
                          <GridItem lg={4} md={4} sm={4} xs={4}>
                            <Button
                              color={'primary'}
                              simple
                              onClick={async () => {
                                const {value} = await Swal.fire({
                                  icon: "question",
                                  title: 'Deseja criar um novo BOT?',
                                  text: 'Você será direcionado para outra janela, salve todas as configurações antes de prosseguir.',
                                  showCancelButton: true,
                                  cancelButtonText: 'Não',
                                  confirmButtonText: 'Sim'
                                });
                                if (!value) return;
                                history.push('/bot_novo');
                              }}
                            >
                              Criar novo BOT
                            </Button>
                          </GridItem>
                        </GridContainer>
                      )}
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
            {campaignMessage.type === 'notification' && (
              <GridItem lg={6} md={6} sm={4} xs={12}>
                <div
                  style={{
                    border: '10px solid #555',
                    width: 270,
                    height: 480,
                    marginTop: 35,
                    borderRadius: 20,
                    backgroundImage: 'url(https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png)',
                    backgroundSize: 'contain',
                    padding: 10,
                    overflow: 'auto'
                  }}
                >
                  <div
                    style={{
                      backgroundColor: '#FFF',
                      width: 'calc(100% - 20px)',
                      height: 'auto',
                      fontSize: 10,
                      fontFamily: 'Roboto',
                      color: '#000',
                      lineHeight: 'normal',
                      padding: 5,
                      borderRadius: 5,
                      boxShadow: '#000 0px 0px 10px -5px',
                      marginTop: 5,
                      marginBottom: 5
                    }}
                  >
                    {campaignMessage.notification.file && (
                      <>
                        {campaignMessage.notification.file.type === 'image' && (
                          <img
                            src={campaignMessage.notification.file.url}
                            alt={campaignMessage.notification.file.name}
                            style={{width: '100%', marginBottom: 10}}
                          />
                        )}
                        {campaignMessage.notification.file.type === 'video' && (
                          <video controls style={{width: '100%'}}>
                            <source src={campaignMessage.notification.file.url} type="video/mp4"/>
                          </video>
                        )}
                        {campaignMessage.notification.file.type === 'audio' && (
                          <audio controls style={{width: '100%', height: 30}}>
                            <source src={campaignMessage.notification.file.url} type="audio/mp3"/>
                          </audio>
                        )}
                        {campaignMessage.notification.file.type === 'pdf' && (
                          <div
                            style={{
                              width: '100%',
                              marginBottom: 10,
                              height: 30,
                              background: '#DDD',
                              borderRadius: 5,
                              display: 'flex'
                            }}
                          >
                            <FilePdf color={'#555'}/>
                            <span
                              style={{
                                fontSize: 14,
                                alignContent: 'center',
                                flexWrap: 'wrap',
                                display: 'flex'
                              }}
                            >
                              {campaignMessage.notification.file.name}
                            </span>
                          </div>
                        )}
                      </>
                    )}
                    {(campaignMessage.notification.message) && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: converterTextoWpp(campaignMessage.notification.message.trim(), true)
                        }}
                      />
                    )}
                  </div>
                </div>
              </GridItem>
            )}
          </GridContainer>
        )}
        {activeTab === 3 && (
          <GridContainer>
            <GridItem lg={12} md={12} sm={12} xs={12}>
              <br/>
              <Button color={'hust'} round onClick={saveConfig}>
                <Save/> Salvar
              </Button>
            </GridItem>
            <GridItem lg={6} md={6} sm={12} xs={12}>
              <Card>
                <CardHeader color="primary" icon>
                  <h4 className={classes.cardIconTitle}>Horários e dias de funcionamento das campanhas</h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    {['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map((day, keyDay) => {
                      const weekDay = campaign.config.days[day];

                      return (
                        <GridItem
                          key={day}
                          lg={6} md={6} sm={6} xs={12}
                          style={{
                            display: 'flex',
                            margin: '5px 0px',
                            alignItems: "center",
                            // backgroundColor: `${keyDay % 2 === 0 ? '#FFF' : '#EEE'}`
                          }}
                        >
                          <div
                            style={{
                              border: '1.5px solid #1A2437',
                              color: '#1A2437',
                              width: 30,
                              height: 30,
                              borderRadius: 5,
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                              fontSize: 10,
                              fontWeight: 600,
                              marginRight: 5
                            }}
                            className={classes.addWeek}
                            onClick={() => {
                              setCampaign(campaign => {
                                campaign.config.days[day].push({startTime: '08:00', endTime: '12:00'});
                                return {...campaign};
                              });
                            }}
                          >
                            {week[day]}
                          </div>
                          <div>
                            {weekDay.map((prop, keyHour) => {
                              return (
                                <div style={{marginTop: 2, marginBottom: 2, display: 'flex'}} key={keyHour}>
                                  <TextField
                                    label="Início:"
                                    type="time"
                                    value={prop.startTime}
                                    fullWidth
                                    onChange={({target: {value}}) => {
                                      setCampaign(campaign => {
                                        campaign.config.days[day][keyHour].startTime = value;
                                        return {...campaign};
                                      });
                                    }}
                                  />
                                  <TextField
                                    label="Final:"
                                    type="time"
                                    value={prop.endTime}
                                    fullWidth
                                    onChange={({target: {value}}) => {
                                      setCampaign(campaign => {
                                        campaign.config.days[day][keyHour].endTime = value;
                                        return {...campaign};
                                      });
                                    }}
                                  />
                                  <Button
                                    round
                                    color={'primary'}
                                    simple
                                    justIcon
                                    onClick={async () => {
                                      const {value} = await Swal.fire({
                                        icon: "question",
                                        title: 'Deseja realmente excluir o horário?',
                                        showCancelButton: true,
                                        cancelButtonText: 'Não',
                                        confirmButtonText: 'Sim'
                                      });
                                      if (!value) return;
                                      setCampaign(campaign => {
                                        delete campaign.config.days[day][keyHour];
                                        campaign.config.days[day] = campaign.config.days[day].filter(el => el);
                                        return {...campaign};
                                      });
                                    }}
                                  >
                                    <Close/>
                                  </Button>
                                </div>
                              );
                            })}
                          </div>
                        </GridItem>
                      );
                    })}
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem lg={3} md={3} sm={12} xs={12} style={{display: 'flex'}}>
              <Card>
                <CardHeader color="primary" icon>
                  <h4 className={classes.cardIconTitle}>Intervalo entre mensagens</h4>
                </CardHeader>
                <CardBody>
                  <TextField
                    label="Entre:"
                    type="time"
                    value={secondsToTime(campaign.config.intervalBetweenMessages.min)}
                    fullWidth
                    // inputProps={{
                    //   // step: 2,
                    //   pattern: '([0-5]?[0-9]$):([0-5][0-9]$)'
                    // }}
                    style={{
                      // minWidth: 350
                      cursor: 'default'
                    }}
                    onChange={({target: {value}}) => setCampaign(campaign => {
                      campaign.config.intervalBetweenMessages.min = timeToSeconds(value);
                      return {...campaign};
                    })}
                  />
                  <br/><br/>
                  <TextField
                    label="Até:"
                    type="time"
                    value={secondsToTime(campaign.config.intervalBetweenMessages.max)}
                    fullWidth
                    // style={{minWidth: 350}}
                    onChange={({target: {value}}) => setCampaign(campaign => {
                      campaign.config.intervalBetweenMessages.max = timeToSeconds(value);
                      return {...campaign};
                    })}
                  />
                </CardBody>
              </Card>
            </GridItem>
            <GridItem lg={3} md={3} sm={12} xs={12} style={{display: 'flex'}}>
              <Card>
                <CardHeader color="primary" icon>
                  <h4 className={classes.cardIconTitle}>Pausa entre disparos</h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem lg={12} md={12} sm={12} xs={12}>
                      <span className={classes.cardIconTitle}>Quantidade de envios a cada disparo:</span>
                    </GridItem>
                    <GridItem lg={6} md={6} sm={6} xs={6}>
                      <TextField
                        label="Entre:"
                        type="number"
                        value={campaign.config.pauseBetweenMessages.quantity.min}
                        fullWidth
                        // style={{minWidth: 350}}
                        onChange={({target: {value}}) => setCampaign(campaign => {
                          campaign.config.pauseBetweenMessages.quantity.min = value;
                          return {...campaign};
                        })}
                      />
                    </GridItem>
                    <GridItem lg={6} md={6} sm={6} xs={6}>
                      <TextField
                        label="Até:"
                        type="number"
                        value={campaign.config.pauseBetweenMessages.quantity.max}
                        fullWidth
                        // style={{minWidth: 350}}
                        onChange={({target: {value}}) => setCampaign(campaign => {
                          campaign.config.pauseBetweenMessages.quantity.max = value;
                          return {...campaign};
                        })}
                      />
                    </GridItem>
                    <GridItem lg={12} md={12} sm={12} xs={12} style={{marginTop: 20}}>
                      <span className={classes.cardIconTitle}>Tempo de pausa:</span>
                    </GridItem>
                    <GridItem lg={6} md={6} sm={6} xs={6}>
                      <TextField
                        label="Entre:"
                        type="time"
                        value={secondsToTime(campaign.config.pauseBetweenMessages.time.min)}
                        fullWidth
                        // style={{minWidth: 350}}
                        onChange={({target: {value}}) => setCampaign(campaign => {
                          campaign.config.pauseBetweenMessages.time.min = timeToSeconds(value);
                          return {...campaign};
                        })}
                      />
                    </GridItem>
                    <GridItem lg={6} md={6} sm={6} xs={6}>
                      <TextField
                        label="Até:"
                        type="time"
                        value={secondsToTime(campaign.config.pauseBetweenMessages.time.max)}
                        fullWidth
                        // style={{minWidth: 350}}
                        onChange={({target: {value}}) => setCampaign(campaign => {
                          campaign.config.pauseBetweenMessages.time.max = timeToSeconds(value);
                          return {...campaign};
                        })}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        )}
      </GridItem>
    </GridContainer>
  );
};

export default CampaignEdit;
