import React, { useCallback, useEffect, useState } from 'react';
import {makeStyles} from "@material-ui/core";
import {dangerColor, hexToRgb} from "../../assets/jss/material-dashboard-pro-react";
import Button from "../../components/CustomButtons/Button";
import {
  Archive,
  ChatNotification,
  Check,
  Folder,
  Globe,
  List2,
  Send,
  Sort, Tag,
  Ticket,
  Time,
  Share, Show
} from "../../assets/img/icons";
import Drawer from "@material-ui/core/Drawer";
import { AccountTree, Filter, SaveSharp, Tune } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import { useReactFlow, getNodePositionWithOrigin } from "reactflow";

const useStyles = makeStyles({
  nodeMenuOption: {
    height: 60,
    border: '2px solid #EBEEF2',
    marginTop: 5,
    marginBottom: 5,
    borderRadius: 14,
    paddingLeft: 10,
    paddingBottom: 10,
    display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    cursor: 'grab',
  },
  nodeEnd: {
    borderColor: '#B62C1A',
    backgroundColor: `rgba(${hexToRgb('#B62C1A')}, 0.05)`
  },
  nodeOptions: {
    borderColor: '#E49D22',
    backgroundColor: `rgba(${hexToRgb('#E49D22')}, 0.05)`
  },
  nodeStart: {
    borderColor: '#08365A',
    backgroundColor: `rgba(${hexToRgb('#08365A')}, 0.1)`
  },
  hoverEffect: {
    '&:hover': {
      background: '#AAA'
    }
  }
});

const BotElementCard = ({ classes, title, description, icon, onDragStart }) => {
  // const classes = useStyles();
  return (
    <div
      className={classes.nodeMenuOption}
      onDragStart={onDragStart}
      draggable
    >
      <div
        style={{
          // float: 'right',
          // right: -35,
          marginTop: 3,
          width: 50,
          height: 50,
          background: `linear-gradient(135deg, #FF3115 0%, #FC2E5D 100%)`,
          borderRadius: 14,
          alignContent: 'center',
          textAlign: "center",
          verticalAlign: 'center',
          position: "relative",
          marginRight: 10
        }}
      >
        <img
          src={icon}
          alt={''}
          style={{
            width: 25,
            height: 25,
            top: "50%",
            left: "50%",
            position: "absolute",
            transform: "translate(-50%,-50%)"
          }}
        />
      </div>
      <div style={{paddingTop: 7, display: 'block'}}>
        <span style={{ color: '#1A1926', fontSize: 15, fontStyle: 'normal', fontWeight: 400 }}>
            {title}
          </span>
        <br/>
        <span>
            {description}
          </span>
      </div>
    </div>
  );
};
const botTypeLabel = { WaitInDepartment: 'Departamento', Word: 'Palavra', Campaign: 'Campanha' };
export default ({ onSave, bots, nodes }) => {
  const classes = useStyles();
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    // event.dataTransfer.getData('')
    // event.dataTransfer.setData('option', {});
    // event.dataTransfer.
    event.dataTransfer.effectAllowed = 'move';
  };

  const [ currentView, setCurrentView ] = useState('');

  const { setViewport, getNode } = useReactFlow();

  const handleTransform = (idInternal) => {
    const { id } = nodes.find(el => el.data.id === idInternal);
    const node = getNode(id);
    const { position: { x, y } } = node;
    const zoom = 0.7;

    setViewport({
      x: Number(x.toFixed()) * (zoom * -1), // + 100
      y: Number(y.toFixed()) * (zoom * -1), // + 200
      zoom
    }, { duration: 800 });
  };

  return (
    <>
      <Drawer
        variant={"permanent"}
        // variant?: 'permanent' | 'persistent' | 'temporary';
        anchor={"right"}
        open={true}
        // classes={{paper: classes.drawerPaper}}
        PaperProps={{
          style: {
            width: 70,
            marginTop: 2,
            zIndex: 9999999
          }
        }}
        // onClose={() => ''}
        ModalProps={{ keepMounted: true }}
      >
        <div style={{ width: '100%', textAlign: 'center', marginTop: 30 }}>
          <Tooltip title={'Elementos'}>
            <Button
              round
              justIcon
              color={currentView === 'elements' ? 'primary' : 'github'}
              onClick={() => setCurrentView(currentView === 'elements' ? '' : 'elements')}
            >
              <Tune/>
            </Button>
          </Tooltip>
          <Tooltip title={'BOTs'}>
            <Button
              round
              justIcon
              color={currentView === 'list_bots' ? 'primary' : 'github'}
              onClick={() => setCurrentView(currentView === 'list_bots' ? '' : 'list_bots')}
            >
              <AccountTree/>
            </Button>
          </Tooltip>
          <Tooltip title={'Salvar'}>
            <Button
              color={'hust'}
              round
              onClick={() => onSave && onSave()}
              justIcon
              style={{ position: 'absolute', bottom: 15, left: 10 }}
            >
              <SaveSharp/>
            </Button>
          </Tooltip>
        </div>
      </Drawer>
      <Drawer
        variant={"persistent"}
        // variant?: 'permanent' | 'persistent' | 'temporary';
        anchor={"right"}
        open={currentView !== ''}
        // classes={{paper: classes.drawerPaper}}
        // style={{width: 70}}
        PaperProps={{
          style: {
            marginRight: 70,
            marginTop: 2,
            zIndex: 9999998
          }
        }}
        onClose={() => setCurrentView('')}
        ModalProps={{ keepMounted: true }}
      >
        {currentView === 'elements' && (
          <aside style={{ overflow: 'auto', minWidth: 300 }}>
            <h3>Elementos</h3>
            {[
              { title: 'Inicio do BOT', description: '', icon: Ticket, id: 'start' },
              { type: 'divider' },
              { title: 'Enviar Mensagem', description: 'Envia uma mensagem simples', icon: Send, id: 'sendMessage' },
              { title: 'Mídia', description: 'Envia arquivos (imagem, PDF etc..)', icon: Folder, id: 'media' },
              { title: 'Menu', description: 'Solicita a escolha de uma opção', icon: List2, id: 'switchOption' },
              { title: 'Consulta em API', description: 'Retorna valores para o usuário', icon: Globe, id: 'callAPI' },
              { title: 'Pausa', description: 'Pausa entre processamentos', icon: Time, id: 'timeout' },
              { title: 'Pergunta para Usuário', description: 'Aguarda a resposta para validar', icon: ChatNotification, id: 'userResponse' },
              { type: 'divider' },
              { title: 'Condicional', description: 'Realiza uma verificação de valor', icon: Share, id: 'conditional' },
              { title: 'Enviar Evento', description: 'Envia um evento para o contato', icon: Show, id: 'sendConnectionEvent' },
              { title: 'Adicionar TAG', description: 'Adiciona uma TAG ao protocolo', icon: Tag, id: 'createTAG' }, // Adicionar TAG
              // { title: '', description: '', icon: ChatNotification, id: 'userResponse' }, // Agendar mensagem
              { type: 'divider' },
              { title: 'Selecionar Departamento', description: 'Permite a escolha de depart.', icon: Archive, id: 'chooseDepartment' },
              { title: 'Transferir', description: 'Faz a transferência para um depart.', icon: Sort, id: 'transferCalled' },
              { title: 'Finalizar', description: 'Encerrar o atendimento', icon: Check, id: 'endCalled' },
            ].map(prop => (
              prop.type === 'divider' ? <hr/> : (
                <BotElementCard
                  title={prop.title}
                  description={prop.description}
                  icon={prop.icon}
                  classes={classes}
                  onDragStart={(event) => onDragStart(event, prop.id)}
                />
              )
            ))}
            {/*<div className={classes.nodeMenuOption + ' ' + classes.nodeOptions}
                 onDragStart={(event) => onDragStart(event, '')}>
              Agendar mensagem&nbsp;<small>Em breve</small>
            </div>*/}
          </aside>
        )}
        {currentView === 'list_bots' && (
          <div style={{ overflow: 'auto', minWidth: 300, padding: 10 }}>
            {bots.map(prop => (
              <div
                className={classes.hoverEffect}
                style={{
                  borderRadius: 14,
                  background: '#F5F7FA',
                  height: 60,
                  transition: '.3s',
                  // display: 'flex',
                  padding: 10,
                  margin: '10px 0',
                  cursor: 'pointer'
                }}
                onClick={() => handleTransform(prop.id)}
              >
                  <span
                    style={{
                      color: '#1A1926',
                      fontFamily: 'NunitoRegular',
                      fontSize: 14,
                      fontWeight: 400,
                      // marginTop: 10
                    }}
                  >
                    #{prop.id} - {prop.name}
                  </span>
                <br/>
                <span>
                    Tipo: {botTypeLabel[prop.config.callerType]}
                  </span>
              </div>
            ))}
          </div>
        )}
      </Drawer>
    </>
  );
};
