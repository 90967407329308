import React from "react";

import { Tooltip, IconButton } from "@material-ui/core";
import BlockIcon from '@material-ui/icons/Block';

import { customUseStyles } from "./styles";

function CustomToolbarSelect() {

  const classes = customUseStyles();

  return (
    <div className={classes.iconContainer}>
      <Tooltip title={"Deselect ALL"}>
        <IconButton className={classes.iconButton} >
          Item 1
          </IconButton>
      </Tooltip>
      <Tooltip title={"Item"}>
        <IconButton className={classes.iconButton} >
          Item 2
        </IconButton>
      </Tooltip>
      <Tooltip title={"Item"}>
        <IconButton className={classes.iconButton}>
          <BlockIcon className={classes.icon} />
        </IconButton>
      </Tooltip>
    </div>
  )
}

export default CustomToolbarSelect;