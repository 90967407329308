import React, {useEffect, useState} from 'react';
import {Card, CardBody, CardFooter, CardHeader, GridContainer, GridItem, Table} from "../../components";
import api from "../../components/api";
import {cardTitle, infoColor, primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import extendedTablesStyle from "../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";
import buttonStyle from "../../assets/jss/material-dashboard-pro-react/components/buttonStyle";
import dashboardStyle from "../../assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import {makeStyles, Tooltip} from "@material-ui/core";
import instagramIcon from '../../assets/img/social/instagram.png';
import telegramIcon from '../../assets/img/social/telegram.png';
import whatsappIcon from '../../assets/img/social/whatsapp.png';
import messengerIcon from '../../assets/img/social/messenger.png';
import illustrationNoConnection from '../../assets/img/Illustration-no-connection.png';
import Success from "../../components/Typography/Success";
import Danger from "../../components/Typography/Danger";
import Button from "../../components/CustomButtons/Button";
import {RotateLeft, SettingsApplications} from "@material-ui/icons";
import Settings from "@material-ui/icons/Settings";
import Swal from "sweetalert2";
import Code from "@material-ui/icons/Code";
import {pontuacaoNumero} from "../../components/Funcoes";
import CardIcon from "../../components/Card/CardIcon";
import Warning from "@material-ui/icons/Warning";
import Primary from "../../components/Typography/Primary";
import Info from "../../components/Typography/Info";
import {WebService} from "../../components/WebService";
import ModalConnectionQRCode from "../Modal/ConnectionQRCode";

const styles = {
  cardTitle,
  ...extendedTablesStyle,
  ...buttonStyle,
  ...dashboardStyle
};
const useStyles = makeStyles(styles);

const ListConnections = ({history}) => {
  const classes = useStyles();

  const [connections, setConnections] = useState([]);
  const [modalCreateConnection, setModalCreateConnection] = useState({open: false, QRCodeData: null});

  const getConnections = async () => {
    const {data} = await api.get('/connection');
    setConnections(data);
  };

  const rebootConnection = async uuid => {
    const {value} = await Swal.fire({
      icon: "question",
      title: 'Deseja realmente reiniciar a conexão?',
      text: 'Essa ação é usada para reestabelecer sua conta caso esteja com algum problema.',
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    });
    if (!value) return;

    const {ok} = await WebService(`/whatsapp_api/reiniciarSessaoWhatsAppM`, {uuid});
    if (ok) Swal.fire({icon: "success", title: 'Sua conexão foi reiniciada com sucesso!'});
  };

  let intervalQRCode;
  const createConnection = async type => {
    if (type !== 'whatsapp') {
      return Swal.fire({
        icon: "info",
        title: 'Novidades à Caminho!',
        text: 'Fique ligado! Em breve, integraremos Instagram e Telegram à nossa plataforma.'
      });
    }

    setModalCreateConnection({open: true, QRCodeData: null, status: 'configuring'});
    const {data: {uuid, updateFrequency}} = await api.post('/connection', {type: 'whatsapp'});
    setModalCreateConnection({open: true, QRCodeData: null, status: 'qrcode'});
    let lastQRCodeHash;
    intervalQRCode = setInterval(async () => {
      const configRequest = {params: {uuid, typeQR: 'data'}};
      const {data: {status, QRCodeData, QRCodehash, message}} = await api.get('/connection/status', configRequest);

      if (!['qrcode', 'configuring'].includes(status)) clearInterval(intervalQRCode);

      if (status === 'qrcode') {
        if (QRCodehash === lastQRCodeHash) return;
        lastQRCodeHash = QRCodehash;
        setModalCreateConnection({open: true, QRCodeData, status});
      }

      if (status === 'configuring') setModalCreateConnection({open: true, QRCodeData: null, status});
      if (status === 'connected') setModalCreateConnection({open: true, QRCodeData: null, status});
      if (status === 'error') setModalCreateConnection({open: true, QRCodeData: null, status});
    }, updateFrequency);
  };

  useEffect(() => {
    getConnections();
  }, []);

  return (
    <GridContainer>
      <ModalConnectionQRCode
        open={modalCreateConnection.open}
        QRCodeData={modalCreateConnection.QRCodeData}
        status={modalCreateConnection.status}
        onClose={() => {
          setModalCreateConnection({open: false, QRCodeData: null});
          clearInterval(intervalQRCode);
          getConnections();
        }}
      />
      <GridItem xs={12} sm={6} md={3} lg={3}>
        <Card>
          <CardHeader color="primary" icon>
            <p className={classes.cardCategory} style={{fontSize: '18px'}}>
              Conexões Ativas
            </p>
            <br></br>
            <div stats style={{display: 'flex', justifyContent: 'space-between'}}>
              <div>
                <center>
                  <h3 className={classes.cardTitle} style={{marginBottom: '-10px', color: primaryColor[0]}}>
                    <b>{pontuacaoNumero(connections.length)}</b>
                  </h3>
                </center>
              </div>
              <div>
                <small>
                  <Success>{connections.filter(el => el.active).length} Online</Success>
                  <Danger>{connections.filter(el => !el.active).length} Offline</Danger>
                </small>
              </div>
              <CardIcon color="primary" style={{width: '50px', height: '50px', float: 'right'}}>
                <Warning style={{margin: '0 auto'}}/>
              </CardIcon>
            </div>
          </CardHeader>
          <CardBody/>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={6} md={3} lg={3}>
        <Card>
          <CardHeader color="info" icon>
            <p className={classes.cardCategory} style={{fontSize: '18px'}}>
              Plataformas conectadas
            </p>
            <br></br>
            <div stats style={{display: 'flex', justifyContent: 'space-between'}}>
              <div>
                <center>
                  <h3 className={classes.cardTitle} style={{marginBottom: '-10px', color: infoColor[0]}}>
                    <b>{pontuacaoNumero(new Set(connections.map(prop => prop.type)).size)}</b>
                  </h3>
                </center>
              </div>
              <div>
                <small>
                  <Success>{connections.filter(el => el.type === 'whatsapp').length} Whatsapp</Success>
                  <Primary>{connections.filter(el => el.type === 'instagram').length} Instagram</Primary>
                  <Info>{connections.filter(el => el.type === 'telegram').length} Telegram</Info>
                  <Info>{connections.filter(el => el.type === 'telegram').length} Messenger</Info>
                  <Primary>{connections.filter(el => el.type === 'telegram').length} Email</Primary>
                </small>
              </div>
              <CardIcon color="info" style={{width: '50px', height: '50px', float: 'right'}}>
                <Warning style={{margin: '0 auto'}}/>
              </CardIcon>
            </div>
          </CardHeader>
          <CardBody/>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={12} lg={12}/>
      {[
        {type: 'whatsapp', name: 'Whatsapp', icon: whatsappIcon, columnIdentifierName: 'Número'},
        {type: 'instagram', name: 'Instagram', icon: instagramIcon, columnIdentifierName: 'Nome de Usuário'},
        {type: 'telegram', name: 'Telegram', icon: telegramIcon, columnIdentifierName: 'Identificador'},
        {type: 'messenger', name: 'Messenger', icon: messengerIcon, columnIdentifierName: 'Nome de Usuário'},
      ].map(prop => (
        <GridItem lg={6} md={6} sm={12} xs={12} key={prop.type}>
          <Card>
            <CardHeader
              className={`${classes.cardHeader} ${classes.textCenter}`}
              style={{display: 'flex', justifyContent: 'space-between'}}
            >
              <h4 className={classes.cardTitle} style={{fontWeight: 800, alignSelf: 'center'}}>{prop.name}</h4>
              <img
                src={prop.icon}
                style={{width: 30, height: 30}}
                alt={prop.type}
              />
            </CardHeader>
            <CardBody style={{maxHeight: 300, overflow: 'auto'}}>
              {connections.filter(el => el.type === prop.type).length ? (
                <Table
                  hover
                  tableHead={['Nome', prop.columnIdentifierName, 'Status', '']}
                  tableData={connections.filter(el => el.type === prop.type).map((prop, key) => {
                    return [
                      prop.name,
                      prop.identifier,
                      prop.active ? <Success>Ativo</Success> : <Danger>Inativo</Danger>,
                      (
                        <div key={prop.identifier}>
                          <Tooltip title={'Configurar conexão'}>
                            <Button
                              color={'github'} simple justIcon round
                              style={{margin: 0, width: 30, height: 30, padding: 0, minWidth: 30}}
                              onClick={() => history.push({
                                pathname: `/conexoes/configuracoes`,
                                state: {id_whatsapp_conexao: prop.id, key}
                              })}
                            >
                              <Settings/>
                            </Button>
                          </Tooltip>
                          {/*********************************************************************/}
                          <Tooltip title={'Reiniciar conexão'}>
                            <Button
                              justIcon round simple color={"success"}
                              onClick={rebootConnection.bind(this, prop.uuid)}
                              style={{margin: 0, width: 30, height: 30, padding: 0, minWidth: 30}}>
                              <RotateLeft/>
                            </Button>
                          </Tooltip>
                          {/*********************************************************************/}
                          <Tooltip title={'Token para integração'}>
                            <Button
                              justIcon round simple color={"info"}
                              onClick={() => Swal.fire({html: `<code>${prop.uuid}</code>`})}
                              style={{margin: 0, width: 30, height: 30, padding: 0, minWidth: 30}}
                            >
                              <Code/>
                            </Button>
                          </Tooltip>
                        </div>
                      )
                    ];
                  })}
                />
              ) : (
                <div style={{width: '100%', textAlign: 'center'}}>
                  <img src={illustrationNoConnection} alt={'no-connection'} style={{width: 250}}/>
                  <br/><br/>
                  <span style={{color: '#1A1926', fontFamily: 'NunitoRegular'}}>
                    Poxa, não há conexões com essa plataforma que tal iniciar<br/>uma agora mesmo? Clique em “nova conexão”.
                  </span>
                </div>
              )}
            </CardBody>
            <CardFooter style={{alignSelf: 'center'}}>
              <Button color={'hust'} round style={{height: 35}} onClick={createConnection.bind(this, prop.type)}>
                Nova Conexão
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      ))}
    </GridContainer>
  );
};

export default ListConnections