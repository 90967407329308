import React, {useEffect, useState} from "react";
import ChartistGraph from "react-chartist";
import {WebService} from "../components/WebService";
import Card from "../components/Card/Card";
import CardBody from "../components/Card/CardBody";
import CardHeader from "../components/Card/CardHeader";
import CardIcon from "../components/Card/CardIcon";
import Timeline from "@material-ui/icons/Timeline";
import {makeStyles} from "@material-ui/core";
import Styles from "../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import GridContainer from "../components/Grid/GridContainer";
import GridItem from "../components/Grid/GridItem";

const Chartist = require("chartist");

const delays = 80, durations = 500;

const chartAnimation = {
  draw: function (data) {
    if (data.type === "line" || data.type === "area") {
      data.element.animate({
        d: {
          begin: 600,
          dur: 700,
          from: data.path.clone().scale(1, 0).translate(0, data.chartRect.height()).stringify(),
          to: data.path.clone().stringify(),
          easing: Chartist.Svg.Easing.easeOutQuint
        }
      });
    } else if (data.type === "point") {
      data.element.animate({
        opacity: {begin: (data.index + 1) * delays, dur: durations, from: 0, to: 1, easing: "ease"}
      });
    }
  }
};

const useStyles = makeStyles(Styles);

export default function Dashboard() {
  const [ticketsPeriodo, setTicketsPeriodo] = useState({
    labels: new Array(31).fill(null).map((e, key) => key + 1),
    series: []
  });
  const [ticketsSemana, setTicketsSemana] = useState({
    labels: ['Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado', 'Domingo'],
    series: []
  });

  const getDashboard = async () => {
    const {ok, chamadosDias, chamadosSemana} = await WebService(`/dashboard/getDashboard`);
    if (ok) {
      // Organizando chamados mês
      const chamadosDiasTotal = new Array(31).fill(0);
      for (let i = 0; i < chamadosDias.length; i++) {
        const {dia, total} = chamadosDias[i];
        chamadosDiasTotal[dia - 1] = parseInt(total);
      }
      setTicketsPeriodo({...ticketsPeriodo, series: [chamadosDiasTotal]});

      // Organizando chamados dia semana
      const chamadosSemanaTotal = new Array(7).fill(0);
      for (let i = 0; i < chamadosSemana.length; i++) {
        const {dia, total} = chamadosSemana[i];
        chamadosSemanaTotal[dia] = parseInt(total);
      }
      setTicketsSemana({...ticketsSemana, series: [chamadosSemanaTotal]});

    }
  };
  useEffect(() => {
    getDashboard();
  }, []);

  const classes = useStyles();

  return (
    <GridContainer>
      <GridItem lg={12} md={12} sm={12} xs={12}>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <Timeline/>
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Tickets no período <small>- Todos os dispositivos</small>
            </h4>
          </CardHeader>
          <CardBody>
            <ChartistGraph
              data={ticketsPeriodo}
              type="Line"
              options={{
                axisY: {onlyInteger: true},
                axisX: {showGrid: false},
                showPoint: true,
                fullWidth: true,
                // showArea: true,
                lineSmooth: Chartist.Interpolation.none(),
                height: "300px"
              }}
              listener={chartAnimation}
            />
          </CardBody>
        </Card>
      </GridItem>
      <GridItem lg={12} md={12} sm={12} xs={12}>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <Timeline/>
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Tickets por dia <small>- Todos os dispositivos</small>
            </h4>
          </CardHeader>
          <CardBody>
            <ChartistGraph
              data={ticketsSemana}
              type="Line"
              options={{
                axisY: {onlyInteger: true},
                axisX: {showGrid: false},
                showPoint: true,
                fullWidth: true,
                showArea: true,
                lineSmooth: Chartist.Interpolation.none(),
                height: "300px"
              }}
              listener={chartAnimation}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
