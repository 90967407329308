import React, {useEffect, useState} from "react";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import BusinessIcon from '@material-ui/icons/Business';
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import ScheduleIcon from '@material-ui/icons/Schedule';
import MUIDataTable from "mui-datatables";
// import CustomToolbar from "../../Usuarios/Toolbar/CustomToolbar";


import CardFooter from 'components/Card/CardFooter.js';

import { List, ListItem, ListItemAvatar, ListItemText, Avatar, IconButton, Chip } from '@material-ui/core';
import { Delete, Edit, Chat, PeopleAlt } from '@material-ui/icons';

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

const useStyles = makeStyles(styles);

export default function Assunto_Chamado(){
    const classes = useStyles();

    const getMuiTheme = () => createMuiTheme({
        overrides: {
          MUIDataTable: {
            root: {
              marging: 0,
              border: 0,
              boxShadow: 'none'
            },
            paper: {
              margin: 0,
              boxShadow: "none",
            }
          },
          MUIDataTableBodyCell: {
            root: {
              padding: 0,
            }
          }
        }
      })
    
      const columns = [
        {
          name: 'assunto',
          label: "Assunto",
          filter: false,
          options: {
            customBodyRender: (assunto) => {
              return (
                <List dense className={classes.root}>
                  <ListItem>
                    <ListItemText primary={assunto.name} />
                  </ListItem>
                </List>
              );
            }
          },
        },
        {
          name: 'assunto.name',
          label: 'Nome',
          options: {
            display: 'excluded',
            filter: true
          }
        },
        {
          name: "actions",
          label: "Ações",
          options: {
            filter: false,
            download: false,
            empty: true,
            customBodyRender: (assunto) => {
              return (
                <>
                  <IconButton color="link" title="Conversa">
                    <Chat fontSize="small" />
                  </IconButton>
                </>
              );
            }
          },
        }
      ];
    
      const data = [
        { assunto: { id: 1, name: 'Suporte'}, actions: '' }
      ];
    
      const options = {
        filter: true,
        selectableRows: 'multiple',
        filterType: "dropdown",
        responsive: "scrollFullHeight",
        rowsPerPage: 10,
        // customToolbar: () => {
        //   return (
        //     <CustomToolbar />
        //   );
        // }
      };

    return (
        <GridContainer>
            <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <BusinessIcon />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>Assunto dos chamados</h3>
            </CardHeader>

            <CardBody style={{margin: 0}}>
                <MuiThemeProvider theme={getMuiTheme()}>
                <MUIDataTable data={data} columns={columns} options={options} />
                </MuiThemeProvider>
            </CardBody>
            </Card>
        </GridContainer>
        
    );
}