import React, { memo, useEffect, useState } from 'react';
import { Handle, Position } from 'reactflow';
import { Card, CardBody } from "../../../components";
import { ChatNotification, List2 } from "../../../assets/img/icons";
import Header from "./components/Header";
import Select from "./components/Input/Select";
import TextBox from "./components/Input/TextBox";
import Checkbox from "./components/Input/Checkbox";
import {hexToRgb, infoColor} from "../../../assets/jss/material-dashboard-pro-react";

function userResponse({ id, data, selected }) {
  const [ variable, setVariable ] = useState(data.variable || '');
  // const [responseType, setResponseType] = useState(data.responseType || '');
  const [ responseType, setResponseType ] = useState('alphanumeric');
  const [ message, setMessage ] = useState(data.message || '');
  const [ messageInvalidOption, setMessageInvalidOption ] = useState(data.messageInvalidOption || '');

  const [ flagLimitResponseTime, setFlagLimitResponseTime ] = useState(data.flagLimitResponseTime || false);
  const [ timeResponse, setTimeResponse ] = useState(data.timeResponse || '10:00');
  const [ repeatQuantity, setRepeatQuantity ] = useState(data.repeatQuantity || 1);

  useEffect(() => {
    data.variable = variable;
    data.responseType = responseType;
    data.message = message;
    data.messageInvalidOption = messageInvalidOption;
    data.flagLimitResponseTime = flagLimitResponseTime;
    data.timeResponse = timeResponse;
    data.repeatQuantity = repeatQuantity;
  }, [ variable, responseType, message, messageInvalidOption, flagLimitResponseTime, timeResponse, repeatQuantity ]);

  return (
    <Card
      style={{
        margin: 0,
        width: 420,
        borderRadius: 14,
        boxShadow: selected ? '0px 50px 50px 0px rgba(4, 15, 21, 0.15), 0px 40px 30px -30px rgba(4, 15, 21, 0.15)' : '#08365A 0px 0px 20px -10px',
        padding: 5
      }}
    >
      <Header title={'Pergunta para Usuário'} subtitle={'Aguarda resposta para validar'} icon={ChatNotification} id={id}/>
      <CardBody style={{padding: 5}}>
        {/*<Select label={'Tipo de resposta'}/>*/}
        <TextBox label={'Nome de Armazenamento'} value={variable} onChange={setVariable}/>
        <TextBox label={'Pergunta para usuário'} value={message} rows={5} onChange={setMessage}/>
        <TextBox label={'Mensagem para opção inválida'} rows={5} value={messageInvalidOption} onChange={setMessageInvalidOption}/>
        <div style={{position: 'relative'}}>
          <Checkbox label={'Limitar tempo de resposta'} checked={flagLimitResponseTime} onClick={() => setFlagLimitResponseTime(!flagLimitResponseTime)}/>
            {flagLimitResponseTime ? (
            <span
              style={{
                alignContent: 'center',
                right: 5,
                position: 'absolute',
                top: 5,
                background: `rgba(${hexToRgb(infoColor[0])}, 0.3)`,
                padding: '0 5px',
                borderRadius: 10,
                color: infoColor[0],
                fontWeight: 600
              }}
            >
              Caso não tenha resposta
            </span>
            ) : ''}
        </div>
          {flagLimitResponseTime ? (
            <>
              <TextBox label={'Nome de Armazenamento'} type={'time'} value={timeResponse} onChange={setTimeResponse}/>
              <Select label={'Repetir pergunta antes do limite atigido'} value={repeatQuantity} onChange={setRepeatQuantity} options={new Array(10).fill(null).map((_, key) => ({name: `${key + 1}x`, value: key + 1}))}/>
            </>
          ): ''}
      </CardBody>
      <Handle type="target" position={Position.Left} style={{ marginLeft: -3, background: '#E94D3F', width: 16, border: '3px solid #FFFFFF', height: 16 }}/>
      <Handle type="source" id={id} position={Position.Right} style={{ marginRight: -3, background: '#FFFFFF', width: 16, border: '2px solid #E94D3F', height: 16 }}/>
      {flagLimitResponseTime ? (
          <Handle type="source" id={`handle_escape_${id}`} position={Position.Right} style={{ marginRight: -3, background: '#FFFFFF', width: 16, border: '2px solid #E94D3F', height: 16, bottom: 'auto', top: 455 }}/>
      ) : ''}
    </Card>
  );
}

export default memo(userResponse);
