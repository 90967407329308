import React, {useEffect, useState} from 'react';
import {Backdrop, Fade, FormControlLabel, makeStyles, Modal} from "@material-ui/core";
import {useStyles} from "../NewChat/TabsInfo/styles";
import Button from '../../components/CustomButtons/Button';
import {dangerColor, primaryColor, warningColor} from "../../assets/jss/material-dashboard-pro-react";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import styles from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import Swal from "sweetalert2";
import {WebService} from "../../components/WebService";
import GridItem from "../../components/Grid/GridItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import GridContainer from "../../components/Grid/GridContainer";
import {SpinnerIcon} from "react-modal-image/lib/icons";

const useStyles1 = makeStyles(styles);
const ModalBulkActionsReportCalleds = ({open, onClose, calleds, users, departments, connections}) => {
  const classes = {...useStyles(), ...useStyles1()};

  const [bulkType, setBulkType] = useState('finish');
  const [progress, setProgress] = useState({loading: false, percent: 0, message: ''});

  const [department, setDepartment] = useState('');
  const [connection, setConnection] = useState('');
  const [user, setUser] = useState('');

  const bulkActions = async () => {
    if (bulkType === 'transfer' && !department) {
      return Swal.fire({icon: "info", title: 'É necessário selecionar um departaento para realizar essa ação.'});
    }

    const {value} = await Swal.fire({
      icon: "warning",
      title: 'Deseja realmente executar essa ação?',
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    });
    if (!value) return;

    const titles = {finish: 'Finaliza', transfer: 'Transferi', transferConnection: 'Transferi'};

    for (let i = 0; i < calleds.length; i += 1) {
      const {id_chamado, status} = calleds[i];
      if (status === 'atendimento_finalizado') continue;

      setProgress(p => {
        p.loading = true;
        p.percent = parseFloat(((i / calleds.length) * 100).toFixed(0));
        p.message = `${titles[bulkType]}ndo chamado ${id_chamado}`;
        return {...p};
      });

      if (bulkType === 'finish') {
        await WebService('/chamado/finalizarChamado', {id_chamado, flag_silencioso: true});
      }

      if (bulkType === 'transfer') {
        const dataPost = {id_chamado, id_departamento: department, id_usuario: user};
        await WebService('/chamado/transferirAtendimento', dataPost, 'POST');
      }

      if (bulkType === 'transferConnection') {
        const dataPost = {id_chamado, id_whatsapp_conexao: connection};
        await WebService('/chamado/transferirConexao', dataPost, 'POST');
      }
    }
    await Swal.fire({icon: "success", title: `Atendimentos ${titles[bulkType]}dos com sucesso!`});
    onClose();
  };

  useEffect(() => {
    setProgress({loading: false, percent: 0, message: ''});
    setDepartment('');
    setConnection('');
    setBulkType('finish');
  }, [open]);

  useEffect(() => {
    setUser('');
  }, [department]);

  useEffect(() => {
    setDepartment('');
    setUser('');
  }, [bulkType]);

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={() => !progress.loading && onClose()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{timeout: 200}}
    >
      <Fade in={open}>
        <div className={classes.paperModal} style={{border: 'none', minWidth: 600}}>
          <h4 style={{fontFamily: 'NunitoRegular', fontSize: 30, marginBottom: 0, color: primaryColor[0]}}>
            Ações em massa
          </h4>
          <hr style={{
            borderBottom: `4px solid ${primaryColor[0]}`,
            width: '40%',
            float: 'left',
            marginTop: 0,
            marginBottom: 0
          }}/>
          <br/>
          <span style={{fontFamily: 'NunitoRegular', color: warningColor[0], fontWeight: '800'}}>
            * Selecione o filtro que deseja executar a ação e clique em buscar.<br/>
            * Verifique com bastante atenção os filtros de busca no relatório.<br/>
            * Após a ação, ela não poderá ser desfeita.<br/>
          </span>
          <br/>
          {progress.loading ? (
            <div style={{width: '100%', textAlign: "center"}}>
              <h3>Carregando {progress.percent}%</h3>
              <span><strong>{progress.message}</strong></span>
            </div>
          ) : (
            <div style={{position: 'relative'}}>
              <FormControlLabel
                className={classes.formCheck}
                control={
                  <Checkbox
                    checked={bulkType === 'finish'}
                    tabIndex={-1}
                    onClick={() => setBulkType('finish')}
                    checkedIcon={<Check className={classes.checkedIcon}/>}
                    icon={<Check className={classes.uncheckedIcon}/>}
                    classes={{checked: classes.checked, root: classes.root}}
                  />
                }
                label="Finalizar atendimentos"
              />
              <br/>
              <FormControlLabel
                className={classes.formCheck}
                control={
                  <Checkbox
                    checked={bulkType === 'transfer'}
                    tabIndex={-1}
                    onClick={() => setBulkType('transfer')}
                    checkedIcon={<Check className={classes.checkedIcon}/>}
                    icon={<Check className={classes.uncheckedIcon}/>}
                    classes={{checked: classes.checked, root: classes.root}}
                  />
                }
                label="Transferir atendimentos"
              />
              <br/>
              <FormControlLabel
                className={classes.formCheck}
                control={
                  <Checkbox
                    checked={bulkType === 'transferConnection'}
                    tabIndex={-1}
                    onClick={() => setBulkType('transferConnection')}
                    checkedIcon={<Check className={classes.checkedIcon}/>}
                    icon={<Check className={classes.uncheckedIcon}/>}
                    classes={{checked: classes.checked, root: classes.root}}
                  />
                }
                label="Transferir conexão"
              />
              {bulkType === 'transfer' && (
                <GridContainer>
                  <GridItem lg={12} md={12} sm={12} xs={12}>
                    <FormControl className={classes.formControl} fullWidth>
                      <InputLabel>Departamento</InputLabel>
                      <Select
                        value={department}
                        onChange={({target: {value}}) => setDepartment(value)}
                        style={{textAlign: 'left'}}
                      >
                        {(departments || []).map(prop => {
                          const {nome, id_departamento} = prop;
                          return (<MenuItem value={id_departamento} key={`dpt_${id_departamento}`}>{nome}</MenuItem>);
                        })}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <p>&nbsp;</p>
                  {!!department && (
                    <GridItem lg={12} md={12} sm={12} xs={12}>
                      <FormControl className={classes.formControl} fullWidth>
                        <InputLabel>Usuário</InputLabel>
                        <Select
                          value={user}
                          onChange={({target: {value}}) => setUser(value)}
                          style={{textAlign: 'left'}}
                        >
                          {(users || []).filter(el => el.id_departamento_fk && !el.me && el.id_departamento_fk.split(",").map(prop => parseInt(prop)).indexOf(department) > -1).map(prop => {
                            const {nome, id_usuario} = prop;
                            const sort = [...users].sort((a, b) => a.nome.localeCompare(b.nome));

                            return (<MenuItem value={id_usuario} key={`usr_${id_usuario}`}>{nome}</MenuItem>);
                          })}
                        </Select>
                      </FormControl>
                    </GridItem>
                  )}
                </GridContainer>
              )}
              {bulkType === 'transferConnection' && (
                <GridContainer>
                  <GridItem lg={12} md={12} sm={12} xs={12}>
                    <FormControl className={classes.formControl} fullWidth>
                      <InputLabel>Conexão</InputLabel>
                      <Select
                        value={connection}
                        onChange={({target: {value}}) => setConnection(value)}
                        style={{textAlign: 'left'}}
                      >
                        <MenuItem value={''} disabled={true}>
                          Selecione uma conexão
                        </MenuItem>
                        {(connections || []).map(prop => {
                          const {conta_nome, conta_numero, id_whatsapp_conexao, flag_ativo} = prop;
                          return (
                            <MenuItem
                              value={id_whatsapp_conexao}
                              key={`conn_${id_whatsapp_conexao}`}
                              disabled={!flag_ativo}
                              style={{background: !flag_ativo ? dangerColor[0] : undefined}}
                            >
                              {conta_nome}&nbsp;-&nbsp;<small>{conta_numero}</small>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridContainer>
              )}
            </div>
          )}
          <div
            style={{
              marginTop: 30,
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Button style={{borderRadius: '24px 24px 24px 0px'}} onClick={onClose} disabled={progress.loading}>
              Cancelar
            </Button>
            <Button
              color={'hust'}
              round
              onClick={() => bulkActions()}
              disabled={progress.loading}
            >
              {progress.loading ? <SpinnerIcon class/> : 'Executar'}
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default ModalBulkActionsReportCalleds;