import React from "react";
import {Switch, Route, Redirect} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import Footer from "components/Footer/Footer.js";
import routes from "routes.js";
import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";
import register from "assets/img/register.jpeg";
import login from "assets/img/login.jpeg";
import lock from "assets/img/lock.jpeg";
import error from "assets/img/clint-mckoy.jpg";
import pricing from "assets/img/bg-pricing.jpeg";
import AuthNavbar from "../components/Navbars/AuthNavbar";
import {LoadRouterProps} from "../components/WebService";

const useStyles = makeStyles(styles);

export default function Pages(props) {

  const wrapper = React.createRef();
  const classes = useStyles();
  React.useEffect(() => {
    document.body.style.overflow = "unset";
    // Specify how to clean up after this effect:
    return function cleanup() {
    };
  });

  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse)
        return getRoutes(prop.views);

      return (prop.layout === "/auth") ?
        <LoadRouterProps path={prop.path} component={prop.component} key={key} setToken={props.setToken} assets={props.assets}/> : null;
    });
  };

  const getBgImage = () => {
    if (window.location.pathname.indexOf("/cadastro") !== -1) {
      return register;
    } else if (window.location.pathname.indexOf("/login") !== -1) {
      return props['assets']['fundoLogin'];
    } else if (window.location.pathname.indexOf("/pricing") !== -1) {
      return pricing;
    } else if (
      window.location.pathname.indexOf("/lock-screen") !== -1
    ) {
      return lock;
    } else if (window.location.pathname.indexOf("/error") !== -1) {
      return error;
    }
  };

  const getActiveRoute = routes => {
    let activeRoute = props['assets']['nomeAplicacao'];
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  return (
    <div>
      {/*TODO - Quando tiver a opção de cadastrar vai ser nessa navbar*/}
      {/*<AuthNavbar brandText={getActiveRoute(routes)} {...props} />*/}
      <div className={classes.wrapper} ref={wrapper}>
        <div
          className={classes.fullPage}
          id={'auth-background-main-div'}
          style={!window.isNativeApp ? { backgroundImage: "url(" + getBgImage() + ")" } : { background: '#FFFFFF' }}
        >
          <Switch>
            {getRoutes(routes)}
            <Redirect from="/" to="/login"/>
          </Switch>
          <Footer white assets={props.assets}/>
        </div>
      </div>
    </div>
  );
}
