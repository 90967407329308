import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Switch, Redirect } from "react-router-dom";
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0";
import "react-chat-elements/dist/main.css";
import { ls } from "./components/Funcoes";
import * as serviceWorker from "./serviceWorker";
import { LoadRouterProps, WebService } from "./components/WebService";
import { create } from "jss";
import localRefs from "jss-local-refs";
import { StylesProvider, jssPreset, ThemeProvider } from "@material-ui/styles";
import RingLoader from "react-spinners/RingLoader";
import {
  infoColor,
  primaryColor,
} from "./assets/jss/material-dashboard-pro-react";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateMomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/pt-br";
import { createTheme } from "@material-ui/core";
import api from "./components/api";

const hist = createBrowserHistory();
const jss = create({ plugins: [...jssPreset().plugins] });
jss.use(localRefs());

const theme = createTheme({
  palette: {
    primary: {
      // light: '#757ce8',
      light: primaryColor[7],
      main: "#942516",
      dark: primaryColor[11],
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
});

moment.locale("pt-br");

const App = () => {
  const [token, setToken] = useState(ls("token"));
  const [loading, setLoading] = useState(true);
  const [usuario, setUsuario] = useState(null);
  const [assets, setAssets] = useState(true);
  const [gestor, setGestor] = useState(
      !!JSON.parse(ls("usuario_gestor") || false)
  );
  const [revenda, setRevenda] = useState(
      !!JSON.parse(ls("cliente_revenda") || false)
  );

  useEffect(() => {
    ReactGA.initialize("UA-167986910-1");
    ReactGA.pageview(window.location.pathname + window.location.search);

    ReactPixel.init("173849165668680");
    ReactPixel.pageView();
  }, []);

  const removeBrandings = () => {
    try {
      // TinyMCE
      document.getElementsByClassName('tox-statusbar__branding')[0].innerHTML = '';
    } catch (e) {
      // *
    }
  };
  useEffect(() => {
    setInterval(removeBrandings, 500);
  }, []);

  const getUserData = async (token) => {
    if (token) {
      const { ok, usuario } = await WebService(`/usuario/getUsuarioID`);
      if (ok) {
        setUsuario(usuario);
        // const {
        //   nome,
        //   email,
        //   telefone,
        //   sexo,
        //   id_usuario,
        //   flag_gestor,
        //   id_departamento_fk,
        // } = usuario;
        // window.Conpass.init({
        //   name: nome,
        //   email,
        //   custom_fields: {id_usuario, gestor: !!flag_gestor, telefone, sexo, departamentos: id_departamento_fk}
        // });
      }
    }
  };

  const getAPIAccess = async (token) => {
    // const {data} = await axios.get(`https://apiv1.hustapp.com/v1/sistema/getHostAccess?token=${token}`);
    // const {host_api} = data;
    // if (host_api) {
    //   const localHostAPI = localStorage.getItem(`host_api`);
    //   if (host_api !== localHostAPI) {
    //     localStorage.setItem(`host_api`, host_api);
    //     window.location.reload();
    //   }
    // }
  };

  useEffect(() => {
    localStorage.setItem("token", token.toString() || "");
    setGestor(!!JSON.parse(ls("usuario_gestor") || false));
    setRevenda(!!JSON.parse(ls("cliente_revenda") || false));
    getUserData(token);
    // **************************************************************************************
    getAPIAccess(token);
    // **************************************************************************************
  }, [token]);

  const getAssetsURL = async () => {
    const url = directParams['domain'] || localStorage.getItem(`host_api`) || window.location.hostname;
    const { data } = await api.get("/application/theme", { params: { url } });

    const {
      nameApp,
      color,
      imageLoginBackground,
      imageLogoIcon,
      imageLogoName,
      imageLoginLogo,
      imageMenuBackground,
    } = data;
    setAssets({
      fundoLogin: imageLoginBackground,
      login: imageLoginLogo,
      fundoMenu: imageMenuBackground,
      logo: imageLogoName,
      nomeLogo: imageLogoIcon,
      nomeAplicacao: nameApp,
      color,
    });

    if (color) {
      const oldColor = localStorage.getItem("@theme-color");
      localStorage.setItem("@theme-color", color);
      if (oldColor !== color) window.location.reload();
    }

    window.document.title = nameApp;
    document.getElementById("favicon").href = imageLogoName;
    setLoading(false);
  };

  useEffect(() => {
    getAssetsURL();
  }, []);

  if (loading) {
    return (
      <div style={{ width: "100%", height: "100vh" }}>
        <RingLoader
          size={300}
          color={primaryColor[0]}
          loading={true}
          css={`
            position: absolute;
            top: calc(50% - 150px);
            left: calc(50% - 150px);
          `}
        />
      </div>
    );
  }

  return (
    <StylesProvider jss={jss}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateMomentUtils}>
          <Router history={hist}>
            <Switch>
              <LoadRouterProps
                path="/"
                component={token ? AdminLayout : AuthLayout}
                setToken={setToken}
                gestor={token ? gestor : null}
                revenda={token ? revenda : null}
                assets={assets}
                usuario={usuario}
              />
              <Redirect from="/" to={token ? "/dashboard" : "/login"} />
            </Switch>
          </Router>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </StylesProvider>
  );
};

const directParams = {};
((window.location.search || "").split("?")[1] || "")
  .split("&")
  .forEach((prop) => {
    const [key, value] = prop.split("=");
    directParams[key] = value;
  });

if (directParams["autologin"]) {
  try {
    const { usuario_gestor, token, cliente_revenda } = JSON.parse(
      atob(directParams["autologin"])
    );
    localStorage.setItem("token", token);
    localStorage.setItem("usuario_gestor", usuario_gestor);
    localStorage.setItem("cliente_revenda", cliente_revenda);
    window.location.href = "/";
    // window.location.reload();
  } catch (e) {}
}

ReactDOM.render(<App />, document.getElementById("root"));

if (window.location.hostname === "localhost")
  window.location.hostname = "127.0.0.1";

// const HustRedirect = localStorage.getItem(`@hust-direct-redirect`);
// if (HustRedirect) window.location.href = `https://app.hust.chat`;


if (window.isNativeApp) {
  serviceWorker.register({
    onUpdate: registration => {
      const waitingServiceWorker = registration.waiting;
      if (waitingServiceWorker) {
        waitingServiceWorker.addEventListener("statechange", event => {
          if (event.target.state === "activated") {
            Swal.fire({
              icon: "info",
              title: 'Uma nova versão do sistema está disponível.',
              text: 'A página será atualizada.'
            }).then(() => window.location.reload());
          }
        });
        waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
      }
    }
  });
} else {
  serviceWorker.unregister();
}
