import React, { memo, useEffect, useState } from 'react';
import { Handle, Position } from 'reactflow';
import { Card, CardBody } from "../../../components";
import RemoveNode from "../components/RemoveNode";
import Header from "./components/Header";
import {Share, Time} from "../../../assets/img/icons";
import TextBox from "./components/Input/TextBox";
import Select from "./components/Input/Select";
import {dangerColor, hexToRgb, infoColor, successColor} from "../../../assets/jss/material-dashboard-pro-react";

function Conditional({ id, data, selected }) {
  const [ value1, setValue1 ] = useState(data.value1 || '');
  const [ value2, setValue2 ] = useState(data.value2 || '');
  const [ operator, setOperator ] = useState(data.operator || '==');

  useEffect(() => {
    if (!data.operator) {
      setOperator('==');
      data.operator = '==';
    }
  }, []);

  useEffect(() => {
    data.value1 = value1;
    data.value2 = value2;
    data.operator = operator;
  }, [ value1, value2, operator ]);

  return (
    <Card
      style={{
        margin: 0,
        width: 400,
        borderRadius: 14,
        boxShadow: selected ? '0px 50px 50px 0px rgba(4, 15, 21, 0.15), 0px 40px 30px -30px rgba(4, 15, 21, 0.15)' : '#08365A 0px 0px 20px -10px',
        padding: 5
      }}
    >
      <Header title={'Condicional'} subtitle={'Realiza uma verificação de valor'} icon={Share} id={id} />
        <br/><br/>
        <CardBody style={{padding: 5, display: 'flex'}}>
        <span
            style={{
                alignContent: 'center',
                right: 5,
                position: 'absolute',
                top: -50,
                background: `rgba(${hexToRgb(successColor[0])}, 0.3)`,
                padding: '0 5px',
                borderRadius: 10,
                color: successColor[0],
                fontWeight: 600
            }}
        >
          Verdadeiro
        </span>
        <span
            style={{
                alignContent: 'center',
                right: 5,
                position: 'absolute',
                top: -15,
                background: `rgba(${hexToRgb(dangerColor[0])}, 0.3)`,
                padding: '0 5px',
                borderRadius: 10,
                color: dangerColor[0],
                fontWeight: 600
            }}
        >
          Falso
        </span>
            <TextBox label={'Valor 1'} value={value1} onChange={setValue1}/>
            <Select label={'Operador'} value={operator} onChange={setOperator} options={[
                {name: 'Igual', value: '=='},
                {name: 'Diferente', value: '!='},
                {name: 'Contém', value: 'includes'},
                {name: 'Maior', value: '>'},
                {name: 'Maior ou Igual', value: '>='},
                {name: 'Menor', value: '<'},
                {name: 'Menor ou Igual', value: '<='},
            ]}/>
            <TextBox label={'Valor 2'} value={value2} onChange={setValue2}/>
        </CardBody>

        <Handle
          type="target"
          position={Position.Left}
          style={{marginLeft: -3, background: '#E94D3F', width: 16, border: '3px solid #FFFFFF', height: 16}}
        />
        <Handle
          type="source"
          position={Position.Right}
          style={{ marginRight: -3, background: '#FFFFFF', width: 16, border: '2px solid #E94D3F', height: 16, bottom: 'auto', top: 65 }}
          id={`${id}_target_true`}
        />
        <Handle
          type="source"
          position={Position.Right}
          style={{ marginRight: -3, background: '#FFFFFF', width: 16, border: '2px solid #E94D3F', height: 16, bottom: 'auto', top: 102 }}
          id={`${id}_target_false`}
        />
    </Card>
  );
}

export default memo(Conditional);
