import React, { useEffect, useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import Card from "../components/Card/Card";
import CardHeader from "../components/Card/CardHeader";
import CardIcon from "../components/Card/CardIcon";
import CardBody from "../components/Card/CardBody";
import { makeStyles } from "@material-ui/core";
import Button from "../components/CustomButtons/Button";
import styles from "../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { WebService } from "../components/WebService";
import Swal from "sweetalert2";
import TextField from "@material-ui/core/TextField";
import GridContainer from "../components/Grid/GridContainer";
import GridItem from "../components/Grid/GridItem";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from '@material-ui/core/Typography';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { nomeProprio } from "../components/Funcoes";
import { AsYouType, parsePhoneNumberFromString } from 'libphonenumber-js';
import Warning from "../components/Typography/Warning";
import Success from "../components/Typography/Success";
import Danger from "../components/Typography/Danger";
import { Link } from 'react-router-dom';
import Switch from "@material-ui/core/Switch";

const useStyles = makeStyles(styles);

export default function Configuracoes({location}) {
  const classes = useStyles();

  const [ conecta, setConecta ] = useState("");
  const [ block, setBlock ] = React.useState(false);
  const [ exclui, setExclui ] = React.useState(false);
  const [ statuss, setStatuss ] = useState("");
  const [ ddd, setDdd ] = useState("");
  const [ departamentos, setDepartamentos ] = useState([]);
  const [ usuarios, setUsuarios ] = useState([]);
  const [ idDepartamentoPadrao, setIdDepartamentoPadrao ] = useState("");
  const [ idUsuarioPadrao, setIdUsuarioPadrao ] = useState("");
  const [ mensagemSaudacao, setMensagemSaudacao ] = useState('');

  const { id_whatsapp_conexao } = location.state;
  const { key } = location.state;

  const tipo = 'whatsapp';
  const tipoConta = { whatsapp: 'success', telegram: 'info' };

  const removerNome = async () => {
    const params = { id_whatsapp_conexao, config_mostrar_nome_atendente: exclui };
    const { ok } = await WebService(`/whatsapp_api/removeNomeAtendente`, params, "POST");

    if (ok) await Swal.fire({ icon: "success", title: "Informações salvas com sucesso!" });
  };

  const salvarHorario = async () => {
    if (block && !mensagemSaudacao)
      return Swal.fire({ icon: "warning", title: 'É necessário digitar a mensagem de saudação quando habilitado' });

    const params = {
      id_whatsapp_conexao,
      config_ddd_padrao: ddd,
      config_id_departamento_fk_padrao: idDepartamentoPadrao,
      config_id_usuario_fk_padrao: idUsuarioPadrao,
      config_mostrar_somente_saudacao: block,
      mensagem_saudacao: (mensagemSaudacao || '').trim()
    };
    const { ok } = await WebService(`/whatsapp_api/alterarHorarioEnvio`, params, "POST");

    if (ok) await Swal.fire({ icon: "success", title: "Informações salvas com sucesso!" });
  };

  const getDepartamentos = async () => {
    const { ok, departamentos, departamentosUsuario } = await WebService(`/departamento/getDepartamentos`);
    if (ok) {
      const tempDepartamentos = [];
      const getChild = (d, n) => {
        d.map(prop => {
          const { nome, id_departamento, subDepartamentos } = prop;
          if (subDepartamentos) getChild(subDepartamentos, `${n ? n + ' -> ' : ''}${nome}`);
          else tempDepartamentos.push({ nome: `${n ? n + ' -> ' : ''}${nome}`, id_departamento });
        });
      };
      getChild(departamentos);
      setDepartamentos(tempDepartamentos);
    }
  };

  const getUsuarios = async () => {
    const { ok, usuarios } = await WebService(`/usuario/getUsuarios`);
    if (ok) setUsuarios(usuarios);
  };

  const getHorario = async () => {
    const {
      ok,
      config_ddd_padrao,
      config_id_departamento_fk_padrao,
      config_id_usuario_fk_padrao,
      config_mostrar_somente_saudacao,
      mensagem_saudacao
    } = await WebService(`/whatsapp_api/getMensagem`, { id_whatsapp_conexao });
    if (ok) {
      setDdd(config_ddd_padrao);
      setIdDepartamentoPadrao(config_id_departamento_fk_padrao);
      setIdUsuarioPadrao(config_id_usuario_fk_padrao);
      setBlock(config_mostrar_somente_saudacao);
      setMensagemSaudacao(mensagem_saudacao);
    }
  };

  const getNomeAtendente = async () => {
    const {
      ok,
      config_mostrar_nome_atendente
    } = await WebService(`/whatsapp_api/getNomeRemovido`, { id_whatsapp_conexao });
    if (ok) setExclui(!config_mostrar_nome_atendente);
  };

  const getConexoes = async () => {
    const { ok, whatsapp_conexao } = await WebService(`/whatsapp_api/getConexoes`);
    if (ok) {
      setConecta(whatsapp_conexao[key]);
      setStatuss(whatsapp_conexao[key].status);
    }
  };

  const bloqueia = async () => {
    if (!block) {
      const { value } = await Swal.fire({
        icon: "question",
        title: 'Tem certeza de que deseja habilitar?',
        html: 'Ao habilitar essa função, a Mensagem de Saudação definida abaixo será a <b>única</b> mensagem que o cliente receberá nesta conexão como resposta e ele <b>não</b> será redirecionado para nenhum departamento.',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sim'
      });
      if (!value) return;
    }

    setBlock(!block);
  }

  useEffect(() => {
    getHorario();
    getDepartamentos();
    getUsuarios();
    getConexoes();
    getNomeAtendente();
  }, []);

  return (
    <div>
      <Card>
        <CardHeader color={tipoConta[tipo]} stats icon>
          <CardIcon color={tipoConta[tipo]} style={{ width: 85, height: 85 }}>
            <i className={`fab fa-whatsapp`}/>
          </CardIcon>
          <div style={{ float: 'left' }}>
            <h4 className={classes.cardIconTitle} style={{ float: 'left', paddingTop: '10px', marginRight: '90%' }}>
              Configuração da conexão <br/>
            </h4>
            <p style={{ float: 'left', fontSize: '14px', color: 'grey', clear: 'both' }}>
              Nesta seção são exibidas as configurações padronizadas para a Conexão selecionada
            </p>
          </div>
          <p style={{ float: 'right', color: '#4f4f4f', fontSize: '17px', paddingTop: '10px' }}>
            {conecta.conta_nome}
            <p style={{ fontSize: '13px', color: 'grey' }}>{new AsYouType().input(`+${conecta.conta_numero}`)}<br/>
              {nomeProprio(conecta.conta_marca_celular)}</p>
            <p style={{ fontSize: '13px', color: 'grey' }}>
              {conecta.flag_ativo && !statuss.desconectado ? (
                statuss.outraAba ?
                  <Warning>WhatsApp aberto em outro local</Warning> : (
                    statuss.celularDesconectadoInternet ?
                      <Warning>Celular desconectado da internet</Warning> : <Success>Ativo</Success>
                  )
              ) : <Danger>Desconectado</Danger>}
            </p>
          </p>
        </CardHeader>
        <CardBody>
          <GridContainer lg={24} md={24} sm={24} xs={24} style={{marginTop: '-5px'}}>
            <GridItem lg={3} md={6} sm={6} xs={24}>
              <TextField
                disabled={block}
                label="DDD Padrão"
                type="number"
                value={ddd}
                fullWidth
                onChange={({ target }) => setDdd(target.value)}
              />
            </GridItem>
            <GridItem lg={4} md={6} sm={6} xs={24}>
              <FormControlLabel
                style={{ fontSize: '10px', color: '#8c8c8c' }}
                label={
                  <Typography style={{ fontSize: '14px', float: 'left' }}>
                    Remover nome do atendente ao enviar
                    mensagem
                  </Typography>
                }
                control={
                  <Switch
                    disabled={block}
                    checked={exclui}
                    onChange={event => setExclui(event.target.checked)}
                    onClick={removerNome}
                    value="exclui"
                    classes={{
                      switchBase: classes.switchBase,
                      checked: classes.switchChecked,
                      thumb: classes.switchIcon,
                      track: classes.switchBar
                    }}
                  />
                }
              />
            </GridItem>
            <GridItem lg={5} md={5} sm={5} xs={24} style={{ marginTop: '-20px' }}>
              <GridItem lg={12} md={12} sm={12} xs={12}>
                <FormControlLabel
                  style={{ fontSize: '10px', color: '#8c8c8c' }}
                  label={
                    <Typography style={{ fontSize: '15px' }}>
                      Enviar mensagem de saudação
                      <p style={{ fontSize: '11px' }}>
                        Ao ativar essa função o cliente não será redirecionado para nenhum
                        departamento e só receberá a mensagem definida a seguir:
                      </p>
                    </Typography>
                  }
                  control={
                    <Switch
                      checked={block}
                      onClick={bloqueia}
                      classes={{
                        switchBase: classes.switchBase,
                        checked: classes.switchChecked,
                        thumb: classes.switchIcon,
                        track: classes.switchBar
                      }}
                    />
                  }
                />
              </GridItem>
              <GridItem lg={12} md={12} sm={12} xs={12} style={{ display: 'block' }}>
                <p style={{ fontSize: '14px', color: '#8c8c8c' }}>Mensagem de saudação</p>
                <textarea
                  rows="7" cols="2"
                  style={{ width: '35%', border: '2px #eeeeee solid', position: 'absolute' }}
                  disabled={!block}
                  value={mensagemSaudacao}
                  onChange={({ target: { value } }) => setMensagemSaudacao(value)}
                />
              </GridItem>
            </GridItem>
            <GridItem lg={6} md={6} sm={6} xs={24}>
              <hr/>
            </GridItem>
            <GridItem lg={12} md={12} sm={12} xs={24}>
              <GridContainer>
                <GridItem lg={3} md={3} sm={3} xs={12}>
                  <FormControl className={classes.formControl} style={{ float: 'left', display: 'inline' }} fullWidth
                               required disabled={block}>
                    <p style={{ fontSize: '16px', color: '#9DA19F' }}>Departamento padrão </p>
                    <p style={{ fontSize: '11.5px', color: '#CDD1CE' }}>
                      Departamento padrão para qual o cliente será direcionado ao entrar em contato com esta conexão
                    </p>
                    <Select
                      value={idDepartamentoPadrao}
                      onChange={({ target: { value } }) => setIdDepartamentoPadrao(value)}
                      style={{ textAlign: 'left', width: '30vh' }}
                    >
                      <MenuItem value={""}>Nenhum</MenuItem>
                      {departamentos.map(prop => {
                        const { nome, id_departamento } = prop;
                        return (<MenuItem value={id_departamento} key={`dpt_${id_departamento}`}>{nome}</MenuItem>);
                      })}
                    </Select>
                  </FormControl>
                </GridItem>
                <p>&nbsp;</p>
                {!!idDepartamentoPadrao && (
                  <GridItem lg={3} md={3} sm={3} xs={12}>
                    <FormControl className={classes.formControl} fullWidth required disabled={block}>
                      <p style={{ fontSize: '16px', color: '#9DA19F' }}>Usuário padrão </p>
                      <p style={{fontSize: '11.5px', color: '#CDD1CE'}}>
                        Usuário padrão que atenderá o cliente <br/> que entrar em contato com esta conexão
                      </p>
                      <Select
                        value={idUsuarioPadrao}
                        onChange={({target: {value}}) => setIdUsuarioPadrao(value)}
                        style={{textAlign: 'left'}}
                      >
                        <MenuItem value={""}>Nenhum</MenuItem>
                        {usuarios.filter(el => el.id_departamento_fk && el.id_departamento_fk.split(",").map(prop => parseInt(prop)).indexOf(idDepartamentoPadrao) > -1).map(prop => {
                          const {nome, id_usuario} = prop;
                          return (<MenuItem value={id_usuario} key={`usr_${id_usuario}`}>{nome}</MenuItem>);
                        })}
                      </Select>
                    </FormControl>
                  </GridItem>
                )}
              </GridContainer>
            </GridItem>
          </GridContainer>
          <br/>
          <div style={{ marginTop: '5%' }}>
            <Link to="/conexoes">
              <Button color={"grey"}> Voltar </Button>
            </Link>
            <Button color={"primary"} onClick={salvarHorario.bind(this)} style={{float: 'right'}}>
              Salvar
            </Button>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}
