import React, {useState, useEffect, useRef} from "react";
import InputMask from 'react-input-mask';

import {WebService, configHost} from "../../../components/WebService";
import * as Swal from "sweetalert2";

//components theme
import {
  GridContainer,
  GridItem,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardIcon,
  CustomInput,
  CustomButtons
} from "../../../components";
import PictureUpload from "../../../components/CustomUpload/PictureUpload.js";
import {getFotoLink, nomeProprio, alterarSenhaUsuario} from "../../../components/Funcoes";

//@material-ui
import {makeStyles} from "@material-ui/core/styles";
import {FormControl, FormControlLabel, InputLabel, Select, MenuItem, Checkbox, Button} from '@material-ui/core';
import {PersonAdd, Check} from '@material-ui/icons';

import {customUseStyles} from "./styles";
import styles from "../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

const useStyles = makeStyles(styles);

function Form(props) {
  const classes = {...customUseStyles(), ...useStyles()};

  const [idEmpresa, setIdEmpresa] = useState("");
  const [idUsuario, setIdUsuario] = useState("");
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [sexo, setSexo] = useState("");
  const [fotoPerfil, setFotoPerfil] = useState("");

  const [flagGestor, setFlagGestor] = useState(false);

  const [departamento, setDepartamento] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);

  const getDepartamentos = async () => {
    const {ok, departamentos} = await WebService(`/departamento/getDepartamentos`);
    if (ok) {
      const tempDepartamentos = [];
      const getChild = (d, n) => {
        d.map(prop => {
          const {nome, id_departamento, subDepartamentos} = prop;
          if (subDepartamentos) getChild(subDepartamentos, `${n ? n + ' -> ' : ''}${nome}`);
          else tempDepartamentos.push({nome: `${n ? n + ' -> ' : ''}${nome}`, id_departamento});
        });
      };
      getChild(departamentos);
      const ordemAlfabetica = [...tempDepartamentos].sort((a, b) => a.nome.localeCompare(b.nome));
      setDepartamentos(ordemAlfabetica);
    }
  };

  const getUsuario = async id_usuario => {
    if (id_usuario) {
      const {ok, usuario} = await WebService(`/usuario/getUsuarioID`, {id_usuario});
      if (ok) {
        const {id_usuario, nome, email, foto_perfil, telefone, id_departamento_fk, flag_gestor, sexo} = usuario;

        setIdUsuario(id_usuario);
        setTelefone(telefone);
        setDepartamento(id_departamento_fk ? id_departamento_fk.split(",").map(prop => parseInt(prop)) : []);
        setNome(nome);
        setEmail(email);
        setFotoPerfil(foto_perfil);
        setFlagGestor(flag_gestor);
        setSexo(sexo);
      }
    }
  };

  const atualizaPerfilUsuario = async (outros = {}) => {
    const usuario = {
      id_usuario: idUsuario,
      nome,
      email,
      telefone,
      foto_perfil: fotoPerfil,
      id_cliente: idEmpresa,
      id_departamento: departamento ? departamento.filter(el => departamentos.map(prop => prop['id_departamento']).indexOf(el) > -1) : null,
      flag_gestor: flagGestor,
      sexo,
      ...outros
    };
    const {ok, id_usuario} = await WebService(`/usuario/atualizaPerfilUsuario`, usuario, "POST");
    if (ok) {
      if (id_usuario) {
        setIdUsuario(id_usuario);
        await getUsuario(id_usuario);
      }

      await Swal.fire({
        title: 'Ação realizada!',
        text: `Usuário ${idUsuario ? 'atualizado' : 'cadastrado'} com sucesso!`,
        icon: 'success',
        showConfirmButton: false,
        timer: 2500
      });

      await props.hideModal();
      await props.getUsers();

    }
  };

  const alterarSenha = async () => {
    await alterarSenhaUsuario({id_usuario: idUsuario});
  };

  useEffect(() => {
    getUsuario(props.usuario);
    getDepartamentos();
  }, []);

  return (
    <>
      <Card className={classes.cardUser}>
        <CardHeader color="info" icon>
          <CardIcon color="info">
            <PersonAdd/>
          </CardIcon>
          <h4 className={classes.cardIconTitle}>{idUsuario ? 'Atualizar' : 'Cadastrar novo'} usuário do sistema</h4>
        </CardHeader>
        <CardBody>
          <form>
            <GridContainer>
              <GridItem xs={6}>
                <CustomInput
                  labelText="Nome"
                  inputProps={{onChange: ({target}) => setNome(nomeProprio(target.value)), value: nome}}
                  formControlProps={{fullWidth: true}}
                  autoFocus={true}
                />
              </GridItem>
              <GridItem xs={6}>
                <InputMask mask="(99) 9 9999-9999" maskChar={null} value={telefone}>
                  {(props) => {
                    console.log(props);
                    return (<CustomInput
                      labelText="Telefone"
                      formControlProps={{fullWidth: true}}
                      inputProps={{
                        // shrink: telefone && true,
                        onChange: ({target}) => setTelefone(target.value)
                      }}
                    />)
                  }}
                </InputMask>
              </GridItem>
              <GridItem xs={6}>
                <CustomInput
                  labelText="E-mail"
                  formControlProps={{fullWidth: true}}
                  inputProps={{value: email, onChange: ({target}) => setEmail(target.value.toLowerCase())}}
                />
                <FormControl fullWidth={true} className={classes.selectFormControl}>
                  <InputLabel>Sexo</InputLabel>
                  <Select
                    value={sexo}
                    onChange={({target: {value}}) => setSexo(value)}
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                  >
                    <MenuItem
                      value={"M"}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}>
                      Masculino
                    </MenuItem>
                    <MenuItem
                      value={"F"}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                    >
                      Feminino
                    </MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth={true} className={classes.selectFormControl}>
                  <InputLabel>Departamento</InputLabel>
                  <Select
                    multiple
                    value={Array.isArray(departamento) ? departamento : [departamento]}
                    onChange={({target: {value}}) => setDepartamento(value)}
                    MenuProps={{className: classes.selectMenu}}
                    classes={{select: classes.select}}
                  >
                    {departamentos.length ? departamentos.map(prop => {
                      const {nome, id_departamento} = prop;
                      return (
                        <MenuItem
                          key={id_departamento}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelectedMultiple
                          }}
                          value={id_departamento}
                        >
                          {nome}
                        </MenuItem>
                      )
                    }) : <MenuItem disabled>Nenhum departamento</MenuItem>}
                  </Select>
                </FormControl>

              </GridItem>
              <GridItem xs={6} className={classes.imageUpload}>
                <div style={{height: '14px'}}></div>
                <InputLabel>Foto de Perfil</InputLabel>
                <center>
                  <PictureUpload
                    recortar
                    accept={"image/*"}
                    preview={getFotoLink(fotoPerfil)}
                    onUpload={async imagem => {
                      const {ok, nomeArquivo} = await WebService(`/usuario/alterarFotoPerfil`, {
                        imagem,
                        id_usuario: idUsuario
                      }, "POST");
                      if (ok) {
                        localStorage.setItem("usuario_foto", `${configHost.baseURL}/usuario/getFotoPerfil?foto_perfil=${nomeArquivo}`);
                        setFotoPerfil(nomeArquivo);
                        // await Swal.fire({icon: "success", title: `Foto do perfil atualizada com sucesso!`});
                      }
                    }}
                  />
                </center>
                <FormControl>
                  <div style={{height: '12px'}}></div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        checked={flagGestor}
                        onChange={() => {
                          setFlagGestor(!flagGestor);
                          //atualizaPerfilUsuario({ flag_gestor: !flagGestor })
                        }}
                        icon={<Check className={classes.uncheckedIcon}/>}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    label="Gestor do Sistema"
                  />
                </FormControl>
              </GridItem>
            </GridContainer>
          </form>
        </CardBody>
        <CardFooter style={{justifyContent: `${idUsuario}` ? 'space-between' : 'flex-end'}} stats>
          {!idEmpresa && idUsuario && (
            <CustomButtons color="facebook" onClick={alterarSenha.bind(this)}>
              Alterar Senha
            </CustomButtons>
          )}
          <CustomButtons onClick={() => atualizaPerfilUsuario()} color="info">
            {idUsuario ? 'Atualizar' : 'Cadastrar'}
          </CustomButtons>
        </CardFooter>
      </Card>
    </>
  );
}

export default Form;
