import React, { useState, useEffect, useRef } from "react";
import InputMask from 'react-input-mask';

import { WebService, configHost } from "../../../components/WebService";
import * as Swal from "sweetalert2";

//components theme
import { GridContainer, GridItem, Card, CardHeader, CardBody, CardFooter, CardIcon, CustomInput, CustomButtons } from "../../../components";
import PictureUpload from "../../../components/CustomUpload/PictureUpload.js";
import { getFotoLink, nomeProprio, alterarSenhaUsuario } from "../../../components/Funcoes";

//@material-ui
import { makeStyles } from "@material-ui/core/styles";
import { PersonAdd, Check, Info, TrendingUpTwoTone } from '@material-ui/icons';

import { customUseStyles } from "./styles";
import styles from "../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
const useStyles = makeStyles(styles);

function FormContatos(props) {
  const classes = { ...customUseStyles(), ...useStyles() };

  const [idEmpresa, setIdEmpresa] = useState("");
  const [novoContato, setNovoContato] = useState({
    id_whatsapp: "",
    id_contato: "",
    telefone: "",
    nome: "",
    fotoPerfil: "",
  });

  const adicionarNovoContato = async cadastrar => {
    if (cadastrar) {
      const { telefone, nome, fotoPerfil, id_contato, id_whatsapp } = novoContato;
      const { ok } = await WebService(`/contato/cadastrarContato`, { id_contato, nome }, "POST");
      if (ok) {
        await Swal.fire({
          title: 'Ação realizada!',
          text: `Contato adicionado com sucesso!`,
          icon: 'success',
          showConfirmButton: false,
          timer: 2500
        });
        props.modalAdicionarContato(false);
      }
    } else {
      setNovoContato({ ...novoContato, nome: "", fotoPerfil: "", id_contato: "", id_whatsapp: "" });
      const { ok, contato } = await WebService(`/contato/getContatoInfo`, { numero: novoContato.telefone });
      if (ok) {
        const { foto_perfil, id_whatsapp, id_contato, nome } = contato;
        setNovoContato({
          ...novoContato,
          id_whatsapp,
          fotoPerfil: foto_perfil,
          id_contato,
          nome,
          telefone: id_whatsapp.split("@")[0]
        });
      }
    }
  };

  const editContato = async (id_contato) => {
    const { ok, contato } = await WebService(`/contato/getContatoInfo`, { numero: id_contato });
    if (ok) {
      const { foto_perfil, id_whatsapp, id_contato, nome } = contato;
      setNovoContato({
        ...novoContato,
        id_whatsapp,
        fotoPerfil: foto_perfil,
        id_contato,
        nome,
        telefone: id_whatsapp.split("@")[0]
      });
    }
  };

  useEffect(() => {
    if (props.id_contato) {
      editContato(props.id_contato);
    }
  }, []);

  return (
    <>
      <Card className={classes.cardUser}>
        <CardHeader color="info" icon>
          <CardIcon color="info">
            <PersonAdd />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>Adicionar Contato</h4>
        </CardHeader>
        <form>
          <CardBody>
            <GridContainer>
              <GridItem xs={12}>
                <p className={classes.cardIconSubtitle}><Info /> Insira o número do contato e clique
                      em <strong> Buscar</strong></p>
              </GridItem>
              <GridItem xs={6}>
                <CustomInput
                  labelText="Telefone"
                  autoFocus={true}
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    value: novoContato.telefone,
                    onChange: ({ target: { value } }) => {
                      setNovoContato({ telefone: value, nome: "", fotoPerfil: "", id_contato: "", id_whatsapp: "" });
                    },
                    id: 'dado-telefone',
                  }}
                />
                <CustomInput
                  labelText="Nome"
                  formControlProps={{ fullWidth: true }}
                  autoFocus={novoContato.id_whatsapp && true}
                  inputProps={{
                    disabled: !novoContato.id_whatsapp,
                    value: novoContato.nome,
                    onChange: ({ target: { value } }) => setNovoContato({ ...novoContato, nome: value })
                  }}
                />
              </GridItem>
              <GridItem xs={6} className={classes.imageUpload}>
                <center>
                  <img
                    alt={"foto-perfil"}
                    src={getFotoLink(novoContato.fotoPerfil)}
                    style={{
                      height: 100,
                      widt: 100,
                      transition: '.1s',
                      borderRadius: 15,
                      // border: `2px solid ${infoColor[0]}`,
                      // opacity: !!novoContato.id_whatsapp ? 1 : 0
                    }}
                  />
                </center>
              </GridItem>
            </GridContainer>
          </CardBody>
          <CardFooter className={classes.cardFooter} stats>
            {novoContato.id_whatsapp ?
              <CustomButtons onClick={() => setNovoContato({
                telefone: "",
                nome: "",
                fotoPerfil: "",
                id_contato: "",
                id_whatsapp: ""
              })}>Limpar</CustomButtons>
              :
              <CustomButtons onClick={() => props.modalAdicionarContato(false)}>Cancelar</CustomButtons>
            }
            {novoContato.id_whatsapp ?
              <CustomButtons color="info" onClick={() => adicionarNovoContato(true)}>Adicionar</CustomButtons>
              :
              <CustomButtons color="warning" onClick={() => adicionarNovoContato(false)}>Buscar</CustomButtons>
            }
          </CardFooter>
        </form>
      </Card>
    </>
  );
}

export default FormContatos;