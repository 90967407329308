import React from 'react';
import { makeStyles, withStyles, fade } from '@material-ui/core/styles';

import { InputBase } from '@material-ui/core';
import {primaryColor} from "../../../assets/jss/material-dashboard-pro-react";

const drawerWidth = 336;

export const useStyles = makeStyles(theme => ({

  boxInfoTitle: {
    
    padding: '2px 4px',
    marginTop: '2px',
    textTransform: 'uppercase',

    '& h4': {
      fontSize: '14px',
      color: primaryColor[3],
      fontWeight: 'bold',
    },

    '& .lineTop': {
      margin: 3,
      color: primaryColor[3],
      border: '1px solid ' + primaryColor[3],
    }
  },
  
  rootGrid: {
    position: 'absolute', 
    top: '60px',
    overflowX: 'hidden',
  },
  brand: {
    position: 'absolute',
    bottom: 10,
    left: '40%', 
    fontSize: '9px'
  },
  marca: {
    color: primaryColor[0], fontSize: '15px'
  },

  // ==================== Perfil =====================

  form: {
    margin: '0 20px 10px 20px',

    '& .label': {
      color: primaryColor[3],
      fontFamily: 'Roboto, sans-serif',
      marginBottom: '3px',
    },

    '& .formInfo': {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      height: '40px',
      borderRadius: '10px',
      border: '1px solid ' + primaryColor[3],
      boxShadow: 'none',

      '&:focus': {
        boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      }
    },

    '& .inputFormInfo': {
      marginLeft: theme.spacing(1),
      color: '#2d393b',
      flex: 1,
      fontSize: 15
    },

    '& .iconButtonInfo': {
      color: primaryColor[3],
      padding: 10,
      
    },

    '.& dividerInfo': {
      height: 28,
      margin: 4,
    },
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paperModal: {
    backgroundColor: '#ffffff',
    border: '2px solid ' + primaryColor[3],
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),

    '& .button': {
      padding: '2px 4px',
      margin: '10px',
      display: 'flex',
      justifyContent: 'flex-end'
    }
  },

  // =================== Histórico =====================
fix: {
  position: 'fixed',
},
  boxHistoryMain: {
    display: 'flex',
    
    justifyContent: 'space-around',
    // alignItems: 'center',
  },

  paperHistoryMain: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: 'none',
    textTransform: 'uppercase',
    
    '& h3': {
      color: primaryColor[3],
      letterSpacing: 0,
      marginTop: '-3px',
      fontSize: '18px'
    },

    '& p': {
      color: primaryColor[3],
      marginTop: '-10px',
      font: 'Regular 36px/43px Roboto'
    }
  },

  h: {
    overflow: 'auto',
    height: 'auto'
  },

  boxRegister: {
    padding: '2px 2px',
    margin: '12px 16px',
    
    display: 'flex',
    justifyContent: 'space-between',
  },
  
  paperRegister: {
    // display: 'flex',
    marginTop: '-15px',
    // marginBottom: '-10px',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    color: '#4f4f4f',

    transition: '.2s',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)'
    },

    '& div': {
      alignItems: 'baseline',
      // alignContent: 'flex-start',
    },


    '& p': {
      fontSize: '13px',
      marginBottom: 0,
    },

    '& iconButton': {
      padding: 0,

    },
    '& h4': {
      marginTop: '-2px',
      fontSize: '13px',
      marginBottom: 0,
      color: primaryColor[3]
    },

    '& svg': {
      color: '#4f4f4f',
    },
  },

  a:{
      width: '30px'
  },

  /*iconButton: {
    fontSize: '20px',
    cursor: 'pointer'
  },*/

  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },

  // ================= Observações =====================

  list: {
    width: '100%',
  },

  paperList: {
    boxShadow: 'none',
    paddingBottom: 50,
  },

  appBar: {
    top: 'auto',
    bottom: 0,
    width: drawerWidth,
    background: primaryColor[3],
  },

  containerInput: {
    padding: '10px',
  },

  chatInputMessage: {
    // padding: 2,
    width: '100%',
    backgroundColor: '#FFF',
    // height: 70,
    maxHeight: 80,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 10,
  },

  input: {
    border: 'none',
    margin: theme.spacing(1),
    width: '100%',
    height: 70,
    maxHeight: 80,
    resize: 'none',
    overflow: 'auto !important',
    fontFamily: '"Roboto", sans-serif',
    '&::-webkit-scrollbar-track': {
      background: 'none',
      borderRadius: 10
    },
    '&::-webkit-scrollbar': {
      width: 5,
      borderRadius: 10,
      background: 'none'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 10,
      // backgroundImage: `linear-gradient(60deg, ${primaryColor[1]}, ${primaryColor[2]})`
    }
  },

  iconButton: {
    padding: 5,

    '& svg': {
      color: primaryColor[3]
    },
  },

  dividerForm: {
    height: 28,
    margin: 4,
  },

  //Search bar com hover

  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    color:primaryColor[3],
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    
  },
  app: {
    background: 'transparent',
    color: '#4f4f4f',
    boxShadow: 'none'
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    
    fontSize: '16px',
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    
    [theme.breakpoints.up('sm')]: {
      
      width: '0ch',
      backgroundPosition: 'left top',
      position:'absolute',
      top:'-20px',
      right:'-210px',
      
      '&:focus': {
        width: '20ch',
      },
    },
  },

}));

export const SelectInput = withStyles((theme) => ({
  root: {
    // 'label + &': {
    //   marginTop: theme.spacing(3),
    // },
    width: '100%'
  },
  input: {
    borderRadius: 10,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid ' + primaryColor[3],
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 10,
      borderColor: primaryColor[4],
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);
