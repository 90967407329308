import React, {useState, useEffect} from "react";
import {WebService, configHost} from "../../../components/WebService";
//components theme
import { GridContainer, GridItem, Card, CardHeader, CardBody, CardFooter, CardIcon, CustomInput, CustomButtons } from "../../../components";

//@material-ui
import { IconButton, FormControl, Input, InputLabel, Select, MenuItem, Checkbox, ListItemText } from '@material-ui/core';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { PostAdd, Close, Edit } from '@material-ui/icons';
import TextField from '@material-ui/core/TextField';
import { useStyles, CssTextField } from "./styles";
import * as Swal from "sweetalert2";
import {getFotoLink} from "../../../components/Funcoes";
import PictureUpload from "../../../components/CustomUpload/PictureUpload";

function Form(props) {
  const classes = useStyles();
  const [dept, setDept] = React.useState([]);
  const [tag, setTag] = React.useState([]);
  const [departamentos, setDepartamentos] = React.useState([]);
  const [departamento, setDepartamento] = React.useState([]);
  const [idResposta, setIdResposta] = React.useState('');
  const [idEmpresa, setIdEmpresa] = React.useState('');
  const [fileID, setFileID] = React.useState('');
  const [texto, setTexto] = React.useState('');

  const getDepartamentos = async () => {
    const {ok, departamentos} = await WebService(`/departamento/getDepartamentos`);
    if (ok) {
      const tempDepartamentos = [];
      const getChild = (d, n) => {
        d.map(prop => {
          const {nome, id_departamento, subDepartamentos} = prop;
          if (subDepartamentos) getChild(subDepartamentos, `${n ? n + ' -> ' : ''}${nome}`);
          else tempDepartamentos.push({nome: `${n ? n + ' -> ' : ''}${nome}`, id_departamento});
        });
      };
      getChild(departamentos);
      const ordemAlfabetica = [...tempDepartamentos].sort((a, b) => a.nome.localeCompare(b.nome));
      setDepartamentos(ordemAlfabetica);
    }
  };

  const getResposta = async id_mensagem_predefinida => {
    if (id_mensagem_predefinida) {
      const {ok, mensagemPredefinida} = await WebService(`/mensagem/getMensagemID`, {id_mensagem_predefinida});
      if (ok) {
        const {id_mensagem_predefinida, mensagem, id_departamento_fk, chave, file} = mensagemPredefinida;
        setTexto(mensagem);
        setIdResposta(id_mensagem_predefinida);
        setDepartamento(id_departamento_fk ? id_departamento_fk.split(",").map(prop => parseInt(prop)) : []);
        setTag(chave);
        if (file) setFileID(file.id_arquivo_midia)
      }
    }
  };

  const atualizaMensagem = async (outros = {}) => {
    const resposta = {
      id_mensagem_predefinida: idResposta,
      mensagem: (texto || '').trim(),
      chave: tag,
      id_cliente: idEmpresa,
      id_departamento: departamento ? departamento.filter(el => departamentos.map(prop => prop['id_departamento']).indexOf(el) > -1) : null,
      fileID,
      ...outros
    };
    const {ok, id_mensagem_predefinida} = await WebService(`/mensagem/atualizaMensagemPredefinida`, resposta, "POST");
    if (ok) {
      if (id_mensagem_predefinida) {
        setIdResposta(id_mensagem_predefinida);
        await getResposta(id_mensagem_predefinida);
      }

      await Swal.fire({
        title: 'Ação realizada!',
        text: `Resposta ${idResposta ? 'atualizada' : 'cadastrada'} com sucesso!`,
        icon: 'success',
        showConfirmButton: false,
        timer: 2500
      });
      await props.hideModal();
      await props.getRespostasRapidas();
    }
    
  };

  useEffect(() => {
    getResposta(props.resposta);
    getDepartamentos();
  }, []);

  return (
    <>
      <IconButton aria-label="close" className={classes.closeButton} onClick={props.handleModal}>
        <Close />
      </IconButton>
      <Card className={classes.cardModal}>
        <CardHeader color="primary" icon>
          <CardIcon color="primary">
            {props.resposta ? <Edit /> : <PostAdd />}
          </CardIcon>
          <h4 className={classes.cardIconTitle} style={{color: '#4f4f4f'}}>{props.resposta ? 'Editar' : 'Criar nova '} Resposta Rápida</h4>
        </CardHeader>
        <CardBody>
          <form>
            <GridContainer>
              <GridItem xs={6}>
                <FormControl fullWidth={true} className={classes.selectFormControl}>
                  <InputLabel>Departamento</InputLabel>
                  <Select
                    multiple
                    value={Array.isArray(departamento) ? departamento : [departamento]}
                    onChange={({target: {value}}) => setDepartamento(value)}
                    MenuProps={{className: classes.selectMenu}}
                    classes={{select: classes.select}}
                  >
                    {departamentos.length ? departamentos.map(prop => {
                      const {nome, id_departamento} = prop;
                      return (
                        <MenuItem
                          key={id_departamento}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelectedMultiple
                          }}
                          value={id_departamento}
                        >
                          {nome}
                        </MenuItem>
                      )
                    }) : <MenuItem disabled>Nenhum departamento</MenuItem>}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={6}>
                <FormControl className={classes.formControl}>
                <TextField id="standard-basic" label="Tag" value={tag} onChange={({target: {value}}) => setTag(value)}/>
                 
                </FormControl>
              </GridItem>
              {idResposta ? (
                <GridItem xs={12}>
                  <PictureUpload
                    // recortar
                    fileUrl={fileID}
                    simple
                    accept={"image/*,video/*,application/pdf"}
                    onUpload={async (fileBase64, {name}) => {
                      const {ok, id_arquivo_midia, fullFileName} = await WebService(`/whatsapp_api/uploadMidia`, {
                        base64: fileBase64.split('base64,')[1],
                        nome_arquivo: name
                      }, "POST");
                      if (ok) setFileID(id_arquivo_midia);
                    }}
                  />
                </GridItem>
              ) : ''}
              <GridItem xs={12}>
                <hr/>
                {/* <CustomInput
                    labelText="Texto"
                    formControlProps={{
                      fullWidth: true
                    }}
                  /> */}
                <TextareaAutosize
                  maxRows={30}
                  minRows={5}
                  placeholder="Mensagem Rápida"
                  className={classes.inputText} value={texto}
                  style={{maxHeight: 500}}
                  onChange={({target: {value}}) => setTexto(value)}
                />
              </GridItem>
            </GridContainer>
          </form>
        </CardBody>
        <CardFooter className={classes.cardFooter} stats>
          <CustomButtons simple color="github" onClick={props.handleModal}>Cancelar</CustomButtons>
          <CustomButtons color="primary" onClick={() => atualizaMensagem()}>
          {idResposta ? 'Atualizar' : 'Cadastrar'}
          </CustomButtons>
        </CardFooter>
      </Card>
    </>
  );
}

export default Form;